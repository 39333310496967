import { TableFilterType } from 'features/TableFilter';

interface InsuranceListFilters {
  warehouseId?: string;
}

export const transformInsuranceListFilters = ({ selectedWarehouseId }: TableFilterType): InsuranceListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
  };
};
