import { api } from 'app/config/apiConfig';
import { Insurance } from '../model/types';

export const updateInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateInsurance: builder.mutation<Insurance, Partial<Insurance>>({
      query: (insurance) => ({
        url: `/insurance/${insurance.insuranceId}`,
        method: 'PUT',
        body: insurance,
      }),
      invalidatesTags: ['Insurance'],
    }),
  }),
});

export const { useUpdateInsuranceMutation } = updateInsuranceApi;
