export const ibanElementStyle = {
  base: {
    iconColor: '#000000',
    color: '#000000',
    fontWeight: '600',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#000000',
      backgroundColor: 'transparent',
    },
    '::placeholder': {
      color: '#788593',
      fontWeight: '400',
    },
  },
};
