import { InvoiceFrequencyType } from 'entities/Invoice';

type PriceRate = 'dailyRate' | 'weekRate' | 'monthRate';

export const getInsuranceRateField = (frequencyType: InvoiceFrequencyType): PriceRate => {
  const rates: Record<InvoiceFrequencyType, PriceRate> = {
    [InvoiceFrequencyType.DAY]: 'dailyRate',
    [InvoiceFrequencyType.WEEK]: 'weekRate',
    [InvoiceFrequencyType.MONTH]: 'monthRate',
  };

  return rates[frequencyType];
};
