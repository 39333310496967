import { api } from 'app/config/apiConfig';
import { Reservation } from '../model/types';

export const getReservationByIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReservationById: builder.query<Reservation, Nullable<string>>({
      query: (reservationId) => ({
        url: `/reservations/${reservationId}`,
      }),
      providesTags: (result) => ['Reservations'],
    }),
  }),
});

export const { useGetReservationByIdQuery } = getReservationByIdApi;
