import { Dropdown, MenuProps } from 'antd';
import React, { FC, memo } from 'react';
import { ReactComponent as ContextMenuDots } from 'shared/assets/icons/ContextMenuDots.svg';
import s from './index.module.scss';
import { NotificationPlacement } from 'antd/es/notification/interface';

interface ContextMenuProps {
  items: MenuProps['items'];
  placement?: NotificationPlacement;
}

export const ContextMenu: FC<ContextMenuProps> = memo((props) => {
  const { items = [], placement = 'bottomRight' } = props;

  return (
    <Dropdown overlayClassName={s.dropdownMenu} menu={{ items }} trigger={['click']} placement={placement}>
      <div className="cursor-pointer">
        <ContextMenuDots />
      </div>
    </Dropdown>
  );
});
