import { api } from 'app/config/apiConfig';
import { Note } from '../model/types';

export const deleteNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteNote: builder.mutation<Note, string>({
      query: (noteId: string) => ({
        url: `/notes/${noteId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notes'],
    }),
  }),
});

export const { useDeleteNoteMutation } = deleteNoteApi;
