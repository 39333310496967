import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract } from 'entities/Contract';
import { ContractsPageSchema } from '../types';

const initialState: ContractsPageSchema = {
  contractNoteDrawerData: null,
};

const contractsPageSlice = createSlice({
  name: 'contractsPage',
  initialState,
  reducers: {
    setOpenContractNoteDrawer: (state: ContractsPageSchema, action: PayloadAction<Nullable<Contract>>) => {
      state.contractNoteDrawerData = action.payload;
    },
  },
});

export const { actions: contractPageActions, reducer: contractPageReducer } = contractsPageSlice;
