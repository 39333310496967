import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useChangeUserDetailsMutation, ChangeUserDetailsParams } from 'entities/User';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { transformEmptyStringToNull } from 'shared/utils/helpers/transformEmptyStringToNull';

interface HookArgs {
  userId: string | undefined;
  updatedData: Nullable<ChangeUserDetailsParams>;
  onSuccess?: () => void;
}

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  handleChangeUserDetails: () => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useChangeUserDetails = ({ userId, updatedData, onSuccess }: HookArgs): HookApi => {
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [changeUserDetails, { isLoading }] = useChangeUserDetailsMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleChangeUserDetails = useCallback(async (): Promise<void> => {
    if (userId && updatedData) {
      try {
        const params = transformEmptyStringToNull(updatedData);

        if (updatedData.dateOfBirth) {
          params.dateOfBirth = getServerDateFormat(updatedData.dateOfBirth);
        }

        await changeUserDetails({
          userId,
          params,
        }).unwrap();
        showNotification('info', t('Success'), t('User has been successfully updated'));
        onSuccess?.();
      } catch (error) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when updating user'));
      } finally {
        closeConfirmModal();
      }
    }
  }, [userId, updatedData, changeUserDetails, t, onSuccess, closeConfirmModal]);

  return {
    openConfirmModal,
    closeConfirmModal,
    handleChangeUserDetails,
    isOpenedConfirm,
    isLoading,
  };
};
