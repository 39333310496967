import { api } from 'app/config/apiConfig';
import { CreateFranchiseParams, Franchise } from '../model/types';

export const createFranchiseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createFranchise: builder.mutation<Franchise, CreateFranchiseParams>({
      query: (args) => ({
        url: '/franchises',
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Franchises'],
    }),
  }),
});

export const { useCreateFranchiseMutation } = createFranchiseApi;
