import React, { FC, memo } from 'react';
import { Select as AntdSelect } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { DefaultOptionType } from 'rc-select/lib/Select';
import s from './index.module.scss';
import classNames from 'classnames';

interface SelectProps {
  options: DefaultOptionType[];
  onChange?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
  label?: string;
  placeholder?: string;
  size?: SizeType;
  value?: Nullable<string>;
  defaultValue?: Nullable<string>;
  className?: string;
  containerClassName?: string;
  bordered?: boolean;
  disabled?: boolean;
  requiredMark?: boolean;
  allowClear?: boolean;
  onClear?: () => void;
}

export const Select: FC<SelectProps> = memo((props) => {
  const {
    size = 'middle',
    options,
    placeholder,
    onChange,
    value,
    defaultValue,
    className,
    disabled,
    bordered = false,
    requiredMark,
    containerClassName,
    label,
    allowClear,
    onClear,
  } = props;

  return (
    <div className={classNames('relative', containerClassName)}>
      {label && <div className="mb-2">{label}</div>}
      {requiredMark && <div className="text-error text-2xl absolute z-10 top-0 left-1 pointer-events-none">*</div>}
      <AntdSelect
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={value || undefined}
        defaultValue={defaultValue}
        optionLabelProp="label"
        className={classNames(s.select, s[size], className)}
        variant={bordered ? 'outlined' : 'borderless'}
        disabled={disabled}
        allowClear={allowClear}
        onClear={onClear}
      />
    </div>
  );
});
