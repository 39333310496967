import { Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { LATITUDE_REGEX, LONGITUDE_REGEX, PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { createWarehouseModalActions } from '../../../model/slice/createWarehouseModalSlice';
import { ActiveStatusToggle } from './ActiveStatusToggle';
import { ComingSoonStatusToggle } from './ComingSoonStatusToggle';

export const WarehouseInfoForm: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const dispatch = useAppDispatch();

  const disableDate = useCallback((currentDate: Dayjs): boolean => {
    const tomorrow = dayjs().startOf('day').add(1, 'day');

    return currentDate.isBefore(tomorrow, 'day');
  }, []);

  const changeName = useCallback(
    (name: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ name }));
    },
    [dispatch],
  );

  const changeAddress = useCallback(
    (address: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ address }));
    },
    [dispatch],
  );

  const changeZipCode = useCallback(
    (zip: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ zip }));
    },
    [dispatch],
  );

  const changePhone = useCallback(
    (phone: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ phone }));
    },
    [dispatch],
  );

  const changeEmail = useCallback(
    (email: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ email }));
    },
    [dispatch],
  );

  const changeLongitude = useCallback(
    (longitude: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ longitude }));
    },
    [dispatch],
  );

  const changeLatitude = useCallback(
    (latitude: string) => {
      dispatch(createWarehouseModalActions.changeFormData({ latitude }));
    },
    [dispatch],
  );

  const changeLaunchDate = useCallback(
    (launchDate: Dayjs) => {
      dispatch(createWarehouseModalActions.changeFormData({ launchDate }));
    },
    [dispatch],
  );

  return (
    <div className="space-y-4 basis-7/12">
      <div className="font-semibold text-2xl">{t('Create Warehouse')}</div>
      <div className="flex flex-col">
        <Form.Item label={t('Name')} name="name" rules={[{ required: true, message: t('Please enter name') }]}>
          <Input placeholder={t('Enter name')} bordered onChange={changeName} />
        </Form.Item>
        <div className="flex space-x-3">
          <Form.Item
            className="basis-11/12"
            label={t('Address')}
            name="address"
            rules={[{ required: true, message: t('Please enter address') }]}
          >
            <Input placeholder={t('Enter address')} bordered onChange={changeAddress} />
          </Form.Item>
          <Form.Item label={t('Zip code')} name="zip" rules={[{ required: true, message: t('Please enter zip code') }]}>
            <Input placeholder={t('Enter zip code')} bordered onChange={changeZipCode} />
          </Form.Item>
        </div>
        <div className="flex space-x-3 [&>div]:flex-1">
          <Form.Item
            label={t('Phone')}
            name="phone"
            rules={[
              { required: true, message: t('Please enter phone') },
              { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number') },
            ]}
          >
            <PhoneInput placeholder={t('Enter phone number')} bordered onChange={changePhone} />
          </Form.Item>
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please enter email') },
              { type: 'email', message: t('Please enter correct email') },
            ]}
          >
            <Input placeholder={t('Enter email')} bordered onChange={changeEmail} />
          </Form.Item>
        </div>
        <div className="flex space-x-3 [&>div]:flex-1">
          <Form.Item
            label={t('Longitude')}
            name="longitude"
            rules={[
              { required: true, message: t('Please enter longitude') },
              { pattern: LONGITUDE_REGEX, message: t('Invalid longitude format') },
            ]}
          >
            <Input placeholder={t('Enter longitude')} bordered onChange={changeLongitude} />
          </Form.Item>
          <Form.Item
            label={t('Latitude')}
            name="latitude"
            rules={[
              { required: true, message: t('Please enter latitude') },
              { pattern: LATITUDE_REGEX, message: t('Invalid latitude format') },
            ]}
          >
            <Input placeholder={t('Enter latitude')} bordered onChange={changeLatitude} />
          </Form.Item>
        </div>
        <Form.Item label={t('Launch date')} name="launchDate" rules={[{ required: true, message: t('Please enter launch date') }]}>
          <DatePicker
            className="h-[52px] w-full"
            format={CLIENT_DATE_FORMAT}
            placeholder={t('Enter launch date')}
            inputReadOnly
            disabledDate={disableDate}
            defaultPickerValue={dayjs().startOf('day')}
            onChange={changeLaunchDate}
          />
        </Form.Item>
        <div className="flex space-x-7">
          <ActiveStatusToggle />
          <ComingSoonStatusToggle />
        </div>
      </div>
    </div>
  );
});
