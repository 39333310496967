import React, { FC, memo, useCallback } from 'react';
import { Collapse } from 'antd';
import { Contract } from 'entities/Contract';
import { useAppTranslation } from 'app/config/i18Config/hooks';

import s from './PaymentCard.module.scss';
import classNames from 'classnames';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useGetCurrencySymbol } from 'app/appState';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import { useAdminRole } from 'entities/User';
import { RefundDeposit } from 'features/RefundDeposit';
import { Button } from 'shared/ui/Button';

interface DepositTabProps {
  contract: Contract;
}

export const DepositTab: FC<DepositTabProps> = memo((props) => {
  const { contract } = props;
  const { deposit } = contract;

  const { t } = useAppTranslation('contracts');

  const currencySymbol = useGetCurrencySymbol();

  const isAdmin = useAdminRole();

  const DepositContent = useCallback((): JSX.Element => {
    return (
      <div>
        <BulletsTable
          theme="clear"
          rows={[
            {
              label: t('Deposit amount'),
              value: `${roundNumber(deposit?.initialDepositAmount)} ${currencySymbol}`,
            },
            {
              label: t('Current deposit amount'),
              value: `${roundNumber(deposit?.depositAmount)} ${currencySymbol}`,
            },
          ]}
        />

        {/* 
          TO DO: make deposit charges UI
        */}

        {isAdmin && deposit && !deposit?.isRefunded && contract.leaveDate && (
          <div className="flex flex-col gap-3 mt-6">
            <div className="flex flex-col gap-3">
              <RefundDeposit depositId={deposit?.depositId} action={<Button theme="danger">{t('Refund deposit')}</Button>} />
            </div>
          </div>
        )}
      </div>
    );
  }, [currencySymbol, deposit, contract.leaveDate, isAdmin, t]);

  return (
    <div>
      {deposit ? (
        <>
          <div className="space-y-3">
            <Collapse
              defaultActiveKey={deposit.depositId}
              className={classNames(s.collapse, {
                [s.refundedOrCredited]: deposit.isRefunded,
              })}
              items={[
                {
                  key: deposit.depositId,
                  label: (
                    <>
                      <div className="flex justify-between font-semibold text-lg">
                        {t('Deposit')} {deposit.isRefunded && `(${t('Refunded')})`}
                        {/* <div className="text-accent">
                        // TO DO: add deposit fetch in this component to get relation deposit -> invoiceItem -> invoice to get invoice documentNumber
                          {t('Invoice')} {deposit?.invoiceItem?.invoice?.documentNumber}
                        </div> */}
                      </div>
                    </>
                  ),
                  children: <DepositContent />,
                },
              ]}
              expandIcon={(panelProps) => (
                <CollapseArrow
                  className={classNames('ease-linear duration-200', {
                    '-rotate-90 fill-primaryLight': panelProps.isActive,
                    'rotate-90 fill-secondaryAccent': !panelProps.isActive,
                  })}
                />
              )}
              expandIconPosition="end"
            />
          </div>
        </>
      ) : (
        <div className="font-semibold text-lg pt-7 pb-4 text-center">{t('Not applied')}</div>
      )}
    </div>
  );
});
