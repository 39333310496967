import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalConfirmModalData } from '../../model/selectors/getGlobalConfirmModalData';
import { globalConfirmModalActions } from '../../model/slice/globalConfirmModalSlice';
import { SetModalDataPayload } from '../../model/types';

interface HookApi {
  isOpened: boolean;
  openModal: (data: SetModalDataPayload) => void;
  setModalLoading: (isLoading: boolean) => void;
  closeModal: () => void;
}

export const useGlobalConfirmModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const isOpened = Boolean(useAppSelector(getGlobalConfirmModalData));

  const openModal = useCallback(
    (data: SetModalDataPayload): void => {
      dispatch(globalConfirmModalActions.setGlobalConfirmModalData(data));
    },
    [dispatch],
  );

  const setModalLoading = useCallback(
    (isLoading: boolean): void => {
      dispatch(globalConfirmModalActions.setGlobalConfirmModalLoading(isLoading));
    },
    [dispatch],
  );

  const closeModal = useCallback((): void => {
    dispatch(globalConfirmModalActions.setGlobalConfirmModalData(null));
  }, [dispatch]);

  return {
    isOpened,
    openModal,
    setModalLoading,
    closeModal,
  };
};
