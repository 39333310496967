import React, { FC, memo } from 'react';
import { ModalMode } from 'app/types/common';
import { City } from 'entities/City';
import { Modal } from 'shared/ui/Modal';
import { CityForm } from './CityForm';

interface CreateCityModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: Nullable<ModalMode>;
  city?: Nullable<City>;
  width?: number;
}

export const CreateCityModal: FC<CreateCityModalProps> = memo((props) => {
  const { isOpen, city, mode, width, onClose } = props;

  return (
    <Modal width={width} isOpen={isOpen} onClose={onClose}>
      <CityForm city={city} mode={mode} onClose={onClose} />
    </Modal>
  );
});
