import React, { FC, memo, ReactNode } from 'react';
import { Divider } from 'antd';
import { ReactComponent as FailIcon } from 'shared/assets/icons/FailIcon.svg';
import { ReactComponent as FailScreenImage } from 'shared/assets/images/FailScreenImage.svg';

interface FailScreenProps {
  title?: string;
  description?: string;
  additionalInfo?: ReactNode;
  actions?: ReactNode;
  mode?: 'page' | 'modal';
}

export const FailScreen: FC<FailScreenProps> = memo((props) => {
  const { title, description, additionalInfo, actions, mode = 'page' } = props;

  const isModalMode = mode === 'modal';

  return (
    <>
      <div className={`flex flex-col items-center wideDesktop: ${!isModalMode && 'hidden'}`}>
        <FailScreenImage className="w-[222px] h-[251px]" />
        <div className="text-error font-semibold text-4xl text-center mb-4">{title}</div>
        <div className={`text-primaryLight font-semibold text-center mb-11 ${isModalMode ? 'text-lg' : 'text-2xl'}`}>{description}</div>
        <div className="w-full">{actions}</div>
      </div>
      <div className={`hidden wideDesktop: ${isModalMode ? 'hidden' : 'flex space-x-28 justify-center items-center'}`}>
        <div className="flex flex-col items-center shadow-xl shadow-secondaryAccent py-11 px-10 rounded-lg max-w-[440px] bg-white">
          <FailIcon className="mb-11" />
          <div className="text-error font-semibold text-4xl text-center mb-4">{title}</div>
          <div className="text-primaryLight font-semibold text-2xl text-center">{description}</div>
          <Divider className="border-secondaryAccent relative" dashed>
            <div className="bg-white shadow shadow-inner-xl shadow-secondaryLight w-10 h-10 rounded-full absolute -translate-y-1/2 -translate-x-1/2 -left-10" />
            <div className="bg-white shadow shadow-inner-xl-reversed shadow-secondaryLight w-10 h-10 rounded-full absolute -translate-y-1/2 -translate-x-1/2 -right-20" />
          </Divider>
          <div className="mb-11 w-full">{additionalInfo}</div>
          <div className="w-full">{actions}</div>
        </div>
        <div>
          <FailScreenImage />
        </div>
      </div>
    </>
  );
});
