import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GratitudeModalPayload, GratitudeModalSchema } from '../types';

const initialState: GratitudeModalSchema = {
  isOpened: false,
  description: null,
  buttonId: undefined,
  type: null,
  onSuccess: undefined,
};

const gratitudeModalSlice = createSlice({
  name: 'gratitudeModal',
  initialState,
  reducers: {
    openModal: (state: GratitudeModalSchema, action: PayloadAction<GratitudeModalPayload>) => {
      state.isOpened = true;
      state.description = action.payload.description;
      state.buttonId = action.payload.buttonId;
      state.type = action.payload.type;
      state.onSuccess = action.payload.onSuccess;
    },
    closeModal: (state: GratitudeModalSchema) => {
      state.isOpened = false;
      state.description = null;
      state.buttonId = undefined;
      state.type = null;
      state.onSuccess = undefined;
    },
  },
});

export const { actions: gratitudeModalActions, reducer: gratitudeModalReducer } = gratitudeModalSlice;
