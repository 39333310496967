import { api } from 'app/config/apiConfig';
import { CreditNote } from 'entities/CreditNote';

export const refundDepositApi = api.injectEndpoints({
  endpoints: (build) => ({
    refundDeposit: build.mutation<CreditNote, string>({
      query: (depositId) => ({
        url: `/billing-documents/deposits/${depositId}/refund`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useRefundDepositMutation } = refundDepositApi;
