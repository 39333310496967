import { api } from 'app/config/apiConfig';
import { CreateUserParams, User } from '../model/types';
import { USERS_PATH } from '../utils/constants';

export const createUserApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<User, CreateUserParams>({
      query: (params) => ({
        url: USERS_PATH,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useCreateUserMutation } = createUserApi;
