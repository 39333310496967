import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthFormMode, AuthFormSchema } from '../types';

const initialState: AuthFormSchema = {
  mode: 'login',
};

const authFormSlice = createSlice({
  name: 'authForm',
  initialState,
  reducers: {
    changeMode: (state: AuthFormSchema, action: PayloadAction<AuthFormMode>) => {
      state.mode = action.payload;
    },
  },
});

export const { actions: authFormActions, reducer: authFormReducer } = authFormSlice;
