import React, { ReactElement } from 'react';
import {
  AppealHistoryActionNames,
  BoxHistoryActionNames,
  ContractHistoryActionNames,
  HistoryActionName,
  LeadHistoryActionNames,
  ReservationHistoryActionNames,
  UserHistoryActionNames,
  WarehouseHistoryActionNames,
} from 'entities/History';
import { ReactComponent as AppealCreate } from 'shared/assets/icons/historyActionIcons/AppealCreate.svg';
import { ReactComponent as AppealCreationFail } from 'shared/assets/icons/historyActionIcons/AppealCreationFail.svg';
import { ReactComponent as BoxCreate } from 'shared/assets/icons/historyActionIcons/BoxCreate.svg';
import { ReactComponent as BoxOpenLongTime } from 'shared/assets/icons/historyActionIcons/BoxOpenLongTime.svg';
import { ReactComponent as BoxUnauthorizedAccess } from 'shared/assets/icons/historyActionIcons/BoxUnauthorizedAccess.svg';
import { ReactComponent as BoxUpdate } from 'shared/assets/icons/historyActionIcons/BoxUpdate.svg';
import { ReactComponent as BoxVerifyState } from 'shared/assets/icons/historyActionIcons/BoxVerifyState.svg';
import { ReactComponent as CloseBox } from 'shared/assets/icons/historyActionIcons/CloseBox.svg';
import { ReactComponent as ContractCreate } from 'shared/assets/icons/historyActionIcons/ContractCreate.svg';
import { ReactComponent as DoorOpenAttempt } from 'shared/assets/icons/historyActionIcons/DoorOpenAttempt.svg';
import { ReactComponent as LeadCreate } from 'shared/assets/icons/historyActionIcons/LeadCreate.svg';
import { ReactComponent as LeadCreationFail } from 'shared/assets/icons/historyActionIcons/LeadCreationFail.svg';
import { ReactComponent as LoginFail } from 'shared/assets/icons/historyActionIcons/LoginFail.svg';
import { ReactComponent as LoginSuccess } from 'shared/assets/icons/historyActionIcons/LoginSuccess.svg';
import { ReactComponent as NewRegistration } from 'shared/assets/icons/historyActionIcons/NewRegistration.svg';
import { ReactComponent as OpenBox } from 'shared/assets/icons/historyActionIcons/OpenBox.svg';
import { ReactComponent as OpenBoxAttempt } from 'shared/assets/icons/historyActionIcons/OpenBoxAttempt.svg';
import { ReactComponent as RegistrationFail } from 'shared/assets/icons/historyActionIcons/RegistrationFail.svg';
import { ReactComponent as ReservationCreate } from 'shared/assets/icons/historyActionIcons/ReservationCreate.svg';
import { ReactComponent as ReservationTakeUp } from 'shared/assets/icons/historyActionIcons/ReservationTakeUp.svg';
import { ReactComponent as ReserveBox } from 'shared/assets/icons/historyActionIcons/ReserveBox.svg';
import { ReactComponent as SirenTurnedOn } from 'shared/assets/icons/historyActionIcons/SirenTurnedOn.svg';
import { ReactComponent as UpdatePassword } from 'shared/assets/icons/historyActionIcons/UpdatePassword.svg';
import { ReactComponent as UpdateUser } from 'shared/assets/icons/historyActionIcons/UpdateUser.svg';
import { HistoryActionIcon } from '../../ui/LatestActivity/HistoryActionIcon';

export const actionIconMapper = (actionName: HistoryActionName): ReactElement => {
  const actionIconsMap: Record<HistoryActionName, ReactElement> = {
    [UserHistoryActionNames.login_success]: <HistoryActionIcon Icon={LoginSuccess} />,
    [UserHistoryActionNames.login_fail]: <HistoryActionIcon Icon={LoginFail} />,
    [UserHistoryActionNames.password_change]: <HistoryActionIcon Icon={UpdatePassword} />,
    [UserHistoryActionNames.new_registration]: <HistoryActionIcon Icon={NewRegistration} />,
    [UserHistoryActionNames.registration_fail]: <HistoryActionIcon Icon={RegistrationFail} />,
    [UserHistoryActionNames.user_updated]: <HistoryActionIcon Icon={UpdateUser} />,
    [BoxHistoryActionNames.box_open]: <HistoryActionIcon Icon={OpenBox} />,
    [BoxHistoryActionNames.box_close]: <HistoryActionIcon Icon={CloseBox} />,
    [BoxHistoryActionNames.box_open_attempt]: <HistoryActionIcon Icon={OpenBoxAttempt} />,
    [BoxHistoryActionNames.box_open_long_time]: <HistoryActionIcon Icon={BoxOpenLongTime} />,
    [BoxHistoryActionNames.box_unauthorized_access]: <HistoryActionIcon Icon={BoxUnauthorizedAccess} />,
    [BoxHistoryActionNames.box_verify_state]: <HistoryActionIcon Icon={BoxVerifyState} />,
    [BoxHistoryActionNames.box_update]: <HistoryActionIcon Icon={BoxUpdate} />,
    [BoxHistoryActionNames.box_create]: <HistoryActionIcon Icon={BoxCreate} />,
    [WarehouseHistoryActionNames.door_open_attempt]: <HistoryActionIcon Icon={DoorOpenAttempt} />,
    [WarehouseHistoryActionNames.siren_turned_on]: <HistoryActionIcon Icon={SirenTurnedOn} />,
    [ReservationHistoryActionNames.reservation_take_up]: <HistoryActionIcon Icon={ReservationTakeUp} />,
    [ReservationHistoryActionNames.reservation_create]: <HistoryActionIcon Icon={ReservationCreate} />,
    [ContractHistoryActionNames.contract_create]: <HistoryActionIcon Icon={ContractCreate} />,
    [AppealHistoryActionNames.appeal_create]: <HistoryActionIcon Icon={AppealCreate} />,
    [AppealHistoryActionNames.appeal_creation_fail]: <HistoryActionIcon Icon={AppealCreationFail} />,
    [LeadHistoryActionNames.lead_creation_fail]: <HistoryActionIcon Icon={LeadCreationFail} />,
    [LeadHistoryActionNames.lead_create]: <HistoryActionIcon Icon={LeadCreate} />,
  };

  const defaultActionIcon = <HistoryActionIcon Icon={ReserveBox} />;

  return actionIconsMap[actionName] || defaultActionIcon;
};
