import { useContext } from 'react';
import { TableFilterContext } from '../../model/context/TableFilterContextProvider';
import { TableFilterContextSchema, TableFilterType } from '../../model/types';

export const useTableFilterContext = <T extends string = CustomAny>(): Omit<TableFilterContextSchema, 'filters'> & {
  filters: TableFilterType<T>;
} => {
  const context = useContext(TableFilterContext);

  return context;
};
