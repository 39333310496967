import { api } from 'app/config/apiConfig';
import { CreateBoxReservationParams, Reservation } from '../model/types';

export const reserveBoxApi = api.injectEndpoints({
  endpoints: (build) => ({
    reserveBox: build.mutation<Reservation, { boxId: string; params: CreateBoxReservationParams }>({
      query: (args) => ({
        url: `/boxes/${args.boxId}/reserve`,
        method: 'POST',
        body: args.params,
      }),
      invalidatesTags: ['Available boxes', 'Reservations', 'Available sizeCodes', 'Warehouses for booking'],
    }),
  }),
});

export const { useReserveBoxMutation } = reserveBoxApi;
