import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useReplenishBalanceMutation } from '../../api/replenishBalanceApi';

interface HookArgs {
  userId: string;
  amount: number;
  description?: string;
}

interface HookApi {
  isOpenedConfirm: boolean;
  isOpenedBalance: boolean;
  isLoading: boolean;
  replenishBalance: (args: HookArgs) => Promise<void>;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
  openBalanceModal: () => void;
  closeBalanceModal: () => void;
}

export const useReplenishBalance = (): HookApi => {
  const [isOpenedBalance, setOpenedBalance] = useState(false);
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [replenishBalanceService, { isLoading }] = useReplenishBalanceMutation();

  const openBalanceModal = useCallback((): void => {
    setOpenedBalance(true);
  }, []);

  const closeBalanceModal = useCallback((): void => {
    setOpenedBalance(false);
  }, []);

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const replenishBalance = useCallback(
    async ({ userId, amount, description }: HookArgs): Promise<void> => {
      try {
        await replenishBalanceService({ userId, params: { amount, description } }).unwrap();
        showNotification('info', t('Success'), t('Balance has been successfully changed'));
        closeBalanceModal();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when changing balance'));
      } finally {
        closeConfirmModal();
      }
    },
    [closeBalanceModal, closeConfirmModal, replenishBalanceService, t],
  );

  return {
    openConfirmModal,
    closeConfirmModal,
    replenishBalance,
    isOpenedConfirm,
    isLoading,
    isOpenedBalance,
    closeBalanceModal,
    openBalanceModal,
  };
};
