import { api } from 'app/config/apiConfig';
import { SizeCode } from '../model/types';

export const createSizeCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createSizeCode: builder.mutation<SizeCode, SizeCode>({
      query: (data) => ({
        url: '/size-codes',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Size codes', 'Available sizeCodes'],
    }),
  }),
});

export const { useCreateSizeCodeMutation } = createSizeCodeApi;
