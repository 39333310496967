import { useMedia } from 'react-use';

interface HookApi {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isWideDesktop: boolean;
}

export const useScreenBreakpoints = (): HookApi => {
  const isMobile = true;
  const isTablet = useMedia('(min-width: 390px)');
  const isDesktop = useMedia('(min-width: 1280px)');
  const isWideDesktop = useMedia('(min-width: 1600px)');

  return {
    isMobile,
    isTablet,
    isDesktop,
    isWideDesktop,
  };
};
