import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StepperModalSchema } from '../types';

const initialState: StepperModalSchema = {
  activeStepIndex: 0,
};

const stepperModalSlice = createSlice({
  name: 'stepperModal',
  initialState,
  reducers: {
    changeActiveStepIndex: (state: StepperModalSchema, action: PayloadAction<number>) => {
      state.activeStepIndex = action.payload;
    },
    goToPreviousStep: (state: StepperModalSchema) => {
      if (state.activeStepIndex > 0) {
        state.activeStepIndex = state.activeStepIndex - 1;
      }
    },
  },
});

export const { actions: stepperModalActions, reducer: stepperModalReducer } = stepperModalSlice;
