import { api } from 'app/config/apiConfig';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';
import { appActions } from '../model/slice/appSlice';
import { GlobalSettings } from '../model/types';

export const getGlobalSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGlobalSettings: build.query<GlobalSettings, void>({
      query: () => ({
        url: '/global-settings',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          dispatch(appActions.startLoading());

          await queryFulfilled;
        } catch (error) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when receiving global settings', { ns: 'common' }));
        } finally {
          dispatch(appActions.stopLoading());
        }
      },
    }),
  }),
});

export const { useGetGlobalSettingsQuery } = getGlobalSettingsApi;
