import { api } from 'app/config/apiConfig';
import { USERS_PATH } from '../utils/constants';
import { Pagination } from 'app/types/common';
import { Invoice } from 'entities/Invoice';

interface Params {
  page?: number;
  limit?: number;
  orderBy?: string;
  userId?: string;
  isActive?: boolean;
}

export const getUserInvoicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserInvoices: builder.query<Pagination<Invoice>, Params>({
      query: (params: Params) => ({
        url: `${USERS_PATH}/${params.userId}/invoices`,
        params,
      }),
      providesTags: ['Invoices'],
    }),
  }),
});

export const { useGetUserInvoicesQuery } = getUserInvoicesApi;
