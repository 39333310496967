import React, { FC } from 'react';
import { CachedBox } from 'entities/Box';
import { BoxCard } from './BoxCard';
import { Reservation } from 'entities/Reservation';
import { ContractWithBookingInfo } from 'entities/Contract';

interface BoxesListProps {
  type: 'reservation' | 'contract';
  boxes?: CachedBox[];
  reservation?: Nullable<Reservation>;
  onOpenContractDetails: (contractId: string) => void;
  warehouseId?: string;
  contractsData?: ContractWithBookingInfo[];
}

export const BoxesList: FC<BoxesListProps> = (props) => {
  const { boxes, type, reservation, warehouseId, contractsData, onOpenContractDetails } = props;

  return (
    <div className="flex flex-col space-y-3">
      {boxes?.map((box) => (
        <BoxCard
          box={box}
          key={box.boxId}
          type={type}
          reservation={reservation}
          warehouseId={warehouseId}
          contractsData={contractsData}
          onOpenContractDetails={onOpenContractDetails}
        />
      ))}
    </div>
  );
};
