import { api } from 'app/config/apiConfig';

import { Insurance } from '../model/types';

export const activateInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    activateInsurance: builder.mutation<Insurance, string>({
      query: (insuranceId) => ({
        url: `/insurance/${insuranceId}/activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['Insurance'],
    }),
  }),
});

export const { useActivateInsuranceMutation } = activateInsuranceApi;
