import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { BoxAvailabilityFilter, BoxesListFilterKeys } from '../../model/types';

interface BoxAvailabilitySelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const BoxAvailabilitySelect: FC<BoxAvailabilitySelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('boxes');

  const boxAvailabilityOptions = useMemo<Array<{ label: string; value: BoxAvailabilityFilter }>>(() => {
    return [
      { label: t('Available'), value: BoxAvailabilityFilter.AVAILABLE },
      { label: t('Unavailable'), value: BoxAvailabilityFilter.UNAVAILABLE },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(BoxesListFilterKeys.availability);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Availability')}
      placeholder={t('All')}
      bordered
      allowClear
      options={boxAvailabilityOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
