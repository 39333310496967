import { api } from 'app/config/apiConfig';
import { VatRate, VatRateParams } from '../model/types';

export const getOneVatRateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOneVatRate: build.query<VatRate, { args: VatRateParams; warehouseId: string }>({
      query: ({ args, warehouseId }) => ({
        url: `warehouses/${warehouseId}/vat-rate`,
        params: args,
      }),
    }),
  }),
});

export const { useGetOneVatRateQuery } = getOneVatRateApi;
