import React, { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import { MainHeader } from 'widgets/MainHeader';

export const MainLayout: FC = memo(() => {
  return (
    <div>
      <div className="wrapper">
        <MainHeader />
        <main className="mb-16">
          <Outlet />
        </main>
      </div>
    </div>
  );
});
