import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompleteRegistrationModalSchema } from '../types';

const initialState: CompleteRegistrationModalSchema = {
  userId: null,
};

const completeRegistrationModalSlice = createSlice({
  name: 'completeRegistrationModal',
  initialState,
  reducers: {
    setOpened: (state: CompleteRegistrationModalSchema, action: PayloadAction<Nullable<string>>) => {
      state.userId = action.payload;
    },
    resetModalState: (state: CompleteRegistrationModalSchema) => {
      state.userId = null;
    },
  },
});

export const { actions: completeRegistrationModalActions, reducer: completeRegistrationModalReducer } = completeRegistrationModalSlice;
