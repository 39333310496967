import React, { FC, memo, PropsWithChildren } from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';
import s from './index.module.scss';

interface ToggleProps extends PropsWithChildren {
  checked?: boolean;
  onChange?: (value: boolean) => void;
}

export const Toggle: FC<ToggleProps> = memo((props) => {
  const { checked = false, children, onChange } = props;

  const handleToggleChange = (checked: boolean): void => {
    onChange?.(checked);
  };

  return (
    <div className="flex gap-2 items-center">
      <Switch
        checked={checked}
        onChange={handleToggleChange}
        className={classNames({
          [s.toggle]: !checked,
          [s.toggleActive]: checked,
        })}
      />
      <div className={`text-sm ${checked ? 'text-accent' : 'text-primaryLight'} `}>{children}</div>
    </div>
  );
});
