import { api } from 'app/config/apiConfig';
import { LateChargeSetting } from '../model/types';

export const updateLateChargeSettingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateLateChargeSetting: builder.mutation<LateChargeSetting, LateChargeSetting>({
      query: (lateChargeSetting: LateChargeSetting) => ({
        url: `/late-charge-settings/${lateChargeSetting.lateChargeSettingId}`,
        method: 'PUT',
        body: lateChargeSetting,
      }),
      invalidatesTags: ['Late charge settings'],
    }),
  }),
});

export const { useUpdateLateChargeSettingMutation } = updateLateChargeSettingApi;
