import { CLIENT_HOST } from 'app/config/envVariables';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import React, { FC, memo, useCallback } from 'react';
import { NavigationBlocker } from 'shared/ui/NavigationBlocker';
import { CheckoutWrapper } from 'widgets/CheckoutForm';

export const CheckoutPage: FC = memo(() => {
  const { t } = useAppTranslation('common');

  const handlePopstate = useCallback((): void => {
    setTimeout(() => {
      // NEED TIMEOUT FOR NAVIGATION AFTER BLOCKER WHEN BACK BUTTON WAS PRESSED !!!
      window.location.href = `${CLIENT_HOST}${AppRoutes.MY_BOXES}`;
    }, 0);
  }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <CheckoutWrapper />
      <NavigationBlocker title={t('Leave page')} description={t('Are you sure you want to leave this page?')} onOk={handlePopstate} />
    </div>
  );
});
