import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Contract } from 'entities/Contract';
import { Invoice } from 'entities/Invoice';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { contractDetailsDrawerActions } from '../../model/slice/contractDetailDrawerSlice';
import { ContractDetails } from '../ContractDetails';
import { InvoiceTab } from './InvoiceTab';
import { PaymentTab } from './PaymentTab';
import { DepositTab } from './DepositTab';
import dayjs from 'dayjs';

interface BillingProps {
  contract: Contract;
  invoices: Invoice[];
}

export const Billing: FC<BillingProps> = memo((props) => {
  const { contract, invoices } = props;

  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('contracts');

  const { isDesktop } = useScreenBreakpoints();

  const [selectedTabKey, setSelectedTabKey] = useState('Invoice');

  const tabs = useMemo(() => {
    let initialTabs = [
      { key: 'ContractDetails', label: t('Contract details') },
      { key: 'Invoice', label: t('Invoice') },
      { key: 'Payment', label: t('Payment') },
      { key: 'Deposit', label: t('Deposit') },
    ];

    if (isDesktop) {
      initialTabs = initialTabs.filter((tab) => tab.key !== 'ContractDetails');
    }

    return initialTabs;
  }, [isDesktop, t]);

  const closeContractDetails = useCallback((): void => {
    dispatch(contractDetailsDrawerActions.setOpenContractDetails(null));
  }, [dispatch]);

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  const payments = useMemo(() => invoices.flatMap((invoice) => invoice.payments), [invoices]);
  const sortedPayments = payments.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)));

  const renderTabContent = useCallback(() => {
    switch (selectedTabKey) {
      case 'ContractDetails':
        return <ContractDetails contract={contract} invoices={invoices} closeContractDetails={closeContractDetails} />;
      case 'Invoice':
        return <InvoiceTab invoices={invoices} />;
      case 'Payment':
        return <PaymentTab contract={contract} payments={sortedPayments} />;
      case 'Deposit':
        return <DepositTab contract={contract} />;
      default:
        return null;
    }
  }, [selectedTabKey, contract, invoices, closeContractDetails, sortedPayments]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="font-semibold text-3xl">{t('Billing')}</div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={closeContractDetails}>
          {t('Close')}
        </div>
      </div>
      <TabSwitcher tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        <div className="max-h-[calc(100vh-115px)] overflow-y-auto pr-3">{renderTabContent()}</div>
      </TabSwitcher>
    </>
  );
});
