import { Form } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { AppLanguage } from 'app/config/i18Config/types';
import { Select } from 'shared/ui/Select';
import { datePickerValueUnder18, disableFutureDatesUnder18 } from 'shared/utils/helpers/disable18YearsDatePicker';
import { ChangeUserDetails } from 'features/ChangeUserDetails';
import { TextArea } from 'shared/ui/TextArea';

interface CustomerDetailsFormProps {
  userId: string;
  language: string;
  email: string;
  dateOfBirth?: Nullable<string>;
  identificationNumber?: Nullable<string>;
  additionalEmail?: Nullable<string>;
  bankingRequisites?: Nullable<string>;
}

export const CustomerDetailsForm: FC<CustomerDetailsFormProps> = memo((props) => {
  const { userId, dateOfBirth, email, additionalEmail, identificationNumber, bankingRequisites, language } = props;

  const { t } = useAppTranslation(['profile', 'common']);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      language,
      identificationNumber,
      additionalEmail,
      bankingRequisites,
      dateOfBirth: dateOfBirth ? dayjs(dateOfBirth) : undefined,
    });
  }, [additionalEmail, dateOfBirth, form, identificationNumber, language, bankingRequisites]);

  const languages = [
    { label: 'English', value: AppLanguage.en },
    { label: 'Deutsch', value: AppLanguage.de },
    { label: 'Polish', value: AppLanguage.pl },
  ];

  return (
    <div className="mt-10">
      <div className="mb-7 font-semibold text-2xl">{t('Customer details')}</div>
      <Form className="space-y-2" name="customerDetailsForm" form={form} layout="vertical">
        <div className="flex items-center space-x-7">
          <Form.Item
            className="w-full"
            name="additionalEmail"
            rules={[
              { type: 'email', message: t('Please enter a correct additional email!') },
              {
                validator: async (_, value) => {
                  value && value === email
                    ? await Promise.reject(new Error(t('Additional email must be different from the main email!')))
                    : await Promise.resolve();
                },
              },
            ]}
          >
            <Input
              bordered
              placeholder={t('Additional email')}
              tooltip={t('Please enter an additional email address to receive copies of bills')}
              label={t('Additional Email')}
            />
          </Form.Item>
          <Form.Item
            className="w-full"
            name="language"
            label={t('Language')}
            rules={[{ required: true, message: t('Please select language!') }]}
          >
            <Select placeholder={t('Select language')} options={languages} bordered />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item className="w-full" name="dateOfBirth" label={t('Date of Birth')}>
            <DatePicker
              bordered
              className="h-[52px] w-full"
              format={CLIENT_DATE_FORMAT}
              disabledDate={disableFutureDatesUnder18}
              defaultPickerValue={datePickerValueUnder18}
            />
          </Form.Item>
          <Form.Item className="w-full" name="identificationNumber" label={t('Identification number')}>
            <Input bordered placeholder={t('Identification number')} />
          </Form.Item>
        </div>
        <div className="flex items-center space-x-7">
          <Form.Item className="w-1/2" name="bankingRequisites" label={t('Banking requisites')}>
            <TextArea bordered placeholder={t('Banking requisites')} />
          </Form.Item>
          <ChangeUserDetails
            userId={userId}
            formInstance={form}
            action={
              <Button containerClassName="basis-1/2" size="large">
                {t('Change customer details')}
              </Button>
            }
          />
        </div>
      </Form>
    </div>
  );
});
