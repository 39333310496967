import React, { FC, memo } from 'react';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalConfirmModalData } from '../model/selectors/getGlobalConfirmModalData';

export const GlobalConfirmModal: FC = memo(() => {
  const modalData = useAppSelector(getGlobalConfirmModalData);

  return (
    <ConfirmModal
      isOpened={Boolean(modalData)}
      title={modalData?.title}
      description={modalData?.description}
      isLoading={modalData?.isLoading}
      onOk={modalData?.onOk}
      onCancel={modalData?.onCancel}
      isConfirmDisabled={modalData?.isConfirmDisabled}
    />
  );
});
