import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getSelectedBox } from '../../model/selectors/getSelectedBox';
import { getSelectedUser } from '../../model/selectors/getSelectedUser';
import { getSelectedWarehouse } from '../../model/selectors/getSelectedWarehouse';
import { createContractModalActions } from '../../model/slice/createContractModalSlice';

export const useWatchStep = (): void => {
  const dispatch = useAppDispatch();

  const selectedUser = useAppSelector(getSelectedUser);
  const selectedWarehouse = useAppSelector(getSelectedWarehouse);
  const selectedBox = useAppSelector(getSelectedBox);

  useEffect(() => {
    if (selectedUser) {
      const fullName = `${selectedUser.firstName} ${selectedUser.lastName}`;
      dispatch(createContractModalActions.changeStepData({ targetStepIndex: 0, stepData: fullName }));
    }
    if (selectedWarehouse) {
      dispatch(createContractModalActions.changeStepData({ targetStepIndex: 1, stepData: selectedWarehouse.name }));
    }
    if (selectedBox) {
      dispatch(createContractModalActions.changeStepData({ targetStepIndex: 2, stepData: selectedBox.name }));
    }
  }, [dispatch, selectedBox, selectedUser, selectedWarehouse]);
};
