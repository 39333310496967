import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

interface HookApi {
  scrollRef: MutableRefObject<Nullable<HTMLDivElement>>;
  scrollToElement: () => void;
  scrollToTop: () => void;
}

export const useScrollToElement = (scrollPosition?: ScrollLogicalPosition, delay?: number): HookApi => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [shouldScrollTo, setShouldScrollTo] = useState(false);

  useEffect(() => {
    const executeScroll = (): void => {
      if (scrollRef.current && shouldScrollTo) {
        scrollRef.current.scrollIntoView({ inline: scrollPosition, block: scrollPosition, behavior: 'smooth' });
        setShouldScrollTo(false);
      }
    };

    if (delay !== undefined) {
      const timer = setTimeout(() => {
        executeScroll();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    } else {
      executeScroll();
    }
  }, [delay, scrollPosition, shouldScrollTo]);

  const scrollToElement = useCallback((): void => {
    setShouldScrollTo(true);
  }, []);

  const scrollToTop = useCallback((): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return {
    scrollRef,
    scrollToElement,
    scrollToTop,
  };
};
