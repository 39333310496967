import classNames from 'classnames';
import React, { FC, LegacyRef, memo } from 'react';
import { StepData } from '../model/types';
import { ReactComponent as CompletedStepIcon } from 'shared/assets/icons/CompletedStepIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface StepProps {
  stepNumber: number;
  stepData: StepData;
  onClick: (stepName: string) => void;
  isActive: boolean;
  isCompleted: boolean;
  isDisabled: boolean;
  containerRef?: LegacyRef<HTMLDivElement>;
}

export const Step: FC<StepProps> = memo((props) => {
  const { stepNumber, stepData, isActive, isCompleted, isDisabled, onClick, containerRef } = props;
  const { t } = useAppTranslation('booking');

  const switchStepTransition = 'ease-linear duration-200';

  const handleStepClick = (): void => {
    !isDisabled && onClick(stepData.label);
  };

  return (
    <div
      ref={containerRef}
      className={classNames('flex items-center text-primaryLight mr-6 last:mr-0', {
        'cursor-pointer': !isActive && !isDisabled,
        'pointer-events-none': isDisabled,
      })}
      onClick={handleStepClick}
    >
      <div
        className={classNames(
          'mr-3 border rounded-full w-10 h-10 flex justify-center items-center font-normal shrink-0',
          switchStepTransition,
          {
            'text-secondaryLight bg-accent': isActive || isCompleted,
            'opacity-40': isDisabled,
          },
        )}
      >
        {isCompleted ? <CompletedStepIcon /> : stepNumber}
      </div>
      <div className={classNames('flex flex-col', { 'opacity-40': isDisabled })}>
        <div
          className={classNames('whitespace-nowrap', switchStepTransition, {
            'font-normal': !isActive,
            'text-accent': isActive,
          })}
        >
          {t(stepData.label)}
        </div>
        {isCompleted && (
          <div className="text-primary font-medium text-xs font-medium text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
            {stepData.stepData}
          </div>
        )}
      </div>
    </div>
  );
});
