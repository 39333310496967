import React, { FC, memo, PropsWithChildren } from 'react';
import { useWatchTableFilterQuery } from '../utils/hooks/useWatchTableFilterQuery';
import { TableFilterDrawer } from './TableFilterDrawer';

interface TableFilterProps extends PropsWithChildren {
  isDisabled?: boolean;
  withQueryStringFilters?: boolean;
}

export const TableFilter: FC<TableFilterProps> = memo((props) => {
  const { isDisabled, withQueryStringFilters = true, children } = props;

  useWatchTableFilterQuery(withQueryStringFilters);

  return <TableFilterDrawer isDisabled={isDisabled}>{children}</TableFilterDrawer>;
});
