import { useCallback } from 'react';
import { useGlobalConfirmModal } from 'app/globalConfirmModal';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useSendLoginLinkMutation } from 'entities/User';

interface OpenConfirmModalArgs {
  userId: Nullable<string> | undefined;
}

interface HooApi {
  executeSendingLoginLink: (args: OpenConfirmModalArgs) => void;
}

export const useSendLoginLink = (): HooApi => {
  const { t } = useAppTranslation('common');

  const { openModal, setModalLoading, closeModal } = useGlobalConfirmModal();

  const [sendLoginLinkService] = useSendLoginLinkMutation();

  const sendLoginLink = useCallback(
    async ({ userId }: OpenConfirmModalArgs) => {
      try {
        if (userId) {
          setModalLoading(true);
          await sendLoginLinkService(userId).unwrap();
          showNotification('info', t('Success'), t('Login link has been successfully sent'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when sending login link'));
      } finally {
        setModalLoading(false);
        closeModal();
      }
    },
    [closeModal, sendLoginLinkService, setModalLoading, t],
  );

  const openConfirmModal = useCallback(
    ({ userId }: OpenConfirmModalArgs): void => {
      openModal({
        title: t('Send login link'),
        description: t('Are you sure you want to send login link '),
        onOk: async () => {
          await sendLoginLink({ userId });
        },
        onCancel: closeModal,
      });
    },
    [closeModal, openModal, sendLoginLink, t],
  );

  return {
    executeSendingLoginLink: openConfirmModal,
  };
};
