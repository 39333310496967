import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGetPaginatedUsersQuery, User } from 'entities/User';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { stepperModalActions } from 'features/StepperModal';
import { createContractModalActions } from '../../model/slice/createContractModalSlice';

export const SelectUserStep: FC = memo(() => {
  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const columns = useMemo<ColumnsType<User>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => (
          <div className="text-primary font-semibold">
            {record.firstName} {record.lastName}
          </div>
        ),
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', width: '50%', render: (_, record) => record.phone },
    ],
    [t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<User>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        dispatch(createContractModalActions.setSelectedUser(selectedElements[0])); // only one user can be selected
        dispatch(stepperModalActions.changeActiveStepIndex(1));
      },
    },
  ];

  return (
    <ServerTable
      columns={columns}
      fetchData={useGetPaginatedUsersQuery}
      rowKey="userId"
      search
      title={t('Choose User')}
      selectionType="radio"
      rowSelectionActions={rowSelectionActions}
    />
  );
});
