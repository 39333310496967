import React, { FC, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { ContractsListFilterKeys } from 'pages/ContractsPage/model/types';
import { ContractStatusSelect } from './ContractStatusSelect';
import { RangeDatePicker } from 'shared/ui/RangeDatePicker/RangeDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { PaymentStatusSelect } from './PaymentStatusSelect';
import { Input } from 'shared/ui/Input';
import { PlannedDepartureSelect } from './PlannedDepartureSelect';

export const ContractsListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const [unitNameError, setUnitNameError] = useState<string | null>(null);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<ContractsListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: ContractsListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  const changeCreationDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.creationDateFrom,
        label: t('From {{creationDateFrom}}', { creationDateFrom: clientFormatDateFrom }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeCreationDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.creationDateTo,
        label: t('To {{creationDateTo}}', { creationDateTo: clientFormatDateTo }),
        value,
        title: t('Creation date'),
      });
    },
    [changeFilters, t],
  );

  const changeStartDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.startDateTo,
        label: t('To {{startDateTo}}', { startDateTo: clientFormatDateTo }),
        value,
        title: t('Start date'),
      });
    },
    [changeFilters, t],
  );

  const changeStartDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.startDateFrom,
        label: t('From {{startDateFrom}}', { startDateFrom: clientFormatDateFrom }),
        value,
        title: t('Start date'),
      });
    },
    [changeFilters, t],
  );

  const changeClosingDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.closingDateTo,
        label: t('To {{closingDateTo}}', { closingDateTo: clientFormatDateTo }),
        value,
        title: t('Closing date'),
      });
    },
    [changeFilters, t],
  );

  const changeClosingDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.closingDateFrom,
        label: t('From {{closingDateFrom}}', { closingDateFrom: clientFormatDateFrom }),
        value,
        title: t('Closing date'),
      });
    },
    [changeFilters, t],
  );

  const changeContractStatus = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: ContractsListFilterKeys.isActive, label, value, title: t('Status') });
    },
    [changeFilters, t],
  );

  const changePaymentStatus = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: ContractsListFilterKeys.isPaid, label, value, title: t('Payment status') });
    },
    [changeFilters, t],
  );

  const changePlannedDeparture = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: ContractsListFilterKeys.isNotified, label, value, title: t('Planned departure') });
    },
    [changeFilters, t],
  );

  const changeUnitName = useCallback(
    (value: Nullable<string>) => {
      if (value && value.length < 2) {
        setUnitNameError(t('Unit name must be at least 2 characters long!'));
      } else {
        setUnitNameError(null);
      }

      changeFilters({ key: ContractsListFilterKeys.unitName, label: `${value}`, value: value || undefined, title: t('Unit') });
    },
    [changeFilters, t],
  );

  const changeNoticeLeaveDateTo = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateTo = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.noticeLeaveDateTo,
        label: t('To {{noticeLeaveDateTo}}', { noticeLeaveDateTo: clientFormatDateTo }),
        value,
        title: t('Planned departure date'),
      });
    },
    [changeFilters, t],
  );

  const changeNoticeLeaveDateFrom = useCallback(
    (value: Nullable<Dayjs>) => {
      const clientFormatDateFrom = dayjs(value).startOf('day').format(CLIENT_DATE_FORMAT);

      changeFilters({
        key: ContractsListFilterKeys.noticeLeaveDateFrom,
        label: t('From {{noticeLeaveDateFrom}}', { noticeLeaveDateFrom: clientFormatDateFrom }),
        value,
        title: t('Planned departure date'),
      });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter isDisabled={Boolean(unitNameError)}>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Creation range')}
          onChangeFrom={changeCreationDateFrom}
          onChangeTo={changeCreationDateTo}
          values={[
            filters.creationDateFrom?.value && dayjs(filters.creationDateFrom?.value),
            filters.creationDateTo?.value && dayjs(filters.creationDateTo?.value),
          ]}
        />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Start contract range')}
          onChangeFrom={changeStartDateFrom}
          onChangeTo={changeStartDateTo}
          values={[
            filters.startDateFrom?.value && dayjs(filters.startDateFrom?.value),
            filters.startDateTo?.value && dayjs(filters.startDateTo?.value),
          ]}
        />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Closing contract range')}
          onChangeFrom={changeClosingDateFrom}
          onChangeTo={changeClosingDateTo}
          values={[
            filters.closingDateFrom?.value && dayjs(filters.closingDateFrom?.value),
            filters.closingDateTo?.value && dayjs(filters.closingDateTo?.value),
          ]}
        />
        <div>
          <div className="mb-2">{t('Unit')}</div>
          <Input placeholder={t('Unit name')} value={filters.unitName?.value} onChange={changeUnitName} bordered />
          {unitNameError && <div className="text-error mt-1">{unitNameError}</div>}
        </div>

        <div className="flex space-x-3 items-center">
          <PaymentStatusSelect value={filters.isPaid?.value} onChange={changePaymentStatus} onClear={clearCurrentFilters} />
          <ContractStatusSelect value={filters.isActive?.value} onChange={changeContractStatus} onClear={clearCurrentFilters} />
        </div>
        <PlannedDepartureSelect value={filters.isNotified?.value} onChange={changePlannedDeparture} onClear={clearCurrentFilters} />
        <RangeDatePicker
          placeholder={[t('From'), t('To')]}
          label={t('Planned departure range')}
          onChangeFrom={changeNoticeLeaveDateFrom}
          onChangeTo={changeNoticeLeaveDateTo}
          values={[
            filters.noticeLeaveDateFrom?.value && dayjs(filters.noticeLeaveDateFrom?.value),
            filters.noticeLeaveDateTo?.value && dayjs(filters.noticeLeaveDateTo?.value),
          ]}
        />
      </div>
    </TableFilter>
  );
});
