import React, { FC, memo, useEffect, useState } from 'react';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { LOCK_ADDRESS_REGEX } from 'shared/utils/regex';
import { Input } from 'shared/ui/Input';

interface LockMaskedInputProps {
  value?: string;
  placeholder?: string;
  className?: string;
  bordered?: boolean;
  size?: SizeType;
  disabled?: boolean;
  onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
}

export const LockMaskedInput: FC<LockMaskedInputProps> = memo((props) => {
  const { placeholder, className, disabled, value, onChange, bordered = false, size = 'middle' } = props;

  const [inputValue, setInputValue] = useState<string | undefined>();
  const [maxLength, setMaxLength] = useState<number | undefined>();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (value: string, event?: React.ChangeEvent<HTMLInputElement>): void => {
    let inputValue = value.toLocaleUpperCase();

    let formattedValue = '';

    if (inputValue[1] === ':') {
      setMaxLength(7);
      const inputType = (event?.nativeEvent as InputEvent).inputType;

      if (inputValue.length === 4 && inputType !== 'deleteContentBackward') {
        formattedValue = inputValue.slice(0, 4) + ':';
      } else {
        formattedValue = inputValue;
      }
    }

    if (inputValue[1] !== ':') {
      inputValue = inputValue.replace(LOCK_ADDRESS_REGEX, '');
      setMaxLength(8);

      for (let i = 0; i < inputValue.length; i++) {
        if (i > 0 && i % 2 === 0) {
          formattedValue += ':';
        }
        formattedValue += inputValue[i];
      }
    }

    setInputValue(formattedValue);
    onChange?.(formattedValue);
  };

  return (
    <Input
      className={className}
      bordered={bordered}
      placeholder={placeholder}
      value={inputValue}
      onChange={handleChange}
      disabled={disabled}
      maxLength={maxLength}
      size={size}
    />
  );
});
