import { api } from 'app/config/apiConfig';
import { AppealData } from 'entities/Appeal';

export const sendAppealApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendAppeal: builder.mutation<AppealData, AppealData>({
      query: (data) => ({
        url: '/appeals',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendAppealMutation } = sendAppealApi;
