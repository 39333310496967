import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WarehouseStatisticCardSchema } from '../types';

const initialState: WarehouseStatisticCardSchema = {
  warehouseId: null,
};

const warehouseStatisticCardSlice = createSlice({
  name: 'warehouseStatisticCard',
  initialState,
  reducers: {
    setDoorsModalOpened: (state: WarehouseStatisticCardSchema, action: PayloadAction<Nullable<string>>) => {
      state.warehouseId = action.payload;
    },
  },
});

export const { actions: warehouseStatisticCardActions, reducer: warehouseStatisticCardReducer } = warehouseStatisticCardSlice;
