export type GratitudeModalPayload = Omit<GratitudeModalSchema, 'isOpened'>;

export interface GratitudeModalSchema {
  isOpened: boolean;
  description: Nullable<string>;
  buttonId?: string;
  type?: Nullable<GratitudeModalType>;
  onSuccess?: () => void;
}

export enum GratitudeModalType {
  RESERVATION = 'reservation',
  CONTACT_FORM = 'contactForm',
}
