import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoxFormDrawerSchema, DrawerMode } from '../types';
import { BoxForListing } from 'entities/Box';

const initialState: BoxFormDrawerSchema = {
  box: null,
  mode: null,
};

const boxFormDrawerSlice = createSlice({
  name: 'boxFormDrawer',
  initialState,
  reducers: {
    setOpenBoxForm: (state: BoxFormDrawerSchema, action: PayloadAction<{ mode: Nullable<DrawerMode>; box?: Nullable<BoxForListing> }>) => {
      state.mode = action.payload.mode;
      state.box = action.payload.box;
    },
  },
});

export const { actions: boxFormDrawerActions, reducer: boxFormDrawerReducer } = boxFormDrawerSlice;
