import { api } from 'app/config/apiConfig';
import { Warehouse } from 'entities/Warehouse';

export const getUserBookingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserBookings: builder.query<Warehouse[], string | undefined>({
      query: (userId: string) => ({
        url: `/users/${userId}/my-bookings`,
      }),
      providesTags: (result) => ['Reservations', 'My bookings'],
    }),
  }),
});

export const { useGetUserBookingsQuery } = getUserBookingsApi;
