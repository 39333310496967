import { LeadStatus } from 'entities/Lead';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface LeadListFilters {
  warehouseId?: string;
  leadStatuses?: LeadStatus[];
  squareFrom?: number;
  squareTo?: number;
  creationDateFrom?: string;
  creationDateTo?: string;
}

export const transformLeadsListFilters = ({
  selectedWarehouseId,
  leadStatuses,
  squareFrom,
  squareTo,
  creationDateFrom,
  creationDateTo,
}: TableFilterType): LeadListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    squareFrom: squareFrom?.value || undefined,
    squareTo: squareTo?.value || undefined,
    leadStatuses: leadStatuses?.value || undefined,
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
  };
};
