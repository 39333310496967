import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocketConnectionSchema } from '../types';
import { Socket } from 'socket.io-client';
import { Immutable } from 'immer';

const initialState: SocketConnectionSchema = {
  socketConnection: null,
};

const socketConnectionSlice = createSlice({
  name: 'socketConnection',
  initialState,
  reducers: {
    setConnection: (state: Immutable<SocketConnectionSchema>, action: PayloadAction<Nullable<Socket>>) => {
      return {
        ...state,
        socketConnection: action.payload,
      };
    },
  },
});

export const { actions: socketConnectionActions, reducer: socketConnectionReducer } = socketConnectionSlice;
