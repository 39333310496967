import { OverlayView, OverlayViewF } from '@react-google-maps/api';
import React, { FC, memo, ReactNode, useCallback, useEffect, useId } from 'react';
import { Popup } from 'shared/ui/Popup';
import { GeolocationCoords } from 'app/types/common';
import { Marker } from '../model/types';

interface MapMarkerProps {
  markerData: Marker;
  selectedMarkerId?: Nullable<string>;
  selectMarker?: (latitude: number, longitude: number, markerId: string) => void;
  icon: ReactNode;
  selectedIcon?: ReactNode;
  onSelectMarker?: (markerCoords: GeolocationCoords) => void;
  withMarkerPopups?: boolean;
}

export const MapMarker: FC<MapMarkerProps> = memo((props) => {
  const { markerData, selectedMarkerId, selectMarker, icon, selectedIcon, onSelectMarker, withMarkerPopups } = props;

  const popupContainerId = useId();

  useEffect(() => {
    if (markerData.id === selectedMarkerId) {
      onSelectMarker?.({ lat: markerData.position.lat, lng: markerData.position.lng });
    }
  }, [markerData, onSelectMarker, selectedMarkerId]);

  const handleSelectMarker = useCallback(() => {
    selectMarker?.(markerData.position.lat, markerData.position.lng, markerData.id);
  }, [markerData, selectMarker]);

  return (
    <OverlayViewF mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={markerData.position}>
      <Popup title={markerData.labelHeading} description={markerData.labelDescription} isOpened={withMarkerPopups ? undefined : false}>
        {/* 24px - is half height of marker icon to set icon peak to the coordinate point */}
        <div id={popupContainerId} className="-translate-x-1/2 -translate-y-[calc(50%+24px)]" onClick={handleSelectMarker}>
          {selectedMarkerId === markerData.id ? selectedIcon : icon}
        </div>
      </Popup>
    </OverlayViewF>
  );
});
