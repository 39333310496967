import { api } from 'app/config/apiConfig';
import { Note, EditNoteData } from '../model/types';

export const updateNoteApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateNote: builder.mutation<Note, EditNoteData>({
      query: (note: Note) => ({
        url: `/notes/${note.noteId}`,
        method: 'PUT',
        body: note,
      }),
      invalidatesTags: ['Notes'],
    }),
  }),
});

export const { useUpdateNoteMutation } = updateNoteApi;
