import React, { FC, memo, PropsWithChildren } from 'react';
import { Tag } from 'shared/ui/Tag';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';

type TagTextSize = 'small' | 'medium' | 'large';

interface InfoTagProps extends PropsWithChildren {
  className?: string;
  textSize?: TagTextSize;
}

export const InfoTag: FC<InfoTagProps> = memo((props) => {
  const { children, className, textSize } = props;

  return (
    <Tag className={className} icon={<InfoIcon className="stroke-accent shrink-0" />} theme="info" textSize={textSize}>
      {children}
    </Tag>
  );
});
