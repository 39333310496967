/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as BillIcon } from 'shared/assets/icons/BillIcon.svg';
import { ReactComponent as NonePaidDays } from 'shared/assets/icons/NonePaidDays.svg';
import { ReactComponent as SquareIcon } from 'shared/assets/icons/SquareIcon.svg';
import { ReactComponent as AccessIcon } from 'shared/assets/icons/AccessIcon.svg';
import { ReactComponent as ContractWhiteIcon } from 'shared/assets/icons/ContractWhiteIcon.svg';
import { ReactComponent as AccessDeniedIcon } from 'shared/assets/icons/AccessDeniedIcon.svg';
import { ReactComponent as ContractIcon } from 'shared/assets/icons/ContractIcon.svg';
import { ReactComponent as KeyIcon } from 'shared/assets/icons/KeyIcon.svg';
import { ReactComponent as CancelIcon } from 'shared/assets/icons/CancelIcon.svg';
import { Button } from 'shared/ui/Button';
import { CachedBox, LockStatusMarker, useWatchLockState } from 'entities/Box';
import { Reservation } from 'entities/Reservation';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useGetCurrencySymbol } from 'app/appState';
import dayjs from 'dayjs';
import { cancelReservationModalActions } from 'features/CancelReservationModal';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { takeUpReservationModalActions } from 'features/TakeUpReservationModal';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { OpenBox } from 'features/OpenBox';
import { ContractWithBookingInfo } from 'entities/Contract';

interface BoxCardProps {
  box: Nullable<CachedBox>;
  type: 'reservation' | 'contract';
  onOpenContractDetails: (contractId: string) => void;
  reservation?: Nullable<Reservation>;
  warehouseId?: string;
  contractsData?: ContractWithBookingInfo[];
}

export const BoxCard: FC<BoxCardProps> = memo((props) => {
  const { box, type, reservation, warehouseId, contractsData, onOpenContractDetails } = props;

  const isReservation = type === 'reservation';

  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { t } = useAppTranslation(['contracts', 'common']);

  const lockStatus = useWatchLockState(box, warehouseId);

  const isExternalBox = box?.timeToOpenLockMS != null;

  const contractWithBookingInfo = useMemo(
    () => contractsData?.find((contractData) => contractData.contractId === box?.contract?.contractId),
    [box?.contract?.contractId, contractsData],
  );

  const openContractDetails = useCallback((): void => {
    contractWithBookingInfo && onOpenContractDetails(contractWithBookingInfo.contractId);
  }, [contractWithBookingInfo, onOpenContractDetails]);

  const openCancelReservationModal = useCallback((): void => {
    if (reservation) {
      dispatch(cancelReservationModalActions.openCancelReservationModal(reservation));
    }
  }, [dispatch, reservation]);

  const openTakeUpReservationModal = useCallback((): void => {
    if (reservation) {
      dispatch(takeUpReservationModalActions.openTakeUpReservationModal(reservation));
    }
  }, [dispatch, reservation]);

  const renderContractInvoicingInfo = useCallback(() => {
    if (contractWithBookingInfo?.isContractPaid) {
      return contractWithBookingInfo.nextInvoiceAmount ? (
        <div className="text-center">
          <div className="text-primaryLight text-lg py-2">
            {t('Next payment')}:{' '}
            <span className="text-black font-semibold">{getClientDateFormat(dayjs(contractWithBookingInfo.nextInvoiceDueDate))}</span>
          </div>
          <div className="text-primaryLight text-lg">
            {t('Total')}:{' '}
            <span className="text-black font-semibold">
              {contractWithBookingInfo.nextInvoiceAmount} {currencySymbol}
            </span>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <div className="text-primaryLight text-lg py-2">
            {t('Calculated to')}:{' '}
            <span className="text-black font-semibold">{getClientDateFormat(dayjs(contractWithBookingInfo.chargedTo))}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <div className="text-primaryLight text-lg">
            {t('Total debt')}:{' '}
            <span className="text-error font-semibold">
              {contractWithBookingInfo?.contractDebt} {currencySymbol}
            </span>
          </div>
        </div>
      );
    }
  }, [contractWithBookingInfo, currencySymbol, t]);

  return (
    <div className="bg-secondaryLight rounded">
      <div className="flex flex-col px-4 py-5 bg-white rounded">
        <div className="flex flex-col gap-1">
          <div className="font-semibold text-2xl flex items-center gap-2">
            {box?.name}
            {box?.floor && <span className="text-primaryLight"> — {getLocalizedString(box.floor.name)}</span>}
            {!isReservation && <LockStatusMarker lockStatus={lockStatus} isExternalBox={isExternalBox} />}
          </div>
          <div className="flex flex-col space-y-4 desktop:justify-between desktop:flex-row desktop:space-y-0">
            <div className="flex flex-col gap-2">
              {/* <Button theme="clear" fontSize="small">
                {t('Show on map')}
              </Button> */}
              <div className="flex items-center text-primaryLight text-sm font-normal">
                <SquareIcon className="mr-2 [&>path]:fill-accent" />
                {box?.square} m<sup>2</sup>
              </div>
              {!isReservation ? (
                <>
                  {contractWithBookingInfo?.isContractPaid ? (
                    <div className="flex items-center text-primaryLight text-sm font-normal">
                      <BillIcon className="mr-2 stroke-accent" />
                      {contractWithBookingInfo.paidDays} {t('Paid days')}
                    </div>
                  ) : (
                    <div className="flex items-center text-error text-sm">
                      <NonePaidDays className="mr-2 stroke-error" /> 0 {t('Paid days')}
                    </div>
                  )}
                  {contractWithBookingInfo?.isContractPaid ? (
                    <div className="flex items-center text-primaryLight text-sm font-normal">
                      <AccessIcon className="mr-2 stroke-accent" />
                      {t('Access is allowed')}
                    </div>
                  ) : (
                    <div className="flex items-center text-error text-sm">
                      <AccessDeniedIcon className="mr-2 stroke-error" />
                      {t('Access is denied')}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="space-x-1.5 desktop:space-x-0 desktop:flex desktop:items-center desktop:gap-1">
                    <span className="text-accent text-sm font-normal flex-1">{t('Start rent date')}:</span>
                    <span className="text-sm text-black flex-2">{getClientDateFormat(reservation?.startRentDate)}</span>
                  </div>
                  <div className="space-x-1.5 desktop:space-x-0 desktop:flex desktop:items-center desktop:gap-1">
                    <span className="text-accent text-sm font-normal flex-1">{t('End rent date')}:</span>
                    <span className="text-sm text-black flex-2">{getClientDateFormat(reservation?.endRentDate)}</span>
                  </div>
                </>
              )}
            </div>
            {reservation && isReservation ? (
              <>
                <div className="self-center desktop:self-auto">
                  <div className="text-error font-semibold text-lg py-2">
                    {t('Expires')}: <span className="text-black font-semibold">{getClientDateFormat(reservation.expirationDate)}</span>
                  </div>
                </div>
                <div className="flex flex-col gap-1.5 desktop:w-1/5">
                  <Button icon={<ContractWhiteIcon />} iconPosition="prev" onClick={openTakeUpReservationModal}>
                    {t('Enter into contract')}
                  </Button>
                  <Button theme="outlined" icon={<CancelIcon />} iconPosition="prev" onClick={openCancelReservationModal}>
                    {t('Cancel reservation')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {renderContractInvoicingInfo()}
                <div className="flex flex-col gap-1.5 desktop:w-1/5">
                  <Button icon={<ContractIcon />} theme="secondary" iconPosition="prev" onClick={openContractDetails}>
                    {t('Contract details')}
                  </Button>
                  <OpenBox
                    box={box}
                    action={
                      <Button icon={<KeyIcon className="stroke-white" />} iconPosition="prev">
                        {t('Open')}
                      </Button>
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
