import React, { FC, memo, useCallback } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmModal } from 'shared/ui/ConfirmModal';

interface NavigationBlockerProps {
  title: string;
  description: string;
  onOk?: () => void;
  isLoading?: boolean;
  isShouldBlock?: boolean;
}

export const NavigationBlocker: FC<NavigationBlockerProps> = memo((props) => {
  const { title, description, onOk, isLoading, isShouldBlock } = props;

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return !isShouldBlock && currentLocation.pathname !== nextLocation.pathname;
  });

  const handleClose = useCallback((): void => {
    blocker.reset?.();
  }, [blocker]);

  const handleConfirm = useCallback((): void => {
    blocker.proceed?.();

    onOk?.();
  }, [blocker, onOk]);

  return (
    <ConfirmModal
      isOpened={blocker?.state === 'blocked'}
      title={title}
      description={description}
      isLoading={isLoading}
      onOk={handleConfirm}
      onCancel={handleClose}
    />
  );
});
