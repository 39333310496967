import { WorkingSchedule, WeekDays } from 'entities/WorkingSchedule';

export const getWorkingScheduleString = (schedules: WorkingSchedule[]): string => {
  if (!schedules.length) {
    return '24/7';
  }

  const daysOrder: { [key in WeekDays]: number } = { Mon: 1, Tues: 2, Wed: 3, Thurs: 4, Fri: 5, Sat: 6, Sun: 7 };
  const copy = [...schedules];

  copy.sort((a, b) => daysOrder[a.weekDay] - daysOrder[b.weekDay]);

  const groupSchedules = copy.reduce((acc: WorkingSchedule[], currentSchedule, index, array) => {
    if (index === 0) {
      return [...acc, currentSchedule];
    }

    const lastScheduleIndex = acc.length - 1;
    const lastSchedule = acc[lastScheduleIndex];
    const isConsecutiveDay = daysOrder[currentSchedule.weekDay] === daysOrder[array[index - 1].weekDay] + 1;
    const hasSameWorkingHours = currentSchedule.workingHours === array[index - 1].workingHours;

    if (isConsecutiveDay && hasSameWorkingHours) {
      const updatedLastSchedule: WorkingSchedule = {
        ...lastSchedule,
        weekDay: `${lastSchedule.weekDay}-${currentSchedule.weekDay}` as WeekDays,
      };

      return [...acc.slice(0, lastScheduleIndex), updatedLastSchedule];
    }

    return [...acc, currentSchedule];
  }, []);

  const scheduleString = groupSchedules
    .map((schedule) => {
      const weekDaysRange = schedule.weekDay
        .split('-')
        .filter((_, index, array) => index === 0 || index === array.length - 1)
        .join(' - ');
      const hoursRange = schedule.workingHours?.replace('/', ' - ');

      return `${weekDaysRange} ${hoursRange}`;
    })
    .join(', ');

  return scheduleString;
};
