import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { noteDrawerActions } from '../model/slice/noteDrawerSlice';
import { getNoteDrawerState } from '../model/selectors/getNoteDrawerState';
import { NoteForm } from './NoteForm';

export const NoteDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const noteDrawerState = useAppSelector(getNoteDrawerState);

  const closeNoteDrawer = useCallback((): void => {
    dispatch(noteDrawerActions.setOpenNoteDrawer({ mode: null, note: null }));
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(noteDrawerState.mode)} onClose={closeNoteDrawer}>
      <NoteForm note={noteDrawerState?.note} mode={noteDrawerState.mode} onClose={closeNoteDrawer} />
    </Drawer>
  );
});
