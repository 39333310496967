export const LONGITUDE_REGEX = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const LATITUDE_REGEX = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;

export const PHONE_NUMBER_REGEX = /^\+?(?:[0-9] ?(?:[0-9]){5,13}[0-9])$/;

export const INTEGER_REGEX = /^-?\d*$/;

export const TWO_DECIMALS_FLOAT_REGEX = /^\d+(\.\d{1,2})?$/;

export const LOCK_ADDRESS_REGEX = /[^A-Z0-9]/g;
