import { Form } from 'antd';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { UserAccountType, UserRoles, CreateUserParams } from 'entities/User';
import { useCreateUser } from 'features/CreateUser';
import { stepperModalActions } from 'features/StepperModal';
import { Button } from 'shared/ui/Button';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { DatePicker } from 'shared/ui/DatePicker';
import { Input } from 'shared/ui/Input';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { Select } from 'shared/ui/Select';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { PHONE_NUMBER_REGEX } from 'shared/utils/regex';
import { AppLanguage } from 'app/config/i18Config/types';
import { datePickerValueUnder18, disableFutureDatesUnder18 } from 'shared/utils/helpers/disable18YearsDatePicker';
import { getUserData } from '../model/selectors/getUserData';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { RoleSelect } from './RoleSelect';

interface AccountDetailsStepProps {
  onSuccess: () => void;
}

export const AccountDetailsStep: FC<AccountDetailsStepProps> = memo((props) => {
  const { onSuccess } = props;

  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('user');

  const [form] = Form.useForm();

  const { createUser, isLoading, isOpenedConfirm, closeConfirmModal, openConfirmModal } = useCreateUser(onSuccess);

  const userData = useAppSelector(getUserData);

  useEffect(() => {
    userData ? form.setFieldsValue(userData) : form.resetFields();
  }, [form, userData]);

  const goToChooseWarehouseStep = useCallback(
    (formValues: CreateUserParams): void => {
      dispatch(stepperModalActions.changeActiveStepIndex(1));
      dispatch(createUserModalActions.setUserData(formValues));
    },
    [dispatch],
  );

  const submit = useCallback(
    (formValues: CreateUserParams): void => {
      const isAdmin = formValues.role === UserRoles.SUPERUSER || formValues.role === UserRoles.MANAGER;

      isAdmin ? goToChooseWarehouseStep(formValues) : openConfirmModal();
    },
    [goToChooseWarehouseStep, openConfirmModal],
  );

  const handleCreateUser = useCallback((): void => {
    const userData: CreateUserParams = form.getFieldsValue();

    createUser(userData);
  }, [createUser, form]);

  return (
    <Form className="flex space-x-20" name="accountDetailsStep" layout="vertical" form={form} onFinish={submit}>
      <div className="basis-1/2">
        <div className="font-semibold text-2xl mb-4">{t('Account details')}</div>
        <Form.Item label={t('Name')} name="firstName" rules={[{ required: true, message: t('Please, enter name') }]}>
          <Input placeholder={t('Enter name')} bordered />
        </Form.Item>
        <Form.Item label={t('Last name')} name="lastName" rules={[{ required: true, message: t('Please, enter last name') }]}>
          <Input placeholder={t('Enter last name')} bordered />
        </Form.Item>
        <Form.Item
          label={t('Email')}
          name="email"
          rules={[
            { required: true, message: t('Please, enter email') },
            { type: 'email', message: t('Please enter correct email') },
          ]}
        >
          <Input placeholder={t('Enter email')} bordered />
        </Form.Item>
        <Form.Item
          label={t('Phone')}
          name="phone"
          rules={[
            { pattern: PHONE_NUMBER_REGEX, message: t('Please provide a valid phone number') },
            { required: true, message: `${t('Please, enter phone number')}!` },
          ]}
        >
          <PhoneInput placeholder={t('Enter phone number')} bordered initialValue={userData?.phone} />
        </Form.Item>
        <Form.Item label={t('Language')} name="language" rules={[{ required: true, message: t('Please, select language') }]}>
          <Select
            placeholder={t('Select language')}
            bordered
            options={[
              { label: t('English'), value: AppLanguage.en },
              { label: t('Deutsch'), value: AppLanguage.de },
            ]}
          />
        </Form.Item>
        <Form.Item label={t('Identification number')} name="identificationNumber">
          <Input placeholder={t('Enter identification number')} bordered />
        </Form.Item>
        <Form.Item className="w-full" name="dateOfBirth" label={t('Date of birth')}>
          <DatePicker
            className="h-[52px] w-full"
            format={CLIENT_DATE_FORMAT}
            placeholder={t('Date of birth')}
            inputReadOnly
            disabledDate={disableFutureDatesUnder18}
            defaultPickerValue={datePickerValueUnder18}
          />
        </Form.Item>
        <RoleSelect />
        <Form.Item label={t('Account type')} name="accountType" rules={[{ required: true, message: t('Please, select account type') }]}>
          <Select
            placeholder={t('Select account type')}
            bordered
            options={[
              { label: t('Personal'), value: UserAccountType.PERSONAL },
              { label: t('Business'), value: UserAccountType.BUSINESS },
            ]}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.accountType !== nextValues.accountType}>
          {({ getFieldValue }) => {
            const isBusinessAccountType = getFieldValue('accountType') === UserAccountType.BUSINESS;

            return isBusinessAccountType ? (
              <Form.Item label={t('Company')} name="company">
                <Input placeholder={t('Enter company')} bordered />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
      </div>
      <div className="basis-1/2">
        <div className="font-semibold text-2xl mb-4">{t('Address')}</div>
        <Form.Item label={t('Country')} name="country" rules={[{ required: true, message: t('Please, enter country') }]}>
          <Input placeholder={t('Enter country')} bordered />
        </Form.Item>
        <div className="flex space-x-3 [&>*]:basis-1/2">
          <Form.Item label={t('City')} name="city" rules={[{ required: true, message: t('Please, enter city') }]}>
            <Input placeholder={t('Enter city')} bordered />
          </Form.Item>
          <Form.Item label={t('Zip code')} name="zip" rules={[{ required: true, message: t('Please, enter zip code') }]}>
            <Input placeholder={t('Enter zip code')} bordered />
          </Form.Item>
        </div>
        <Form.Item label={t('Street')} name="street" rules={[{ required: true, message: t('Please, enter street') }]}>
          <Input placeholder={t('Enter street')} bordered />
        </Form.Item>
        <div className="flex space-x-3 [&>*]:basis-1/2">
          <Form.Item label={t('House')} name="house" rules={[{ required: true, message: t('Please, enter house') }]}>
            <Input placeholder={t('Enter house')} bordered />
          </Form.Item>
          <Form.Item label={t('Apartment')} name="apartment">
            <Input placeholder={t('Enter apartment')} bordered />
          </Form.Item>
        </div>
        <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.role !== nextValues.role}>
          {({ getFieldValue }) => {
            const isAdmin = getFieldValue('role') === UserRoles.SUPERUSER || getFieldValue('role') === UserRoles.MANAGER;

            return <Button type="submit">{t(isAdmin ? 'Next' : 'Create')}</Button>;
          }}
        </Form.Item>
      </div>
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create user')}
        description={t('Are you sure you want to create user?')}
        isLoading={isLoading}
        onOk={handleCreateUser}
        onCancel={closeConfirmModal}
      />
    </Form>
  );
});
