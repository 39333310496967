import { Warehouse } from '../../model/types';

export const getMinMaxSquare = (warehouse: Warehouse): { minSquare: number; maxSquare: number } => {
  const sizeCodes = warehouse.sizeCodes;

  let minSquare = Number.POSITIVE_INFINITY;
  let maxSquare = Number.NEGATIVE_INFINITY;

  if (sizeCodes) {
    for (const sizeCode of sizeCodes) {
      minSquare = Math.min(minSquare, sizeCode.square);
      maxSquare = Math.max(maxSquare, sizeCode.square);
    }
  }

  const transformValue = (value: number): number => {
    return value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY ? 0 : value;
  };

  return { minSquare: transformValue(minSquare), maxSquare: transformValue(maxSquare) };
};
