import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'entities/User';
import { Warehouse } from 'entities/Warehouse';
import { Box } from 'entities/Box';
import { CreateContractModalSchema, createContractSteps } from '../types';

const initialState: CreateContractModalSchema = {
  isOpened: false,
  steps: createContractSteps,
  selectedUser: null,
  selectedWarehouse: null,
  selectedBox: null,
};

const createContractModalSlice = createSlice({
  name: 'createContractModal',
  initialState,
  reducers: {
    setOpened: (state: CreateContractModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeStepData: (state: CreateContractModalSchema, action: PayloadAction<{ targetStepIndex: number; stepData: string }>) => {
      state.steps = state.steps.map((step, index) =>
        index === action.payload.targetStepIndex ? { ...step, stepData: action.payload.stepData } : step,
      );
    },
    setSelectedUser: (state: CreateContractModalSchema, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    setSelectedWarehouse: (state: CreateContractModalSchema, action: PayloadAction<Warehouse>) => {
      state.selectedWarehouse = action.payload;
    },
    setSelectedBox: (state: CreateContractModalSchema, action: PayloadAction<Nullable<Box>>) => {
      state.selectedBox = action.payload;
    },
    resetModalState: (state: CreateContractModalSchema) => {
      state.selectedUser = null;
      state.selectedWarehouse = null;
      state.selectedBox = null;
      state.isOpened = false;
    },
  },
});

export const { actions: createContractModalActions, reducer: createContractModalReducer } = createContractModalSlice;
