import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Box, getAvailableBoxesApi } from 'entities/Box';

interface GetBoxesSuggestionResponse extends Pagination<Box> {
  noAvailableBoxes: boolean;
}

export const getBoxesSuggestionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBoxesSuggestion: build.query<GetBoxesSuggestionResponse, string>({
      queryFn: async (sizeCodeId, api) => {
        let noAvailableBoxes = false;

        const availableBoxes = api.dispatch(
          getAvailableBoxesApi.endpoints.getAvailableBoxes.initiate({
            sizeCodeId,
            isAvailable: true,
          }),
        );
        const availableResponse = await availableBoxes.unwrap();

        if (!availableResponse.content.length) {
          noAvailableBoxes = true;

          const randomBoxes = api.dispatch(
            getAvailableBoxesApi.endpoints.getAvailableBoxes.initiate({
              sizeCodeId,
              isAvailable: false,
            }),
          );
          const randomResponse = await randomBoxes.unwrap();

          return {
            data: { ...randomResponse, noAvailableBoxes },
          };
        }

        return {
          data: { ...availableResponse, noAvailableBoxes },
        };
      },
      providesTags: ['Available boxes'],
    }),
  }),
});

export const { useGetBoxesSuggestionQuery } = getBoxesSuggestionApi;
