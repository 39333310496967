import { AppLanguage, appLanguages } from 'app/config/i18Config/types';
import i18n from 'app/config/i18Config/i18n';

type ParsedLocalization = Record<AppLanguage, string>;

export const parseJSONLocalization = (JSONString: Nullable<string> | undefined): Nullable<ParsedLocalization> => {
  if (!JSONString) {
    console.error('JSON string not presented');
    return null;
  }

  try {
    const parsedLocalization: ParsedLocalization = JSON.parse(JSONString);

    const isValidLocalizationKeys = Object.keys(parsedLocalization).every((key) => appLanguages.includes(key as AppLanguage));

    if (!isValidLocalizationKeys) {
      console.error('JSON string has invalid language key(s)');
      return null;
    }

    return parsedLocalization;
  } catch (e: CustomAny) {
    console.error('Error while parsing JSON: ', e);
    return null;
  }
};

export const getLocalizedString = (JSONString: Nullable<string> | undefined): string => {
  const currentLanguage = i18n.language as AppLanguage;

  const parsedLocalization = parseJSONLocalization(JSONString);

  if (parsedLocalization) {
    return parsedLocalization[currentLanguage];
  }

  return JSONString || '';
};

export const validateLocalizedJSON = (JSONString: Nullable<string> | undefined): boolean => {
  if (!JSONString) {
    return false;
  }

  try {
    const parsedString = JSON.parse(JSONString);

    const isValidLocalizationKeys = Object.keys(parsedString).every((key) => appLanguages.includes(key as AppLanguage));

    if (!isValidLocalizationKeys) {
      return false;
    }

    return true;
  } catch (e: CustomAny) {
    return false;
  }
};
