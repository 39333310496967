import { ColumnsType } from 'antd/es/table';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { City, useGetPaginatedCitiesQuery } from 'entities/City';
import { stepperModalActions } from 'features/StepperModal';
import React, { FC, memo, useMemo } from 'react';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { ModalMode } from 'app/types/common';
import { CreateCityModal, useCreateCityModal } from 'widgets/CreateCityModal';
import { createWarehouseModalActions } from '../../model/slice/createWarehouseModalSlice';

export const SelectCityStep: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const dispatch = useAppDispatch();

  const { isOpened, openModal, closeModal } = useCreateCityModal();

  const columns = useMemo<ColumnsType<City>>(
    () => [
      {
        title: t('City'),
        key: 'city',
        render: (_, record) => record.name,
      },
      { title: t('Country'), key: 'country', render: (_, record) => record.country },
    ],
    [t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<City>> = [
    {
      name: t('Next step'),
      onClick: (selectedElements) => {
        dispatch(createWarehouseModalActions.setSelectedCity(selectedElements[0])); // only one city can be selected
        dispatch(stepperModalActions.changeActiveStepIndex(1));
      },
    },
  ];

  const tableActions: Array<ServerTableActions<City>> = useMemo(
    () => [
      {
        name: t('Did not find city? Add'),
        theme: 'clear',
        onClick: openModal,
      },
    ],
    [openModal, t],
  );

  return (
    <>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedCitiesQuery}
        rowKey="cityId"
        search
        title={t('Select a City')}
        selectionType="radio"
        rowSelectionActions={rowSelectionActions}
        tableActions={tableActions}
      />
      <CreateCityModal width={400} isOpen={isOpened} mode={ModalMode.CREATE} onClose={closeModal} />
    </>
  );
});
