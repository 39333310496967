import { Note } from 'entities/Note';

export interface NoteDrawerSchema {
  note?: Nullable<Note>;
  mode: Nullable<DrawerMode>;
}

export enum DrawerMode {
  NOTE_EDIT = 'note_edit',
  NOTE_CREATE = 'note_create',
}
