import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TakeUpReservationModalSchema } from '../types';
import { Reservation } from 'entities/Reservation';

const initialState: TakeUpReservationModalSchema = {
  reservation: null,
};

const takeUpReservationModalSlice = createSlice({
  name: 'takeUpReservationModal',
  initialState,
  reducers: {
    openTakeUpReservationModal: (state: TakeUpReservationModalSchema, action: PayloadAction<Nullable<Reservation>>) => {
      state.reservation = action.payload;
    },
  },
});

export const { actions: takeUpReservationModalActions, reducer: takeUpReservationModalReducer } = takeUpReservationModalSlice;
