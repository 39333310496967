import { MutableRefObject, useLayoutEffect } from 'react';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';

export const useScrollToActiveStep = (activeStep: Nullable<string>): MutableRefObject<Nullable<HTMLDivElement>> => {
  const { scrollToElement, scrollRef } = useScrollToElement('center');

  const { isDesktop } = useScreenBreakpoints();

  useLayoutEffect(() => {
    if (!isDesktop) {
      scrollToElement();
    }
  }, [activeStep, isDesktop, scrollToElement]);

  return scrollRef;
};
