import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SizeCodeFormDrawerSchema, DrawerMode } from '../types';
import { SizeCodeForListing } from 'entities/SizeCode';

const initialState: SizeCodeFormDrawerSchema = {
  sizeCode: null,
  mode: null,
};

const sizeCodeDrawerSlice = createSlice({
  name: 'sizeCodeDrawer',
  initialState,
  reducers: {
    setOpenSizeCodeForm: (
      state: SizeCodeFormDrawerSchema,
      action: PayloadAction<{ mode: Nullable<DrawerMode>; sizeCode?: Nullable<SizeCodeForListing> }>,
    ) => {
      state.mode = action.payload.mode;
      state.sizeCode = action.payload.sizeCode;
    },
  },
});

export const { actions: sizeCodeFormDrawerActions, reducer: sizeCodeFormDrawerReducer } = sizeCodeDrawerSlice;
