import { Contract } from 'entities/Contract';

export interface ContractsPageSchema {
  contractNoteDrawerData: Nullable<Contract>;
}

export enum ContractStatusFilter {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

export enum PaymentStatusFilter {
  PAID = 'paid',
  UNPAID = 'unpaid',
}

export enum NotifiedAboutLeaveFilter {
  NOTIFIED = 'notified',
  UNNOTIFIED = 'unnotified',
}

export enum ContractsListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  isActive = 'isActive',
  isPaid = 'isPaid',
  isNotified = 'isNotified',
  unitName = 'unitName',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
  startDateFrom = 'startDateFrom',
  startDateTo = 'startDateTo',
  closingDateFrom = 'closingDateFrom',
  closingDateTo = 'closingDateTo',
  noticeLeaveDateFrom = 'noticeLeaveDateFrom',
  noticeLeaveDateTo = 'noticeLeaveDateTo',
}
