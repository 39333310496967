import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingInfoStacks, BookingInfoStackSchema } from '../types';

const initialState: BookingInfoStackSchema = {
  openedStack: null,
};

const bookingInfoStackSlice = createSlice({
  name: 'bookingInfoStack',
  initialState,
  reducers: {
    setOpenedStack: (state: BookingInfoStackSchema, action: PayloadAction<BookingInfoStacks>) => {
      state.openedStack = action.payload;
    },
  },
});

export const { actions: bookingInfoStackActions, reducer: bookingInfoStackReducer } = bookingInfoStackSlice;
