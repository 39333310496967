import React, { FC, useEffect, ReactElement, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Invoice, MarkAsPaidInvoiceParams, useMarkAsPaidInvoiceMutation } from 'entities/Invoice';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { InputNumber } from 'shared/ui/InputNumber';
import { showNotification } from 'app/providers/NotificationsProvider';
import { PaymentMethod } from 'entities/Payment';
import { Select } from 'shared/ui/Select';
import { Form } from 'antd';

interface MarkAsPaidInvoiceProps {
  invoice: Invoice;
  action: ReactElement;
}

export const MarkAsPaidInvoice: FC<MarkAsPaidInvoiceProps> = memo((props) => {
  const { invoice, action } = props;

  const [form] = Form.useForm();

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { t } = useAppTranslation('contracts');

  const [markAsPaidInvoice, { isLoading }] = useMarkAsPaidInvoiceMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  useEffect(() => {
    if (invoice) {
      form.setFieldValue('amount', invoice.debt);
    }
  }, [invoice, form]);

  const handleMarkAsPaidInvoice = useCallback(
    async (data: MarkAsPaidInvoiceParams): Promise<void> => {
      try {
        if (invoice) {
          await markAsPaidInvoice({ ...data, invoiceId: invoice.invoiceId }).unwrap();
          showNotification('info', t('Success'), t('Invoice has been successfully marked as paid'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when marking invoice as paid'));
      } finally {
        closeModal();
      }
    },
    [invoice, markAsPaidInvoice, t, closeModal],
  );

  const paymentMethodOptions = [
    {
      label: t('Stripe'),
      value: PaymentMethod.STRIPE,
    },
    {
      label: t('Bank transfer'),
      value: PaymentMethod.BANK_TRANSFER,
    },
    {
      label: t('Cash'),
      value: PaymentMethod.CASH,
    },
  ];

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleMarkAsPaidInvoice} className="flex flex-col">
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Mark invoice as paid')}</div>
          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to mark this invoice as paid? You must select payment method!')}
          </div>

          <div className="my-5">
            <Form.Item
              label={t('Enter payment amount')}
              name="amount"
              rules={[
                { required: true, message: `${t('Please, enter payment amount')}!` },
                () => ({
                  async validator(_, value) {
                    // TO DO: Invoice with deposit logic
                    if (!value || value <= invoice.debt) {
                      await Promise.resolve();
                      return;
                    }
                    return await Promise.reject(new Error(t('Payment amount must be less or equal {{debt}}', { debt: invoice.debt })));
                  },
                }),
              ]}
            >
              <InputNumber placeholder={t('Enter payment amount')} bordered />
            </Form.Item>
            <Form.Item
              label={t('Select payment method')}
              name="paymentMethod"
              rules={[{ required: true, message: `${t('Please, select payment method')}!` }]}
            >
              <Select options={paymentMethodOptions} placeholder={t('Select payment method')} bordered />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
