import { ThemeColors } from '../../model/types';

export const defaultTheme: ThemeColors = {
  primary: '#313131',
  primaryLight: '#788593',
  secondary: '#EAEEF4',
  secondaryLight: '#F3F6F9',
  secondaryAccent: '#D7DFEA',
  accent: '#2058E7',
  accentLight: '#DEE8FF',
  warn: '#EF9644',
  warnLight: '#FFF3CD66',
  error: '#EF4444',
  errorLight: '#FEE4E2',
  white: '#FFFFFF',
  success: '#2BA865',
  successLight: '#E5F8EE',
};
