import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import { User } from 'entities/User';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { bookingPageActions } from 'pages/BookingPage';
import { ContractInfo } from 'entities/Contract';

export const setInitialUserDetailsFields = (
  formInstance: FormInstance,
  userData: Nullable<User>,
  dispatch: ReturnType<typeof useAppDispatch>,
  contractInfo?: ContractInfo,
): void => {
  const data = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    phone: userData?.phone,
    dateOfBirth: userData?.dateOfBirth ? dayjs(userData.dateOfBirth) : undefined,
    identificationNumber: userData?.identificationNumber,
    language: userData?.language,
    company: userData?.company,
    email: userData?.email,
    street: userData?.street,
    country: userData?.country,
    city: userData?.city,
    house: userData?.house,
    apartment: userData?.apartment,
    zip: userData?.zip,
  };

  dispatch(bookingPageActions.setUserDetails(data));
  formInstance.setFieldsValue({ ...data, accountType: contractInfo?.accountType });
};
