import { Rule, RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { TFunction } from 'i18next';

export const creditAmoutValidator = (
  debt: number,
  maxCreditAmountDueToDepositAmount: Nullable<number>,
  allowedCreditDepositAmount: Nullable<number>,
  t: TFunction,
): Rule => ({
  async validator(_: RuleObject, value: StoreValue): Promise<void> {
    if (maxCreditAmountDueToDepositAmount) {
      if (value !== debt && value > maxCreditAmountDueToDepositAmount) {
        await Promise.reject(
          new Error(
            t('Credited amount must be less or equal {{maxCreditAmountDueToDepositAmount}} or equal {{debt}}', {
              maxCreditAmountDueToDepositAmount,
              debt,
            }),
          ),
        );
        return;
      }

      if (!value || value <= debt) {
        await Promise.resolve();
        return;
      }

      await Promise.reject(new Error(t('Credited amount must be less or equal {{debt}}', { debt })));
      return;
    }

    if (allowedCreditDepositAmount) {
      if (value !== allowedCreditDepositAmount) {
        await Promise.reject(
          new Error(
            t('Credited amount must be equal {{allowedCreditDepositAmount}}', {
              allowedCreditDepositAmount,
            }),
          ),
        );
        return;
      }

      if (!value || value === allowedCreditDepositAmount) {
        await Promise.resolve();
        return;
      }

      await Promise.reject(
        new Error(
          t('Credited amount must be equal {{allowedCreditDepositAmount}}', {
            allowedCreditDepositAmount,
          }),
        ),
      );
      return;
    }
  },
});
