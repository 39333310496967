import _ from 'lodash';
import { ReactElement, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

type QueryObject = Record<string, { value: string; label: string | ReactElement; title: string }>;

interface HookApi {
  parsedQuery: Record<string, string>;
  deleteParam: (deletedKey: string) => void;
  clearQueryString: () => void;
  encode: (queryObject: QueryObject) => void;
  getEncodedString: (queryObject: QueryObject) => Record<string, string>;
  decode: (encryptedString: Record<string, string>) => QueryObject;
}

export const useQueryString = (): HookApi => {
  const [searchParams, setSearchParams] = useSearchParams();

  const parse = useCallback(() => {
    const queryObject = Object.fromEntries(searchParams);

    return queryObject;
  }, [searchParams]);

  const deleteParam = useCallback(
    (deletedKey: string) => {
      searchParams.delete(deletedKey);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const clearQueryString = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const getEncodedString = useCallback((queryObject: QueryObject) => {
    const result: Record<string, string> = {};

    for (const key in queryObject) {
      if (queryObject[key].value != null) {
        const bytes = new TextEncoder().encode(JSON.stringify(queryObject[key])); // using this encoder for encode special symbols
        const binaryString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
        const encrypted = window.btoa(binaryString);

        result[key] = encrypted;
      }
    }

    return result;
  }, []);

  const encode = useCallback(
    (queryObject: QueryObject): void => {
      const result: Record<string, string> = {};

      if (_.isEmpty(queryObject)) {
        setSearchParams({});
      }

      for (const key in queryObject) {
        if (queryObject[key].value != null) {
          const bytes = new TextEncoder().encode(JSON.stringify(queryObject[key])); // using this encoder for encode special symbols
          const binaryString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
          const encrypted = window.btoa(binaryString);

          result[key] = encrypted;

          setSearchParams(result);
        } else {
          deleteParam(key);
        }
      }
    },
    [deleteParam, setSearchParams],
  );

  const decode = useCallback((encryptedParams: Record<string, string>): QueryObject => {
    const result: QueryObject = {};

    try {
      for (const key in encryptedParams) {
        const decodedBinaryString = window.atob(encryptedParams[key]);

        if (!decodedBinaryString) {
          continue;
        }

        const buffer = Uint8Array.from(decodedBinaryString, (m) => m.codePointAt(0) as number);
        const readableString = new TextDecoder().decode(buffer); // using this decoder for decode special symbols

        result[key] = JSON.parse(readableString);
      }

      return result;
    } catch (e: CustomAny) {
      console.log(e);

      return result;
    }
  }, []);

  return {
    parsedQuery: parse(),
    deleteParam,
    clearQueryString,
    encode,
    getEncodedString,
    decode,
  };
};
