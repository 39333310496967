import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Franchise, useGetPaginatedFranchisesQuery } from 'entities/Franchise';
import { stepperModalActions } from 'features/StepperModal';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { ModalMode } from 'app/types/common';
import { CreateFranchiseModal, useCreateFranchiseModal } from 'widgets/CreateFranchiseModal';
import { createWarehouseModalActions } from '../../model/slice/createWarehouseModalSlice';

export const SelectFranchiseStep: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const dispatch = useAppDispatch();

  const { isOpened, openModal, closeModal } = useCreateFranchiseModal();

  const columns = useMemo<ColumnsType<Franchise>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => record.name,
      },
      { title: t('Company code'), key: 'companyCode', render: (_, record) => record.companyCode },
      { title: t('Banking requisites'), key: 'bankingRequisites', render: (_, record) => record.bankingRequisites },
    ],
    [t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Franchise>> = [
    {
      name: t('Next step'),
      onClick: (selectedElements) => {
        dispatch(createWarehouseModalActions.setSelectedFranchise(selectedElements[0])); // only one franchise can be selected
        dispatch(stepperModalActions.changeActiveStepIndex(2));
      },
    },
  ];

  const tableActions: Array<ServerTableActions<Franchise>> = useMemo(
    () => [
      {
        name: t('Did not find franchise? Add'),
        theme: 'clear',
        onClick: openModal,
      },
    ],
    [openModal, t],
  );

  return (
    <>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedFranchisesQuery}
        rowKey="franchiseId"
        search
        title={t('Select a Franchise')}
        selectionType="radio"
        rowSelectionActions={rowSelectionActions}
        tableActions={tableActions}
      />
      <CreateFranchiseModal width={400} isOpen={isOpened} mode={ModalMode.CREATE} onClose={closeModal} />
    </>
  );
});
