import { api } from 'app/config/apiConfig';
import { City } from '../model/types';

export const getCitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCities: build.query<City[], void>({
      query: () => ({
        url: '/cities',
      }),
      providesTags: (result) => ['Cities'],
    }),
  }),
});

export const { useGetCitiesQuery } = getCitiesApi;
