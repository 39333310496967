import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadFormModalSchema, LeadFormModalMode } from '../types';
import { Lead } from 'entities/Lead';

const initialState: LeadFormModalSchema = {
  lead: null,
  mode: null,
};

const leadFromModalSlice = createSlice({
  name: 'leadFromModal',
  initialState,
  reducers: {
    setOpenLeadFormModal: (
      state: LeadFormModalSchema,
      action: PayloadAction<{ mode: Nullable<LeadFormModalMode>; lead?: Nullable<Lead> }>,
    ) => {
      state.lead = action.payload.lead;
      state.mode = action.payload.mode;
    },
  },
});

export const { actions: leadFormModalActions, reducer: leadFormModalReducer } = leadFromModalSlice;
