import { api } from 'app/config/apiConfig';
import { USERS_PATH } from '../utils/constants';
import { Reservation } from 'entities/Reservation';

export const getUserReservationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserReservations: builder.query<Reservation[], string | undefined>({
      query: (userId: string) => ({
        url: `${USERS_PATH}/${userId}/reservations`,
      }),
      providesTags: (result) => ['Reservations'],
    }),
  }),
});

export const { useGetUserReservationsQuery } = getUserReservationsApi;
