export const trimPriceFrequency = (rentalPrice: string | undefined): string | undefined => {
  if (rentalPrice) {
    const separator = rentalPrice.indexOf('/') + 1;
    const frequencySecondChar = separator + 2;

    const priceFrequency = rentalPrice.slice(0, frequencySecondChar);

    return priceFrequency;
  }
};
