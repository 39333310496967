import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { UserRoles } from 'entities/User';
import { StepperModal, stepperModalActions } from 'features/StepperModal';
import { TableFilterContextProvider, useTableFilterContext } from 'features/TableFilter';
import { getOpenedState } from '../model/selectors/getOpenedState';
import { getSelectedRole } from '../model/selectors/getSelectedRole';
import { getSteps } from '../model/selectors/getSteps';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { CreateUserSteps } from '../model/types';
import { AccountDetailsStep } from './AccountDetailsStep';
import { ChooseWarehouseStep } from './ChooseWarehouseStep';

export const CreateUserModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(getOpenedState);
  const steps = useAppSelector(getSteps);
  const selectedRole = useAppSelector(getSelectedRole);

  const { clearAllFilters } = useTableFilterContext();

  const filteredStepsByRole = useMemo(() => {
    const isAdmin = selectedRole === UserRoles.SUPERUSER || selectedRole === UserRoles.MANAGER;

    return isAdmin ? steps : steps.filter(({ label }) => label !== CreateUserSteps.CHOSE_WAREHOUSE);
  }, [selectedRole, steps]);

  const closeModal = useCallback(() => {
    dispatch(createUserModalActions.resetModalState());
    dispatch(stepperModalActions.changeActiveStepIndex(0));

    clearAllFilters();
  }, [dispatch, clearAllFilters]);

  const stepsContent: ReactNode[] = [
    <AccountDetailsStep key="1" onSuccess={closeModal} />,
    <TableFilterContextProvider key="2">
      <ChooseWarehouseStep onSuccess={closeModal} />
    </TableFilterContextProvider>,
  ];

  return <StepperModal width={1118} isOpened={isOpened} onClose={closeModal} steps={filteredStepsByRole} stepsContent={stepsContent} />;
});
