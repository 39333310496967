import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarehouseStatisticCard, WarehouseDoorsModal } from 'features/WarehouseStatisticCard';
import { useGetWarehousesWithOccupancyStatisticQuery } from 'entities/Warehouse';

export const WarehousesPage: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const { data } = useGetWarehousesWithOccupancyStatisticQuery();

  return (
    <div className="mt-7">
      <WarehouseDoorsModal />
      <div className="font-semibold text-3xl">{t('Warehouses')}</div>
      <div className="flex flex-wrap gap-3 mt-4">
        {data?.map((warehouse) => (
          <WarehouseStatisticCard key={warehouse.warehouseId} warehouseData={warehouse} />
        ))}
      </div>
    </div>
  );
});
