import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren, ReactNode } from 'react';

interface ContentLineProps extends PropsWithChildren {
  title: ReactNode;
  titleClassName?: string;
  description?: string;
}

export const ContentLine: FC<ContentLineProps> = memo((props) => {
  const { title, description, titleClassName, children } = props;

  return (
    <div className="flex space-x-6">
      <div className="flex-1">
        <div className={classNames('font-semibold text-base', titleClassName)}>{title}</div>
        {description && <div className="text-sm text-primaryLight mt-1">{description}</div>}
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
});
