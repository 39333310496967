import { api } from 'app/config/apiConfig';
import { SizeCodeForBooking } from '../model/types';

interface Params {
  warehouseId?: string;
}

export const getSizeCodesForBookingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSizeCodesForBooking: build.query<SizeCodeForBooking[], Params>({
      query: (args) => ({
        url: '/size-codes/booking',
        params: args,
      }),
      providesTags: ['Available sizeCodes'],
    }),
  }),
});

export const { useGetSizeCodesForBookingQuery } = getSizeCodesForBookingApi;
