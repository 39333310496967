import { api } from 'app/config/apiConfig';
import { Franchise, UpdateFranchiseParams } from '../model/types';

export const updateFranchiseApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateFranchise: builder.mutation<Franchise, { franchiseId: string; params: UpdateFranchiseParams }>({
      query: ({ franchiseId, params }) => ({
        url: `/franchises/${franchiseId}`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Franchises'],
    }),
  }),
});

export const { useUpdateFranchiseMutation } = updateFranchiseApi;
