import { api } from 'app/config/apiConfig';
import { SizeCode } from 'entities/SizeCode';

export const getSizeCodesByWarehouseIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSizeCodesByWarehouseId: build.query<SizeCode[], Nullable<string | undefined>>({
      query: (warehouseId) => ({
        url: '/size-codes',
        params: { warehouseId },
      }),
    }),
  }),
});

export const { useGetSizeCodesByWarehouseIdQuery } = getSizeCodesByWarehouseIdApi;
