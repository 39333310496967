import React, { FC, memo, ReactNode } from 'react';
import { Divider } from 'antd';
import { ReactComponent as SuccessIcon } from 'shared/assets/icons/SuccessIcon.svg';
import { ReactComponent as SuccessScreenImage } from 'shared/assets/images/SuccessScreenImage.svg';
import { ReactComponent as SuccessScreenMobileImage } from 'shared/assets/images/SuccessScreenMobileImage.svg';

interface SuccessScreenProps {
  title?: string;
  description?: string;
  additionalInfo?: ReactNode;
  actions?: ReactNode;
}

export const SuccessScreen: FC<SuccessScreenProps> = memo((props) => {
  const { title, description, additionalInfo, actions } = props;

  return (
    <>
      <div className="flex flex-col items-center wideDesktop:hidden">
        <SuccessScreenMobileImage />
        <div className="text-success font-semibold text-4xl text-center mb-4">{title}</div>
        <div className="text-primaryLight font-semibold text-2xl text-center mb-11">{description}</div>
        <div className="w-full">{actions}</div>
      </div>
      <div className="hidden space-x-28 justify-center items-center wideDesktop:flex">
        <div className="flex flex-col items-center shadow-xl shadow-secondaryAccent py-11 px-10 rounded-lg max-w-[440px] bg-white">
          <SuccessIcon className="mb-11" />
          <div className="text-success font-semibold text-4xl text-center mb-4">{title}</div>
          <div className="text-primaryLight font-semibold text-2xl text-center">{description}</div>
          <Divider className="border-secondaryAccent relative" dashed>
            <div className="bg-white shadow shadow-inner-xl shadow-secondaryLight w-10 h-10 rounded-full absolute -translate-y-1/2 -translate-x-1/2 -left-10" />
            <div className="bg-white shadow shadow-inner-xl-reversed shadow-secondaryLight w-10 h-10 rounded-full absolute -translate-y-1/2 -translate-x-1/2 -right-20" />
          </Divider>
          <div className="mb-11 w-full">{additionalInfo}</div>
          <div className="w-full">{actions}</div>
        </div>
        <div>
          <SuccessScreenImage />
        </div>
      </div>
    </>
  );
});
