import { api } from 'app/config/apiConfig';

import { Box } from '../model/types';

export const deactivateBoxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deactivateBox: builder.mutation<Box, string>({
      query: (boxId) => ({
        url: `/boxes/${boxId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['Boxes', 'Available boxes', 'Warehouses for booking'],
    }),
  }),
});

export const { useDeactivateBoxMutation } = deactivateBoxApi;
