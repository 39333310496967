export enum CountryType {
  AT = 'AT',
  DE = 'DE',
  PL = 'PL',
  CH = 'CH',
}

export interface City {
  name: string;
  cityId: string;
  timezone: string;
  longitude: number;
  latitude: number;
  country: CountryType;
  createdAt: string;
  updatedAt: string;
}
