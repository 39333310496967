import { Insurance } from 'entities/Insurance';

export interface InsuranceFormDrawerSchema {
  mode: Nullable<DrawerMode>;
  insurance?: Nullable<Insurance>;
}

export interface InsuranceFormFields {
  warehouseId: string;
  name: string;
  coverageAmount: number;
  dailyRate?: number;
  weekRate?: number;
  monthRate?: number;
  insurerPrice?: number;
}

export enum DrawerMode {
  INSURANCE_EDIT = 'insurance_edit',
  INSURANCE_CREATE = 'insurance_create',
}
