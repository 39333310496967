import { api } from 'app/config/apiConfig';
import { Contract } from '../model/types';

export const removeNotificationLeaveDateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    removeNotificationLeaveDate: builder.mutation<Contract, Nullable<string>>({
      query: (contractId) => ({
        url: `/contracts/${contractId}/remove-notify-leave`,
        method: 'PUT',
      }),
      invalidatesTags: ['Contracts', 'Contract details'],
    }),
  }),
});

export const { useRemoveNotificationLeaveDateMutation } = removeNotificationLeaveDateApi;
