import { api } from 'app/config/apiConfig';
import { City } from '../model/types';

export const deleteCityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteCity: builder.mutation<City, string>({
      query: (cityId: string) => ({
        url: `/cities/${cityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cities'],
    }),
  }),
});

export const { useDeleteCityMutation } = deleteCityApi;
