import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Box } from 'entities/Box';
import { WarehouseForBooking } from 'entities/Warehouse';
import { UserDetailsInfo } from 'widgets/UserDetailsForm';
import { BookingPageSchema } from '../types';
import { userDetailsFormState } from './intitialStates';

const initialState: BookingPageSchema = {
  warehouseInfo: null,
  boxInfo: null,
  selectedSizeCodeId: null,
  useDetailsForm: userDetailsFormState,
};

const bookingPageSlice = createSlice({
  name: 'bookingPage',
  initialState,
  reducers: {
    setWarehouseInfo: (state: BookingPageSchema, action: PayloadAction<WarehouseForBooking>) => {
      state.warehouseInfo = action.payload;
    },
    setBoxInfo: (state: BookingPageSchema, action: PayloadAction<{ selectedBox: Box; selectedSizeCodeId: string }>) => {
      state.boxInfo = action.payload.selectedBox;
      state.selectedSizeCodeId = action.payload.selectedSizeCodeId;
    },
    setUserDetails: (state: BookingPageSchema, action: PayloadAction<Partial<UserDetailsInfo>>) => {
      state.useDetailsForm = { ...state.useDetailsForm, ...action.payload };
    },
    clearInfoAfterWarehouseChange: (state: BookingPageSchema) => {
      state.boxInfo = null;
      state.selectedSizeCodeId = null;
    },
    resetAllBookingInfo: (state: BookingPageSchema) => {
      state.warehouseInfo = null;
      state.boxInfo = null;
      state.selectedSizeCodeId = null;
      state.useDetailsForm = userDetailsFormState;
    },
  },
});

export const { actions: bookingPageActions, reducer: bookingPageReducer } = bookingPageSlice;
