import { api } from 'app/config/apiConfig';
import { City } from '../model/types';

export const createCityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createCity: builder.mutation<City, City>({
      query: (data) => ({
        url: '/cities',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Cities'],
    }),
  }),
});

export const { useCreateCityMutation } = createCityApi;
