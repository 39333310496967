import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { getBoxFormState } from '../model/selectors/getBoxFormState';
import { boxFormDrawerActions } from '../model/slice/boxFormDrawerSlice';
import { BoxForm } from './BoxForm';

export const BoxFormDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const boxFormState = useAppSelector(getBoxFormState);

  const closeBoxFormDrawer = useCallback((): void => {
    dispatch(boxFormDrawerActions.setOpenBoxForm({ mode: null, box: null }));
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(boxFormState.mode)} onClose={closeBoxFormDrawer}>
      <BoxForm box={boxFormState.box} mode={boxFormState.mode} handleClose={closeBoxFormDrawer} />
    </Drawer>
  );
});
