import { Form } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Toggle } from 'shared/ui/Toggle';
import { getFormData } from '../../../model/selectors/getFormData';
import { createWarehouseModalActions } from '../../../model/slice/createWarehouseModalSlice';

export const ComingSoonStatusToggle: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const dispatch = useAppDispatch();

  const { isComingSoon } = useAppSelector(getFormData);

  const changeComingSoonStatus = useCallback(
    (isComingSoon: boolean) => {
      dispatch(createWarehouseModalActions.changeFormData({ isComingSoon }));
    },
    [dispatch],
  );

  return (
    <Form.Item name="isComingSoon" valuePropName="checked">
      <Toggle checked={isComingSoon} onChange={changeComingSoonStatus}>
        {t('Coming soon')}
      </Toggle>
    </Form.Item>
  );
});
