export enum InvoiceStatusFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum LeadsListFilterKeys {
  selectedWarehouseId = 'selectedWarehouseId',
  isActive = 'isActive',
  leadStatuses = 'leadStatuses',
  squareFrom = 'squareFrom',
  squareTo = 'squareTo',
  creationDateFrom = 'creationDateFrom',
  creationDateTo = 'creationDateTo',
}
