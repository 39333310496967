import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getSocketConnection } from 'app/socketConnection';
import { useState } from 'react';

export const useSubscriber = <T>(eventName: string): Nullable<T> => {
  const [data, setData] = useState<Nullable<T>>(null);

  const socketConnection = useAppSelector(getSocketConnection);

  socketConnection?.on(eventName, (result: Nullable<T>) => {
    setData(result);
  });

  return data;
};
