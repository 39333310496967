import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Reservation } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  warehouseId?: string | string[];
  userId?: string;
  enableExpired?: boolean;
  enableCancelled?: boolean;
  enableTakenUp?: boolean;
  orderBy?: string;
}

export const getPaginatedReservationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedReservations: builder.query<Pagination<Reservation>, Params>({
      query: (args) => ({
        url: '/reservations',
        params: args,
      }),
      providesTags: (result) => ['Reservations'],
    }),
  }),
});

export const { useGetPaginatedReservationsQuery } = getPaginatedReservationsApi;
