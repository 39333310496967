import { api } from 'app/config/apiConfig';

export const sendInvoiceByEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendInvoiceByEmail: builder.mutation<void, string>({
      query: (invoiceId) => ({
        url: `/billing-documents/invoices/${invoiceId}/send-invoice`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useSendInvoiceByEmailMutation } = sendInvoiceByEmailApi;
