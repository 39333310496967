import { SizeCodeForListing } from 'entities/SizeCode';

export interface SizeCodeFormDrawerSchema {
  mode: Nullable<DrawerMode>;
  sizeCode?: Nullable<SizeCodeForListing>;
}

export enum DrawerMode {
  SIZE_CODE_EDIT = 'size_code_edit',
  SIZE_CODE_CREATE = 'size_code_create',
}
