import React, { FC, useEffect, useState, memo } from 'react';
import { Stripe, StripeElementLocale, StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm } from 'widgets/CheckoutForm';
import i18n from 'app/config/i18Config/i18n';
import { getLanguageFormat } from 'shared/utils/helpers/getLanguageFormat';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';

export const CheckoutWrapper: FC = memo(() => {
  const [stripe, setUpStripe] = useState<Promise<Stripe | null>>();

  const { parsedQuery } = useQueryString();

  const options: StripeElementsOptions = {
    clientSecret: parsedQuery.secret,
  };

  useEffect(() => {
    if (parsedQuery.publicKey) {
      const locale = getLanguageFormat(i18n.language) as StripeElementLocale;

      const stripePromise = loadStripe(parsedQuery.publicKey, {
        locale,
      });

      setUpStripe(stripePromise);
    }
  }, [parsedQuery.publicKey]);

  if (!stripe) {
    return null;
  }

  return (
    <Elements stripe={stripe} options={options}>
      <CheckoutForm paymentData={parsedQuery} />
    </Elements>
  );
});
