import { Box, CachedBox } from 'entities/Box';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

export const getDepositAmount = (isDepositApplied: boolean, box: Nullable<Box | CachedBox>): Nullable<number> => {
  if (!isDepositApplied || !box) {
    return null;
  }

  if (box.monthRate) {
    return box.monthRate;
  }

  if (box.weekRate) {
    return roundNumber(box.weekRate * 2);
  }

  if (box.dailyRate) {
    return roundNumber(box.dailyRate * 7, 2);
  }

  return null;
};
