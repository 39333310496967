import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InsuranceFormDrawerSchema, DrawerMode } from '../types';
import { Insurance } from 'entities/Insurance';

const initialState: InsuranceFormDrawerSchema = {
  insurance: null,
  mode: null,
};

const insuranceFormDrawerSlice = createSlice({
  name: 'insuranceFormDrawer',
  initialState,
  reducers: {
    setOpenInsuranceForm: (
      state: InsuranceFormDrawerSchema,
      action: PayloadAction<{ mode: Nullable<DrawerMode>; insurance?: Nullable<Insurance> }>,
    ) => {
      state.mode = action.payload.mode;
      state.insurance = action.payload.insurance;
    },
  },
});

export const { actions: insuranceFormDrawerActions, reducer: insuranceFormDrawerReducer } = insuranceFormDrawerSlice;
