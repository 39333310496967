import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Header } from 'features/Header';
import { LangSwitcher } from 'features/LangSwitcher';
import { Stepper } from 'features/Stepper';
import React, { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { HeaderAuthAction } from 'features/HeaderAuthAction';
import { AppLogo } from 'app/appState';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getLoggedUserData } from 'entities/User';
import { getActiveStep } from '../model/selectors/getActiveStep';
import { getMaxProgressStep } from '../model/selectors/getMaxProgressStep';
import { getSteps } from '../model/selectors/getSteps';
import { bookingHeaderActions } from '../model/slice/bookingHeaderSlice';
import { BookingSteps } from '../model/types';
import { useWatchStep } from '../utils/hooks/useWatchStep';
import { ReactComponent as RightIcon } from 'shared/assets/icons/RightIcon.svg';

export const BookingHeader: FC = memo(() => {
  const { t } = useAppTranslation('booking');

  const dispatch = useAppDispatch();

  const activeStep = useAppSelector(getActiveStep);
  const maxProgressStep = useAppSelector(getMaxProgressStep);
  const steps = useAppSelector(getSteps);
  const loggedUserData = useAppSelector(getLoggedUserData);

  const currentStepIndex = steps.findIndex((step) => step.label === activeStep);
  const maxProgressStepIndex = steps.findIndex((step) => step.label === maxProgressStep);
  const hasPreviousStep = currentStepIndex > 0;
  const hasNextStep = currentStepIndex < maxProgressStepIndex;

  useWatchStep();

  const actions = useMemo<ReactNode[]>(() => {
    return [<LangSwitcher key="1" />, <HeaderAuthAction key="2" userEmail={loggedUserData?.email} />];
  }, [loggedUserData?.email]);

  const changeActiveStep = useCallback(
    (stepName: string): void => {
      dispatch(bookingHeaderActions.changeActiveStep(stepName as BookingSteps));
    },
    [dispatch],
  );

  const goToPreviousStep = useCallback((): void => {
    dispatch(bookingHeaderActions.goToPreviousStep());
  }, [dispatch]);

  const continueBooking = useCallback((): void => {
    dispatch(bookingHeaderActions.goToMaxProgressStep());
  }, [dispatch]);

  return (
    <div className="sticky top-0 z-20 max-h-headerHeight">
      <Header logo={<AppLogo />} actions={actions} />
      <Stepper steps={steps} activeStep={activeStep} onChangeStep={changeActiveStep} />
      <div className="flex justify-between bg-white py-4">
        <Button
          theme="clear"
          icon={<RightIcon className="[&>path]:fill-accent rotate-180" />}
          iconPosition="prev"
          isDisabled={!hasPreviousStep}
          onClick={goToPreviousStep}
        >
          {t('Previous step')}
        </Button>
        <Button theme="clear" icon={<RightIcon className="[&>path]:fill-accent" />} isDisabled={!hasNextStep} onClick={continueBooking}>
          {t('Continue booking')}
        </Button>
      </div>
    </div>
  );
});
