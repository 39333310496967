import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { ContractInfo } from 'entities/Contract';
import {
  CompleteRegisterUserParams,
  getInfoFromToken,
  getLoggedUserData,
  RegisterUserParams,
  useAuthorizedState,
  useCheckUserDataComplete,
  useCompleteRegistrationMutation,
  useRegistrationMutation,
} from 'entities/User';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Box } from 'entities/Box';
import { LeadData, useCreateLeadMutation } from 'entities/Lead';
import { WarehouseForBooking } from 'entities/Warehouse';
import { showNotification } from 'app/providers/NotificationsProvider';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { AppLanguage } from 'app/config/i18Config/types';
import { UserDetailsInfo } from '../../model/types';
import i18n from 'app/config/i18Config/i18n';

interface HookArgs {
  contractInfo: ContractInfo;
  warehouseInfo: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  closeConfirmModal: () => void;
  onCompleteStep: () => void;
  formData?: Nullable<UserDetailsInfo>;
}

interface HookApi {
  isProceedToPaymentLoading: boolean;
  executeProceedToPayment: () => Promise<void>;
}

export const useProceedToPayment = ({
  contractInfo,
  onCompleteStep,
  closeConfirmModal,
  formData,
  boxInfo,
  warehouseInfo,
}: HookArgs): HookApi => {
  const { t } = useAppTranslation(['booking', 'common']);

  const loggedUserData = useAppSelector(getLoggedUserData);
  const isAuthorized = useAuthorizedState();
  const isUserDataCompleted = useCheckUserDataComplete();

  const [registration, { isLoading: isRegistrationLoading }] = useRegistrationMutation();
  const [completeRegistration, { isLoading: isCompleteRegistrationLoading }] = useCompleteRegistrationMutation();
  const [createLead] = useCreateLeadMutation();

  const isLoading = isRegistrationLoading || isCompleteRegistrationLoading;

  const proceedToPayment = useCallback(async (): Promise<void> => {
    if (formData) {
      const {
        firstName,
        lastName,
        email,
        street,
        country,
        city,
        password,
        phone,
        zip,
        house,
        dateOfBirth,
        company,
        identificationNumber,
        apartment,
      } = formData;

      const completeRegistrationData = {
        firstName,
        lastName,
        company,
        phone,
        country,
        dateOfBirth: getServerDateFormat(dateOfBirth),
        street,
        house,
        zip,
        apartment,
        city,
        language: i18n.language as AppLanguage,
        identificationNumber,
        accountType: contractInfo.accountType,
      };

      const registrationData = {
        ...completeRegistrationData,
        email,
        password,
      };

      const today = dayjs().startOf('day');

      const leadData: LeadData = {
        firstName,
        lastName,
        phone,
        email,
        language: i18n.language as AppLanguage,
        warehouseId: warehouseInfo?.warehouseId,
        square: boxInfo?.sizeCode?.square,
        note: t('Lead registration from booking page'),
      };

      try {
        if (!isAuthorized) {
          const tokens = await registration(registrationData as RegisterUserParams).unwrap();

          if (tokens) {
            const userId = getInfoFromToken(tokens.accessToken)?.userId;

            await createLead({
              ...leadData,
              userId,
              note: t('Lead completed full registration from booking page'),
              userCreationDate: getServerDateFormat(today),
            }).unwrap();
          }

          showNotification('info', t('Success', { ns: 'common' }), t('User has been registered successfully', { ns: 'common' }));
          onCompleteStep();
        } else if (loggedUserData?.userId && !isUserDataCompleted) {
          await completeRegistration({
            userId: loggedUserData.userId,
            userData: completeRegistrationData as CompleteRegisterUserParams,
          }).unwrap();

          await createLead({
            ...leadData,
            userId: loggedUserData.userId,
            note: t('Lead completed full registration from booking page'),
            userCreationDate: getServerDateFormat(today),
          }).unwrap();

          showNotification('info', t('Success', { ns: 'common' }), t('User has been registered successfully', { ns: 'common' }));
          closeConfirmModal();
          onCompleteStep();
        } else {
          onCompleteStep();
        }
      } catch (error: CustomAny) {
        await createLead({ ...leadData, isThirdPartyAuthorized: true });

        if (isErrorApiResponse(error)) {
          const errorMessage = Array.isArray(error.data.message)
            ? error.data.message.map((message) => t(message, { ns: 'common' }))
            : t(error.data.message, { ns: 'common' });
          showNotification('error', t('Error', { ns: 'common' }), errorMessage);
        } else {
          console.log(error);
        }
        console.log(error);
      } finally {
        closeConfirmModal();
      }
    }
  }, [
    formData,
    contractInfo.accountType,
    warehouseInfo?.warehouseId,
    boxInfo?.sizeCode?.square,
    t,
    isAuthorized,
    loggedUserData?.userId,
    isUserDataCompleted,
    registration,
    onCompleteStep,
    createLead,
    completeRegistration,
    closeConfirmModal,
  ]);

  return { executeProceedToPayment: proceedToPayment, isProceedToPaymentLoading: isLoading };
};
