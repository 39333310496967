import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { useGetCurrencySymbol } from 'app/appState';
import { getBoxRentalPrice, Box, useGetAvailableBoxesQuery } from 'entities/Box';
import { ServerTable } from 'shared/ui/ServerTable';
import { stepperModalActions } from 'features/StepperModal';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useTableFilterContext } from 'features/TableFilter';
import { getSelectedWarehouse } from '../../../model/selectors/getSelectedWarehouse';
import { sendOfferModalActions } from '../../../model/slice/sendOfferModalSlice';
import { BoxesListFilter } from './BoxesListFilter';
import { transformBoxesListFilters } from './utils/transformBoxesListFilters';

export const SelectBoxStep: FC = memo(() => {
  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const selectedWarehouse = useAppSelector(getSelectedWarehouse);

  const { setFiltersOpened, appliedFilters, tags } = useTableFilterContext();

  const currencySymbol = useGetCurrencySymbol();

  const tableActions: Array<ServerTableActions<Box>> = useMemo(
    () => [
      {
        name: t('Filters', { ns: 'boxes' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [setFiltersOpened, t],
  );

  const columns = useMemo<ColumnsType<Box>>(
    () => [
      {
        title: t('Box'),
        key: 'name',
        render: (_, record) => record.name,
      },
      {
        title: t('Size'),
        key: 'sizeCode.square',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => {
          return `${record.sizeCode.square} m²`;
        },
      },
      {
        title: t('Location'),
        key: 'floor.floorId',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => `${getLocalizedString(record.floor?.name) || ''}`,
      },
      {
        title: t('Price'),
        key: 'price',
        render: (_, record) => {
          const price = getBoxRentalPrice(record);

          return price ? `${price} ${currencySymbol}` : '';
        },
      },
    ],
    [currencySymbol, t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Box>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        dispatch(sendOfferModalActions.setSelectedBoxes(selectedElements));
        dispatch(stepperModalActions.changeActiveStepIndex(3));
      },
    },
  ];

  return (
    <>
      <ServerTable
        columns={columns}
        rowKey="boxId"
        search
        title={t('Choose Boxes')}
        selectionType="checkbox"
        rowSelectionActions={rowSelectionActions}
        fetchData={useGetAvailableBoxesQuery}
        defaultFilters={{
          warehouseId: selectedWarehouse?.warehouseId,
          isAvailable: true,
          ...transformBoxesListFilters(appliedFilters),
        }}
        tags={tags}
        tableActions={tableActions}
      />
      <BoxesListFilter />
    </>
  );
});
