import { useCallback, useEffect, useState } from 'react';

interface HookApi {
  isCountDown: boolean;
  isExpired: boolean;
  secondsLeft: Nullable<number>;
  runCountDown: (timeSec: number) => void;
  resetCountDown: () => void;
}

export const useCountDown = (): HookApi => {
  const [counter, setCounter] = useState<Nullable<number>>(null);

  const isCountDown = counter ? counter > 0 : false;

  const isExpired = counter === 0;

  const runCountDown = useCallback((timeSec: number): void => {
    setCounter(timeSec);
  }, []);

  const resetCountDown = useCallback((): void => {
    setCounter(null);
  }, []);

  useEffect(() => {
    if (counter !== null) {
      const timer = setInterval(() => {
        if (counter > 0) {
          setCounter(counter - 1);
        } else {
          resetCountDown();
        }
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [counter, resetCountDown]);

  return { isCountDown, isExpired, secondsLeft: counter, runCountDown, resetCountDown };
};
