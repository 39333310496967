export enum AppRoutes {
  HOME = '/',
  NOT_FOUND = '*',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  WAREHOUSES = '/warehouses',
  CITIES = '/cities',
  USERS = '/users',
  CONTRACTS = '/contracts',
  PAYMENTS = '/payments',
  NOTES = '/notes',
  TASKS = '/tasks',
  GENERAL = '/general',
  BOOKING = '/booking',
  MY_BOXES = '/my-boxes',
  GLOBAL_SETTINGS = '/global-settings',
  CHECKOUT = '/checkout',
  PAYMENT_SUCCESS = '/payment-success',
  PAYMENT_FAILED = '/payment-failed',
  BOXES = '/boxes',
  INVOICES = '/billing-documents/invoices',
  CREDIT_NOTES = '/billing-documents/credit-notes',
  INSURANCE = '/billing-documents/insurance',
  RESERVATIONS = '/reservations',
  ADMIN_PANEL = '/admin-panel',
  LATE_CHARGE_SETTINGS = '/late-charge-settings',
  PROFILE = '/profile',
  BILLING_DOCUMENTS = '/billing-documents',
  INSURANCE_SETTINGS = '/insurance-settings',
}
