import { useAppTranslation } from 'app/config/i18Config/hooks';
import { SizeCodeForBooking, SizeGroup } from 'entities/SizeCode';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { SizeFilterButton } from '../../model/types';

interface UniqueSizeGroup {
  sizeGroup: SizeGroup;
  minBoxSquare: Nullable<number>;
  maxBoxSquare: Nullable<number>;
  availableBoxesCount: number[];
}

export const useSizeGroupsFilters = (sizeCodes: SizeCodeForBooking[] | undefined): SizeFilterButton[] => {
  const { t } = useAppTranslation('booking');

  const { isDesktop } = useScreenBreakpoints();

  if (!sizeCodes) {
    return [];
  }

  const labelsMap: Record<SizeGroup, string> = {
    [SizeGroup.XS]: `${t('Extra small')} (${SizeGroup.XS})`,
    [SizeGroup.S]: `${t('Small')} (${SizeGroup.S})`,
    [SizeGroup.M]: `${t('Medium')} (${SizeGroup.M})`,
    [SizeGroup.L]: `${t('Large')} (${SizeGroup.L})`,
    [SizeGroup.XL]: `${t('Extra large')} (${SizeGroup.XL})`,
  };

  const getDescription = (minBoxSquare: Nullable<number>, maxBoxSquare: Nullable<number>): string | undefined => {
    const hasSizes = Boolean(minBoxSquare) && Boolean(maxBoxSquare);

    return hasSizes ? t(`${isDesktop ? 'Size ' : ''}{{minBoxSquare}} - {{maxBoxSquare}} m2`, { minBoxSquare, maxBoxSquare }) : undefined;
  };

  const uniqueSizeGroups: Record<SizeGroup | string, UniqueSizeGroup | never> = {}; // "string" and "never" types for empty object

  sizeCodes.forEach((sizeCode) => {
    const isGroupExists = Boolean(uniqueSizeGroups[sizeCode.sizeGroup]);

    if (!isGroupExists) {
      uniqueSizeGroups[sizeCode.sizeGroup] = {
        sizeGroup: sizeCode.sizeGroup,
        minBoxSquare: sizeCode.square,
        maxBoxSquare: sizeCode.square,
        availableBoxesCount: [sizeCode.availableBoxesCount],
      };
    } else {
      const minBoxSquare =
        sizeCode.square != null
          ? Math.min(sizeCode.square, uniqueSizeGroups[sizeCode.sizeGroup].minBoxSquare ?? sizeCode.square)
          : uniqueSizeGroups[sizeCode.sizeGroup].minBoxSquare;
      const maxBoxSquare =
        sizeCode.square != null
          ? Math.max(sizeCode.square, uniqueSizeGroups[sizeCode.sizeGroup].maxBoxSquare ?? sizeCode.square)
          : uniqueSizeGroups[sizeCode.sizeGroup].maxBoxSquare;

      uniqueSizeGroups[sizeCode.sizeGroup].minBoxSquare = roundNumber(minBoxSquare);
      uniqueSizeGroups[sizeCode.sizeGroup].maxBoxSquare = roundNumber(maxBoxSquare);
      uniqueSizeGroups[sizeCode.sizeGroup].availableBoxesCount = [
        ...uniqueSizeGroups[sizeCode.sizeGroup].availableBoxesCount,
        sizeCode.availableBoxesCount,
      ];
    }
  });

  const sizeFilterButtons: SizeFilterButton[] = Object.values(uniqueSizeGroups).map((group) => ({
    label: labelsMap[group.sizeGroup],
    description: getDescription(group.minBoxSquare, group.maxBoxSquare),
    value: group.sizeGroup,
    isDisabled: group.availableBoxesCount.every((count) => count < 1),
  }));

  return [{ label: t('All units Sizes'), description: t('XS, S, M, L, XL'), value: 'All' }, ...sizeFilterButtons];
};
