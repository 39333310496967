import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import { Modal } from 'shared/ui/Modal';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Checkbox } from 'shared/ui/Checkbox';
import { Button } from 'shared/ui/Button';
import DOMPurify from 'dompurify';
import { NoResults } from 'shared/ui/NoResults';
import RestrictedItemsImage from 'shared/assets/images/RestrictedItemsImage.png';

interface ContractPreviewModalProps {
  isOpened: boolean;
  isLoading: boolean;
  contractPreview: Node | undefined;
  onOk: () => void;
  onCancel: () => void;
}

export const ContractPreviewModal: FC<ContractPreviewModalProps> = memo((props) => {
  const { isOpened, isLoading, contractPreview, onOk, onCancel } = props;

  const { t } = useAppTranslation(['booking', 'common']);

  const [termsAgreement, setTermsAgreement] = useState(false);

  const toggleTermsAgreement = useCallback((): void => {
    setTermsAgreement((prevState) => !prevState);
  }, []);

  const actions = (): ReactNode => (
    <div className="flex space-x-3 justify-end">
      <Button theme="secondary" isLoading={isLoading} onClick={onCancel}>
        {t('Cancel', { ns: 'common' })}
      </Button>
      <Button isDisabled={!termsAgreement} isLoading={isLoading} onClick={onOk}>
        {t('Confirm', { ns: 'common' })}
      </Button>
    </div>
  );

  return (
    <Modal isOpen={isOpened} footer={actions()} onClose={onCancel}>
      {contractPreview ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contractPreview) }} />
          <img className="mt-10 contrast-200" src={RestrictedItemsImage} alt="Restricted items" />
        </>
      ) : (
        <NoResults />
      )}
      <Checkbox className="my-5" name="termsAgreement" checked={termsAgreement} onChange={toggleTermsAgreement}>
        {t('By continuing I agree with the terms and conditions')}
      </Checkbox>
    </Modal>
  );
});
