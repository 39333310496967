import { Divider } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useGetPaginatedHistoryQuery } from 'entities/History';
import { HistoryActionRow } from './HistoryAction';
import { HistoryModal } from './HistoryModal';

interface LatestActivityProps {
  email: string;
  loggedUserEmail: string | undefined;
}

export const LatestActivity: FC<LatestActivityProps> = memo((props) => {
  const { email, loggedUserEmail } = props;

  const { t } = useAppTranslation('profile');

  const [openedHistoryModal, setOpenedHistoryModal] = useState(false);

  const { data } = useGetPaginatedHistoryQuery({ userName: email, limit: 5 }, { skip: !email });

  const hasRecords = (data?.content.length ?? 0) > 0;

  const openHistoryModal = useCallback(() => {
    setOpenedHistoryModal(true);
  }, []);

  const closeHistoryModal = useCallback(() => {
    setOpenedHistoryModal(false);
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between mb-7">
        <div className="text-2xl font-semibold">{t('Latest Activity')}</div>
        {hasRecords && (
          <div className="text-lg text-accent cursor-pointer" onClick={openHistoryModal}>
            {t('Show all')}
          </div>
        )}
      </div>
      <div className="flex flex-col">
        {hasRecords ? (
          data?.content.map((action, index, array) => (
            <div key={action.actionId}>
              <HistoryActionRow data={action} loggedUserEmail={loggedUserEmail} />
              {index < array.length - 1 ? <Divider className="border-secondaryAccent ml-[20px]" type="vertical" /> : null}
            </div>
          ))
        ) : (
          <div className="text-xl text-primaryLight">{t('No data')}</div>
        )}
      </div>
      <HistoryModal email={email} loggedUserEmail={loggedUserEmail} isOpened={openedHistoryModal} onCLose={closeHistoryModal} />
    </div>
  );
});
