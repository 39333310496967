import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

interface DiscountLabelProps {
  discount: number;
}

export const DiscountLabel: FC<DiscountLabelProps> = memo((props) => {
  const { discount } = props;

  const { t } = useAppTranslation('booking');

  const { isDesktop } = useScreenBreakpoints();

  if (!isDesktop) {
    return (
      <div className="bg-success px-4 py-1 rounded-b-lg text-white leading-tight">
        <div className="font-semibold text-xs uppercase">
          {discount}% {t('discount')}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-success p-2 rounded-lg text-white leading-tight">
      <div className="font-semibold text-xs uppercase">
        {discount}% {t('discount')}
      </div>
    </div>
  );
});
