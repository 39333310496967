import React, { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { CachedBox, useOpenBoxMutation } from 'entities/Box';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { useCountDown } from 'shared/utils/hooks/useCountDown';
import { useGeolocation } from 'shared/utils/hooks/useGeolocation';

interface OpenBoxProps {
  box: Nullable<CachedBox>;
  action: ReactElement;
  openByAdmin?: boolean;
}

export const OpenBox: FC<OpenBoxProps> = memo((props) => {
  const { box, action, openByAdmin } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const [isOpenedConfirm, setOpenedConfirm] = useState<boolean>(false);

  const [openBox, { isLoading: isOpenLoading }] = useOpenBoxMutation();

  const coords = useGeolocation();

  const { isCountDown, runCountDown } = useCountDown();

  const isExternalBox = box?.timeToOpenLockMS != null;

  const description = t(isExternalBox ? 'Press confirm to open/close box {{boxName}}' : 'Are you sure you want to open box {{boxName}}', {
    boxName: box?.name,
    ns: 'common',
  });

  const isExternalBoxOpening = isOpenLoading || isCountDown;

  const isDisabled = isExternalBox ? isExternalBoxOpening : isOpenLoading;

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  useEffect(() => {
    if (!isCountDown) {
      closeConfirmModal();
    }
  }, [closeConfirmModal, isCountDown]);

  const handleOpenBox = useCallback(async () => {
    try {
      if (box?.lockAddress) {
        const params = {
          boxId: box.originalEntityId,
          longitude: openByAdmin ? coords.lng : undefined,
          latitude: openByAdmin ? coords.lat : undefined,
        };

        await openBox(params).unwrap();

        isExternalBox && runCountDown(box?.timeToOpenLockMS ? box.timeToOpenLockMS / 1000 : 0);
      } else {
        showNotification('error', t('Error', { ns: 'common' }), t('Box does not have lock address', { ns: 'common' }));
      }
    } catch (error: CustomAny) {
      console.log(error);
      const errorMessage = t(error?.data?.message?.[0] || 'Error while opening box', { ns: 'common' });

      showNotification('error', t('Error', { ns: 'common' }), errorMessage);
    }
  }, [box, coords, isExternalBox, openBox, openByAdmin, runCountDown, t]);

  return (
    <>
      {React.cloneElement(action, { onClick: openConfirmModal })}
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Open box', { ns: 'common' })}
        description={description}
        isLoading={isDisabled}
        onOk={handleOpenBox}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
