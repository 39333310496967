import { api } from 'app/config/apiConfig';
import { Floor } from '../model/types';

export const getFloorsByWarehouseIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFloorsByWarehouseId: build.query<Floor[], Nullable<string | undefined>>({
      query: (warehouseId) => ({
        url: `warehouses/${warehouseId}/floors`,
      }),
    }),
  }),
});

export const { useGetFloorsByWarehouseIdQuery } = getFloorsByWarehouseIdApi;
