import { api } from 'app/config/apiConfig';
import { Contract } from '../model/types';

export const getContractByIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractById: builder.query<Contract, Nullable<string>>({
      query: (contractId) => ({
        url: `/contracts/${contractId}`,
      }),
      providesTags: (result) => ['Contract details'],
    }),
  }),
});

export const { useGetContractByIdQuery } = getContractByIdApi;
