import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { SizeCodeForBooking } from 'entities/SizeCode';
import SizeCodeDefaultImage from 'shared/assets/images/SizeCodeDefaultImage.png';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';

interface SizeGuideSlideProps {
  sizeCode: SizeCodeForBooking;
}

export const SizeGuideSlide: FC<SizeGuideSlideProps> = memo((props) => {
  const { sizeCode } = props;

  const { t } = useAppTranslation('booking');

  return (
    <div className="flex flex-col">
      <div className="mb-3 font-semibold text-2xl">{t('Box {{square}} m²', { square: sizeCode.square })}</div>
      <img
        className="w-[500px] h-[500px] object-contain self-center mb-3"
        src={sizeCode.imageUrl || SizeCodeDefaultImage}
        alt={sizeCode.square.toString()}
      />
      <div className="mb-1 font-semibold text-lg">{t('What Fits?')}</div>
      <div className="font-normal">{getLocalizedString(sizeCode.extendedDescription)}</div>
    </div>
  );
});
