import { Tag as AntdTag } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren, ReactNode } from 'react';

type TagTheme = 'info' | 'warn' | 'error';
type TagTextSize = 'small' | 'medium' | 'large';

interface TagProps extends PropsWithChildren {
  icon?: ReactNode;
  theme?: TagTheme;
  textSize?: TagTextSize;
  className?: string;
  onClick?: () => void;
}

export const Tag: FC<TagProps> = memo((props) => {
  const { children, icon, theme = 'info', textSize = 'small', className, onClick } = props;

  const themesMap: Record<TagTheme, { background: string; text: string }> = {
    info: { background: 'bg-accentLight', text: 'text-accent' },
    warn: { background: 'bg-warnLight', text: 'text-warn' },
    error: { background: 'bg-errorLight', text: 'text-error' },
  };

  const textSizesMap: Record<TagTextSize, string> = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
  };

  return (
    <AntdTag
      onClick={onClick}
      className={classNames(
        'flex items-center py-2 px-3 m-0 w-fit [&>span]:ml-2',
        { 'cursor-pointer': Boolean(onClick) },
        themesMap[theme].background,
        className,
      )}
      icon={icon}
      bordered={false}
    >
      <span className={classNames('font-normal break-normal whitespace-normal', themesMap[theme].text, textSizesMap[textSize])}>
        {children}
      </span>
    </AntdTag>
  );
});
