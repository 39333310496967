import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData } from '../../model/selectors/getLoggedUserData';
import { UserRoles } from 'entities/User';

export const useAdminRole = (): boolean => {
  const user = useAppSelector(getLoggedUserData);

  const isAdmin = user && [UserRoles.SUPERUSER, UserRoles.MANAGER].includes(user.role);

  return Boolean(isAdmin);
};
