import { FormInstance } from 'antd';
import React, { FC, useCallback } from 'react';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface ProceedToPaymentButtonProps {
  formInstance: FormInstance;
  isDisabled: boolean;
  setProceedToPaymentMode: () => void;
}

export const ProceedToPaymentButton: FC<ProceedToPaymentButtonProps> = (props) => {
  const { isDisabled, setProceedToPaymentMode, formInstance } = props;

  const { t } = useAppTranslation('booking');

  const handleSubmit = useCallback(() => {
    setProceedToPaymentMode();
    formInstance.submit();
  }, [formInstance, setProceedToPaymentMode]);

  return (
    <>
      <Button containerClassName="w-full" size="large" isDisabled={isDisabled} onClick={handleSubmit}>
        {t('Proceed to payment')}
      </Button>
    </>
  );
};
