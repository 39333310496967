type AntdSorterOrder = 'ascend' | 'descend';
type ApiSorterOrder = 'ASC' | 'DESC';

export const tableSort = (fieldName: string, order: AntdSorterOrder): string => {
  const orderNameMap: Record<AntdSorterOrder, ApiSorterOrder> = {
    ascend: 'ASC',
    descend: 'DESC',
  };

  return `${fieldName}:${orderNameMap[order]}`;
};
