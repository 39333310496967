import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReservationDetailDrawerSchema } from '../types';

const initialState: ReservationDetailDrawerSchema = {
  reservationId: null,
};

const reservationDetailDrawerSlice = createSlice({
  name: 'reservationDetailDrawer',
  initialState,
  reducers: {
    setOpenReservationDetails: (state: ReservationDetailDrawerSchema, action: PayloadAction<Nullable<string>>) => {
      state.reservationId = action.payload;
    },
  },
});

export const { actions: reservationDetailDrawerActions, reducer: reservationDetailDrawerReducer } = reservationDetailDrawerSlice;
