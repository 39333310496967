import { api } from 'app/config/apiConfig';
import { Insurance } from '../model/types';

export const getInsuranceByWarehouseIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInsuranceById: build.query<Insurance, string | undefined>({
      query: (insuranceId) => ({
        url: `/insurance/${insuranceId}`,
      }),
    }),
  }),
});

export const { useGetInsuranceByIdQuery } = getInsuranceByWarehouseIdApi;
