import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { NoResults } from 'shared/ui/NoResults';
import { reservationDetailDrawerActions } from '../model/slice/reservationDetailDrawerSlice';
import { useGetReservationByIdQuery } from 'entities/Reservation';
import { getReservationId } from '../model/selectors/getReservationId';
import { ReservationDetails } from './ReservationDetails';

export const ReservationDetailsDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const reservationId = useAppSelector(getReservationId);

  const { data: reservation } = useGetReservationByIdQuery(reservationId, { skip: !reservationId });

  const closeContractDetails = useCallback((): void => {
    dispatch(reservationDetailDrawerActions.setOpenReservationDetails(null));
  }, [dispatch]);

  return (
    <Drawer width={460} open={Boolean(reservationId)} onClose={closeContractDetails}>
      {reservation ? (
        <div className="flex min-h-full h-max">
          <div className="flex-1 py-5 px-11 shadow-left">
            <ReservationDetails reservation={reservation} />
          </div>
        </div>
      ) : (
        <NoResults />
      )}
    </Drawer>
  );
});
