import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { getSizeCodeFormState } from '../model/selectors/getSizeCodeFormState';
import { sizeCodeFormDrawerActions } from '../model/slice/sizeCodeFormDrawerSlice';
import { SizeCodeForm } from './SizeCodeForm';

export const SizeCodeFormDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const sizeCodeFormState = useAppSelector(getSizeCodeFormState);

  const closeSizeCodeFormDrawer = useCallback((): void => {
    dispatch(sizeCodeFormDrawerActions.setOpenSizeCodeForm({ mode: null, sizeCode: null }));
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(sizeCodeFormState.mode)} onClose={closeSizeCodeFormDrawer}>
      <SizeCodeForm sizeCode={sizeCodeFormState.sizeCode} mode={sizeCodeFormState.mode} handleClose={closeSizeCodeFormDrawer} />
    </Drawer>
  );
});
