import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData } from 'entities/User';
import { contractOptionsActions } from 'entities/Contract';

export const useInitContractInformationData = (): void => {
  const dispatch = useAppDispatch();
  const loggedUserData = useAppSelector(getLoggedUserData);

  useEffect(() => {
    if (loggedUserData) {
      dispatch(contractOptionsActions.setContractOptions({ accountType: loggedUserData.accountType }));
    }
  }, [dispatch, loggedUserData]);
};
