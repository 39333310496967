import { Card, Divider, Form, Input as AntdInput } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { AppLogo } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useLoginMutation } from 'entities/User';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { GoogleAuthButton } from 'features/GoogleAuthButton';
import { logInModalActions } from '../model/slice/loginModalSlice';
import { LogInModalValues } from '../model/types';

export const AuthForm: FC = memo(() => {
  const { t } = useAppTranslation(['login-page', 'common']);
  const dispatch = useAppDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const [form] = Form.useForm();

  const closeModal = useCallback((): void => {
    dispatch(logInModalActions.setOpened(false));
  }, [dispatch]);

  const openForgotPasswordModal = useCallback((): void => {
    dispatch(logInModalActions.changeMode('forgotPassword'));
  }, [dispatch]);

  const submit = useCallback(
    async (values: LogInModalValues): Promise<void> => {
      try {
        const { email, password, rememberUser } = values;

        await login({ loginData: { email, password }, remember: rememberUser }).unwrap();
        closeModal();
      } catch (error: CustomAny) {
        if (isErrorApiResponse(error)) {
          const errorMessage = Array.isArray(error.data.message)
            ? error.data.message.map((message) => t(message, { ns: 'common' }))
            : t(error.data.message, { ns: 'common' });
          showNotification('error', t('Error', { ns: 'common' }), errorMessage);
        } else {
          console.log(error);
        }
      }
    },
    [closeModal, login, t],
  );

  const handleSuccessGoogleAuth = useCallback((): void => {
    closeModal();
  }, [closeModal]);

  const handleFailGoogleAuth = useCallback(
    (error: string): void => {
      if (isErrorApiResponse(error)) {
        showNotification('error', t('Error', { ns: 'common' }), t(error.data.message));
      } else {
        console.log(error);
      }
    },
    [t],
  );

  return (
    <Form name="login" initialValues={{ rememberUser: true }} form={form} autoComplete="off" onFinish={submit}>
      <Card
        className="bg-transparent border-none"
        title={
          <div className="flex justify-center items-center p-4">
            <AppLogo width={180} height="100%" />
          </div>
        }
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('Please enter your email!') },
            { type: 'email', message: t('Please enter correct email!') },
          ]}
        >
          <Input placeholder={t('Your email')} bordered />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('Please enter your password!') },
            { min: 8, message: t('Password must be at least 8 characters!') },
          ]}
        >
          <AntdInput.Password className="h-14" placeholder={t('Your password')} variant="outlined" size="large" />
        </Form.Item>
        <div className="flex justify-between items-center">
          <Form.Item name="rememberUser" valuePropName="checked" className="mb-0">
            <Checkbox name="rememberUser" defaultChecked>
              {t('Remember me')}
            </Checkbox>
          </Form.Item>
          <div className="text-sm text-accent cursor-pointer" onClick={openForgotPasswordModal}>
            {t('Forgot password?')}
          </div>
        </div>
        <Button type="submit" containerClassName="w-full" isLoading={isLoading}>
          {t('Login')}
        </Button>
        <Divider>{t('OR')}</Divider>
        <GoogleAuthButton className="w-full" onSuccessAuth={handleSuccessGoogleAuth} onFailAuth={handleFailGoogleAuth}>
          {t('Sign in with Google')}
        </GoogleAuthButton>
      </Card>
    </Form>
  );
});
