export enum BookingInfoStacks {
  WAREHOUSE_INFORMATION = 'warehouseInformation',
  YOUR_BOX_UNIT = 'yourBoxUnit',
  ORDER_SUMMARY = 'orderSummary',
}

export enum BookingStack {
  WAREHOUSE_INFORMATION = 'Warehouse information',
  YOUR_BOX_UNIT = 'Your Box unit',
  ORDER_SUMMARY = 'Order summary',
}

export interface BookingInfoStackSchema {
  openedStack: Nullable<BookingInfoStacks>;
}
