import dayjs, { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT, SERVER_DATE_FORMAT, CLIENT_DATE_WITH_TIME_FORMAT } from 'shared/utils/constants';

export const getServerDateFormat = (date: Nullable<Dayjs> | string | undefined): string =>
  date ? dayjs(date).format(SERVER_DATE_FORMAT) : '';

export const getClientDateFormat = (date: Nullable<Dayjs> | string | undefined): string =>
  date ? dayjs(date).format(CLIENT_DATE_FORMAT) : '';

export const getClientDateWithTimeFormat = (date: Nullable<Dayjs> | string | undefined): string =>
  date ? dayjs(date).format(CLIENT_DATE_WITH_TIME_FORMAT) : '';

export const getFilterServerFormatDate = (value?: string): string | undefined => {
  return value ? dayjs(value).startOf('day').format(SERVER_DATE_FORMAT) : undefined;
};
