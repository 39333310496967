import { Menu, MenuProps } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren, ReactNode } from 'react';
import s from './index.module.scss';

interface Tab {
  key: string;
  label: string;
  icon?: ReactNode;
}

interface TabSwitcherProps extends PropsWithChildren {
  tabs: Tab[];
  selectedTabKey: string;
  onChangeTab: (tab: string) => void;
  className?: string;
}

export const TabSwitcher: FC<TabSwitcherProps> = memo((props) => {
  const { tabs, selectedTabKey, children, onChangeTab, className } = props;

  const menuItems: MenuProps['items'] = tabs.map(({ label, icon, key }) => ({ label, key, icon }));

  const handleChangeTab = ({ key }: { key: string }): void => {
    onChangeTab(key);
  };

  return (
    <div className={classNames('flex flex-col', className)}>
      <Menu className={s.menu} mode="horizontal" items={menuItems} selectedKeys={[selectedTabKey]} onSelect={handleChangeTab} />
      <>{children}</>
    </div>
  );
});
