import React, { FC, memo, PropsWithChildren, useContext } from 'react';
import { useAppTranslation } from '../../../app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { TableFilterContext } from '../model/context/TableFilterContextProvider';

interface TableFilterDrawerProps extends PropsWithChildren {
  isDisabled?: boolean;
}

export const TableFilterDrawer: FC<TableFilterDrawerProps> = memo((props) => {
  const { children, isDisabled } = props;

  const { t } = useAppTranslation('common');

  const { isOpened, setFiltersOpened, applyFilters } = useContext(TableFilterContext);

  const closeFilters = (): void => {
    setFiltersOpened(false);
  };

  return (
    <Drawer width={500} open={isOpened} onClose={closeFilters}>
      <div className="flex flex-col px-10 py-4">
        <div className="flex justify-between items-center pt-4 pb-14">
          <div className="font-semibold text-3xl">{t('Filters')}</div>
          <div className="text-primaryLight font-normal cursor-pointer" onClick={closeFilters}>
            {t('Close')}
          </div>
        </div>
        {children}
        <Button containerClassName="mt-10" type="submit" onClick={applyFilters} isDisabled={isDisabled}>
          {t('Search')}
        </Button>
      </div>
    </Drawer>
  );
});
