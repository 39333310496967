import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { SizeCode, SizeGroup } from '../model/types';
import { InvoiceFrequencyType } from 'entities/Invoice';

interface Params {
  warehouseId?: string;
  search?: string;
  orderBy?: string;
  page?: number;
  limit?: number;
  priceFactor?: number;
  priceFrom?: number;
  priceTo?: number;
  priceRateType?: InvoiceFrequencyType;
  squareFrom?: number;
  squareTo?: number;
  sizeGroup?: SizeGroup;
}

export const getPaginatedSizeCodesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedSizeCodes: build.query<Pagination<SizeCode>, Params | void>({
      query: (args) => ({
        url: '/size-codes/settings',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Size codes'],
    }),
  }),
});

export const { useGetPaginatedSizeCodesQuery } = getPaginatedSizeCodesApi;
