import React, { FC } from 'react';
import { Warehouse } from 'entities/Warehouse';
import { WarehouseCard } from 'features/WarehouseCard';
import { BoxesList } from 'features/BoxesList';
import { Divider } from 'antd';
import { CachedBox } from 'entities/Box';
import { Reservation } from 'entities/Reservation';
import { ContractWithBookingInfo } from 'entities/Contract';

interface MyBookingProps {
  type: 'reservation' | 'contract';
  warehouse: Nullable<Warehouse>;
  onOpenContractDetails: (contractId: string) => void;
  boxes?: CachedBox[];
  reservation?: Nullable<Reservation>;
  contractsData?: ContractWithBookingInfo[];
}

export const MyBooking: FC<MyBookingProps> = (props) => {
  const { warehouse, type, boxes, reservation, contractsData, onOpenContractDetails } = props;

  return (
    <div className="flex flex-col gap-6">
      <WarehouseCard warehouse={warehouse} type={type} />
      <Divider className="m-0" />
      <BoxesList
        boxes={boxes}
        type={type}
        warehouseId={warehouse?.warehouseId}
        reservation={reservation}
        contractsData={contractsData}
        onOpenContractDetails={onOpenContractDetails}
      />
    </div>
  );
};
