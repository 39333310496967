import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { City } from 'entities/City';
import { Franchise } from 'entities/Franchise';
import { CreateWarehouseFormFields, CreateWarehouseModalSchema, createWarehouseSteps } from '../types';

const initialState: CreateWarehouseModalSchema = {
  isOpened: false,
  steps: createWarehouseSteps,
  selectedCity: null,
  selectedFranchise: null,
  formData: {
    name: undefined,
    address: undefined,
    zip: undefined,
    phone: undefined,
    email: undefined,
    longitude: undefined,
    latitude: undefined,
    launchDate: undefined,
    isActive: false,
    isComingSoon: false,
  },
};

const createWarehouseModalSlice = createSlice({
  name: 'createWarehouseModal',
  initialState,
  reducers: {
    setOpened: (state: CreateWarehouseModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeStepData: (state: CreateWarehouseModalSchema, action: PayloadAction<{ targetStepIndex: number; stepData: string }>) => {
      state.steps = state.steps.map((step, index) =>
        index === action.payload.targetStepIndex ? { ...step, stepData: action.payload.stepData } : step,
      );
    },
    setSelectedCity: (state: CreateWarehouseModalSchema, action: PayloadAction<City>) => {
      state.selectedCity = action.payload;
    },
    setSelectedFranchise: (state: CreateWarehouseModalSchema, action: PayloadAction<Franchise>) => {
      state.selectedFranchise = action.payload;
    },
    changeFormData: (state: CreateWarehouseModalSchema, action: PayloadAction<Partial<CreateWarehouseFormFields>>) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetModalState: (state: CreateWarehouseModalSchema) => {
      state.selectedCity = null;
      state.selectedFranchise = null;
      state.isOpened = false;
    },
  },
});

export const { actions: createWarehouseModalActions, reducer: createWarehouseModalReducer } = createWarehouseModalSlice;
