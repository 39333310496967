import { api } from 'app/config/apiConfig';

export const chargeInvoiceApi = api.injectEndpoints({
  endpoints: (build) => ({
    chargeInvoice: build.mutation<void, string>({
      query: (invoiceId) => ({
        url: `/billing-documents/invoices/${invoiceId}/charge`,
        method: 'POST',
      }),
      invalidatesTags: ['Invoices'],
    }),
  }),
});

export const { useChargeInvoiceMutation } = chargeInvoiceApi;
