import { useGetBoxLockStatusQuery } from 'entities/Box/api/getBoxLockStatusApi';
import { Box, CachedBox, LockState } from 'entities/Box/model/types';
import { useOnLockStatusChanged } from './sockets/useOnLockStatusChanged';
import { useEffect, useState } from 'react';

export const useWatchLockState = (box: Nullable<Box | CachedBox>, warehouseId: string | undefined): LockState => {
  const boxId = box && 'originalEntityId' in box ? box.originalEntityId : box?.boxId;

  const [lockState, setLockState] = useState<LockState>(LockState.NA);

  const { data: initialLockState } = useGetBoxLockStatusQuery(boxId as string, { skip: !boxId });
  const newLockStatus = useOnLockStatusChanged();

  useEffect(() => {
    if (initialLockState) {
      setLockState(initialLockState.status);
    }

    if (newLockStatus && newLockStatus.warehouseId === warehouseId && newLockStatus.lockId === box?.lockAddress) {
      setLockState(newLockStatus.status);
    }
  }, [box, warehouseId, initialLockState, newLockStatus]);

  return lockState;
};
