import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGetPaginatedWarehousesQuery, Warehouse, getMinMaxSquare } from 'entities/Warehouse';
import { ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { stepperModalActions } from 'features/StepperModal';
import { createContractModalActions } from '../../model/slice/createContractModalSlice';

export const SelectWarehouseStep: FC = memo(() => {
  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const columns = useMemo<ColumnsType<Warehouse>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'name',
        render: (_, record) => record.name,
      },
      {
        title: t('Address'),
        key: 'address',
        render: (_, record) => `${record.address} ${record.city.name} ${record.zip || ''}`,
      },
      {
        title: t('Sizes'),
        key: 'sizes',
        render: (_, record) => {
          const { minSquare, maxSquare } = getMinMaxSquare(record);
          const hasSizes = Boolean(minSquare && maxSquare);

          return hasSizes ? `${minSquare} m² - ${maxSquare} m²` : '-';
        },
      },
    ],
    [t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Warehouse>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        dispatch(createContractModalActions.setSelectedWarehouse(selectedElements[0])); // only one warehouse can be selected
        dispatch(stepperModalActions.changeActiveStepIndex(2));
      },
    },
  ];

  return (
    <ServerTable
      columns={columns}
      fetchData={useGetPaginatedWarehousesQuery}
      rowKey="warehouseId"
      search
      title={t('Choose Warehouse')}
      selectionType="radio"
      rowSelectionActions={rowSelectionActions}
    />
  );
});
