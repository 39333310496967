import { DocumentType, Invoice } from 'entities/Invoice';
import { Payment } from 'entities/Payment';
import { User } from 'entities/User';

export enum CreditNoteType {
  PAYMENT = 'payment',
  INVOICE = 'invoice',
}
export interface CreditNote {
  creditNoteId: string;
  user: User;
  invoice?: Invoice;
  payment?: Payment;
  creditedAmount: number;
  description?: string;
  issuedBy?: string;
  documentNumber: string;
  documentType: DocumentType;
  createdAt: string;
}
