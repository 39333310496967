import { api } from 'app/config/apiConfig';
import { OpenWarehouseDoorParams } from '../model/types';

export const openWarehouseDoorApi = api.injectEndpoints({
  endpoints: (build) => ({
    openWarehouseDoor: build.mutation<void, { warehouseId: string; args: OpenWarehouseDoorParams }>({
      query: ({ warehouseId, args }) => ({
        url: `/warehouses/${warehouseId}/open`,
        method: 'PUT',
        body: args,
      }),
    }),
  }),
});

export const { useOpenWarehouseDoorMutation } = openWarehouseDoorApi;
