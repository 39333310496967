import { InvoiceFrequencyType } from 'entities/Invoice';
import { SizeCodeType, SizeGroup } from 'entities/SizeCode';
import { TableFilterType } from 'features/TableFilter';

interface SizeCodeListFilters {
  warehouseId?: string;
  priceFrom?: number;
  priceTo?: number;
  squareFrom?: number;
  squareTo?: number;
  priceRateType?: InvoiceFrequencyType;
  sizeGroup?: SizeGroup;
  sizeCodeType?: SizeCodeType;
}

export const transformSizeCodesListFilters = ({
  selectedWarehouseId,
  priceFrom,
  priceTo,
  priceType,
  squareFrom,
  squareTo,
  sizeGroup,
  sizeCodeType,
}: TableFilterType): SizeCodeListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    priceFrom: priceFrom?.value || undefined,
    priceTo: priceTo?.value || undefined,
    squareFrom: squareFrom?.value || undefined,
    squareTo: squareTo?.value || undefined,
    priceRateType: priceType?.value || undefined,
    sizeGroup: sizeGroup?.value || undefined,
    sizeCodeType: sizeCodeType?.value || undefined,
  };
};
