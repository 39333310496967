import { api } from 'app/config/apiConfig';
import { LockStatus } from '../model/types';

export const getBoxLockStatusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBoxLockStatus: build.query<LockStatus, string>({
      query: (boxId) => ({
        url: `/boxes/${boxId}/lock-status`,
      }),
    }),
  }),
});

export const { useGetBoxLockStatusQuery } = getBoxLockStatusApi;
