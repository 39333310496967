import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { getMinMaxSquare, useGetPaginatedWarehousesQuery, Warehouse } from 'entities/Warehouse';
import { useCreateUser } from 'features/CreateUser';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { getSelectedWarehouses } from '../model/selectors/getSelectedWarehouses';
import { getUserData } from '../model/selectors/getUserData';
import { createUserModalActions } from '../model/slice/createUserModalSlice';
import { transformWarehouseListFilters } from '../utils/helpers/transformWarehouseListFilters';
import { WarehouseListFilter } from './WarehouseListFilter';

interface ChooseWarehouseStepProps {
  onSuccess: () => void;
}

export const ChooseWarehouseStep: FC<ChooseWarehouseStepProps> = memo((props) => {
  const { onSuccess } = props;

  const { t } = useAppTranslation('user');

  const dispatch = useAppDispatch();

  const [showCreateButtonWithoutWarehouses, setShowCreateButtonWithoutWarehouses] = useState(true);

  const { createUser, isLoading, isOpenedConfirm, closeConfirmModal, openConfirmModal } = useCreateUser(onSuccess);

  const { setFiltersOpened, appliedFilters, tags } = useTableFilterContext();

  const userData = useAppSelector(getUserData);
  const selectedWarehouses = useAppSelector(getSelectedWarehouses);

  const columns = useMemo<ColumnsType<Warehouse>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'name',
        render: (_, record) => record.name,
      },
      {
        title: t('Address'),
        key: 'address',
        render: (_, record) => `${record.address} ${record.city.name} ${record.zip || ''}`,
      },
      {
        title: t('Sizes'),
        key: 'sizes',
        render: (_, record) => {
          const { minSquare, maxSquare } = getMinMaxSquare(record);
          const hasSizes = Boolean(minSquare && maxSquare);

          return hasSizes ? `${minSquare} m² - ${maxSquare} m²` : '-';
        },
      },
    ],
    [t],
  );

  const tableActions = useMemo((): Array<ServerTableActions<Box>> => {
    const tableActions: Array<ServerTableActions<Box>> = [
      {
        name: t('Filters'),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ];

    const additionalTableActions: Array<ServerTableActions<Box>> = [
      {
        name: t('Create'),
        onClick: () => {
          openConfirmModal();
        },
      },
    ];

    const resultedTableActions = !showCreateButtonWithoutWarehouses ? tableActions : [...tableActions, ...additionalTableActions];

    return resultedTableActions;
  }, [openConfirmModal, setFiltersOpened, showCreateButtonWithoutWarehouses, t]);

  const selectWarehouses = (warehouseIds: string[]): void => {
    dispatch(createUserModalActions.setSelectedWarehouses(warehouseIds));
  };

  const handleCreateUser = useCallback((): void => {
    if (userData) {
      createUser({ ...userData, assignedWarehouseIds: selectedWarehouses });
    }
  }, [createUser, selectedWarehouses, userData]);

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Warehouse>> = [
    {
      name: t('Create'),
      onClick: (selectedElements) => {
        openConfirmModal();
        selectWarehouses(selectedElements.map(({ warehouseId }) => warehouseId));
      },
    },
  ];

  return (
    <div>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedWarehousesQuery}
        rowKey="warehouseId"
        search
        title={t('Choose warehouse')}
        selectionType="checkbox"
        rowSelectionActions={rowSelectionActions}
        onRowSelect={(record, selected, selectedRows) => {
          setShowCreateButtonWithoutWarehouses(!selectedRows.length);
        }}
        onAllRowsSelect={(selected, selectedRows) => {
          setShowCreateButtonWithoutWarehouses(!selectedRows.length);
        }}
        tableActions={tableActions}
        defaultFilters={{
          ...transformWarehouseListFilters(appliedFilters),
        }}
        tags={tags}
      />
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create user')}
        description={t('Are you sure you want to create user?')}
        isLoading={isLoading}
        onOk={handleCreateUser}
        onCancel={closeConfirmModal}
      />
      <WarehouseListFilter />
    </div>
  );
});
