import { api } from 'app/config/apiConfig';
import { ContractWithBookingInfo } from '../model/types';

export const getContractsWithBookingInfoApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractsWithBookingInfo: builder.query<ContractWithBookingInfo[], string | undefined>({
      query: (userId: string) => ({
        url: `/users/${userId}/contracts/booking-info`,
      }),
      providesTags: () => ['Reservations', 'My bookings'],
    }),
  }),
});

export const { useGetContractsWithBookingInfoQuery } = getContractsWithBookingInfoApi;
