import { useCallback } from 'react';
import { exportModalActions } from '../model/slice/exportModalSlice';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Column, ExportEntity } from '../model/types';

interface OpenExportModalArgs {
  filters: AnyObject;
  columns: Column[];
  entity: ExportEntity;
}

interface HooApi {
  openExportModal: (args: OpenExportModalArgs) => void;
}

export const useOpenExportModal = (): HooApi => {
  const dispatch = useAppDispatch();

  const openExportModal = useCallback(
    ({ filters, columns, entity }: OpenExportModalArgs): void => {
      dispatch(
        exportModalActions.openModal({
          filters,
          columns,
          entity,
        }),
      );
    },
    [dispatch],
  );

  return {
    openExportModal,
  };
};
