import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'shared/ui/Button';
import queryString from 'query-string';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { SuccessScreen } from 'shared/ui/SuccessScreen';
import { useGetCurrencySymbol } from 'app/appState';

export const PaymentSuccessPage: FC = () => {
  const { amount } = queryString.parse(window.location.search);

  const navigate = useNavigate();
  const { t } = useAppTranslation('booking');

  const goToHomePage = (): void => {
    navigate(AppRoutes.HOME);
  };

  const currencySymbol = useGetCurrencySymbol();

  return (
    <div className="p-[100px]">
      <SuccessScreen
        title={t('Congratulations!')}
        description={t('Box has been successfully reserved')}
        additionalInfo={
          <div className="mb-10 text-primaryLight flex justify-between">
            <div className="text-lg">{t('Amount Paid:')}</div>
            <div className="font-semibold">
              {amount} {currencySymbol}
            </div>
          </div>
        }
        actions={
          <Button onClick={goToHomePage} size="large" containerClassName="w-full">
            {t('Home')}
          </Button>
        }
      />
    </div>
  );
};
