import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RangeInput } from 'shared/ui/RangeInput/RangeInput';
import { useGetCurrencySymbol } from 'app/appState';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { FloorSelect } from './FloorSelect';
import { PriceTypeSelect } from './PriceTypeSelect';
import { BoxesListFilterKeys } from '../../../../model/types';
import { getSelectedWarehouse } from '../../../../model/selectors/getSelectedWarehouse';
import { useAppSelector } from 'app/config/storeConfig/hooks';

export const BoxesListFilter: FC = memo(() => {
  const { t } = useAppTranslation('boxes');

  const currencySymbol = useGetCurrencySymbol();

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<BoxesListFilterKeys>();

  const selectedWarehouse = useAppSelector(getSelectedWarehouse);

  const changePriceFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.priceFrom,
        label: `${t('From {{priceFrom}}', { priceFrom: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.priceTo,
        label: `${t('To {{priceTo}}', { priceTo: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceType = useCallback(
    (value: string, label: string) => {
      changeFilters({
        key: BoxesListFilterKeys.priceType,
        label: t('Price type: {{priceType}}', { priceType: label }),
        value,
        title: t('Price type'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.squareFrom,
        label: t('From {{squareFrom}} m²', { squareFrom: value }),
        value,
        title: t('Square'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({ key: BoxesListFilterKeys.squareTo, label: t('To {{squareTo}} m²', { squareTo: value }), value, title: t('Square') });
    },
    [changeFilters, t],
  );

  const changeFloor = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.floorId, label, value, title: t('Floor') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <div className="flex space-x-3 items-center">
          <RangeInput
            values={[filters.priceFrom?.value, filters.priceTo?.value]}
            containerClassName="basis-2/3"
            placeholder={[t('From'), t('To')]}
            label={t('Price')}
            type="number"
            onChangeFrom={changePriceFrom}
            onChangeTo={changePriceTo}
          />
          <PriceTypeSelect value={filters.priceType?.value} onChange={changePriceType} onClear={clearCurrentFilters} />
        </div>
        <RangeInput
          values={[filters.squareFrom?.value, filters.squareTo?.value]}
          placeholder={[t('From'), t('To')]}
          label={t('Square')}
          type="number"
          onChangeFrom={changeSquareFrom}
          onChangeTo={changeSquareTo}
        />
        <FloorSelect
          selectedWarehouseId={selectedWarehouse?.warehouseId}
          value={filters.floorId?.value}
          onChange={changeFloor}
          onClear={clearCurrentFilters}
        />
      </div>
    </TableFilter>
  );
});
