import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppRoutes } from 'app/config/routerConfig/types';
import { clearAccessToken, clearRefreshToken, setAccessToken, setRefreshToken } from '../../utils/helpers/authHelpers';
import { AuthResponse, User, UserSchema } from '../types';

const initialState: UserSchema = {
  userData: null,
  credentials: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state: UserSchema, action: PayloadAction<{ credentials: AuthResponse; remember?: boolean }>) => {
      const { accessToken, refreshToken } = action.payload.credentials;

      state.credentials = action.payload.credentials;
      setAccessToken(accessToken, action.payload.remember);
      setRefreshToken(refreshToken, action.payload.remember);
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.userData = action.payload;
    },
    logOut: (state) => {
      state.userData = null;
      state.credentials = null;

      window.location.href = AppRoutes.LOGIN;
      clearAccessToken();
      clearRefreshToken();
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
