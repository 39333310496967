import { api } from 'app/config/apiConfig';
import { Lead } from '../model/types';

export const deleteLeadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteLead: builder.mutation<Lead, string>({
      query: (leadId: string) => ({
        url: `/leads/${leadId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Leads'],
    }),
  }),
});

export const { useDeleteLeadMutation } = deleteLeadApi;
