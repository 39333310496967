import { Box } from 'entities/Box';
import { User } from 'entities/User';
import { Warehouse } from 'entities/Warehouse';
import { StepData, stepsMapper } from 'features/Stepper';

export enum CreateContractSteps {
  SELECT_USER = 'Select a User',
  SELECT_WAREHOUSE = 'Select a Warehouse',
  SELECT_BOX = 'Select a Box',
  BOOKING_OPTIONS = 'Booking Options',
}

export const createContractSteps = stepsMapper([...Object.values(CreateContractSteps)]);

export interface CreateContractModalSchema {
  isOpened: boolean;
  steps: StepData[];
  selectedUser: Nullable<User>;
  selectedWarehouse: Nullable<Warehouse>;
  selectedBox: Nullable<Box>;
}

export enum BoxesListFilterKeys {
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  priceType = 'priceType',
  squareFrom = 'squareFrom',
  squareTo = 'squareTo',
  floorId = 'floorId',
}
