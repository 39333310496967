import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WarehouseMapSchema } from '../types';

const initialState: WarehouseMapSchema = {
  selectedWarehouseCard: null,
  outlinedWarehouseCard: null,
  search: undefined,
  routedWarehouseId: null,
};

const warehouseMapSlice = createSlice({
  name: 'warehouseMap',
  initialState,
  reducers: {
    setSelectedCard: (state: WarehouseMapSchema, action: PayloadAction<Nullable<string>>) => {
      state.selectedWarehouseCard = action.payload;
      state.outlinedWarehouseCard = action.payload;
    },
    setOutlinedCard: (state: WarehouseMapSchema, action: PayloadAction<Nullable<string>>) => {
      state.outlinedWarehouseCard = action.payload;
    },
    changeSearch: (state: WarehouseMapSchema, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },
    setRoutedWarehouseId: (state: WarehouseMapSchema, action: PayloadAction<Nullable<string>>) => {
      state.routedWarehouseId = action.payload;
    },
  },
});

export const { actions: warehouseMapActions, reducer: warehouseMapReducer } = warehouseMapSlice;
