import React, { FC, PropsWithChildren } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ConfigProvider } from 'antd';
import de_DE from 'antd/locale/de_DE';
import en_US from 'antd/locale/en_US';
import pl_PL from 'antd/locale/pl_PL';
import 'dayjs/locale/de';

const locales = {
  en_US,
  de_DE,
  pl_PL,
};

type LocaleType = keyof typeof locales;

export const AntdConfigProvider: FC<PropsWithChildren> = (props) => {
  const { i18n } = useAppTranslation();

  const language = i18n.language as LocaleType;

  return <ConfigProvider locale={locales[language]}>{props.children}</ConfigProvider>;
};
