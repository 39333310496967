import { api } from 'app/config/apiConfig';
import { Contract } from '../model/types';

interface Params {
  userId: string | undefined;
  page?: number;
  limit?: number;
  orderBy?: string;
  isActive?: boolean;
}

export const getContractsByUserIdApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractsByUserId: builder.query<Contract[], Params>({
      query: (params) => ({
        url: `/users/${params.userId}/contracts`,
        params,
      }),
    }),
  }),
});

export const { useGetContractsByUserIdQuery } = getContractsByUserIdApi;
