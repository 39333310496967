import React, { FC, PropsWithChildren } from 'react';
import { useTheme } from '../utils/hooks/useTheme';

export const ThemeProvider: FC<PropsWithChildren> = (props) => {
  const theme = useTheme();

  return (
    <>
      <style>{`
      :root {
        --primary: ${theme.primary};
        --primaryLight: ${theme.primaryLight};
        --secondary: ${theme.secondary};
        --secondaryLight: ${theme.secondaryLight};
        --secondaryAccent: ${theme.secondaryAccent};
        --accent: ${theme.accent};
        --accentLight: ${theme.accentLight};
        --warn: ${theme.warn};
        --warnLight: ${theme.warnLight};
        --error: ${theme.error};
        --errorLight: ${theme.errorLight};
        --white: ${theme.white};
        --success: ${theme.success};
        --successLight: ${theme.successLight};
      }
    `}</style>
      {props.children}
    </>
  );
};
