import { api } from 'app/config/apiConfig';
import { GenerateContractParams } from '../model/types';

export const getContractPreviewApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractPreview: builder.mutation<Node, GenerateContractParams>({
      query: (args) => ({
        url: '/contracts-generate',
        method: 'POST',
        responseHandler: 'text',
        body: args,
      }),
    }),
  }),
});

export const { useGetContractPreviewMutation } = getContractPreviewApi;
