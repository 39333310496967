import { Form } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Toggle } from 'shared/ui/Toggle';
import { getFormData } from '../../../model/selectors/getFormData';
import { createWarehouseModalActions } from '../../../model/slice/createWarehouseModalSlice';

export const ActiveStatusToggle: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const dispatch = useAppDispatch();

  const { isActive } = useAppSelector(getFormData);

  const changeActiveStatus = useCallback(
    (isActive: boolean) => {
      dispatch(createWarehouseModalActions.changeFormData({ isActive }));
    },
    [dispatch],
  );

  return (
    <Form.Item name="isActive" valuePropName="checked">
      <Toggle checked={isActive} onChange={changeActiveStatus}>
        {t('Active')}
      </Toggle>
    </Form.Item>
  );
});
