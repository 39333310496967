import { api } from 'app/config/apiConfig';
import { AuthResponse, getMeApi, userActions } from 'entities/User';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';

export const googleAuthApi = api.injectEndpoints({
  endpoints: (build) => ({
    googleAuth: build.mutation<AuthResponse, string>({
      query: (googleToken) => ({
        url: '/authorization/google-login',
        method: 'POST',
        body: { googleToken },
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const googleAuthResponse = await queryFulfilled;

          dispatch(userActions.setCredentials({ credentials: googleAuthResponse.data, remember: true }));

          const getMeResponse = await dispatch(getMeApi.endpoints.getMe.initiate()).unwrap();

          dispatch(userActions.setUserData(getMeResponse));
        } catch (error) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when authorization via Google', { ns: 'common' }));
        }
      },
    }),
  }),
});

export const { useGoogleAuthMutation } = googleAuthApi;
