import { api } from 'app/config/apiConfig';
import { Contract, NotifyLeaveDateParams } from '../model/types';

export const notifyLeaveDateApi = api.injectEndpoints({
  endpoints: (builder) => ({
    notifyLeaveDate: builder.mutation<Contract, NotifyLeaveDateParams>({
      query: (data) => ({
        url: `/contracts/${data.contractId}/notify-leave`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Contracts', 'Contract details'],
    }),
  }),
});

export const { useNotifyLeaveDateMutation } = notifyLeaveDateApi;
