import React, { FC, memo } from 'react';
import { Menu as AntdMenu } from 'antd';
import s from './index.module.scss';
import classNames from 'classnames';
import { MenuMode } from 'rc-menu/lib/interface';
import { ItemType, MenuItemType } from 'antd/es/menu/hooks/useItems';

interface MenuProps {
  className?: string;
  mode?: MenuMode;
  items?: Array<ItemType<MenuItemType>>;
  activeKey?: string;
}

export const Menu: FC<MenuProps> = memo((props) => {
  const { activeKey, className, items, mode = 'horizontal' } = props;

  return <AntdMenu className={classNames(s.menu, className)} mode={mode} items={items} activeKey={activeKey} />;
});
