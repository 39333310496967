import React, { FC, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useNavigate } from 'react-router-dom';
import { Card, Divider, Form, Input as AntdInput } from 'antd';
import { LangSwitcher } from 'features/LangSwitcher';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Checkbox } from 'shared/ui/Checkbox';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useLoginMutation } from 'entities/User';
import { GoogleAuthButton } from 'features/GoogleAuthButton';
import { AppLogo } from 'app/appState';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { authFormActions } from '../model/slice/authFormSlice';
import { LoginFormValues } from '../model/types';

interface AuthFormProps {
  className?: string;
}

export const AuthForm: FC<AuthFormProps> = (props) => {
  const { className } = props;
  const navigate = useNavigate();
  const { t } = useAppTranslation(['login-page', 'common']);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [login, { isLoading }] = useLoginMutation();

  const handleSuccessGoogleAuth = useCallback((): void => {
    navigate(AppRoutes.HOME);
  }, [navigate]);

  const handleFailGoogleAuth = useCallback(
    (error: string): void => {
      if (isErrorApiResponse(error)) {
        showNotification('error', t('Error', { ns: 'common' }), t(error.data.message));
      } else {
        console.log(error);
      }
    },
    [t],
  );

  const handleSubmit = useCallback(
    async (values: LoginFormValues): Promise<void> => {
      const { email, password, rememberUser } = values;

      try {
        await login({ loginData: { email, password }, remember: rememberUser }).unwrap();
        navigate(AppRoutes.HOME);
      } catch (error: CustomAny) {
        if (isErrorApiResponse(error)) {
          const errorMessage = Array.isArray(error.data.message)
            ? error.data.message.map((message) => t(message, { ns: 'common' }))
            : t(error.data.message, { ns: 'common' });
          showNotification('error', t('Error', { ns: 'common' }), errorMessage);
        } else {
          console.log(error);
        }
      }
    },
    [login, navigate, t],
  );

  const goToBookingPage = useCallback((): void => {
    navigate(AppRoutes.BOOKING);
  }, [navigate]);

  const openForgotPasswordScreen = useCallback((): void => {
    dispatch(authFormActions.changeMode('resetPassword'));
  }, [dispatch]);

  return (
    <Card
      className={className}
      title={
        <div className="flex items-center p-3 justify-center">
          <AppLogo width={180} height="100%" />
          <div className="absolute top-0 right-0">
            <LangSwitcher />
          </div>
        </div>
      }
    >
      <Form name="basic" initialValues={{ rememberUser: true }} form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('Please enter your email!') },
            { type: 'email', message: t('Please enter correct email!') },
          ]}
        >
          <Input placeholder={t('Your email')} bordered />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t('Please enter your password!') },
            { min: 8, message: t('Password must be at least 8 characters!') },
          ]}
        >
          <AntdInput.Password className="h-14" placeholder={t('Your password')} size="large" />
        </Form.Item>
        <div className="flex justify-between items-center">
          <Form.Item name="rememberUser" valuePropName="checked" className="mb-0">
            <Checkbox name="rememberUser" defaultChecked>
              {t('Remember me')}
            </Checkbox>
          </Form.Item>
          <div className="text-sm text-accent cursor-pointer" onClick={openForgotPasswordScreen}>
            {t('Forgot password?')}
          </div>
        </div>
        <Form.Item>
          <Button type="submit" containerClassName="w-full" isLoading={isLoading}>
            {t('Login')}
          </Button>
        </Form.Item>
        <Divider>{t('OR')}</Divider>
        <Form.Item>
          <GoogleAuthButton className="w-full" onSuccessAuth={handleSuccessGoogleAuth} onFailAuth={handleFailGoogleAuth}>
            {t('Sign in with Google')}
          </GoogleAuthButton>
        </Form.Item>
        <Form.Item>
          <Button containerClassName="w-full mt-6" onClick={goToBookingPage}>
            {t('Go to booking')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
