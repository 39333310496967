import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from 'antd';
import classNames from 'classnames';
import React, { FC, memo } from 'react';
import s from './index.module.scss';

interface DrawerProps extends AntdDrawerProps {}

export const Drawer: FC<DrawerProps> = memo((props) => {
  const { children, className, closable = false, ...restProps } = props;

  return (
    <AntdDrawer className={classNames(s.drawer, className)} closable={closable} {...restProps}>
      {children}
    </AntdDrawer>
  );
});
