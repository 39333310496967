import React, { FC, memo } from 'react';
import { Contract } from 'entities/Contract';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Payment } from 'entities/Payment';
import { PaymentCard } from './PaymentCard';

interface PaymentTabProps {
  payments: Payment[];
  contract: Contract;
}

export const PaymentTab: FC<PaymentTabProps> = memo((props) => {
  const { payments, contract } = props;

  const { t } = useAppTranslation('contracts');

  return (
    <div>
      {payments.length ? (
        <>
          <div className="font-semibold text-lg pt-7 pb-4">{t('History')}</div>
          <div className="space-y-3">
            {payments.map((payment) => (
              <PaymentCard
                key={payment.paymentId}
                payment={payment}
                deposit={contract.deposit}
                user={contract.user}
                isClosedContract={Boolean(contract.leaveDate)}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="font-semibold text-lg pt-7 pb-4 text-center">{t('No payments yet')}</div>
      )}
    </div>
  );
});
