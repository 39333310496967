import { FormInstance } from 'antd';
import React, { FC, memo, ReactElement, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { useChangeUserDetails } from '../utils/hooks/useChangeUserDetails';

interface ChangeUserDetailsProps {
  userId: string | undefined;
  action: ReactElement;
  formInstance: FormInstance;
  onSuccess?: () => void;
}

export const ChangeUserDetails: FC<ChangeUserDetailsProps> = memo((props) => {
  const { userId, action, formInstance, onSuccess } = props;

  const { t } = useAppTranslation('user');

  const updatedData = formInstance.getFieldsValue();

  const { handleChangeUserDetails, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useChangeUserDetails({
    userId,
    updatedData,
    onSuccess,
  });

  const handleClick = useCallback(async (): Promise<void> => {
    try {
      await formInstance.validateFields();
      openConfirmModal();
    } catch (e) {
      console.log(e);
    }
  }, [formInstance, openConfirmModal]);

  return (
    <>
      {React.cloneElement(action, { onClick: handleClick })}
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Change account details')}
        description={t('Are you sure you want to change account details?')}
        isLoading={isLoading}
        onOk={handleChangeUserDetails}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
