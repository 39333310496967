export interface Column {
  key: string;
  title: string;
}

export type ExportModalPayload = Omit<ExportModalSchema, 'isOpened'>;

export enum ExportEntity {
  LEAD = 'lead',
  USER = 'user',
  INVOICE = 'invoice',
  CONTRACT = 'contract',
  BOX = 'box',
  CREDIT_NOTE = 'creditNote',
  INSURANCE = 'insurance',
}

export interface ExportModalSchema {
  isOpened: boolean;
  filters: AnyObject;
  columns: Column[];
  entity: Nullable<ExportEntity>;
}

export interface ColumnOption {
  label: string;
  value: string;
}
