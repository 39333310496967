import React, { FC, memo } from 'react';
import { InsuranceSettingsTable } from './InsuranceSettingsTable';
import { TableFilterContextProvider } from 'features/TableFilter';

export const InsuranceSettingsPage: FC = memo(() => {
  return (
    <TableFilterContextProvider>
      <InsuranceSettingsTable />
    </TableFilterContextProvider>
  );
});
