import { api } from 'app/config/apiConfig';
import { LateChargeSetting } from '../model/types';
import { LateChargeSettingFormFields } from 'features/LateChargeSettingFormDrawer';

export const createLateChargeSettingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLateChargeSetting: builder.mutation<LateChargeSetting, LateChargeSettingFormFields>({
      query: (data) => ({
        url: '/late-charge-settings',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Late charge settings'],
    }),
  }),
});

export const { useCreateLateChargeSettingMutation } = createLateChargeSettingApi;
