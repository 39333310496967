import React, { FC, memo, PropsWithChildren } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useTheme } from 'app/providers/ThemeProvider';

interface TooltipProps extends PropsWithChildren {
  color?: string;
  fontColor?: string;
  title: string;
  placement?: TooltipPlacement;
  className?: string;
  arrow?: boolean;
}

export const Tooltip: FC<TooltipProps> = memo((props) => {
  const { children, title, placement, color, fontColor, className, arrow = true } = props;

  const theme = useTheme();

  return (
    <AntdTooltip
      className={className}
      title={<div style={{ color: fontColor || theme.white }}>{title}</div>}
      color={color || theme.accent}
      placement={placement}
      arrow={arrow}
    >
      {/* Empty div to fix issue with tooltip child https://github.com/ant-design/ant-design/issues/25214#issuecomment-884640910 */}
      <div />
      {children}
    </AntdTooltip>
  );
});
