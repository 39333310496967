import { api } from 'app/config/apiConfig';

import { Insurance } from '../model/types';

export const deactivateInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deactivateInsurance: builder.mutation<Insurance, string>({
      query: (insuranceId) => ({
        url: `/insurance/${insuranceId}/deactivate`,
        method: 'PUT',
      }),
      invalidatesTags: ['Insurance'],
    }),
  }),
});

export const { useDeactivateInsuranceMutation } = deactivateInsuranceApi;
