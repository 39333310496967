import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CancelReservationModalSchema } from '../types';
import { Reservation } from 'entities/Reservation';

const initialState: CancelReservationModalSchema = {
  reservation: null,
};

const cancelReservationModalSlice = createSlice({
  name: 'cancelReservationModal',
  initialState,
  reducers: {
    openCancelReservationModal: (state: CancelReservationModalSchema, action: PayloadAction<Nullable<Reservation>>) => {
      state.reservation = action.payload;
    },
  },
});

export const { actions: cancelReservationModalActions, reducer: cancelReservationModalReducer } = cancelReservationModalSlice;
