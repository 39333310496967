import React, { FC, memo } from 'react';
import { TableFilterContextProvider } from 'features/TableFilter';
import { CreateContractModal } from 'widgets/CreateContractModal';
import { ContractsTable } from './ContractsTable';
import { ContractDetailsDrawer } from 'widgets/ContractDetailsDrawer';
import { ExportModal } from 'features/ExportModal';

export const ContractsPage: FC = memo(() => {
  return (
    <>
      <TableFilterContextProvider>
        <ContractsTable />
      </TableFilterContextProvider>
      <ContractDetailsDrawer />
      <ExportModal />
      <TableFilterContextProvider>
        <CreateContractModal />
      </TableFilterContextProvider>
    </>
  );
});
