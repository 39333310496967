import { stepsMapper, StepData } from 'features/Stepper';

export enum BookingSteps {
  SELECT_LOCATION = 'Select a location',
  SELECT_BOX = 'Select a box',
  CONTRACT_INFORMATION = 'Contract information',
  YOUR_DETAILS = 'Your details',
  PAYMENT = 'Payment',
}

export const bookingSteps = stepsMapper([...Object.values(BookingSteps)]);

export interface BookingHeaderSchema {
  steps: StepData[];
  activeStep: BookingSteps;
  maxProgressStep: BookingSteps;
  completedSteps: StepData[];
}
