import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseListFilterKeys } from '../../model/types';
import { FranchiseSelect } from './FranchiseSelect';

export const WarehouseListFilter: FC = memo(() => {
  const { t } = useAppTranslation('user');

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<WarehouseListFilterKeys>();

  const selectFranchise = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: WarehouseListFilterKeys.franchiseId, label, value, title: t('Franchise') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter withQueryStringFilters={false}>
      <div>
        <FranchiseSelect value={filters.franchiseId?.value} onChange={selectFranchise} onClear={clearCurrentFilters} />
      </div>
    </TableFilter>
  );
});
