import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalConfirmModalSchema, SetModalDataPayload } from '../types';

const initialState: GlobalConfirmModalSchema = {
  globalConfirmModalData: null,
};

const globalConfirmModalSlice = createSlice({
  name: 'globalConfirmModal',
  initialState,
  reducers: {
    setGlobalConfirmModalData: (state: GlobalConfirmModalSchema, action: PayloadAction<Nullable<SetModalDataPayload>>) => {
      state.globalConfirmModalData = action.payload;
    },
    setGlobalConfirmModalLoading: (state: GlobalConfirmModalSchema, action: PayloadAction<boolean>) => {
      state.globalConfirmModalData = { ...state.globalConfirmModalData, isLoading: action.payload };
    },
  },
});

export const { actions: globalConfirmModalActions, reducer: globalConfirmModalReducer } = globalConfirmModalSlice;
