import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginModalMode, LogInModalSchema } from '../types';

const initialState: LogInModalSchema = {
  isOpened: false,
  mode: 'login',
};

const logInModalSlice = createSlice({
  name: 'logInModal',
  initialState,
  reducers: {
    setOpened: (state: LogInModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeMode: (state: LogInModalSchema, action: PayloadAction<LoginModalMode>) => {
      state.mode = action.payload;
    },
  },
});

export const { actions: logInModalActions, reducer: logInModalReducer } = logInModalSlice;
