import React, { FC, memo, ReactElement, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { useSendInvoiceByEmail } from '../utils/hooks/useSendInvoiceByEmail';

interface SendInvoiceByEmailProps {
  invoiceId: string;
  action: ReactElement;
}

export const SendInvoiceByEmail: FC<SendInvoiceByEmailProps> = memo((props) => {
  const { invoiceId, action } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const { send, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useSendInvoiceByEmail();

  const sendInvoice = useCallback(() => {
    send(invoiceId);
  }, [invoiceId, send]);

  return (
    <>
      {React.cloneElement(action, { onClick: openConfirmModal })}
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Send invoice box')}
        description={t('Are you sure you want to send invoice?')}
        isLoading={isLoading}
        onOk={sendInvoice}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
