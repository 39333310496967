import React, { FC, memo, useCallback, useEffect } from 'react';
import { Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { TextArea } from 'shared/ui/TextArea';
import { Checkbox } from 'shared/ui/Checkbox';
import { showNotification } from 'app/providers/NotificationsProvider';
import { CreateNoteData, Note, useCreateNoteMutation, useUpdateNoteMutation } from 'entities/Note';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'shared/ui/DatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { Paragraph } from 'shared/ui/Paragraph';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { DrawerMode } from '../model/types';

interface NoteFormProps {
  note?: Nullable<Note>;
  mode: Nullable<DrawerMode>;
  onClose: () => void;
}

export const NoteForm: FC<NoteFormProps> = memo((props) => {
  const { note, mode, onClose } = props;
  const { t } = useAppTranslation(['boxes', 'common']);

  const [form] = Form.useForm();

  const [createNote, { isLoading: isCreationLoading }] = useCreateNoteMutation();
  const [updateNote, { isLoading: isUpdationLoading }] = useUpdateNoteMutation();

  const isLoading = isUpdationLoading || isCreationLoading;

  const isNoteEditMode = mode === DrawerMode.NOTE_EDIT;

  useEffect(() => {
    if (note) {
      form.setFieldsValue({
        ...note,
        deadlineDate: note?.deadlineDate && dayjs(note.deadlineDate),
        description: null,
      });
    } else {
      form.resetFields();
    }
  }, [form, note]);

  const handleSubmit = useCallback(
    async (data: CreateNoteData): Promise<void> => {
      try {
        switch (mode) {
          case DrawerMode.NOTE_CREATE:
            await createNote({
              ...data,
              deadlineDate: data.deadlineDate && getServerDateFormat(data.deadlineDate),
            }).unwrap();
            showNotification('info', t('Success'), t('Note has been successfully created'));
            break;
          case DrawerMode.NOTE_EDIT:
            if (note) {
              const { noteId } = note;
              await updateNote({
                ...data,
                noteId,
                deadlineDate: data.deadlineDate && getServerDateFormat(data.deadlineDate),
              }).unwrap();
              showNotification('info', t('Success'), t('Note has been successfully updated'));
            }
            break;
        }

        form.resetFields();

        onClose();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t(`Error when ${!isNoteEditMode ? 'creating' : 'updating'} note`));
      }
    },
    [mode, form, onClose, createNote, t, note, updateNote, isNoteEditMode],
  );

  const disableDate = useCallback((currentDate: Dayjs): boolean => {
    const today = dayjs().startOf('day');

    return currentDate.isBefore(today, 'day');
  }, []);

  const paragraphs = note?.description.split('\n');

  const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
    <p key={index} className="font-normal !mb-1">
      {paragraph}
    </p>
  ));

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col px-10 py-4">
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-3xl">{t(isNoteEditMode ? 'Edit Note' : 'Create Note')}</div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={onClose}>
          {t('Close')}
        </div>
      </div>

      {isNoteEditMode && (
        <div className="flex items-center mb-3">
          <div className="flex-1">
            <div>{t('Created by')}</div>
            <div className="font-semibold text-xl">
              {note?.createdBy ? `${note.createdBy.firstName} ${note.createdBy.lastName}` : t('System')}
            </div>
          </div>

          <Form.Item name="isCompleted" valuePropName="checked" className="flex-2">
            <Checkbox name="isCompleted" className="mt-6">
              {t('Completed note')}
            </Checkbox>
          </Form.Item>
        </div>
      )}

      <Form.Item className="w-full" name="deadlineDate" label={t('Deadline date')}>
        <DatePicker
          className="h-[52px] w-full"
          format={CLIENT_DATE_FORMAT}
          placeholder={t('Enter deadline date')}
          inputReadOnly
          disabledDate={disableDate}
        />
      </Form.Item>

      <Form.Item
        label={t('Description')}
        name="description"
        rules={[{ required: !isNoteEditMode, message: `${t('Please, enter description')}!` }]}
      >
        <TextArea placeholder={t('Add description...')} bordered rows={3} />
      </Form.Item>

      <Button type="submit" isLoading={isLoading}>
        {t('Save')}
      </Button>

      {isNoteEditMode && (
        <div className="mt-6">
          <div className="mb-2">{t('Descriptions history')}:</div>
          <Paragraph expandable={false}>{paragraphElements}</Paragraph>
        </div>
      )}
    </Form>
  );
});
