import { Form } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useCreateWarehouse } from 'features/CreateWarehouse';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getFormData } from '../../../model/selectors/getFormData';
import { getSelectedCity } from '../../../model/selectors/getSelectedCity';
import { getSelectedFranchise } from '../../../model/selectors/getSelectedFranchise';
import { CreateWarehouseFormFields } from '../../../model/types';
import { useCreateWarehouseModal } from '../../../utils/hooks/useCreateWarehouseModal';
import { SummaryInfo } from './SummaryInfo';
import { WarehouseInfoForm } from './WarehouseInfoForm';

export const CreateWarehouseStep: FC = memo(() => {
  const { t } = useAppTranslation('warehouses');

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const selectedCity = useAppSelector(getSelectedCity);
  const selectedFranchise = useAppSelector(getSelectedFranchise);
  const formData = useAppSelector(getFormData);

  const { closeModal } = useCreateWarehouseModal();
  const { createWarehouse, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useCreateWarehouse();

  const onSuccessCreate = (): void => {
    closeModal();
    navigate(AppRoutes.WAREHOUSES);
  };

  const handleCreateWarehouse = useCallback(() => {
    if (selectedCity && selectedFranchise) {
      const formFields: CreateWarehouseFormFields = form.getFieldsValue();

      createWarehouse(
        {
          ...formFields,
          cityId: selectedCity.cityId,
          franchiseId: selectedFranchise.franchiseId,
          latitude: Number(formFields.latitude),
          longitude: Number(formFields.longitude),
          launchDate: getServerDateFormat(formFields.launchDate),
          isActive: Boolean(formFields.isActive),
          isComingSoon: Boolean(formFields.isComingSoon),
        },
        onSuccessCreate,
      );
    }
  }, [createWarehouse, form, onSuccessCreate, selectedCity, selectedFranchise]);

  return (
    <>
      <Form className="flex flex-col" name="createWarehouse" form={form} layout="vertical" onFinish={openConfirmModal}>
        <div className="flex space-x-20">
          <WarehouseInfoForm />
          <SummaryInfo cityName={selectedCity?.name} franchiseName={selectedFranchise?.name} formData={formData} />
        </div>
        <div className="flex w-5/12 pl-10 space-x-2.5 self-end">
          <Button containerClassName="flex-1" theme="outlined" onClick={closeModal}>
            {t('Cancel')}
          </Button>
          <Button containerClassName="flex-1" type="submit">
            {t('Create Warehouse')}
          </Button>
        </div>
      </Form>
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create warehouse')}
        description={t('Are you sure you want to create warehouse?')}
        isLoading={isLoading}
        onOk={handleCreateWarehouse}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
