import { api } from 'app/config/apiConfig';

export const pauseContractAutochargesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    pauseAutocharges: builder.mutation<void, string>({
      query: (contractId) => ({
        url: `/contracts/${contractId}/autocharges/pause`,
        method: 'POST',
      }),
      invalidatesTags: ['Contracts', 'Contract details', 'Invoices'],
    }),
  }),
});

export const { usePauseAutochargesMutation } = pauseContractAutochargesApi;
