import { getGlobalSettings } from 'app/appState';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { ThemeColors } from '../../model/types';
import { defaultTheme } from '../constants';

export const useTheme = (): ThemeColors => {
  const globalSettings = useAppSelector(getGlobalSettings);

  const theme = globalSettings?.themeColors || defaultTheme;

  return theme;
};
