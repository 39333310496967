import { Form } from 'antd';
import React, { FC, memo, ReactElement, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { ReplenishBalanceParams } from '../model/types';
import { useReplenishBalance } from '../utils/hooks/useReplenishBalance';
import { BalanceModal } from './BalanceModal';

interface ReplenishBalanceProps {
  userId: string;
  action: ReactElement;
}

export const ReplenishBalance: FC<ReplenishBalanceProps> = memo((props) => {
  const { userId, action } = props;

  const { t } = useAppTranslation('profile');

  const {
    isLoading,
    isOpenedConfirm,
    isOpenedBalance,
    replenishBalance,
    closeConfirmModal,
    openConfirmModal,
    closeBalanceModal,
    openBalanceModal,
  } = useReplenishBalance();

  const [form] = Form.useForm();

  const handleReplenishBalance = useCallback(async () => {
    const { amount, description }: ReplenishBalanceParams = form.getFieldsValue();

    await replenishBalance({ userId, amount, description });

    form.resetFields();
  }, [form, replenishBalance, userId]);

  return (
    <>
      {React.cloneElement(action, { onClick: openBalanceModal })}
      <BalanceModal
        isOpened={isOpenedBalance}
        formInstance={form}
        isLoading={isLoading}
        onSubmit={openConfirmModal}
        onCancel={closeBalanceModal}
      />
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Replenish balance')}
        description={t('Are you sure you want to replenish balance?')}
        isLoading={isLoading}
        onOk={handleReplenishBalance}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
