import { useCallback } from 'react';
import { gratitudeModalActions } from '../model/slice/gratitudeModalSlice';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { GratitudeModalType } from '../model/types';

interface OpenGratitudeModalArgs {
  description: string;
  type: GratitudeModalType;
  buttonId?: string;
  onSuccess?: () => void;
}

interface HooApi {
  openGratitudeModal: (args: OpenGratitudeModalArgs) => void;
}

export const useOpenGratitudeModal = (): HooApi => {
  const dispatch = useAppDispatch();

  const openGratitudeModal = useCallback(
    ({ type, description, buttonId, onSuccess }: OpenGratitudeModalArgs): void => {
      dispatch(
        gratitudeModalActions.openModal({
          description,
          buttonId,
          type,
          onSuccess,
        }),
      );
    },
    [dispatch],
  );

  return {
    openGratitudeModal,
  };
};
