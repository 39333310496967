import { Form } from 'antd';
import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { UserAccountType } from 'entities/User';

interface AccountTypeSelectProps {
  onChange: (accountType: UserAccountType) => void;
}

export const AccountTypeSelect: FC<AccountTypeSelectProps> = memo((props) => {
  const { onChange } = props;

  const { t } = useAppTranslation('booking');

  const accountTypes = [
    { label: 'Personal', value: UserAccountType.PERSONAL },
    { label: 'Business', value: UserAccountType.BUSINESS },
  ];

  const handleChange = (value: string): void => {
    onChange(value as UserAccountType);
  };

  return (
    <Form.Item name="accountType" rules={[{ required: true, message: t('Please select your account type!') }]}>
      <Select placeholder={t('Account type')} options={accountTypes} onChange={handleChange} />
    </Form.Item>
  );
});
