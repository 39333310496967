import { api } from 'app/config/apiConfig';
import { SizeCode } from '../model/types';

export const getSizeCodeBySizeCodeIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSizeCodeById: build.query<SizeCode, Nullable<string | undefined>>({
      query: (sizeCodeId) => ({
        url: `/size-codes/${sizeCodeId}`,
      }),
    }),
  }),
});

export const { useGetSizeCodeByIdQuery } = getSizeCodeBySizeCodeIdApi;
