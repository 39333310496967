import { Form, FormInstance } from 'antd';
import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { InputNumber } from 'shared/ui/InputNumber';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { WarningPing } from 'shared/ui/WarningPing';
import { TWO_DECIMALS_FLOAT_REGEX } from 'shared/utils/regex';
import { ReplenishBalanceParams } from '../model/types';

interface BalanceModalProps {
  isOpened: boolean;
  formInstance: FormInstance;
  onSubmit: (formValues: ReplenishBalanceParams) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const BalanceModal: FC<BalanceModalProps> = memo((props) => {
  const { isOpened, formInstance, onSubmit, onCancel, isLoading } = props;

  const { t } = useAppTranslation('profile');

  return (
    <Modal width={380} isOpen={isOpened} onClose={onCancel} closeIcon={false}>
      <WarningPing className="mb-4" type="warning" />
      <div className="text-lg font-semibold mb-2.5">{t('Replenishment of balance')}</div>
      {/* eslint-disable-next-line quotes */}
      <div className="font-normal text-primaryLight mb-9">{t("Add the amount to be deposited to the user's balance")}</div>
      {/* eslint-disable-next-line quotes */}
      <div className="mb-2.5">{t("Add to a user's balance")}</div>
      <Form name="balanceModal" form={formInstance} layout="vertical" onFinish={onSubmit}>
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: t('Please enter balance') },
            { pattern: TWO_DECIMALS_FLOAT_REGEX, message: t('Please provide integer!') },
          ]}
        >
          <InputNumber placeholder={t('Enter balance')} bordered />
        </Form.Item>
        <Form.Item className="mb-9" name="description">
          <TextArea placeholder={t('Add your description...')} rows={4} bordered />
        </Form.Item>
        <div className="flex flex-col space-y-2">
          <Form.Item noStyle>
            <Button containerClassName="w-full" type="submit" isLoading={isLoading}>
              {t('Replenish')}
            </Button>
          </Form.Item>
          <Button containerClassName="w-full" theme="secondary" isLoading={isLoading} onClick={onCancel}>
            {t('Close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});
