import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { useGetAllFranchisesQuery } from 'entities/Franchise';
import { WarehouseListFilterKeys } from '../../model/types';

interface FranchiseSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const FranchiseSelect: FC<FranchiseSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('user');

  const { data } = useGetAllFranchisesQuery();

  const franchiseOptions = useMemo<Array<{ label: string; value: string }>>(() => {
    return data?.map(({ franchiseId, name }) => ({ label: name, value: franchiseId })) || [];
  }, [data]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(WarehouseListFilterKeys.franchiseId);
  }, [onClear]);

  return (
    <Select
      className="w-full"
      label={t('Franchise')}
      placeholder={t('Select')}
      bordered
      allowClear
      options={franchiseOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
