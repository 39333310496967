import React, { FC, MouseEvent, memo, useCallback, useState } from 'react';
import { PaymentElement, useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { Card, Divider } from 'antd';
import { Button } from 'shared/ui/Button';
import { InfoTag } from 'shared/ui/InfoTag';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { StripeIbanElement } from '@stripe/stripe-js';
import { useGetInvoiceByIdQuery } from 'entities/Invoice';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ibanElementStyle } from './checkoutFormStyles';
import { AppRoutes } from 'app/config/routerConfig/types';
import { PageLoader } from 'features/PageLoader';
import { AppLogo, useGetCurrencySymbol } from 'app/appState';
import { PaymentFailedModal } from './PaymentFailedModal';

interface CheckoutFormProps {
  paymentData: CustomAny;
}

export const CheckoutForm: FC<CheckoutFormProps> = memo((props) => {
  const { paymentData } = props;
  const navigate = useNavigate();
  const { t } = useAppTranslation('common');
  const [isStripeLoading, setStripeLoading] = useState<boolean>(false);
  const [paymentErrorCode, setPaymentErrorCode] = useState<Nullable<string>>(null);

  const { data: invoice, isLoading: isGettingInvoiceLoading } = useGetInvoiceByIdQuery(paymentData.invoiceId, {
    skip: !paymentData.invoiceId,
  });

  const currencySymbol = useGetCurrencySymbol();

  const isNotReadyToPay = _.isEmpty(paymentData) || !paymentData.secret || !paymentData.publicKey;

  if (isNotReadyToPay) {
    navigate(AppRoutes.HOME);
  }

  const stripeApi = useStripe();
  const elements = useElements();

  const ibanElementOptions = {
    supportedCountries: ['SEPA'],
    placeholderCountry: invoice ? invoice.user.country : undefined,
    style: ibanElementStyle,
  };

  const handleSubmit = useCallback(
    async (event: MouseEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();

      setStripeLoading(true);

      if (!stripeApi || !elements || !paymentData.secret) {
        return;
      }

      let result;

      if (paymentData.processor === 'sepa_debit') {
        result = await stripeApi.confirmSepaDebitPayment(paymentData.secret, {
          payment_method: {
            sepa_debit: elements.getElement(IbanElement) as StripeIbanElement,
            billing_details: {
              name: paymentData.customerFullName,
              email: paymentData.email,
            },
          },
          return_url: `${window.location.origin}${AppRoutes.PAYMENT_SUCCESS}?amount=${paymentData.amount}`,
        });
      } else {
        result = await stripeApi.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}${AppRoutes.PAYMENT_SUCCESS}?amount=${paymentData.amount}`,
            payment_method_data: {
              billing_details: {
                name: paymentData.customerFullName,
                email: paymentData.email,
              },
            },
          },
        });
      }

      setStripeLoading(false);

      if (result?.error?.type === 'validation_error') {
        return;
      }

      if (result?.error?.code) {
        // navigate(AppRoutes.PAYMENT_FAILED, {
        //   state: {
        //     errorCode: result?.error?.decline_code || result.error.code,
        //   },
        // });
        setPaymentErrorCode(result?.error?.decline_code || result.error.code);
      }
    },
    [elements, paymentData, stripeApi],
  );

  const closeModal = useCallback((): void => {
    setPaymentErrorCode(null);
  }, []);

  return (
    <div>
      {isGettingInvoiceLoading ? (
        <PageLoader />
      ) : (
        <Card
          className="w-[350px] pt-3 desktop:w-[450px]"
          title={
            <div className="flex items-center p-3 justify-center">
              <AppLogo width={180} height="100%" />
            </div>
          }
        >
          <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
            {paymentData.processor === 'sepa_debit' ? (
              <div>
                <div className="mb-1 text-primaryLight">IBAN:</div>
                <div className="border rounded">
                  <IbanElement options={ibanElementOptions} className="p-3" />
                </div>
              </div>
            ) : (
              <PaymentElement
                options={{
                  wallets: { applePay: 'auto', googlePay: 'auto' },
                  terms: { sepaDebit: 'auto' },
                }}
              />
            )}

            <Button type="submit" className="mt-6 mb-2" isLoading={isStripeLoading}>
              {t('Pay')} {paymentData.amount} {currencySymbol}
            </Button>

            <Divider className="m-0" />

            <InfoTag>{t('You may be redirected to 3D-Secure verification page')}</InfoTag>
          </form>
        </Card>
      )}
      <PaymentFailedModal errorCode={paymentErrorCode} closeModal={closeModal} />
    </div>
  );
});
