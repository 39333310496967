import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';

export const NotFoundPage: FC = memo(() => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="text-4xl">Page does not exist</div>
      <div className="text-sm text-accent">
        <Link to={AppRoutes.HOME}>Go to home page</Link>
      </div>
    </div>
  );
});
