import { ColumnsType } from 'antd/es/table';
import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ServerTable } from 'shared/ui/ServerTable';
import { ReactComponent as SizeCodeEditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { SizeCodeForListing, SizeCodeType, useDeleteSizeCodeMutation, useGetPaginatedSizeCodesQuery } from 'entities/SizeCode';
import { useGetCurrencySymbol } from 'app/appState';
import { useTableFilterContext } from 'features/TableFilter';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Paragraph } from 'shared/ui/Paragraph';
import { transformSizeCodesListFilters } from '../utils/transformSizeCodesListFilters';
import { Link } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { DrawerMode, SizeCodeFormDrawer, sizeCodeFormDrawerActions } from 'features/SizeCodeFormDrawer';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useGlobalConfirmModal } from 'app/globalConfirmModal';
import { SizeCodesListFilter } from './SizeCodesListFilter';

export const SizeCodesTable: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'boxes', 'common']);
  const dispatch = useAppDispatch();

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const [deleteSizeCode] = useDeleteSizeCodeMutation();

  const currencySymbol = useGetCurrencySymbol();

  const { openModal, closeModal } = useGlobalConfirmModal();

  const createSizeCode = useCallback((): void => {
    dispatch(sizeCodeFormDrawerActions.setOpenSizeCodeForm({ mode: DrawerMode.SIZE_CODE_CREATE }));
  }, [dispatch]);

  const editSizeCode = useCallback(
    (sizeCode: SizeCodeForListing): void => {
      dispatch(sizeCodeFormDrawerActions.setOpenSizeCodeForm({ mode: DrawerMode.SIZE_CODE_EDIT, sizeCode }));
    },
    [dispatch],
  );

  useEffect(() => {
    // TO DO INVESTIGATE HOW TO CLEAR ALL FILTERS WITHOUT USE EFFECT !!!
    return () => {
      clearAllFilters();
    };
  }, []);

  const handleDeleteSizeCode = useCallback(
    async (selectedSizeCode: SizeCodeForListing): Promise<void> => {
      try {
        if (selectedSizeCode) {
          await deleteSizeCode(selectedSizeCode.sizeCodeId).unwrap();
          showNotification('info', t('Success'), t('Size code setting deleted successfully', { ns: 'common' }));
        }

        closeModal();
      } catch (error) {
        showNotification('error', t('Error'), t('Error when deleting size code', { ns: 'common' }));
        console.log('error', error);
      }
    },
    [closeModal, deleteSizeCode, t],
  );

  const openConfirmModal = useCallback(
    (selectedSizeCode: SizeCodeForListing): void => {
      openModal({
        title: t('Delete size code'),
        description: t(
          `${
            selectedSizeCode.totalBoxesAmount
              ? 'This size code can not be removed as there are already boxes with this size code!'
              : 'Are you sure you want to delete this size code?'
          }`,
        ),
        onCancel: closeModal,
        onOk: async () => {
          await handleDeleteSizeCode(selectedSizeCode);
        },
        isConfirmDisabled: Boolean(selectedSizeCode.totalBoxesAmount),
      });
    },
    [closeModal, handleDeleteSizeCode, openModal, t],
  );

  const rowActions: Array<ServerTableRowActions<SizeCodeForListing>> = useMemo(
    () => [
      {
        name: 'editSizeCode',
        icon: <SizeCodeEditIcon />,
        theme: 'clear',
        description: t('Edit sizeCode'),
        onClick: editSizeCode,
      },
      {
        name: 'deleteSizeCode',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete sizeCode'),
        onClick: openConfirmModal,
      },
    ],
    [editSizeCode, openConfirmModal, t],
  );

  const columns = useMemo<ColumnsType<SizeCodeForListing>>(
    () => [
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '10%',
        render: (_, record) => (
          <Link className="text-accent underline font-normal" to={`${AppRoutes.WAREHOUSES}/${record.warehouseId}`}>
            {record.warehouseName}
          </Link>
        ),
      },
      {
        title: t('Square'),
        width: '5%',
        key: 'square',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => (
          <div className="font-bold">
            {record.square}m<sup className="font-bold">{record.sizeCodeType === SizeCodeType.CUBIC_BOX ? 3 : 2}</sup>
          </div>
        ),
      },
      {
        title: t('Cost'),
        width: '15%',
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.dailyRate && (
              <div>
                {record.dailyRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Day')}</span>
              </div>
            )}
            {record.weekRate && (
              <div>
                {record.weekRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Week')}</span>
              </div>
            )}
            {record.monthRate && (
              <div>
                {record.monthRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Month')}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Size group'),
        key: 'sizeGroup',
        width: '5%',
        render: (_, record) => <div className="text-primary font-normal">{record.sizeGroup}</div>,
      },
      {
        title: t('Total boxes'),
        key: 'totalBoxesAmount',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{record.totalBoxesAmount}</div>,
      },
      {
        title: t('Occupied boxes'),
        key: 'occupiedBoxesAmount',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{record.occupiedBoxesAmount}</div>,
      },
      {
        title: t('Unavailable boxes'),
        key: 'unavailableBoxesAmount',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{record.unavailableBoxesAmount}</div>,
      },
      {
        title: t('Available for book'),
        key: 'availableBoxesAmount',
        width: '10%',
        render: (_, record) => <div className="text-primary font-normal">{record.availableBoxesAmount}</div>,
      },
      {
        title: t('Promotions'),
        key: 'availableBoxesAmount',
        width: '25%',
        render: (_, record) => {
          return record.rentOptions?.map((option, index) => (
            <div key={index} className="text-primaryLight font-normal">
              {index + 1}. {getLocalizedString(option)}
            </div>
          ));
        },
      },
    ],
    [t, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<SizeCodeForListing>> = useMemo(
    () => [
      {
        name: t('Add Size Code'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createSizeCode,
      },
      {
        name: t('Filters', { ns: 'boxes' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [t, createSizeCode, setFiltersOpened],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Size Codes List')}</div>
      <ServerTable
        columns={columns}
        rowActions={rowActions}
        fetchData={useGetPaginatedSizeCodesQuery}
        defaultFilters={transformSizeCodesListFilters(appliedFilters)}
        rowKey="sizeCodeId"
        search
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        tableActions={tableActions}
        tableActionsPosition="prev"
        searchPlaceholder={t('Search')}
      />
      <SizeCodeFormDrawer />
      <SizeCodesListFilter />
    </div>
  );
});
