import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { InvoicesListFilterKeys } from '../../model/types';
import { InvoiceStatus } from 'entities/Invoice';

interface InvoiceStatusSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const InvoiceStatusSelect: FC<InvoiceStatusSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('contracts');

  const invoiceStatusOptions = useMemo<Array<{ label: string; value: InvoiceStatus }>>(() => {
    return [
      { label: t('Active'), value: InvoiceStatus.ACTIVE },
      { label: t('Overdue'), value: InvoiceStatus.OVERDUE },
      { label: t('Inactive'), value: InvoiceStatus.INACTIVE },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(InvoicesListFilterKeys.isActive);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Status')}
      placeholder={t('All')}
      bordered
      allowClear
      options={invoiceStatusOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
