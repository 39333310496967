import { api } from 'app/config/apiConfig';
import { PaymentProcessor } from 'entities/Payment';

interface PaymentArgs {
  invoiceId: string;
  processor: PaymentProcessor;
  isAutopayment: boolean;
}

interface PaymentResponse {
  redirectionUrl?: 'string';
  paymentData?: CustomAny;
}

export const payInvoiceApi = api.injectEndpoints({
  endpoints: (build) => ({
    payInvoice: build.mutation<PaymentResponse, PaymentArgs>({
      query: ({ invoiceId, processor, isAutopayment }) => ({
        url: `/billing-documents/invoices/${invoiceId}/pay`,
        method: 'POST',
        params: { processor, isAutopayment },
      }),
      invalidatesTags: ['Invoices'],
    }),
  }),
});

export const { usePayInvoiceMutation } = payInvoiceApi;
