import { Form } from 'antd';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { contractOptionsActions, getContractOptions } from 'entities/Contract';
import { getInsuranceRateField, useGetInsuranceByWarehouseIdQuery } from 'entities/Insurance';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { getLoggedUserData, useAuthorizedState, useCheckUserDataComplete } from 'entities/User';
import { ReserveBox } from 'features/ReserveBox';
import _ from 'lodash';
import { getBoxInfo, getWarehouseInfo } from 'pages/BookingPage';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { Collapse } from 'shared/ui/Collapse';
import { useInitContractInformationData } from '../utils/hooks/useInitContractInformationData';
import { AccountTypeContent } from './AccountTypeContent';
import { ContractDetails } from './ContractDetails';
import { GratitudeModalType, useOpenGratitudeModal } from 'features/GratitudeModal';

interface ContractInformationFormProps {
  selectedWarehouseId: string | undefined;
  onCompleteStepAuthorized: () => void;
  onCompleteStepUnAuthorized: () => void;
}

export const ContractInformationForm: FC<ContractInformationFormProps> = memo((props) => {
  const { onCompleteStepAuthorized, onCompleteStepUnAuthorized, selectedWarehouseId } = props;

  const { t } = useAppTranslation('booking');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuthorized = useAuthorizedState();
  const boxInfo = useAppSelector(getBoxInfo);
  const warehouseInfo = useAppSelector(getWarehouseInfo);
  const loggedUserData = useAppSelector(getLoggedUserData);
  const isUserDataCompleted = useCheckUserDataComplete();
  const currencySymbol = useGetCurrencySymbol();
  const needAuthorization = !isAuthorized || !isUserDataCompleted;
  const contractOptions = useAppSelector(getContractOptions);
  const loggedUser = useAppSelector(getLoggedUserData);
  const userAccountType = contractOptions.accountType;

  useInitContractInformationData();

  const { openGratitudeModal } = useOpenGratitudeModal();

  const { data: insurances } = useGetInsuranceByWarehouseIdQuery(selectedWarehouseId, { skip: !selectedWarehouseId });

  const insuranceOptions = useMemo<Array<{ value: string; label: string }>>(() => {
    const rateField = getInsuranceRateField(contractOptions.invoiceFrequencyType);

    const filteredByRateFieldInsurance = insurances?.filter((insurance) => insurance[rateField]) || [];

    const options = filteredByRateFieldInsurance.map((insurance) => {
      const label = `${insurance[rateField]} ${currencySymbol}/${t(contractOptions.invoiceFrequencyType)}`;

      return {
        value: insurance.insuranceId,
        label,
      };
    });

    return options;
  }, [contractOptions.invoiceFrequencyType, insurances, currencySymbol, t]);

  const isValidForm = useMemo((): boolean => {
    let isValid = Boolean(contractOptions.contractDuration) && Boolean(contractOptions.startDate) && Boolean(contractOptions.rentOption);
    const hasInsurance = !_.isEmpty(insurances) && !_.isEmpty(insuranceOptions);

    if (hasInsurance) {
      isValid = isValid && Boolean(contractOptions.insuranceId);
    }

    if (contractOptions.invoiceFrequencyType === InvoiceFrequencyType.DAY) {
      isValid = isValid && Boolean(contractOptions.endDate);
    }

    return isValid;
  }, [
    contractOptions.contractDuration,
    contractOptions.startDate,
    contractOptions.rentOption,
    contractOptions.invoiceFrequencyType,
    contractOptions.insuranceId,
    contractOptions.endDate,
    insurances,
    insuranceOptions,
  ]);

  const isReserveDisabled = useMemo<boolean>(() => {
    let isDisabled = !isUserDataCompleted || !warehouseInfo || !boxInfo || !contractOptions.startDate || !contractOptions.insuranceId;

    if (contractOptions.invoiceFrequencyType === 'day') {
      isDisabled = isDisabled || !contractOptions.endDate;
    }

    return isDisabled;
  }, [
    boxInfo,
    contractOptions.endDate,
    contractOptions.insuranceId,
    contractOptions.invoiceFrequencyType,
    contractOptions.startDate,
    isUserDataCompleted,
    warehouseInfo,
  ]);

  const goToNextStep = (): void => {
    needAuthorization ? onCompleteStepUnAuthorized() : onCompleteStepAuthorized();
  };

  const handleOpenGratitudeModal = useCallback((): void => {
    openGratitudeModal({
      description: t(
        'Our company values each and every customer. If you have any questions or feedback, please don`t hesitate to reach out!',
      ),
      type: GratitudeModalType.RESERVATION,
      buttonId: 'reservation id', // TODO ADD ID FROM MARCUS !!!
    });
  }, [openGratitudeModal, t]);

  const onReservationSuccess = useCallback(() => {
    dispatch(contractOptionsActions.resetContractOptions());

    navigate(AppRoutes.MY_BOXES);

    handleOpenGratitudeModal();
  }, [dispatch, handleOpenGratitudeModal, navigate]);

  const [form] = Form.useForm();

  return (
    <Form className="space-y-3" name="contractInfo" form={form} autoComplete="off" onFinish={goToNextStep}>
      <Collapse title={t('1. Account')} content={<AccountTypeContent userAccountType={userAccountType} />} isOpened activeKey="1" />
      <Collapse
        title={t('2. Dates & Insurance')}
        content={
          <ContractDetails
            selectedWarehouse={warehouseInfo}
            boxInfo={boxInfo}
            userInfo={loggedUserData}
            userAccountType={userAccountType}
          />
        }
        isOpened
        activeKey="1"
      />
      <div className="mt-5 flex space-x-3">
        {!needAuthorization && (
          <ReserveBox
            action={
              <Button containerClassName="w-full" size="medium" theme="secondary" isDisabled={isReserveDisabled}>
                {t('Reserve')}
              </Button>
            }
            boxId={boxInfo?.boxId}
            warehouseId={warehouseInfo?.warehouseId}
            contractOptions={contractOptions}
            userId={loggedUser?.userId}
            onSuccess={onReservationSuccess}
            needConfirmBookingDocs
          />
        )}
        <Button containerClassName="w-full" size="medium" type="submit" isDisabled={!isValidForm}>
          {t('Continue')}
        </Button>
      </div>
    </Form>
  );
});
