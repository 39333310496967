import React, { FC, memo, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings, useGetCurrencySymbol } from 'app/appState';
import { Box, useGetBoxRentalPrice } from 'entities/Box';
import { ContractInfo } from 'entities/Contract';
import { BulletsTable, TableRow } from 'shared/ui/BulletsTable';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { getDepositAmount } from 'entities/Invoice';

interface PriceDetailsTableProps {
  contractInfo: ContractInfo;
  boxInfo: Nullable<Box>;
}

export const PriceDetailsTable: FC<PriceDetailsTableProps> = memo((props) => {
  const { boxInfo, contractInfo } = props;

  const { t } = useAppTranslation('booking');

  const globalSettings = useAppSelector(getGlobalSettings);
  const currencySymbol = useGetCurrencySymbol();

  const rentalPrice = useGetBoxRentalPrice(boxInfo, contractInfo.invoiceFrequencyType);
  const depositAmount = getDepositAmount(Boolean(globalSettings?.isDepositApplied), boxInfo);

  const priceDetailsBullets = useMemo(() => {
    const tableRows: TableRow[] = [];

    const firstServicePeriodUnitPrice = contractInfo.contractPeriodsUnitPrices[0];
    const secondServicePeriodUnitPrice = contractInfo.contractPeriodsUnitPrices[1];

    if (firstServicePeriodUnitPrice) {
      tableRows.push({
        label: t('1st {{invoiceFrequencyType}} rent', { invoiceFrequencyType: t(contractInfo.invoiceFrequencyType) }),
        value: `${firstServicePeriodUnitPrice.rate} ${currencySymbol}`,
      });
    }

    if (secondServicePeriodUnitPrice) {
      tableRows.push({
        label: t('2nd {{invoiceFrequencyType}} rent', { invoiceFrequencyType: t(contractInfo.invoiceFrequencyType) }),
        value: `${secondServicePeriodUnitPrice.rate} ${currencySymbol}`,
      });
    }

    if (contractInfo.payForEntirePeriod) {
      tableRows.push({
        label: t('Insurance for {{contractDuration}} {{invoiceFrequencyType}}', {
          contractDuration: contractInfo.contractDuration,
          invoiceFrequencyType: t(`${contractInfo.invoiceFrequencyType}s`),
        }),
        value: `${roundNumber(contractInfo.insuranceAmountWithoutVat)} ${currencySymbol}`,
      });
    } else {
      tableRows.push({
        label: t('Insurance for 1st {{invoiceFrequencyType}}', {
          invoiceFrequencyType: t(contractInfo.invoiceFrequencyType),
        }),
        value: `${roundNumber(contractInfo.insuranceAmountWithoutVat)} ${currencySymbol}`,
      });
    }

    tableRows.push({
      label: t('Deposit (one time)'),
      value: depositAmount ? `${depositAmount} ${currencySymbol}` : t('No deposit'),
    });

    const totalWithoutVat = roundNumber(contractInfo.totalToPay - contractInfo.vatRateAmount - contractInfo.insuranceVatRateAmount);
    const totalWithVat = roundNumber(contractInfo.totalToPay);

    if (totalWithoutVat === totalWithVat) {
      tableRows.push({
        label: t('VAT'),
        value: '0%',
      });

      tableRows.push({
        label: t(
          `Total to pay (for ${
            contractInfo.payForEntirePeriod ? '{{contractDuration}} {{invoiceFrequencyTypes}}' : '1st {{invoiceFrequencyType}}'
          })`,
          {
            contractDuration: contractInfo.contractDuration,
            invoiceFrequencyType: t(contractInfo.invoiceFrequencyType),
            invoiceFrequencyTypes: t(`${contractInfo.invoiceFrequencyType}s`),
          },
        ),
        value: `${totalWithVat} ${currencySymbol}`,
        highlighted: true,
      });
    } else {
      tableRows.push({
        label: t(
          `Total for ${
            contractInfo.payForEntirePeriod ? '{{contractDuration}} {{invoiceFrequencyTypes}}' : '1st {{invoiceFrequencyType}}'
          }`,
          {
            contractDuration: contractInfo.contractDuration,
            invoiceFrequencyType: t(contractInfo.invoiceFrequencyType),
            invoiceFrequencyTypes: t(`${contractInfo.invoiceFrequencyType}s`),
          },
        ),
        value: `${totalWithoutVat} ${currencySymbol}`,
      });

      if (contractInfo.vatRatePercent === contractInfo.insuranceVatRatePercent) {
        tableRows.push({
          label: t('VAT ({{vatRatePercent}}%)', { vatRatePercent: contractInfo.vatRatePercent }),
          value: roundNumber(contractInfo.vatRateAmount + contractInfo.insuranceVatRateAmount),
        });
      } else {
        tableRows.push({
          label: t('VAT ({{vatRatePercent}}%)', { vatRatePercent: contractInfo.vatRatePercent }),
          value: `${roundNumber(contractInfo.vatRateAmount)} ${currencySymbol}`,
        });

        tableRows.push({
          label: t('Insurance VAT ({{vatRatePercent}}%)', { vatRatePercent: contractInfo.insuranceVatRatePercent }),
          value: `${roundNumber(contractInfo.insuranceVatRateAmount)} ${currencySymbol}`,
        });
      }

      tableRows.push({
        label: t('Total to pay'),
        value: `${totalWithVat} ${currencySymbol}`,
        highlighted: true,
      });
    }

    return tableRows;
  }, [
    contractInfo.contractPeriodsUnitPrices,
    contractInfo.payForEntirePeriod,
    contractInfo.totalToPay,
    contractInfo.vatRateAmount,
    contractInfo.insuranceVatRateAmount,
    contractInfo.invoiceFrequencyType,
    contractInfo.vatRatePercent,
    contractInfo.contractDuration,
    contractInfo.insuranceAmountWithoutVat,
    contractInfo.insuranceVatRatePercent,
    t,
    depositAmount,
    currencySymbol,
  ]);

  return (
    <div className="flex flex-col bg-secondaryLight rounded-lg">
      <BulletsTable heading={t('Price details')} rows={priceDetailsBullets} labelClassName="max-w-[220px]" />
      {/* <div className="text-sm text-primaryLight mt-5">
        {t('Rental price from {{contractDuration}} {{invoiceFrequencyType}}: {{rentalPrice}}', {
          contractDuration: contractInfo?.contractDuration + 1,
          rentalPrice,
          invoiceFrequencyType: t(contractInfo.invoiceFrequencyType),
        })}
      </div> */}
    </div>
  );
});
