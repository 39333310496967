import { io } from 'socket.io-client';
import { SOCKET_HOST } from 'app/config/envVariables';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { socketConnectionActions } from 'app/socketConnection';
import { getLoggedUserData } from 'entities/User/model/selectors/getLoggedUserData';
import { Warehouse } from 'entities/Warehouse';

export const useSocketConnect = (): void => {
  const dispatch = useAppDispatch();

  const loggedUserData = useAppSelector(getLoggedUserData);

  const query: AnyObject = {};

  if (loggedUserData) {
    Object.entries(loggedUserData).forEach(([key, value]) => {
      if (key === 'warehouses') {
        query.assignedWarehouseIds = value.map((warehouse: Warehouse) => warehouse.warehouseId);
      } else {
        query[key] = value;
      }
    });
  }

  const socket = io(SOCKET_HOST, {
    path: '/socket',
    autoConnect: true,
    reconnection: true,
    query,
  });

  useEffect(() => {
    socket.connect();

    socket.on('connect', () => {
      dispatch(socketConnectionActions.setConnection(socket));
      console.log('==== SOCKET CONNECTION ====');
    });

    socket.on('disconnect', () => {
      dispatch(socketConnectionActions.setConnection(null));
      console.log('==== SOCKET DISCONNECTION ====');
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch, socket]);
};
