import { api } from 'app/config/apiConfig';
import { Insurance } from '../model/types';

export const deleteInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteInsurance: builder.mutation<Insurance, string>({
      query: (insuranceId: string) => ({
        url: `/insurance/${insuranceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Insurance'],
    }),
  }),
});

export const { useDeleteInsuranceMutation } = deleteInsuranceApi;
