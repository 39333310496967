import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useSendInvoiceByEmailMutation } from 'entities/Invoice';

interface HookApi {
  send: (invoiceId: string) => void;
  isOpenedConfirm: boolean;
  isLoading: boolean;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useSendInvoiceByEmail = (): HookApi => {
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [sendInvoice, { isLoading }] = useSendInvoiceByEmailMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const send = useCallback(
    async (invoiceId: string): Promise<void> => {
      try {
        await sendInvoice(invoiceId).unwrap();

        showNotification('info', t('Success'), t('Invoice has been successfully sent'));
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when sending invoice'));
      } finally {
        closeConfirmModal();
      }
    },
    [closeConfirmModal, sendInvoice, t],
  );

  return {
    send,
    openConfirmModal,
    closeConfirmModal,
    isOpenedConfirm,
    isLoading,
  };
};
