import React, { FC, memo, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { showNotification } from 'app/providers/NotificationsProvider';
import { leadNoteDrawerActions } from '../model/slice/leadNoteDrawerSlice';
import { getLeadNoteDrawerState } from '../model/selectors/getLeadNoteDrawerState';
import { TextArea } from 'shared/ui/TextArea';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useUpdateLeadMutation } from 'entities/Lead';
import { Button } from 'shared/ui/Button';
import { Paragraph } from 'shared/ui/Paragraph';

export const LeadNoteDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation(['user', 'common']);

  const { lead } = useAppSelector(getLeadNoteDrawerState);

  const [note, setNote] = useState<Nullable<string>>(null);

  const [updateLead, { isLoading }] = useUpdateLeadMutation();

  const closeBoxFormDrawer = useCallback((): void => {
    dispatch(leadNoteDrawerActions.setOpenLeadNoteDrawer(null));
  }, [dispatch]);

  const handleChangeNote = useCallback((note: string) => {
    setNote(note);
  }, []);

  const handleAddLeadNote = useCallback(async (): Promise<void> => {
    try {
      if (lead && note) {
        const adminNoteString = `\n${t('Admin note:')}\n${note}`;
        await updateLead({ leadId: lead.leadId, note: adminNoteString }).unwrap();
        showNotification('info', t('Success'), t('Lead note added successfully'));

        closeBoxFormDrawer();

        setNote(null);
      }
    } catch (error) {
      showNotification('error', t('Error'), t('Error when adding lead note contract'));
      console.log('error', error);
    }
  }, [closeBoxFormDrawer, lead, note, t, updateLead]);

  const paragraphs = lead?.note?.split('\n');

  const paragraphElements = paragraphs?.map((paragraph: string, index: number) => (
    <p key={index} className="font-normal !mb-1">
      {paragraph}
    </p>
  ));

  return (
    <Drawer width={450} open={Boolean(lead)} onClose={closeBoxFormDrawer}>
      <div className="flex flex-col px-10 py-4">
        <div className="flex justify-between items-center pt-4 pb-10">
          <div className="font-semibold text-3xl">{t('Notes')}</div>
          <div className="text-primaryLight font-normal cursor-pointer" onClick={closeBoxFormDrawer}>
            {t('Close')}
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div>
            <div className="mb-5">
              <div className="mb-2">{t('Note')}</div>
              <TextArea placeholder={t('Add your note...')} rows={4} bordered value={note} onChange={handleChangeNote} />
            </div>
            <Button onClick={handleAddLeadNote} isLoading={isLoading} isDisabled={!lead || !note}>
              {t('Add Note')}
            </Button>
          </div>

          <div>
            <div className="mb-2">{t('Note history')}:</div>
            <Paragraph expandable={false}>{paragraphElements}</Paragraph>
          </div>
        </div>
      </div>
    </Drawer>
  );
});
