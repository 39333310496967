import { Box } from 'entities/Box';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { BookingSteps } from 'widgets/BookingHeader';
import { getOpenedStack } from '../../model/selectors/getOpenedStack';
import { bookingInfoStackActions } from '../../model/slice/bookingInfoStackSlice';
import { BookingInfoStacks } from '../../model/types';

export const useSubscribeOpenedStack = (bookingStep: Nullable<BookingSteps>, boxInfo: Nullable<Box>): Nullable<BookingInfoStacks> => {
  const dispatch = useAppDispatch();

  const openedStack = useAppSelector(getOpenedStack);

  useEffect(() => {
    if (bookingStep === BookingSteps.SELECT_BOX && !boxInfo) {
      dispatch(bookingInfoStackActions.setOpenedStack(BookingInfoStacks.WAREHOUSE_INFORMATION));
    }

    if ((bookingStep === BookingSteps.SELECT_BOX && Boolean(boxInfo)) || bookingStep === BookingSteps.CONTRACT_INFORMATION) {
      dispatch(bookingInfoStackActions.setOpenedStack(BookingInfoStacks.YOUR_BOX_UNIT));
    }

    if (bookingStep === BookingSteps.YOUR_DETAILS || bookingStep === BookingSteps.PAYMENT) {
      dispatch(bookingInfoStackActions.setOpenedStack(BookingInfoStacks.ORDER_SUMMARY));
    }
  }, [bookingStep, boxInfo, dispatch]);

  return openedStack;
};
