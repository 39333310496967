import React, { FC, PropsWithChildren, memo, MouseEvent } from 'react';
import { Typography } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import s from './index.module.scss';
import classNames from 'classnames';

interface ParagraphProps extends PropsWithChildren {
  className?: string;
  rows?: number;
  expandable?: boolean;
}

export const Paragraph: FC<ParagraphProps> = memo((props) => {
  const { children, className, rows, expandable = true } = props;

  const { t } = useAppTranslation('common');

  const stopPropagation = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  return (
    <Typography.Paragraph
      ellipsis={{ rows, expandable, symbol: t('View more') }}
      className={classNames(s.paragraph, className)}
      onClick={stopPropagation}
    >
      {children}
    </Typography.Paragraph>
  );
});
