import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Insurance } from '../model/types';

interface Params {
  warehouseId?: string;
  search?: string;
  orderBy?: string;
}

export const getPaginatedInsuranceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedInsurance: build.query<Pagination<Insurance>, Params | void>({
      query: (args) => ({
        url: '/insurance/settings',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Insurance'],
    }),
  }),
});

export const { useGetPaginatedInsuranceQuery } = getPaginatedInsuranceApi;
