import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getSelectedCity } from '../../model/selectors/getSelectedCity';
import { getSelectedFranchise } from '../../model/selectors/getSelectedFranchise';
import { createWarehouseModalActions } from '../../model/slice/createWarehouseModalSlice';

export const useWatchStep = (): void => {
  const dispatch = useAppDispatch();

  const selectedCity = useAppSelector(getSelectedCity);
  const selectedFranchise = useAppSelector(getSelectedFranchise);

  useEffect(() => {
    if (selectedCity) {
      dispatch(createWarehouseModalActions.changeStepData({ targetStepIndex: 0, stepData: selectedCity.name }));
    }
    if (selectedFranchise) {
      dispatch(createWarehouseModalActions.changeStepData({ targetStepIndex: 1, stepData: selectedFranchise.name }));
    }
  }, [dispatch, selectedCity, selectedFranchise]);
};
