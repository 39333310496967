import { DefaultOptionType } from 'antd/es/select';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { getGlobalSettings, useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { checkEmptyFields } from 'shared/utils/helpers/checkEmptyFields';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Box, getBoxRateField } from 'entities/Box';
import { addPercent, calculateDiscountValues, useGetBoxDiscountsQuery } from 'entities/Discount';
import { getInsuranceRateField, useGetInsuranceByWarehouseIdQuery } from 'entities/Insurance';
import { getDepositAmount } from 'entities/Invoice';
import { User, UserAccountType, getLoggedUserData, useAdminRole, useGetUserReservationsQuery } from 'entities/User';
import { VatRateParams, useGetOneVatRateQuery } from 'entities/VatRate';
import { useGetContractsByUserIdQuery } from '../../api/getContractsByUserIdApi';
import { getContractOptions } from '../../model/selectors/getContractOptions';
import { contractOptionsActions } from '../../model/slice/contractOptionsSlice';
import { ContractInfo, UnitType } from '../../model/types';
import { getVatRateAmount } from '../helpers/getVatRateAmount';
import { LoyaltyUserSetting, PaymentFrequencySetting, RentOptionDocument, useGetRentOptionsQuery } from 'entities/RentOption';
import { showNotification } from 'app/providers/NotificationsProvider';
import { Warehouse, WarehouseForBooking } from 'entities/Warehouse';

interface HookArgs {
  selectedWarehouse: Nullable<WarehouseForBooking | Warehouse>;
  box: Nullable<Box>;
  user: Nullable<User>;
  userAccountType: UserAccountType | undefined;
  onRentOptionsChange?: () => void;
}

interface HookApi {
  contractOptions: ContractInfo;
  availableRentOptions: RentOptionDocument[];
  changeRentOption: (rentOption: Nullable<RentOptionDocument>) => void;
  handleStartDateChange: (date: Nullable<Dayjs>) => void;
  handleEndDateChange: (date: Nullable<Dayjs>) => void;
  insuranceOptions: SelectOption[];
  handleInsuranceChange: (selectedInsuranceId: string, insuranceOption: DefaultOptionType | DefaultOptionType[]) => void;
  handleEntirePaymentChange: (isChecked: boolean) => void;
  disabledStartDate: (currentDate: Dayjs, startFrom?: Dayjs) => boolean;
  disabledEndDate: (currentDate: Dayjs) => boolean;
  resetContractOptions: () => void;
  firstAvailiableEndDate: Nullable<Dayjs>;
  firstAvailiableStartDate: Nullable<Dayjs>;
}

export const useContractOptions = ({ userAccountType, selectedWarehouse, box, user, onRentOptionsChange }: HookArgs): HookApi => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation('booking');

  const currencySymbol = useGetCurrencySymbol();

  const contractOptions = useAppSelector(getContractOptions);
  const globalSettings = useAppSelector(getGlobalSettings);
  const loggedUser = useAppSelector(getLoggedUserData);

  const isAdmin = useAdminRole();

  const boxVatRateParams = {
    accountType: userAccountType,
    unitType: UnitType.BOX,
  };

  const insuranceVatRateParams = {
    accountType: userAccountType,
    unitType: UnitType.INSURANCE,
  };

  const rentOptionsParams = {
    accountType: userAccountType,
    warehouseId: selectedWarehouse?.warehouseId,
  };

  const { data: userContracts } = useGetContractsByUserIdQuery({ userId: user?.userId }, { skip: !user });
  const { data: userReservations } = useGetUserReservationsQuery(user?.userId, { skip: !user });

  const isNewCustomer = useMemo<boolean>(() => {
    return !userContracts?.length && !userReservations?.length;
  }, [userContracts]);

  const requiredBoxDiscountsParams = {
    boxId: box?.boxId,
    warehouseId: selectedWarehouse?.warehouseId,
    contractDuration: contractOptions.contractDuration,
    periodType: contractOptions.invoiceFrequencyType,
    isNewCustomer,
    isPrePaymentApplied: contractOptions.payForEntirePeriod,
  };

  const boxDiscountsParams = {
    ...requiredBoxDiscountsParams,
    discountKeys: contractOptions.rentOption?.discountKeys || undefined,
  };

  const { data: insurances } = useGetInsuranceByWarehouseIdQuery(selectedWarehouse?.warehouseId, { skip: !selectedWarehouse?.warehouseId });
  const { data: rentOptions } = useGetRentOptionsQuery(rentOptionsParams, { skip: checkEmptyFields(rentOptionsParams) });
  const { data: discounts } = useGetBoxDiscountsQuery(boxDiscountsParams, { skip: checkEmptyFields(requiredBoxDiscountsParams) });
  const { data: boxVatRate } = useGetOneVatRateQuery(
    { warehouseId: selectedWarehouse?.warehouseId as string, args: boxVatRateParams as VatRateParams },
    { skip: !userAccountType || !selectedWarehouse?.warehouseId },
  );
  const { data: insuranceVatRate } = useGetOneVatRateQuery(
    { warehouseId: selectedWarehouse?.warehouseId as string, args: insuranceVatRateParams as VatRateParams },
    { skip: !userAccountType || !selectedWarehouse?.warehouseId },
  );

  const availableRentOptions = useMemo<RentOptionDocument[]>(() => {
    if (!rentOptions || !box) {
      return [];
    }

    return rentOptions
      .filter((rentOption) => {
        const rateField = getBoxRateField(rentOption.invoiceFrequencyType);

        if (isNewCustomer) {
          return [LoyaltyUserSetting.ALL, LoyaltyUserSetting.ONLY_NEW].includes(rentOption.loyaltyUserSetting) && box[rateField];
        }

        return [LoyaltyUserSetting.ALL, LoyaltyUserSetting.ONLY_OLD].includes(rentOption.loyaltyUserSetting) && box[rateField];
      })
      .filter((rentOption) => (rentOption.unitSizes?.length ? rentOption.unitSizes.includes(box.sizeCode.square.toString()) : true))
      .sort((a, b) => a.order - b.order);
  }, [rentOptions, isNewCustomer, box]);

  useEffect(() => {
    const isCurrentRentOptionValid = availableRentOptions.find(
      (rentOption) => rentOption.rentOptionId === contractOptions?.rentOption?.rentOptionId,
    );

    if (!isCurrentRentOptionValid) {
      if (contractOptions.rentOption) {
        showNotification('warning', t('Rent option change'), t('Your rent plan has been changed due to new account settings'));
      }

      dispatch(contractOptionsActions.resetContractOptionsAfterRentChange());
      onRentOptionsChange?.();
    }
  }, [userAccountType, contractOptions.rentOption, availableRentOptions, dispatch, t, onRentOptionsChange]);

  useEffect(() => {
    loggedUser && dispatch(contractOptionsActions.setContractOptions({ accountType: loggedUser.accountType }));
  }, [dispatch, loggedUser]);

  const insuranceOptions = useMemo<SelectOption[]>(() => {
    const rateField = getInsuranceRateField(contractOptions.invoiceFrequencyType);

    const filteredByRateFieldInsurance = insurances?.filter((insurance) => insurance[rateField]) || [];

    const sortedByCoverageAmount = filteredByRateFieldInsurance.sort((a, b) => (a.coverageAmount || 0) - (b.coverageAmount || 0));

    const options = sortedByCoverageAmount.map((insurance) => {
      const label = `${insurance.coverageAmount} ${currencySymbol}`;

      return {
        value: insurance.insuranceId,
        label,
      };
    });

    return options;
  }, [contractOptions.invoiceFrequencyType, insurances, currencySymbol]);

  const changeRentOption = useCallback(
    (rentOption: Nullable<RentOptionDocument>): void => {
      const dispatchObject: Partial<ContractInfo> = {};

      if (!rentOption) {
        dispatch(contractOptionsActions.resetContractOptions());
      }

      dispatchObject.rentOption = rentOption;
      dispatchObject.contractDuration = rentOption?.minContractDuration;
      dispatchObject.invoiceFrequencyType = rentOption?.invoiceFrequencyType;
      dispatchObject.endDate = null;

      if (rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ONLY_REGULAR) {
        dispatchObject.payForEntirePeriod = false;
      }

      if (rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ONLY_ENTIRE_PERIOD) {
        dispatchObject.payForEntirePeriod = true;
      }

      if (contractOptions.invoiceFrequencyType !== rentOption?.invoiceFrequencyType || !insuranceOptions.length) {
        dispatchObject.insuranceId = null;
        dispatchObject.insuranceAmountWithVat = 0;
        dispatchObject.insuranceRate = 0;
        dispatchObject.insuranceAmountWithoutVat = 0;
        dispatchObject.insuranceVatRateAmount = 0;
        dispatchObject.coverageAmount = null;
      }

      dispatch(contractOptionsActions.setContractOptions(dispatchObject));
    },
    [contractOptions.invoiceFrequencyType, insuranceOptions, dispatch],
  );

  useEffect(() => {
    const defaultOption = availableRentOptions.find((rentOption) => rentOption.isDefault);

    if (defaultOption && !contractOptions?.rentOption?.rentOptionId) {
      changeRentOption(defaultOption);
    }
  }, [userAccountType, contractOptions, availableRentOptions, dispatch, changeRentOption]);

  const handleStartDateChange = useCallback(
    (date: Nullable<Dayjs>): void => {
      dispatch(
        contractOptionsActions.setContractOptions({
          startDate: date,
        }),
      );
    },
    [dispatch],
  );

  const handleEndDateChange = useCallback(
    (date: Nullable<Dayjs>): void => {
      const contractDuration = dayjs(date).diff(dayjs(contractOptions.startDate, CLIENT_DATE_FORMAT), 'day');

      dispatch(
        contractOptionsActions.setContractOptions({
          endDate: date,
          contractDuration: contractDuration + 1,
        }),
      );
    },
    [dispatch, contractOptions.startDate],
  );

  const handleInsuranceChange = useCallback(
    (selectedInsuranceId: string, insuranceOption: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(insuranceOption)) {
        const selectedInsurance = insurances?.find(({ insuranceId }) => insuranceId === selectedInsuranceId) || null;

        const rateField = getInsuranceRateField(contractOptions.invoiceFrequencyType);

        const insuranceRate = selectedInsurance?.[rateField] || 0;
        const insuranceAmount = insuranceRate * (contractOptions.payForEntirePeriod ? contractOptions.contractDuration : 1);
        const vatRatePercent = contractOptions.insuranceVatRatePercent || 0;
        const vatRateAmount = getVatRateAmount(insuranceAmount, vatRatePercent);

        const insuranceAmountWithVat = roundNumber(Math.round((insuranceAmount + vatRateAmount) * 100) / 100);

        dispatch(
          contractOptionsActions.setContractOptions({
            insuranceId: selectedInsuranceId,
            insuranceAmountWithVat,
            insuranceAmountWithoutVat: insuranceAmount,
            insuranceVatRateAmount: vatRateAmount,
            insuranceRate,
            coverageAmount: selectedInsurance?.coverageAmount,
          }),
        );
      }
    },
    [
      insurances,
      contractOptions.invoiceFrequencyType,
      contractOptions.payForEntirePeriod,
      contractOptions.contractDuration,
      contractOptions.insuranceVatRatePercent,
      dispatch,
    ],
  );

  useEffect(() => {
    const selectedInsurance = insurances?.find(({ insuranceId }) => insuranceId === contractOptions.insuranceId) || null;

    const rateField = getInsuranceRateField(contractOptions.invoiceFrequencyType);

    const insuranceRate = selectedInsurance?.[rateField] || 0;
    const insuranceAmount = insuranceRate * (contractOptions.payForEntirePeriod ? contractOptions.contractDuration : 1);
    const vatRatePercent = contractOptions.insuranceVatRatePercent || 0;
    const vatRateAmount = getVatRateAmount(insuranceAmount, vatRatePercent);
    const insuranceAmountWithVat = roundNumber(Math.round((insuranceAmount + vatRateAmount) * 100) / 100);

    dispatch(
      contractOptionsActions.setContractOptions({
        insuranceAmountWithVat,
        insuranceAmountWithoutVat: insuranceAmount,
        insuranceRate,
        insuranceVatRateAmount: vatRateAmount,
      }),
    );
  }, [
    contractOptions.contractDuration,
    contractOptions.insuranceId,
    contractOptions.insuranceVatRatePercent,
    contractOptions.invoiceFrequencyType,
    contractOptions.payForEntirePeriod,
    dispatch,
    insurances,
  ]);

  const handleEntirePaymentChange = useCallback(
    (isChecked: boolean): void => {
      dispatch(
        contractOptionsActions.setContractOptions({
          payForEntirePeriod: isChecked,
        }),
      );
    },
    [dispatch],
  );

  const disabledStartDate = useCallback(
    (currentDate: Dayjs, startFrom?: Dayjs): boolean => {
      const needStartFromSpecialDay = startFrom ? currentDate < startFrom : false;

      const startDay = needStartFromSpecialDay ? dayjs(startFrom) : dayjs().startOf('day');
      const maxDateAhead = startDay.add(3, 'month').startOf('day');

      if (selectedWarehouse?.isComingSoon && selectedWarehouse?.launchDate) {
        const launchDate = dayjs(selectedWarehouse.launchDate).startOf('day');

        return currentDate && (currentDate < launchDate || (!isAdmin && currentDate >= maxDateAhead));
      }

      return currentDate && (currentDate < startDay || (!isAdmin && currentDate >= maxDateAhead));
    },
    [isAdmin, selectedWarehouse?.isComingSoon, selectedWarehouse?.launchDate],
  );

  const disabledEndDate = useCallback(
    (currentDate: Dayjs): boolean => {
      const startDate = dayjs(contractOptions.startDate, CLIENT_DATE_FORMAT).startOf('day');

      // For daily rent 1 day contract means contract duration 0 (startDate = EndDate)
      const minContractDuration = contractOptions?.rentOption?.minContractDuration
        ? contractOptions?.rentOption?.minContractDuration - 1
        : 0;

      const minContractEndDate = startDate.add(minContractDuration, 'day').startOf('day');

      const sixtyDaysAhead = startDate.add(60, 'day').startOf('day');

      return currentDate && (currentDate < minContractEndDate || currentDate >= sixtyDaysAhead);
    },
    [contractOptions.startDate, contractOptions?.rentOption],
  );

  const firstAvailiableEndDate = useMemo(() => {
    const startDate = dayjs(contractOptions.startDate, CLIENT_DATE_FORMAT).startOf('day');

    const minContractDuration = contractOptions?.rentOption?.minContractDuration ? contractOptions?.rentOption?.minContractDuration - 1 : 0;

    const minContractEndDate = startDate.add(minContractDuration, 'day').startOf('day');

    return minContractEndDate;
  }, [contractOptions?.rentOption?.minContractDuration, contractOptions.startDate]);

  const firstAvailiableStartDate = useMemo(() => {
    if (selectedWarehouse?.isComingSoon && selectedWarehouse?.launchDate) {
      const startDate = dayjs(selectedWarehouse.launchDate).startOf('day');

      return startDate;
    }

    return dayjs().startOf('day');
  }, [selectedWarehouse?.isComingSoon, selectedWarehouse?.launchDate]);

  useEffect(() => {
    if (box) {
      const rateField = getBoxRateField(contractOptions.invoiceFrequencyType);
      const payForEntirePeriod = contractOptions.payForEntirePeriod;
      const rentalUnitPrice = (box[rateField] as number) * box.priceFactor;
      const vatRatePercent = contractOptions.vatRatePercent || 0;
      const insuranceAmountWithVat = contractOptions.insuranceAmountWithVat || 0;
      const discountsArray = discounts || [];

      const depositAmount = getDepositAmount(Boolean(globalSettings?.isDepositApplied), box) || 0;

      const contractDurationPeriodNumbers = Array.from({ length: contractOptions.contractDuration }, (el, index) => index + 1);

      const {
        totalAmount: allServicePeriodsUnitPrice,
        totalDiscountPercent: averageDiscountPercent,
        totalDiscountAmount,
        contractPeriodsUnitPrices,
      } = calculateDiscountValues(rentalUnitPrice, discountsArray, contractOptions.contractDuration, contractDurationPeriodNumbers);

      const firstServicePeriodUnitPrice = contractPeriodsUnitPrices[0]?.rate;
      const firstServicePeriodDiscountPercent = contractPeriodsUnitPrices[0]?.discountPercent;
      const firstServicePeriodDiscountAmount = contractPeriodsUnitPrices[0]?.discountAmount;

      const firstServicePeriodUnitPriceWithVat = addPercent(firstServicePeriodUnitPrice, vatRatePercent);
      const firstServicePeriodTotalPrice = firstServicePeriodUnitPriceWithVat + insuranceAmountWithVat;

      const allServicePeriodsUnitPriceWithVat = addPercent(allServicePeriodsUnitPrice, vatRatePercent);
      const totalInsurancePriceWithVat = insuranceAmountWithVat;
      const allServicePeriodsTotalPrice = allServicePeriodsUnitPriceWithVat + totalInsurancePriceWithVat;

      const vatRateAmount = roundNumber(
        getVatRateAmount(payForEntirePeriod ? allServicePeriodsUnitPrice : firstServicePeriodUnitPrice, vatRatePercent),
      );
      const discountPercent = payForEntirePeriod ? averageDiscountPercent : firstServicePeriodDiscountPercent;
      const discountAmount = payForEntirePeriod
        ? roundNumber(totalDiscountAmount / contractOptions.contractDuration, 2)
        : firstServicePeriodDiscountAmount;

      const totalToPay = roundNumber(depositAmount + (payForEntirePeriod ? allServicePeriodsTotalPrice : firstServicePeriodTotalPrice));

      dispatch(
        contractOptionsActions.setContractOptions({
          totalToPay,
          vatRateAmount,
          discountAmount,
          discountPercent,
          contractPeriodsUnitPrices,
        }),
      );
    }
  }, [
    box,
    contractOptions.contractDuration,
    contractOptions.insuranceAmountWithVat,
    contractOptions.invoiceFrequencyType,
    dispatch,
    contractOptions.payForEntirePeriod,
    contractOptions.vatRatePercent,
    contractOptions.discountAmount,
    contractOptions.discountPercent,
    discounts,
    globalSettings?.isDepositApplied,
  ]);

  useEffect(() => {
    dispatch(
      contractOptionsActions.setContractOptions({
        vatRatePercent: boxVatRate?.ratePercent || 0,
        insuranceVatRatePercent: insuranceVatRate?.ratePercent || 0,
      }),
    );
  }, [boxVatRate, dispatch, insuranceVatRate]);

  useEffect(() => {
    if (insuranceOptions?.length === 1) {
      handleInsuranceChange(insuranceOptions[0].value, insuranceOptions[0]);
    }
  }, [handleInsuranceChange, insuranceOptions]);

  const resetContractOptions = useCallback(() => {
    dispatch(contractOptionsActions.resetContractOptions());
  }, [dispatch]);

  return {
    contractOptions,
    availableRentOptions,
    changeRentOption,
    handleStartDateChange,
    handleEndDateChange,
    disabledStartDate,
    disabledEndDate,
    insuranceOptions,
    handleInsuranceChange,
    handleEntirePaymentChange,
    resetContractOptions,
    firstAvailiableEndDate,
    firstAvailiableStartDate,
  };
};
