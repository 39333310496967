import classNames from 'classnames';
import React, { FC, memo, useCallback, useLayoutEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { ReactComponent as CheckMark } from 'shared/assets/icons/CheckMark.svg';
import { Box, useGetBoxRentalPrice } from 'entities/Box';
import { useGetCurrencySymbol } from 'app/appState';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { trimPriceFrequency } from '../utils/helpers/trimPriceFrequency';
import { DiscountLabel } from './DiscountLabel';

interface BoxRowProps {
  box: Box;
  selectedBoxId: string | undefined;
  hasRentDiscounts: boolean;
  hasGeneralDiscounts: boolean;
  onSelectBox: (box: Box) => void;
  onBookBox: (box: Box) => void;
}

export const BoxRow: FC<BoxRowProps> = memo((props) => {
  const { box, selectedBoxId, hasRentDiscounts, hasGeneralDiscounts, onSelectBox, onBookBox } = props;

  const { t } = useAppTranslation('booking');

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  const currencySymbol = useGetCurrencySymbol();

  const { isDesktop } = useScreenBreakpoints();

  useLayoutEffect(() => {
    selectedBoxId === box.boxId && scrollToElement();
  }, [box.boxId, scrollToElement, selectedBoxId]);

  const discount = box.priceFactor < 1 ? 100 - box.priceFactor * 100 : null;
  const isSelected = selectedBoxId === box.boxId;
  const rentalPrice = useGetBoxRentalPrice(box);

  const handleSelectBox = useCallback(
    (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      e.preventDefault();

      onSelectBox(box);
    },
    [box, onSelectBox],
  );

  const handleBookBox = useCallback((): void => {
    onBookBox(box);
  }, [box, onBookBox]);

  return (
    <>
      <div className="desktop:hidden">{discount && <DiscountLabel discount={discount} />}</div>
      <div ref={scrollRef} className="p-4 desktop:p-0" onClick={handleSelectBox}>
        <div className={classNames('p-3 rounded-lg ease-linear duration-100', { 'bg-accentLight': isSelected })}>
          <div className={classNames('flex items-center space-x-7 justify-between cursor-pointer desktop:px-6')}>
            <div className="flex flex-col desktop:basis-1/3">
              <div className="text-lg mb-2">{box.name}</div>
              <div className="flex items-center space-x-1.5">
                <CheckMark className="shrink-0 stroke-1 stroke-accent" />
                <span className="text-xs font-normal text-primaryLight">
                  {`${roundNumber(box.height)}m (${t('H')}) x ${roundNumber(box.width)}m (${t('W')}) x ${roundNumber(box.length)}m (${t(
                    'D',
                  )})`}
                </span>
              </div>
              <div className="flex items-center space-x-1.5">
                <CheckMark className="shrink-0 stroke-1 stroke-accent" />
                <span className="text-xs font-normal text-primaryLight">{`${t('Volume')}: ${roundNumber(
                  box.height * box.width * box.length,
                )} m³`}</span>
              </div>
              {box.floor && (
                <div className="flex items-center space-x-1.5">
                  <CheckMark className="shrink-0 stroke-accent" />
                  <span className="text-xs font-normal text-primaryLight">{getLocalizedString(box.floor.name)}</span>
                </div>
              )}
            </div>
            <div className="hidden desktop:block desktop:basis-1/12 ">{discount && <DiscountLabel discount={discount} />}</div>
            <div className="flex flex-col items-end desktop:basis-4/12">
              <div className="text-lg font-semibold desktop:text-2xl">{!isDesktop ? trimPriceFrequency(rentalPrice) : rentalPrice}</div>
              {box.dailyRate && (
                <div className="text-xs font-normal text-primaryLight">
                  {hasRentDiscounts || hasGeneralDiscounts ? t('Excluding discount') : `${box.dailyRate} ${currencySymbol}/${t('day')}`}
                </div>
              )}
            </div>
            <div className="hidden desktop:flex desktop:justify-center desktop:basis-2/12">
              <Button containerClassName="shrink-0" onClick={handleBookBox}>
                {t('Book now')}
              </Button>
            </div>
          </div>
          <Button containerClassName="mx-2 mt-3 desktop:hidden" onClick={handleBookBox}>
            {t('Book now')}
          </Button>
        </div>
      </div>
    </>
  );
});
