import React, { FC, memo } from 'react';
import { useScrollToActiveStep } from '../utils/hooks/useScrollToActiveStep';
import { StepData } from '../model/types';
import { Step } from './Step';
import { StepDivider } from './StepDivider';

interface StepperProps {
  steps: StepData[];
  activeStep: Nullable<string>;
  onChangeStep: (stepName: string) => void;
}

export const Stepper: FC<StepperProps> = memo((props) => {
  const { steps, activeStep, onChangeStep } = props;

  const scrollRef = useScrollToActiveStep(activeStep);

  return (
    <div className="flex bg-white overflow-x-hidden py-2 desktop:py-4 desktop:px-12 desktop:justify-center">
      {steps.map((step, index, array) => {
        const stepNumber = index + 1;
        const isLastStep = stepNumber >= array.length;
        const activeStepIndex = array.findIndex((value) => value.label === activeStep);
        const isCompleted = index < activeStepIndex;
        const isActive = step.label === activeStep;

        return (
          <div key={step.label} className="flex items-center">
            <Step
              containerRef={isActive ? scrollRef : undefined}
              stepNumber={stepNumber}
              stepData={step}
              isActive={isActive}
              isCompleted={isCompleted}
              isDisabled={!isActive && !isCompleted}
              onClick={onChangeStep}
            />
            {!isLastStep && <StepDivider />}
          </div>
        );
      })}
    </div>
  );
});
