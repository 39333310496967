import { api } from 'app/config/apiConfig';
import { CreateWarehouseParams, Warehouse } from '../model/types';

export const createWarehouseApi = api.injectEndpoints({
  endpoints: (build) => ({
    createWarehouse: build.mutation<Warehouse, CreateWarehouseParams>({
      query: (args) => ({
        url: '/warehouses',
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Warehouses', 'Warehouses for booking'],
    }),
  }),
});

export const { useCreateWarehouseMutation } = createWarehouseApi;
