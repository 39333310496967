import React, { FC, memo, ReactElement, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Modal } from 'shared/ui/Modal';
import { getModalMode } from '../model/selectors/getModalMode';
import { getOpenedState } from '../model/selectors/getOpenedState';
import { logInModalActions } from '../model/slice/loginModalSlice';
import { AuthForm } from './AuthForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const LogInModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(getOpenedState);
  const modalMode = useAppSelector(getModalMode);

  const closeModal = useCallback((): void => {
    dispatch(logInModalActions.setOpened(false));
  }, [dispatch]);

  const renderForm = (): ReactElement => {
    switch (modalMode) {
      case 'login':
        return <AuthForm />;
      case 'forgotPassword':
        return <ForgotPasswordForm />;
      default:
        return <AuthForm />;
    }
  };

  return (
    <Modal isOpen={isOpened} onClose={closeModal}>
      {renderForm()}
    </Modal>
  );
});
