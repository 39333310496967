import { api } from 'app/config/apiConfig';
import { LateChargeSetting } from '../model/types';

export const getLateChargeSettingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLateChargeSettings: build.query<LateChargeSetting[], void>({
      query: () => ({
        url: '/late-charge-settings',
      }),
      providesTags: (result) => ['Late charge settings'],
    }),
  }),
});

export const { useGetLateChargeSettingsQuery } = getLateChargeSettingApi;
