import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Note } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  orderBy?: string;
  isCompleted?: boolean;
}

export const getPaginatedNotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedNotes: build.query<Pagination<Note>, Params | void>({
      query: (args) => ({
        url: '/notes',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Notes'],
    }),
  }),
});

export const { useGetPaginatedNotesQuery } = getPaginatedNotesApi;
