import { api } from 'app/config/apiConfig';
import { WarehouseForBooking } from '../model/types';

export const getWarehousesForBookingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWarehousesForBooking: build.query<WarehouseForBooking[], void>({
      query: () => ({
        url: '/warehouses/booking',
      }),
      providesTags: ['Warehouses for booking'],
    }),
  }),
});

export const { useGetWarehousesForBookingQuery } = getWarehousesForBookingApi;
