import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ServerTable } from 'shared/ui/ServerTable';
import { ServerTableActions, ServerTableRowSelectionActions } from 'shared/ui/ServerTable/types';
import { stepperModalActions } from 'features/StepperModal';
import { sendOfferModalActions } from '../../model/slice/sendOfferModalSlice';
import { Lead, useGetPaginatedLeadsQuery } from 'entities/Lead';
import { AppLanguage } from 'app/config/i18Config/types';
import { leadFormModalActions, LeadFormModalMode } from 'features/LeadFormModal';

export const SelectEmailStep: FC = memo(() => {
  const { t } = useAppTranslation('contracts');

  const dispatch = useAppDispatch();

  const createLead = useCallback((): void => {
    dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_CREATE }));
  }, [dispatch]);

  const editLead = useCallback(
    (lead: Lead): void => {
      dispatch(leadFormModalActions.setOpenLeadFormModal({ mode: LeadFormModalMode.LEAD_EDIT, lead }));
    },
    [dispatch],
  );

  const getLanguage = (language?: Nullable<AppLanguage>): string => {
    switch (language) {
      case AppLanguage.en:
        return 'English';
      case AppLanguage.de:
        return 'Deutsch';
      default:
        return '';
    }
  };

  const columns = useMemo<ColumnsType<Lead>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => (
          <div className="text-primary font-semibold">
            {record.firstName} {record.lastName}
          </div>
        ),
      },
      { title: t('Email'), key: 'email', render: (_, record) => record.email },
      { title: t('Phone'), key: 'phone', width: '25%', render: (_, record) => record.phone },
      {
        title: t('Language'),
        key: 'language',
        width: '25%',
        render: (_, record) => <div>{getLanguage(record?.language)}</div>,
      },
    ],
    [t],
  );

  const tableActions: Array<ServerTableActions<Lead>> = useMemo(
    () => [
      {
        name: t('Did not find lead?'),
        theme: 'clear',
        iconPosition: 'prev',
        onClick: createLead,
      },
    ],
    [createLead, t],
  );

  const rowSelectionActions: Array<ServerTableRowSelectionActions<Lead>> = [
    {
      name: t('Next'),
      onClick: (selectedElements) => {
        if (!selectedElements[0].email) {
          editLead(selectedElements[0]);
        } else {
          dispatch(sendOfferModalActions.setSelectedEmail(selectedElements[0].email));
          selectedElements[0]?.language && dispatch(sendOfferModalActions.setSelectedLanguage(selectedElements[0].language));
          dispatch(stepperModalActions.changeActiveStepIndex(1));
        }
      },
    },
  ];

  return (
    <ServerTable
      columns={columns}
      fetchData={useGetPaginatedLeadsQuery}
      rowKey="leadId"
      search
      title={t('Choose Lead')}
      selectionType="radio"
      rowSelectionActions={rowSelectionActions}
      tableActions={tableActions}
    />
  );
});
