import { Card, Form, Input as AntdInput } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { AppLogo } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useSetNewPasswordMutation } from 'entities/User';
import { LangSwitcher } from 'features/LangSwitcher';
import { Button } from 'shared/ui/Button';
import { useNavigate, useParams } from 'react-router-dom';

export const ResetPasswordPage: FC = memo(() => {
  const { t } = useAppTranslation(['login-page', 'common']);

  const navigate = useNavigate();

  const { resetPasswordId } = useParams();

  const [setNewPassword, { isLoading }] = useSetNewPasswordMutation();

  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async ({ newPassword }: { newPassword: string }) => {
      if (resetPasswordId) {
        try {
          await setNewPassword({ newPassword, resetPasswordId }).unwrap();
          showNotification('info', t('Success'), t('Password reset has been successful'));
          navigate(AppRoutes.HOME);
        } catch (error: CustomAny) {
          console.log(error);
          showNotification('error', t('Error'), t('Error when resetting password'));
        }
      }
    },
    [navigate, resetPasswordId, setNewPassword, t],
  );

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-accentLight">
      <Card
        className="w-[370px] pt-[24px] tablet:w-[450px]"
        title={
          <div className="flex items-center p-3 justify-center">
            <AppLogo width={180} height="100%" />
            <div className="absolute top-0 right-0">
              <LangSwitcher />
            </div>
          </div>
        }
      >
        <Form name="resetPassword" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="newPassword"
            rules={[
              { required: true, message: t('Please enter your new password!') },
              { min: 8, message: t('Password must be at least 8 characters!') },
            ]}
          >
            <AntdInput.Password className="h-14" placeholder={t('New password')} size="large" />
          </Form.Item>
          <Form.Item
            name="repeatPassword"
            rules={[
              { required: true, message: t('Please repeat password!') },
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    await Promise.resolve();
                    return;
                  }
                  return await Promise.reject(new Error(t('Password must be the same')));
                },
              }),
            ]}
          >
            <AntdInput.Password placeholder={t('Repeat password')} size="large" />
          </Form.Item>
          <Button type="submit" isLoading={isLoading}>
            {t('Set new password')}
          </Button>
        </Form>
      </Card>
    </div>
  );
});
