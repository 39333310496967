import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getSelectedBoxes } from '../../model/selectors/getSelectedBoxes';
import { getSelectedEmail } from '../../model/selectors/getSelectedEmail';
import { getSelectedWarehouse } from '../../model/selectors/getSelectedWarehouse';
import { sendOfferModalActions } from '../../model/slice/sendOfferModalSlice';
import { useAppTranslation } from 'app/config/i18Config/hooks';

export const useWatchStep = (): void => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation('boxes');

  const selectedEmail = useAppSelector(getSelectedEmail);
  const selectedWarehouse = useAppSelector(getSelectedWarehouse);
  const selectedBoxes = useAppSelector(getSelectedBoxes);

  useEffect(() => {
    if (selectedEmail) {
      dispatch(sendOfferModalActions.changeStepData({ targetStepIndex: 0, stepData: selectedEmail }));
    }
    if (selectedWarehouse) {
      dispatch(sendOfferModalActions.changeStepData({ targetStepIndex: 1, stepData: selectedWarehouse.name }));
    }
    if (selectedBoxes.length) {
      dispatch(sendOfferModalActions.changeStepData({ targetStepIndex: 2, stepData: `${t('Selected boxes:')} ${selectedBoxes.length}` }));
    }
  }, [dispatch, selectedBoxes.length, selectedEmail, selectedWarehouse, t]);
};
