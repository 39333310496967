import { InvoiceFrequencyType, RentState } from 'entities/Invoice';
import { TableFilterType } from 'features/TableFilter';

interface BoxListFilters {
  warehouseId?: string;
  isActive?: boolean;
  isForOwnNeeds?: boolean;
  hasNotes?: boolean;
  priceFrom?: number;
  priceTo?: number;
  squareFrom?: number;
  squareTo?: number;
  priceRateType?: InvoiceFrequencyType;
  rentState?: RentState;
  floorId?: string;
}

export const transformBoxesListFilters = ({
  selectedWarehouseId,
  hasNotes,
  priceFrom,
  priceTo,
  priceType,
  type,
  isForOwnNeeds,
  squareFrom,
  squareTo,
  floorId,
  availability,
}: TableFilterType): BoxListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    isActive: availability?.value === undefined ? undefined : availability?.value === 'available',
    isForOwnNeeds: isForOwnNeeds?.value === undefined ? undefined : isForOwnNeeds?.value === 'forOwnNeeds',
    hasNotes: hasNotes?.value === undefined ? undefined : hasNotes?.value === 'hasNotes',
    priceFrom: priceFrom?.value || undefined,
    priceTo: priceTo?.value || undefined,
    squareFrom: squareFrom?.value || undefined,
    squareTo: squareTo?.value || undefined,
    priceRateType: priceType?.value || undefined,
    rentState: type?.value || undefined,
    floorId: floorId?.value || undefined,
  };
};
