import { api } from 'app/config/apiConfig';
import { SizeCode } from '../model/types';

export const deleteSizeCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteSizeCode: builder.mutation<SizeCode, string>({
      query: (sizeCodeId: string) => ({
        url: `/size-codes/${sizeCodeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Size codes', 'Available sizeCodes'],
    }),
  }),
});

export const { useDeleteSizeCodeMutation } = deleteSizeCodeApi;
