import React, { FC, Suspense } from 'react';
import './styles/index.scss';
import { AppRouter } from 'app/providers/RouterProvider';
import { PageLoader } from 'features/PageLoader';

export const App: FC = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <AppRouter />
    </Suspense>
  );
};
