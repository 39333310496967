import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getBoxInfo, getWarehouseInfo } from 'pages/BookingPage';
import { useGetCurrencySymbol } from 'app/appState';
import { getLoggedUserData } from 'entities/User';
import { getContractOptions } from 'entities/Contract';
import { bookingHeaderActions } from '../../model/slice/bookingHeaderSlice';
import { BookingSteps } from '../../model/types';
import { useAppTranslation } from 'app/config/i18Config/hooks';

export const useWatchStep = (): void => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('booking');

  const selectedWarehouse = useAppSelector(getWarehouseInfo);
  const selectedBox = useAppSelector(getBoxInfo);
  const contractOptions = useAppSelector(getContractOptions);
  const currencySymbol = useGetCurrencySymbol();
  const loggedUser = useAppSelector(getLoggedUserData);

  const { totalToPay, invoiceFrequencyType, contractDuration } = contractOptions;
  const contractStepLabel = `${t('Total for')} ${contractDuration} ${t(invoiceFrequencyType)}: ${totalToPay} ${currencySymbol}`;
  const hasTotalValue = Boolean(contractDuration) && Boolean(invoiceFrequencyType) && Boolean(totalToPay);

  useEffect(() => {
    selectedWarehouse &&
      dispatch(bookingHeaderActions.changeStepData({ targetStep: BookingSteps.SELECT_LOCATION, stepData: selectedWarehouse.name }));

    selectedBox && dispatch(bookingHeaderActions.changeStepData({ targetStep: BookingSteps.SELECT_BOX, stepData: selectedBox.name }));

    hasTotalValue &&
      dispatch(
        bookingHeaderActions.changeStepData({
          targetStep: BookingSteps.CONTRACT_INFORMATION,
          stepData: contractStepLabel,
        }),
      );

    loggedUser?.email &&
      dispatch(bookingHeaderActions.changeStepData({ targetStep: BookingSteps.YOUR_DETAILS, stepData: loggedUser.email }));
  }, [dispatch, selectedBox, selectedWarehouse, contractStepLabel, hasTotalValue, loggedUser?.email]);
};
