import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { InvoicesListFilterKeys, InvoiceTypeFilter } from '../../model/types';

interface InvoiceTypeSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const InvoiceTypeSelect: FC<InvoiceTypeSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation(['contracts', 'common']);

  const invoiceTypeOptions = useMemo<Array<{ label: string; value: InvoiceTypeFilter }>>(() => {
    return [
      { label: t('Personal invoices'), value: InvoiceTypeFilter.PERSONAL },
      { label: t('Contract invoices'), value: InvoiceTypeFilter.CONTRACT },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(InvoicesListFilterKeys.invoiceType);
  }, [onClear]);

  return (
    <Select
      containerClassName="flex-1"
      className="w-full"
      label={t('Invoice type')}
      placeholder={t('All')}
      bordered
      allowClear
      options={invoiceTypeOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
