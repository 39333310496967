import { ThemeColors } from 'app/providers/ThemeProvider';
import { CountryType } from 'entities/City';
import { NotifyLeaveDaysLimits } from 'entities/Contract';

export interface AppSchema {
  loadingCount: number;
  globalSettings: Nullable<GlobalSettings>;
}

export enum Currencies {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  PLN = 'PLN',
}

export enum BookingDocumentsNames {
  PRIVACY_POLICY = 'Privacy Policy',
  RULES = 'Company rules',
  TERMS_AND_CONDITIONS = 'Terms & conditions',
}

export interface GlobalSettings {
  bankingRequisits: string;
  logoUrl: string;
  currency: Currencies;
  isBookingAvailiable: boolean;
  isDepositApplied: boolean;
  reservationPeriod: string;
  themeColors: ThemeColors;
  paymentMethods: string[];
  invoiceLeadDays: string;
  notifyLeaveDays: NotifyLeaveDaysLimits;
  bookingDocuments: Array<{ docName: string; link: string }>;
  country: CountryType;
  showGeneralPromotions: boolean;
}
