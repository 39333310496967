import { api } from 'app/config/apiConfig';
import { Contract } from 'entities/Contract';

export const takeUpReservationApi = api.injectEndpoints({
  endpoints: (build) => ({
    takeUpReservation: build.mutation<Contract, string>({
      query: (reservationId) => ({
        url: `/reservations/${reservationId}/take-up`,
        method: 'POST',
      }),
      invalidatesTags: ['Reservations', 'My bookings', 'Invoices'],
    }),
  }),
});

export const { useTakeUpReservationMutation } = takeUpReservationApi;
