export interface Pagination<ContentType> {
  page: number;
  limit: number;
  pageCount: number;
  total: number;
  isLastPage: boolean;
  content: ContentType[];
}

export interface ErrorApiResponse {
  data: {
    error: string;
    statusCode: number;
    message: string | string[];
  };
}

export interface GeolocationCoords {
  lat: number;
  lng: number;
}

export enum ModalMode {
  EDIT = 'Edit',
  CREATE = 'Create',
}
