import { api } from 'app/config/apiConfig';
import { AuthResponse, userActions } from 'entities/User';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';
import { AUTH_RESET_PASSWORD_PATH } from '../utils/constants';

interface Args {
  newPassword: string;
  resetPasswordId: string;
}

export const setNewPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    setNewPassword: builder.mutation<AuthResponse, Args>({
      query: (args) => ({
        url: `${AUTH_RESET_PASSWORD_PATH}`,
        method: 'PUT',
        body: args,
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const { data: credentials } = await queryFulfilled;

          dispatch(userActions.setCredentials({ credentials, remember: true }));
        } catch (error) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when logging in', { ns: 'common' }));
        }
      },
    }),
  }),
});

export const { useSetNewPasswordMutation } = setNewPasswordApi;
