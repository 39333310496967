import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { contractOptionsActions } from 'entities/Contract';
import React, { FC, memo, useCallback, useLayoutEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { useCheckUserDataComplete, UserAccountType } from 'entities/User';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';

interface AccountTypeContentProps {
  userAccountType: UserAccountType | undefined;
}

export const AccountTypeContent: FC<AccountTypeContentProps> = memo((props) => {
  const { userAccountType } = props;

  const { t } = useAppTranslation('booking');
  const dispatch = useAppDispatch();

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  useLayoutEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  const isUserDataCompleted = useCheckUserDataComplete();

  const accountTypeOptions = [
    { value: UserAccountType.PERSONAL, label: t('Personal') },
    { value: UserAccountType.BUSINESS, label: t('Business') },
  ];

  const changeAccountType = useCallback(
    (accountType: UserAccountType): void => {
      dispatch(contractOptionsActions.setContractOptions({ accountType }));
    },
    [dispatch],
  );

  return (
    <div>
      <div ref={scrollRef} className="font-semibold text-base mb-1">
        {t('Account type')}
      </div>
      <div className="text-sm text-primaryLight mb-3">
        {t('Please provide us with your information so that we can suggest the best pricing options to you')}
      </div>

      <RadioButtonGroup
        options={accountTypeOptions}
        value={userAccountType}
        isDisabled={isUserDataCompleted}
        onChange={changeAccountType}
      />
    </div>
  );
});
