import React, { FC, memo, useState } from 'react';
import { Checkbox as AntdCheckbox, Divider } from 'antd';
import classNames from 'classnames';
import s from './index.module.scss';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { Checkbox } from 'shared/ui/Checkbox';
import type { CheckboxProps as AntdCheckboxProps } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface CheckboxGroupProps {
  options: Array<{ value: string; label: string }>;
  name: string;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (checkedValue: CheckboxValueType[]) => void;
  requiredMark?: boolean;
}

export const CheckboxGroup: FC<CheckboxGroupProps> = memo((props) => {
  const { name, className, options, onChange, disabled, requiredMark } = props;

  const { t } = useAppTranslation('common');

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const checkAll = options.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < options.length;

  const handleChange = (checkedValue: CheckboxValueType[]): void => {
    setCheckedList(checkedValue);
    onChange?.(checkedValue);
  };

  const onCheckAllChange: AntdCheckboxProps['onChange'] = (e) => {
    const isChecked = e.target.checked;
    const newCheckedList = isChecked ? options.map((option) => option.value) : [];
    setCheckedList(newCheckedList);
    onChange?.(newCheckedList);
  };

  return (
    <div>
      <Checkbox name={name} indeterminate={indeterminate} onChangeEvent={onCheckAllChange} checked={checkAll}>
        <span>{t('Select all')}</span>
      </Checkbox>
      <Divider className="my-2" />
      <div className="flex items-center">
        {requiredMark && <div className="text-error text-2xl mr-1 pointer-events-none">*</div>}
        <AntdCheckbox.Group
          className={classNames(s.checkbox, className)}
          options={options}
          value={checkedList}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
});
