import React, { FC, memo, ReactElement } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { StepperModal } from 'features/StepperModal';
import { getSteps } from '../model/selectors/getSteps';
import { useCreateWarehouseModal } from '../utils/hooks/useCreateWarehouseModal';
import { useWatchStep } from '../utils/hooks/useWatchStep';
import { CreateWarehouseStep } from './steps/CreateWarehouseStep';
import { SelectCityStep } from './steps/SelectCityStep';
import { SelectFranchiseStep } from './steps/SelectFranchiseStep';

export const CreateWarehouseModal: FC = memo(() => {
  const steps = useAppSelector(getSteps);

  useWatchStep();

  const { isOpened, closeModal } = useCreateWarehouseModal();

  const stepsContent: ReactElement[] = [<SelectCityStep key="1" />, <SelectFranchiseStep key="2" />, <CreateWarehouseStep key="3" />];

  return <StepperModal width={1118} isOpened={isOpened} steps={steps} onClose={closeModal} stepsContent={stepsContent} />;
});
