import { api } from 'app/config/apiConfig';
import { AuthResponse, userActions } from 'entities/User';
import { LoginData } from '../model/types';
import { AUTH_LOGIN_PATH } from '../utils/constants';

export const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, { loginData: LoginData; remember?: boolean }>({
      query: ({ loginData }) => ({
        url: AUTH_LOGIN_PATH,
        method: 'POST',
        body: loginData,
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const { data: credentials } = await queryFulfilled;

          dispatch(userActions.setCredentials({ credentials, remember: args.remember }));
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useLoginMutation } = loginApi;
