import { Divider, Form } from 'antd';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useGetBoxRentalPrice } from 'entities/Box';
import { useContractOptions } from 'entities/Contract';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { ContentLine } from 'shared/ui/ContentLine';
import { DatePicker } from 'shared/ui/DatePicker';
import { InfoTag } from 'shared/ui/InfoTag';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { Select } from 'shared/ui/Select';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { ReserveBox } from 'features/ReserveBox';
import { CreateContract } from 'features/CreateContract';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { getSelectedBox } from '../../model/selectors/getSelectedBox';
import { getSelectedUser } from '../../model/selectors/getSelectedUser';
import { getSelectedWarehouse } from '../../model/selectors/getSelectedWarehouse';
import { PaymentFrequencySetting } from 'entities/RentOption';

interface BookingOptionsStepProps {
  onClose: () => void;
}

export const BookingOptionsStep: FC<BookingOptionsStepProps> = memo((props) => {
  const { onClose } = props;

  const { t } = useAppTranslation('contracts');

  const currencySymbol = useGetCurrencySymbol();

  const selectedWarehouse = useAppSelector(getSelectedWarehouse);
  const selectedUser = useAppSelector(getSelectedUser);
  const selectedBox = useAppSelector(getSelectedBox);

  const {
    contractOptions,
    availableRentOptions,
    changeRentOption,
    insuranceOptions,
    handleEndDateChange,
    disabledEndDate,
    handleInsuranceChange,
    disabledStartDate,
    handleEntirePaymentChange,
    handleStartDateChange,
    resetContractOptions,
    firstAvailiableEndDate,
    firstAvailiableStartDate,
  } = useContractOptions({
    userAccountType: selectedUser?.accountType,
    user: selectedUser,
    selectedWarehouse,
    box: selectedBox,
  });

  const isCreationDisabled = useMemo<boolean>(() => {
    let isDisabled = !contractOptions.startDate || !contractOptions.insuranceId;

    if (contractOptions.invoiceFrequencyType === 'day') {
      isDisabled = isDisabled || !contractOptions.endDate;
    }

    return isDisabled;
  }, [contractOptions]);

  const rentalPrice = useGetBoxRentalPrice(selectedBox, contractOptions.invoiceFrequencyType);

  // TO DO: update to PriceDetails component
  const priceDetailsBullets = useMemo(
    () => [
      { label: t('Rental cost'), value: rentalPrice, emptyValueSymbol: `- ${currencySymbol}` },
      {
        label: t('VAT ({{vatRatePercent}}%)', {
          vatRatePercent: contractOptions.vatRatePercent,
        }),
        value: `${contractOptions?.vatRateAmount} ${currencySymbol}`,
      },
      {
        label: t('Discount ({{discountPercent}}%)', {
          discountPercent: contractOptions.discountPercent,
        }),
        value: `${contractOptions?.discountAmount} ${currencySymbol}`,
      },
      {
        label: t('Insurance rate (inc. {{insuranceVatRatePercent}}% VAT)', {
          insuranceVatRatePercent: contractOptions.insuranceVatRatePercent,
        }),
        value: `${contractOptions.insuranceAmountWithVat} ${currencySymbol}`,
      },
    ],
    [contractOptions, currencySymbol, rentalPrice, t],
  );

  const contractDurationOptions = useMemo<SelectOption[]>(() => {
    return availableRentOptions.map((rentOption) => ({
      label: getLocalizedString(rentOption.label),
      value: rentOption.rentOptionId,
    }));
  }, [availableRentOptions]);

  const changeContractDuration = useCallback(
    (rentOptionId: string) => {
      const selectedRentOption = availableRentOptions.find((rentOption) => rentOption.rentOptionId === rentOptionId);

      changeRentOption(selectedRentOption || null);
    },
    [availableRentOptions, changeRentOption],
  );

  const totalBullets = useMemo(() => {
    const contractDurationLabel = `${t('Total for')} ${contractOptions.contractDuration} ${t(contractOptions.invoiceFrequencyType)}`;
    return [
      {
        label: contractDurationLabel,
        value: contractOptions.totalToPay && `${roundNumber(contractOptions.totalToPay)} ${currencySymbol}`,
        highlighted: true,
        emptyValueSymbol: `- ${currencySymbol}`,
      },
    ];
  }, [contractOptions, currencySymbol, t]);

  const [form] = Form.useForm();

  const closeModal = useCallback(() => {
    onClose();
    resetContractOptions();
  }, [onClose, resetContractOptions]);

  return (
    <Form className="flex flex-col" name="bookingOptions" form={form}>
      <div className="font-semibold text-2xl mb-4">{t('Booking options')}</div>
      <div className="flex space-x-20">
        <div>
          <div className="text-accent mb-1">{t('Contract duration')}</div>
          <div className="font-normal text-sm text-primaryLight">
            {t('Please pick the closest length of stay option to obtain a quote price')}
          </div>
          <InfoTag className="my-4">
            {t('Daily contracts are open-ended and automatically extended for one more day if not moved out.')}
          </InfoTag>
          {/* <Form.Item name={name} initialValue={defaultValue}> </Form.Item> */}
          <RadioButtonGroup
            options={contractDurationOptions}
            withBorder
            onChange={changeContractDuration}
            value={contractOptions?.rentOption?.rentOptionId}
            labelPosition="prev"
          />
          {contractOptions?.rentOption?.description ? (
            <div className="text-sm text-primaryLight mb-3">{getLocalizedString(contractOptions.rentOption.description)}</div>
          ) : null}
          {contractOptions?.rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ALL && (
            <div className="mt-3">
              <InfoTag>{t('Discount information for entire period!')}</InfoTag>
              <Checkbox
                name="payForEntirePeriod"
                className="mt-4"
                checked={contractOptions.payForEntirePeriod}
                onChange={handleEntirePaymentChange}
              >
                {t('Pay for the entire period')}
              </Checkbox>
            </div>
          )}
          <Divider />
          <>
            <ContentLine title={t('Move-in date')}>
              <DatePicker
                className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
                format={CLIENT_DATE_FORMAT}
                onChange={handleStartDateChange}
                placeholder={t(CLIENT_DATE_FORMAT)}
                inputReadOnly
                requiredMark
                disabledDate={disabledStartDate}
                value={contractOptions.startDate}
                defaultPickerValue={firstAvailiableStartDate}
              />
            </ContentLine>
            <Divider />
          </>
          {contractOptions.invoiceFrequencyType === InvoiceFrequencyType.DAY && (
            <>
              <ContentLine title={t('Move-out date')}>
                <DatePicker
                  format={CLIENT_DATE_FORMAT}
                  className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
                  onChange={handleEndDateChange}
                  placeholder={t(CLIENT_DATE_FORMAT)}
                  disabled={!contractOptions.startDate}
                  inputReadOnly
                  disabledDate={disabledEndDate}
                  requiredMark
                  value={contractOptions.endDate}
                  defaultPickerValue={firstAvailiableEndDate}
                />
              </ContentLine>
              <Divider />
            </>
          )}
          <ContentLine
            title={
              <div className="flex flex-col">
                <span className="font-semibold">{t('Insurance')}</span>
                <span className="font-semibold">{t('Coverage amount')}</span>
              </div>
            }
            description={t('Storage insurance is a requirement of self storage')}
          >
            <Form.Item name="insuranceId">
              {!insuranceOptions?.length ? (
                <InfoTag>{t('No insurance provided!')}</InfoTag>
              ) : (
                <div>
                  <Select
                    className="!h-[56px] w-full max-w-[280px]"
                    placeholder={t('Select')}
                    onChange={handleInsuranceChange}
                    options={insuranceOptions}
                    value={contractOptions.insuranceId}
                    bordered
                    requiredMark
                  />
                  {contractOptions.insuranceId && (
                    <div className="text-sm mt-2 pl-1 font-semibold">
                      {t(`{{rate}} {{currencySymbol}}/${contractOptions.invoiceFrequencyType}`, {
                        rate: contractOptions.insuranceRate,
                        currencySymbol,
                      })}
                    </div>
                  )}
                </div>
              )}
            </Form.Item>
          </ContentLine>
        </div>
        <div className="flex-1">
          <div className="border border-secondaryAccent rounded-lg py-7 px-6">
            <BulletsTable heading="Price details" rows={priceDetailsBullets} theme="clear" />
            <Divider className="my-4" />
            <BulletsTable rows={totalBullets} theme="clear" />
          </div>
        </div>
      </div>
      <div className="flex space-x-2.5 self-end mt-20">
        <ReserveBox
          contractOptions={contractOptions}
          boxId={selectedBox?.boxId}
          userId={selectedUser?.userId}
          action={
            <Button theme="secondary" isDisabled={isCreationDisabled}>
              {t('Create Reservation')}
            </Button>
          }
          warehouseId={selectedWarehouse?.warehouseId}
          onSuccess={closeModal}
        />
        <CreateContract
          contractOptions={contractOptions}
          warehouseId={selectedWarehouse?.warehouseId}
          boxId={selectedBox?.boxId}
          userId={selectedUser?.userId}
          action={<Button isDisabled={isCreationDisabled}>{t('Create Contract')}</Button>}
          onSuccess={closeModal}
        />
      </div>
    </Form>
  );
});
