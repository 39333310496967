import { api } from 'app/config/apiConfig';
import { Invoice } from '../model/types';

interface Params {
  contractId?: Nullable<string>;
  includeContractItemsRelations?: boolean;
}

export const getInvoicesByContractIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoicesByContractId: build.query<Invoice[], Params>({
      query: (args) => ({
        url: `/contracts/${args.contractId}/invoices`,
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Invoices'],
    }),
  }),
});

export const { useGetInvoicesByContractIdQuery } = getInvoicesByContractIdApi;
