import React, { FC, memo } from 'react';
import { Box } from 'entities/Box';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Collapse } from 'shared/ui/Collapse';
import { PromotionContent } from './PromotionContent';
import { SelectSizeContent } from './SelectSizeContent';

interface SelectBoxSizeFormProps {
  selectedBoxId: string | undefined;
  selectedWarehouseId: string | undefined;
  onSelectBox: (box: Box, sizeCodeId: string) => void;
  onCompleteStep: (box: Box, sizeCodeId: string) => void;
}

export const SelectBoxSizeForm: FC<SelectBoxSizeFormProps> = memo((props) => {
  const { t } = useAppTranslation('booking');

  return (
    <div className="space-y-3">
      <Collapse
        title={t('Promotion')}
        content={<PromotionContent selectedWarehouseId={props.selectedWarehouseId} />}
        activeKey="1"
        isOpened
      />
      <Collapse title={t('Select the size of the box')} content={<SelectSizeContent {...props} />} activeKey="2" isOpened />
    </div>
  );
});
