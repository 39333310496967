import { Form } from 'antd';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { ModalMode } from 'app/types/common';
import { Franchise, useCreateFranchiseMutation, useUpdateFranchiseMutation } from 'entities/Franchise';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { TextArea } from 'shared/ui/TextArea';
import { FranchiseFormFields } from '../model/types';

interface FranchiseFormProps {
  franchise?: Nullable<Franchise>;
  mode: Nullable<ModalMode>;
  onClose: () => void;
}

export const FranchiseForm: FC<FranchiseFormProps> = memo((props) => {
  const { franchise, mode, onClose } = props;

  const { t } = useAppTranslation(['franchise', 'common']);

  const [createFranchise, { isLoading: isCreationLoading }] = useCreateFranchiseMutation();
  const [updateFranchise, { isLoading: isUpdationLoading }] = useUpdateFranchiseMutation();

  const isLoading = isUpdationLoading || isCreationLoading;

  const [form] = Form.useForm();

  useEffect(() => {
    mode === ModalMode.EDIT ? form.setFieldsValue(franchise) : form.resetFields();
  }, [mode, form, franchise]);

  const submit = useCallback(
    async (data: FranchiseFormFields): Promise<void> => {
      try {
        switch (mode) {
          case ModalMode.CREATE:
            await createFranchise(data).unwrap();
            showNotification('info', t('Success', { ns: 'common' }), t('Franchise has been successfully created'));
            break;
          case ModalMode.EDIT:
            if (franchise) {
              const { franchiseId } = franchise;
              await updateFranchise({ franchiseId, params: data }).unwrap();
              showNotification('info', t('Success', { ns: 'common' }), t('Franchise has been successfully updated'));
            }
            break;
        }

        form.resetFields();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification(
          'error',
          t('Error', { ns: 'common' }),
          t(`Error when ${mode === ModalMode.CREATE ? 'creating' : 'updating'} franchise`),
        );
      }

      onClose();
    },
    [onClose, mode, form, createFranchise, franchise, updateFranchise, t],
  );

  return (
    <Form form={form} layout="vertical" onFinish={submit} className="flex flex-col">
      <div className="text-2xl mb-6">{t(`${mode} franchise`)}</div>
      <Form.Item label={t('Name')} name="name" rules={[{ required: true, message: `${t('Please enter name')}!` }]}>
        <Input placeholder={t('Franchise')} bordered />
      </Form.Item>
      <Form.Item
        label={t('Company code')}
        name="companyCode"
        rules={[
          { required: true, message: `${t('Please enter company code')}!` },
          { max: 2, message: t('Company code must have 2 characters') },
        ]}
      >
        <Input placeholder={t('12')} bordered />
      </Form.Item>
      <Form.Item
        label={t('Banking requisites')}
        name="bankingRequisites"
        rules={[{ required: true, message: `${t('Please enter banking requisites')}!` }]}
      >
        <TextArea placeholder={t('Viktualienmarkt 8, 80331 München')} rows={4} bordered />
      </Form.Item>
      <div className="flex justify-end gap-3 mt-2">
        <Button theme="secondary" onClick={onClose}>
          {t('Cancel', { ns: 'common' })}
        </Button>
        <Button type="submit" isLoading={isLoading}>
          {t('Save', { ns: 'common' })}
        </Button>
      </div>
    </Form>
  );
});
