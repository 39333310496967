import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RangeInput } from 'shared/ui/RangeInput/RangeInput';
import { useGetCurrencySymbol } from 'app/appState';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { SizeCodesListFilterKeys } from '../../model/types';
import { PriceTypeSelect } from './PriceTypeSelect';
import { WarehouseSelect } from './WarehouseSelect';
import { SizeGroupSelect } from './SizeGroupSelect';
import { SizeCodeTypeSelect } from './SizeCodeTypeSelect';

export const SizeCodesListFilter: FC = memo(() => {
  const { t } = useAppTranslation('boxes');

  const currencySymbol = useGetCurrencySymbol();

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<SizeCodesListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: SizeCodesListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  const changePriceFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: SizeCodesListFilterKeys.priceFrom,
        label: `${t('From {{priceFrom}}', { priceFrom: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: SizeCodesListFilterKeys.priceTo,
        label: `${t('To {{priceTo}}', { priceTo: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceType = useCallback(
    (value: string, label: string) => {
      changeFilters({
        key: SizeCodesListFilterKeys.priceType,
        label: t('Price type: {{priceType}}', { priceType: label }),
        value,
        title: t('Price type'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: SizeCodesListFilterKeys.squareFrom,
        label: t('From {{squareFrom}} m²', { squareFrom: value }),
        value,
        title: t('Square'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: SizeCodesListFilterKeys.squareTo,
        label: t('To {{squareTo}} m²', { squareTo: value }),
        value,
        title: t('Square'),
      });
    },
    [changeFilters, t],
  );

  const changeSizeGroup = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: SizeCodesListFilterKeys.sizeGroup, label, value, title: t('Size group') });
    },
    [changeFilters, t],
  );

  const changeSizeCodeType = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: SizeCodesListFilterKeys.sizeCodeType, label, value, title: t('Size code type') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <div className="flex space-x-3 items-center">
          <RangeInput
            values={[filters.priceFrom?.value, filters.priceTo?.value]}
            containerClassName="basis-2/3"
            placeholder={[t('From'), t('To')]}
            label={t('Price')}
            type="number"
            onChangeFrom={changePriceFrom}
            onChangeTo={changePriceTo}
          />
          <PriceTypeSelect value={filters.priceType?.value} onChange={changePriceType} onClear={clearCurrentFilters} />
        </div>
        <RangeInput
          values={[filters.squareFrom?.value, filters.squareTo?.value]}
          placeholder={[t('From'), t('To')]}
          label={t('Square')}
          type="number"
          onChangeFrom={changeSquareFrom}
          onChangeTo={changeSquareTo}
        />
        <div className="flex space-x-3 items-center">
          <SizeGroupSelect value={filters.sizeGroup?.value} onChange={changeSizeGroup} onClear={clearCurrentFilters} />
          <SizeCodeTypeSelect value={filters.sizeCodeType?.value} onChange={changeSizeCodeType} onClear={clearCurrentFilters} />
        </div>
      </div>
    </TableFilter>
  );
});
