import { TableRowSelection } from 'antd/es/table/interface';
import React, { FC, useEffect, useMemo, useState, ReactNode, useCallback, memo } from 'react';
import { useDebounce } from 'react-use';
import { Table as AtndTable } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import classNames from 'classnames';
import { ReactComponent as SearchIcon } from 'shared/assets/icons/SearchIcon.svg';

import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Checkbox } from 'shared/ui/Checkbox';
import { TableActions, TableActionsPositions, TableParams, TableRowActions, TableRowSelectionActions } from './types';

import s from './index.module.scss';

interface TableProps {
  data?: AnyObject[];
  columns: ColumnsType<CustomAny>;
  loading?: boolean;
  bordered?: boolean;
  className?: string;
  rowKey: string;
  search?: boolean;
  searchField?: string;
  searchPlaceholder?: string;
  pagination?: boolean | TablePaginationConfig;
  size?: SizeType;
  tableActions?: Array<TableActions<CustomAny>>;
  title?: React.ReactNode;
  rowClassName?: (record: CustomAny, index: number) => string;
  rowActions?: Array<TableRowActions<CustomAny>>;
  rowActionsClassName?: string;
  rowSelectionActions?: Array<TableRowSelectionActions<CustomAny>>;
  selectionType?: 'checkbox' | 'radio';
  tableActionsPosition?: TableActionsPositions;
}

export const Table: FC<TableProps> = memo((props) => {
  const { t } = useAppTranslation('common');

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const {
    columns,
    data,
    loading,
    className,
    bordered = true,
    rowKey = '',
    search = false,
    searchField = '',
    searchPlaceholder = t('Search'),
    pagination = false,
    tableActions = [],
    rowActions = [],
    size = 'large',
    title,
    rowSelectionActions,
    selectionType = 'radio',
    rowActionsClassName,
    tableActionsPosition = 'post',
  } = props;

  const [dataSource, setDataSource] = useState<AnyObject[]>();
  const [searchText, setSearchText] = useState<Nullable<string>>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<Nullable<string>>(null);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('elements')}`,
      size: 'default',
    },
  });

  const commonRowActionsClass = 'flex justify-end space-x-7';

  useDebounce(
    () => {
      setDebouncedSearch(searchText);
    },
    600,
    [searchText],
  );

  useEffect(() => {
    if (search) {
      const filteredBySearchData = data?.filter((element) => element[searchField].includes(debouncedSearch));
      setDataSource(filteredBySearchData);
    }
  }, [data, debouncedSearch, search, searchField]);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const onSearch = useCallback((value: string): void => {
    setSearchText(value);
  }, []);

  const isShowHeader = (title && search) || (title && !search && !tableActions);

  const handleTableChange = (pagination: TablePaginationConfig): void => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        ...pagination,
      },
    }));
  };

  const onSelectChange = useCallback((newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
  }, []);

  const rowSelection: TableRowSelection<CustomAny> = {
    type: selectionType,
    columnTitle:
      selectionType === 'checkbox'
        ? (originNode) => {
            // @ts-expect-error Origin antd checkbox props
            return <Checkbox name="selectAll" checked={originNode.props.checked} onChangeEvent={originNode.props.onChange} />;
          }
        : undefined,
    renderCell: (value, record, index, originNode) => {
      return (
        // @ts-expect-error Origin antd checkbox props
        <Checkbox name={record[rowKey]} className={originNode.props.className} checked={value} onChangeEvent={originNode.props.onChange} />
      );
    },
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderTableActions = useCallback((): ReactNode => {
    const actions = [...tableActions.map((action) => ({ ...action, type: 'table' }))];

    return (
      <>
        {actions.map(({ name, icon, iconPosition, theme, isLoading, isDisabled, onClick }) => {
          return (
            <Button
              key={name}
              icon={icon}
              iconPosition={iconPosition}
              theme={theme}
              isLoading={isLoading}
              isDisabled={isDisabled}
              className={tableActionsPosition === 'post' ? 'ml-3' : 'mr-3'}
              onClick={() => {
                onClick(dataSource || []);
              }}
            >
              {name}
            </Button>
          );
        })}
      </>
    );
  }, [dataSource, tableActions, tableActionsPosition]);

  const renderRowActions = useCallback(
    (item: AnyObject) => {
      return (
        <div className={classNames(commonRowActionsClass, rowActionsClassName)}>
          {rowActions.map(({ name, label, icon, iconPosition, theme, isLoading, isDisabled, onClick }) => {
            return (
              <Button
                key={name}
                icon={icon}
                iconPosition={iconPosition}
                theme={theme}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onClick={() => {
                  onClick(item);
                }}
              >
                {label}
              </Button>
            );
          })}
        </div>
      );
    },
    [rowActions, rowActionsClassName],
  );

  const tableColumns = useMemo(() => {
    return [
      ...columns.map((column) => {
        return {
          ...column,
        };
      }),
      {
        title: '',
        key: 'actions',
        render: (_: null, item: AnyObject) => renderRowActions(item),
      },
    ];
  }, [columns, renderRowActions]);

  const tableClass = classNames(s.table, {
    'border-solid border border-gray-200 rounded-lg': bordered,
  });

  return (
    <>
      <div className={className}>
        {(search || tableActions) && (
          <div className="flex flex-1 items-center h-[80px]">
            {tableActions.length && tableActionsPosition === 'prev' ? renderTableActions() : null}
            {search ? (
              <Input
                className="w-[300px] ml-auto"
                placeholder={searchPlaceholder}
                prefix={<SearchIcon className="stroke-accent" />}
                onChange={onSearch}
                bordered
              />
            ) : (
              <div className="mr-auto flex-shrink-0 text-2xl font-semibold">{title}</div>
            )}
            {tableActions.length && tableActionsPosition === 'post' ? renderTableActions() : undefined}
          </div>
        )}

        <AtndTable
          className={tableClass}
          columns={tableColumns}
          dataSource={dataSource}
          rowKey={(record) => record[rowKey]}
          loading={loading}
          size={size}
          onChange={handleTableChange}
          pagination={pagination && tableParams.pagination}
          rowSelection={rowSelectionActions?.length ? rowSelection : undefined}
        />
      </div>
    </>
  );
});
