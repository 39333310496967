import { api } from 'app/config/apiConfig';
import { Box } from '../model/types';
import { BoxFormFields } from 'features/BoxFormDrawer';

export const createBoxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createBox: builder.mutation<Box, BoxFormFields>({
      query: (data) => ({
        url: '/boxes',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Boxes', 'Warehouses for booking'],
    }),
  }),
});

export const { useCreateBoxMutation } = createBoxApi;
