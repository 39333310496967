import { api } from 'app/config/apiConfig';
import { Contract, CreateContractParams } from '../model/types';

export const createContractApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createContract: builder.mutation<Contract, { boxId: string; params: CreateContractParams }>({
      query: (args) => ({
        url: `/boxes/${args.boxId}/contracts`,
        method: 'POST',
        body: args.params,
      }),
      invalidatesTags: ['Contracts', 'Available boxes'],
    }),
  }),
});

export const { useCreateContractMutation } = createContractApi;
