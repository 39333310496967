import React, { FC, memo } from 'react';
import { InvoicesInsuranceTable } from './InvoicesInsuranceTable';
import { TableFilterContextProvider } from 'features/TableFilter';

export const InvoicesInsurancePage: FC = memo(() => {
  return (
    <TableFilterContextProvider>
      <InvoicesInsuranceTable />
    </TableFilterContextProvider>
  );
});
