import { Divider } from 'antd';
import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { CreateWarehouseFormFields } from '../../../model/types';

interface SummaryInfoProps {
  cityName: string | undefined;
  franchiseName: string | undefined;
  formData: Partial<CreateWarehouseFormFields>;
}

export const SummaryInfo: FC<SummaryInfoProps> = memo((props) => {
  const { cityName, franchiseName, formData } = props;

  const { zip, phone, name, email, latitude, longitude, address, launchDate } = formData;

  const { t } = useAppTranslation('warehouses');

  const headingClass = 'text-accent mb-2.5';
  const valueClass = 'font-normal';

  return (
    <div className="space-y-4 basis-5/12">
      <div className="font-semibold text-2xl">{t('Summary')}</div>
      <div className="border border-secondaryAccent rounded-lg py-7 px-5">
        <div>
          <div className={headingClass}>{t('City')}</div>
          <div className={valueClass}>{cityName}</div>
        </div>
        <Divider className="border-secondaryAccent" />
        <div>
          <div className={headingClass}>{t('Franchise')}</div>
          <div className={valueClass}>{franchiseName}</div>
        </div>
        <Divider className="border-secondaryAccent" />
        <div>
          <div className={headingClass}>{t('Warehouse info')}</div>
          <div className={valueClass}>{name}</div>
          <div className={valueClass}>
            {address} {zip ? ',' : ''} {zip}
          </div>
          <div className={valueClass}>{phone}</div>
          <div className={valueClass}>{email}</div>
          <div className={valueClass}>
            {longitude} {latitude ? ',' : ''} {latitude}
          </div>
          <div className={valueClass}>{getClientDateFormat(launchDate)}</div>
        </div>
      </div>
    </div>
  );
});
