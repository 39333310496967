import { api } from 'app/config/apiConfig';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';
import { userActions } from '../model/slice/userSlice';
import { AuthResponse, RegisterUserParams } from '../model/types';

export const registrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registration: builder.mutation<AuthResponse, RegisterUserParams>({
      query: (args) => ({
        url: '/authorization/sign-up',
        method: 'POST',
        body: args,
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data: credentials } = await queryFulfilled;

          dispatch(userActions.setCredentials({ credentials, remember: true }));
        } catch (error) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when registration user', { ns: 'common' }));
        }
      },
    }),
  }),
});

export const { useRegistrationMutation } = registrationApi;
