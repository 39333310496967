import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getAppLoadingState } from '../../model/selectors/getAppLoadingState';
import { appActions } from '../../model/slice/appSlice';

interface HookApi {
  isAppLoading: boolean;
  startAppLoading: () => void;
  stopAppLoading: () => void;
}

export const useAppLoader = (): HookApi => {
  const dispatch = useAppDispatch();

  const isAppLoading = useAppSelector(getAppLoadingState);

  const startAppLoading = (): void => {
    dispatch(appActions.startLoading());
  };

  const stopAppLoading = (): void => {
    dispatch(appActions.stopLoading());
  };

  return {
    isAppLoading,
    startAppLoading,
    stopAppLoading,
  };
};
