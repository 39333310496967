import React, { FC, memo, ReactElement } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ContractInfo } from 'entities/Contract';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { useCreateContract } from '../utils/hooks/useCreateContract';

interface CreateContractProps {
  warehouseId: string | undefined;
  boxId: string | undefined;
  userId: string | undefined;
  contractOptions: ContractInfo;
  action: ReactElement;
  onSuccess?: () => void;
}

export const CreateContract: FC<CreateContractProps> = memo((props) => {
  const { contractOptions, boxId, warehouseId, action, userId, onSuccess } = props;

  const { t } = useAppTranslation(['booking', 'common']);

  const { handleCreateContract, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useCreateContract({
    contractOptions,
    boxId,
    warehouseId,
    userId,
    onSuccess,
  });

  return (
    <>
      {React.cloneElement(action, { onClick: openConfirmModal })}
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Create contract')}
        description={t('Are you sure you want to create contract?')}
        isLoading={isLoading}
        onOk={handleCreateContract}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
