import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useGetFloorsByWarehouseIdQuery } from 'entities/Floor';
import { Select } from 'shared/ui/Select';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { BoxesListFilterKeys } from '../../model/types';

interface FloorSelectProps {
  selectedWarehouseId: Nullable<string> | undefined;
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const FloorSelect: FC<FloorSelectProps> = memo((props) => {
  const { selectedWarehouseId, value, onChange, onClear } = props;

  const { t } = useAppTranslation('boxes');

  const { data } = useGetFloorsByWarehouseIdQuery(selectedWarehouseId, { skip: !selectedWarehouseId });

  const floorOptions = useMemo<Array<{ label: string; value: string }>>(() => {
    return data?.map(({ floorId, name }) => ({ label: getLocalizedString(name), value: floorId })) || [];
  }, [data]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(BoxesListFilterKeys.floorId);
  }, [onClear]);

  return (
    <Select
      className="w-full"
      label={t('Floor')}
      placeholder={t('Select')}
      bordered
      allowClear
      disabled={!selectedWarehouseId}
      options={floorOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
