import dayjs, { Dayjs } from 'dayjs';

export const disableFutureDatesUnder18 = (currentDate: Dayjs): boolean => {
  const today = dayjs().startOf('day');
  const eighteenYearsAgo = today.subtract(18, 'year').startOf('day');

  return currentDate.isAfter(eighteenYearsAgo);
};

export const datePickerValueUnder18 = dayjs(dayjs().subtract(18, 'years'));
