export interface InvoiceInsurance {
  warehouseId: string;
  warehouseName: string;
  userId: string;
  userFullName: string;
  invoiceId: string;
  invoiceNumber: string;
  dateFrom: string;
  dateTo: string;
  totalPrice: number;
  contractId: string;
  contractNumber: string;
  coverageAmount: number;
  vatRatePercent: number;
  initialPrice: number;
  boxNumber: string;
  invoiceDueDate: string;
  invoiceDebt: number;
  insurerPrice?: number;
}

export enum InvoiceStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  OVERDUE = 'overdue',
}
