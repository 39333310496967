import { api } from 'app/config/apiConfig';
import { User } from 'entities/User';
import { ReplenishBalanceParams } from '../model/types';

export const replenishBalanceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    replenishBalance: builder.mutation<User, { userId: string; params: ReplenishBalanceParams }>({
      query: ({ userId, params }) => ({
        url: `/users/${userId}/balance/refill`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useReplenishBalanceMutation } = replenishBalanceApi;
