import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RequestFormModalPayload, RequestFormModalSchema } from '../types';

const initialState: RequestFormModalSchema = {
  isOpen: false,
  title: null,
  description: null,
  mode: null,
  square: null,
};

const requestFormModalSlice = createSlice({
  name: 'requestFormModal',
  initialState,
  reducers: {
    openRequestModal: (state: RequestFormModalSchema, action: PayloadAction<RequestFormModalPayload>) => {
      const { title, mode, description, square } = action.payload;
      state.isOpen = true;
      state.mode = mode;
      state.title = title;
      state.description = description;
      state.square = square;
    },
    closeRequestModal: (state: RequestFormModalSchema) => {
      state.isOpen = false;
      state.mode = null;
      state.title = null;
      state.description = null;
      state.square = null;
    },
  },
});

export const { actions: requestFormModalActions, reducer: requestFormModalReducer } = requestFormModalSlice;
