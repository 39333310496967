import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { getLateChargeSettingFormState } from '../model/selectors/getLateChargeSettingFormState';
import { LateChargeSettingForm } from './LateChargeSettingForm';
import { lateChargeSettingFormDrawerActions } from '../model/slice/lateChargeSettingFormDrawerSlice';

export const LateChargeSettingFormDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const lateChargeSettingFormState = useAppSelector(getLateChargeSettingFormState);

  const closeBoxFormDrawer = useCallback((): void => {
    dispatch(lateChargeSettingFormDrawerActions.setOpenLateChargeSettingForm({ mode: null, lateChargeSetting: null }));
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(lateChargeSettingFormState.mode)} onClose={closeBoxFormDrawer}>
      <LateChargeSettingForm
        lateChargeSetting={lateChargeSettingFormState.lateChargeSetting}
        mode={lateChargeSettingFormState.mode}
        handleClose={closeBoxFormDrawer}
      />
    </Drawer>
  );
});
