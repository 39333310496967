import React, { FC, memo, PropsWithChildren } from 'react';
import { useAppLoader } from 'app/appState';

export const PageLoader: FC<PropsWithChildren> = memo((props) => {
  const { isAppLoading } = useAppLoader();

  return (
    <>
      {isAppLoading && (
        <div className="z-50 fixed inset-0">
          <div className="w-full h-full bg-white" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="animate-spin h-16 w-16 border-t-4 border-gray-500 rounded-full" />
          </div>
        </div>
      )}
      {props.children}
    </>
  );
});
