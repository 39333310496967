import classNames from 'classnames';
import React, { FC, memo, ReactNode, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData } from 'entities/User';
import { MenuItem } from '../model/types';
import { Menu } from 'shared/ui/Menu';

interface HeaderProps {
  logo?: ReactNode;
  actions?: ReactNode[];
  menuItems?: MenuItem[];
  headerContent?: ReactNode;
  className?: string;
}

export const Header: FC<HeaderProps> = memo((props) => {
  const { className, actions, logo, menuItems = [], headerContent } = props;
  const { pathname } = useLocation();

  const activeRoute = menuItems.find(({ label }) => {
    if (React.isValidElement(label)) {
      return label.props.to === pathname;
    }

    return null;
  });

  const loggedUserRole = useAppSelector(getLoggedUserData)?.role;

  const getAllowedRoutes = useCallback((): MenuItem[] => {
    if (loggedUserRole) {
      const allowedRoutes = menuItems.filter((item) => {
        const hasAccess = item.allowedroles?.length ? item.allowedroles.includes(loggedUserRole) : true;

        return hasAccess;
      });

      return allowedRoutes;
    }

    return [];
  }, [loggedUserRole, menuItems]);

  const renderHeaderContent = (): ReactNode => {
    if (menuItems.length && !headerContent) {
      return <Menu className="flex flex-1 space-x-4 border-none" items={getAllowedRoutes()} activeKey={String(activeRoute?.key)} />;
    }

    if (Boolean(headerContent) && !menuItems.length) {
      return headerContent;
    }

    return null;
  };

  return (
    <div className={classNames('flex justify-between items-center py-[15px] bg-white', className)}>
      <div className="mr-5">
        <Link to={AppRoutes.HOME}>{logo}</Link>
      </div>
      {renderHeaderContent()}
      <div className="flex items-center justify-between space-x-3 ml-5">{actions}</div>
    </div>
  );
});
