import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SizeFilter, BoxSizeFilterSchema } from '../types';

const initialState: BoxSizeFilterSchema = {
  selectedFilter: 'All',
};

const boxSizeFilterSlice = createSlice({
  name: 'boxSizeFilter',
  initialState,
  reducers: {
    changeFilter: (state: BoxSizeFilterSchema, action: PayloadAction<SizeFilter>) => {
      state.selectedFilter = action.payload;
    },
    resetFilter: (state: BoxSizeFilterSchema) => {
      state.selectedFilter = 'All';
    },
  },
});

export const { actions: boxSizeFilterActions, reducer: boxSizeFilterReducer } = boxSizeFilterSlice;
