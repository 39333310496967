import React, { FC, ReactNode, memo } from 'react';
import classNames from 'classnames';
import { WarningPing } from '../WarningPing';

interface MessagePanelProps {
  type?: 'info' | 'error';
  className?: string;
  title?: string;
  description?: string;
  actions?: ReactNode;
}

export const MessagePanel: FC<MessagePanelProps> = memo((props) => {
  const { type = 'info', className, title, description, actions } = props;

  const commonClasses = 'flex flex-col gap-6 px-4 py-5 bg-white rounded shadow-md';

  return (
    <div className={classNames(commonClasses, className)}>
      <WarningPing type={type} />
      <div className="flex flex-col gap-2">
        <div className="font-semibold text-xl">{title}</div>
        <div className="text-primaryLight">{description}</div>
      </div>
      {actions}
    </div>
  );
});
