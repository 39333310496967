import React, { FC, FunctionComponent, memo, SVGProps } from 'react';

interface HistoryActionIconProps {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const HistoryActionIcon: FC<HistoryActionIconProps> = memo((props) => {
  const { Icon } = props;

  return (
    <div className="flex justify-center items-center w-12 h-12 border bg-accentLight rounded-full shrink-0">
      <Icon className="[&>path]:stroke-accent [&>circle]:stroke-accent" />
    </div>
  );
});
