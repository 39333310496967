import { api } from 'app/config/apiConfig';
import { Insurance } from '../model/types';
import { InsuranceFormFields } from 'features/InsuranceFormDrawer';

export const createInsuranceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createInsurance: builder.mutation<Insurance, InsuranceFormFields>({
      query: (data) => ({
        url: '/insurance',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Insurance', 'Warehouses for booking'],
    }),
  }),
});

export const { useCreateInsuranceMutation } = createInsuranceApi;
