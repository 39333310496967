import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RangeInput } from 'shared/ui/RangeInput/RangeInput';
import { useGetCurrencySymbol } from 'app/appState';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { BoxesListFilterKeys } from '../../model/types';
import { BoxAvailabilitySelect } from './BoxAvailabilitySelect';
import { BoxTypeSelect } from './BoxTypeSelect';
import { FloorSelect } from './FloorSelect';
import { PriceTypeSelect } from './PriceTypeSelect';
import { WarehouseSelect } from './WarehouseSelect';
import { ForOwnNeedsSelect } from './ForOwnNeedsSelect';
import { BoxNoteSelect } from './BoxNoteSelect';

export const BoxesListFilter: FC = memo(() => {
  const { t } = useAppTranslation('boxes');

  const currencySymbol = useGetCurrencySymbol();

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<BoxesListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  const changePriceFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.priceFrom,
        label: `${t('From {{priceFrom}}', { priceFrom: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.priceTo,
        label: `${t('To {{priceTo}}', { priceTo: value })}${currencySymbol}`,
        value,
        title: t('Price'),
      });
    },
    [currencySymbol, changeFilters, t],
  );

  const changePriceType = useCallback(
    (value: string, label: string) => {
      changeFilters({
        key: BoxesListFilterKeys.priceType,
        label: t('Price type: {{priceType}}', { priceType: label }),
        value,
        title: t('Price type'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareFrom = useCallback(
    (value: Nullable<number>) => {
      changeFilters({
        key: BoxesListFilterKeys.squareFrom,
        label: t('From {{squareFrom}} m²', { squareFrom: value }),
        value,
        title: t('Square'),
      });
    },
    [changeFilters, t],
  );

  const changeSquareTo = useCallback(
    (value: Nullable<number>) => {
      changeFilters({ key: BoxesListFilterKeys.squareTo, label: t('To {{squareTo}} m²', { squareTo: value }), value, title: t('Square') });
    },
    [changeFilters, t],
  );

  const changeFloor = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.floorId, label, value, title: t('Floor') });
    },
    [changeFilters, t],
  );

  const changeBoxType = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.type, label, value, title: t('Type') });
    },
    [changeFilters, t],
  );

  const changeAvailability = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.availability, label, value, title: t('Availability') });
    },
    [changeFilters, t],
  );

  const changeForOwnNeeds = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.isForOwnNeeds, label, value, title: t('For own needs') });
    },
    [changeFilters, t],
  );

  const changeHasNotes = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: BoxesListFilterKeys.hasNotes, label, value, title: t('Note') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
        <div className="flex space-x-3 items-center">
          <RangeInput
            values={[filters.priceFrom?.value, filters.priceTo?.value]}
            containerClassName="basis-2/3"
            placeholder={[t('From'), t('To')]}
            label={t('Price')}
            type="number"
            onChangeFrom={changePriceFrom}
            onChangeTo={changePriceTo}
          />
          <PriceTypeSelect value={filters.priceType?.value} onChange={changePriceType} onClear={clearCurrentFilters} />
        </div>
        <RangeInput
          values={[filters.squareFrom?.value, filters.squareTo?.value]}
          placeholder={[t('From'), t('To')]}
          label={t('Square')}
          type="number"
          onChangeFrom={changeSquareFrom}
          onChangeTo={changeSquareTo}
        />
        <FloorSelect
          selectedWarehouseId={filters.selectedWarehouseId?.value}
          value={filters.floorId?.value}
          onChange={changeFloor}
          onClear={clearCurrentFilters}
        />
        <div className="flex space-x-3 items-center">
          <BoxTypeSelect value={filters.type?.value} onChange={changeBoxType} onClear={clearCurrentFilters} />
          <BoxAvailabilitySelect value={filters.availability?.value} onChange={changeAvailability} onClear={clearCurrentFilters} />
        </div>
        <div className="flex space-x-3 items-center">
          <ForOwnNeedsSelect value={filters.isForOwnNeeds?.value} onChange={changeForOwnNeeds} onClear={clearCurrentFilters} />
          <BoxNoteSelect value={filters.hasNotes?.value} onChange={changeHasNotes} onClear={clearCurrentFilters} />
        </div>
      </div>
    </TableFilter>
  );
});
