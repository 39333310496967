import React, { FC, memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { Drawer } from 'shared/ui/Drawer';
import { getInsuranceFormState } from '../model/selectors/getInsuranceFormState';
import { InsuranceForm } from './InsuranceForm';
import { insuranceFormDrawerActions } from '../model/slice/insuranceFormDrawerSlice';

export const InsuranceFormDrawer: FC = memo(() => {
  const dispatch = useAppDispatch();

  const insuranceFormState = useAppSelector(getInsuranceFormState);

  const closeInsuranceFormDrawer = useCallback((): void => {
    dispatch(insuranceFormDrawerActions.setOpenInsuranceForm({ mode: null, insurance: null }));
  }, [dispatch]);

  return (
    <Drawer width={500} open={Boolean(insuranceFormState.mode)} onClose={closeInsuranceFormDrawer}>
      <InsuranceForm insurance={insuranceFormState.insurance} mode={insuranceFormState.mode} handleClose={closeInsuranceFormDrawer} />
    </Drawer>
  );
});
