import { api } from 'app/config/apiConfig';
import { AUTH_RESET_PASSWORD_PATH } from '../utils/constants';

export const resetPasswordApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetPassword: builder.mutation<void, string>({
      query: (email) => ({
        url: AUTH_RESET_PASSWORD_PATH,
        method: 'POST',
        body: { email },
      }),
    }),
  }),
});

export const { useResetPasswordMutation } = resetPasswordApi;
