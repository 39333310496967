import dayjs from 'dayjs';
import React, { FC, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { GlobalSettings } from 'app/appState';
import { Box, useGetBoxRentalPrice, useBookAndPayMutation } from 'entities/Box';
import { ContractInfo, ContractPreviewModal, useGetContractPreviewMutation } from 'entities/Contract';
import { PaymentProcessor, PaymentResponse } from 'entities/Payment';
import { User } from 'entities/User';
import { WarehouseForBooking } from 'entities/Warehouse';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';
import i18n from 'app/config/i18Config/i18n';
import { GratitudeModalType, useOpenGratitudeModal } from 'features/GratitudeModal';

interface BookAndPayButtonProps {
  isDisabled: boolean;
  boxInfo: Nullable<Box>;
  contractInfo: ContractInfo;
  globalSettings: Nullable<GlobalSettings>;
  warehouseInfo: Nullable<WarehouseForBooking>;
  loggedUserData: Nullable<User>;
  processor: PaymentProcessor;
  isAutopaymentEnabled: boolean;
  onCompleteStep: () => void;
}

export const BookAndPayButton: FC<BookAndPayButtonProps> = memo((props) => {
  const {
    isDisabled,
    boxInfo,
    contractInfo,
    warehouseInfo,
    loggedUserData,
    globalSettings,
    onCompleteStep,
    processor,
    isAutopaymentEnabled,
  } = props;

  const [isContractPreviewOpened, setContractPreviewOpened] = useState<boolean>(false);
  const navigate = useNavigate();

  const [getContractPreview, { data, isLoading: getContractPreviewLoading }] = useGetContractPreviewMutation();
  const [bookAndPay, { isLoading: bookAndPayLoading }] = useBookAndPayMutation();

  const { t } = useAppTranslation(['booking', 'common']);

  const { openGratitudeModal } = useOpenGratitudeModal();

  const rentalCost = useGetBoxRentalPrice(boxInfo, contractInfo.invoiceFrequencyType);
  const isPayLaterMethod = processor === PaymentProcessor.BANK_TRANSFER || processor === PaymentProcessor.OTHER;

  const openContractPreview = useCallback(async (): Promise<void> => {
    if (globalSettings && loggedUserData && boxInfo && warehouseInfo && rentalCost) {
      try {
        await getContractPreview({
          warehouseName: warehouseInfo.name,
          warehouseAddress: warehouseInfo.address,
          startDate: getServerDateFormat(contractInfo.startDate),
          boxId: boxInfo.boxId,
          boxNumber: boxInfo.name,
          boxSquare: boxInfo.sizeCode.square,
          invoiceFrequencyType: contractInfo.invoiceFrequencyType,
          contractDuration: contractInfo.contractDuration,
          discountPercent: contractInfo.discountPercent,
          clientFirstName: loggedUserData.firstName,
          clientLastName: loggedUserData.lastName,
          clientStreet: loggedUserData.street,
          clientCountry: loggedUserData.country,
          clientCity: loggedUserData.city,
          clientHouse: loggedUserData.house,
          clientApartment: loggedUserData.apartment,
          clientZip: loggedUserData.zip,
          language: i18n.language || loggedUserData.language,
          clientEmail: loggedUserData.email,
          clientPhone: loggedUserData.phone,
          rentalCost,
          insuranceAmount: contractInfo.insuranceAmountWithoutVat,
          insuranceVatRateAmount: contractInfo.insuranceVatRateAmount,
          discountAmount: contractInfo.discountAmount,
          totalToPay: contractInfo.totalToPay,
          paymentFrequencyPeriod: contractInfo.payForEntirePeriod ? 'entire period' : 'regular',
          vatRateAmount: contractInfo.vatRateAmount,
          currency: globalSettings.currency,
        }).unwrap();
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when getting contract preview'));
        console.log(error);
      } finally {
        setContractPreviewOpened(true);
      }
    }
  }, [t, boxInfo, contractInfo, getContractPreview, globalSettings, loggedUserData, warehouseInfo, rentalCost]);

  const closeContractPreview = useCallback((): void => {
    setContractPreviewOpened(false);
  }, []);

  const onSuccessBookAndPay = useCallback(
    (paymentResponse: PaymentResponse) => {
      if (paymentResponse.redirectionUrl.includes(PaymentProcessor.STRIPE || PaymentProcessor.SEPA)) {
        const query = paymentResponse.redirectionUrl.split('?')[1];
        navigate(`${AppRoutes.CHECKOUT}?${query}`);
      } else {
        window.location.href = paymentResponse.redirectionUrl;
      }
    },
    [navigate],
  );

  const handleOpenGratitudeModal = useCallback(
    (paymentResponse: PaymentResponse): void => {
      openGratitudeModal({
        description: t(
          'Our company values each and every customer. If you have any questions or feedback, please don`t hesitate to reach out!',
        ),
        type: GratitudeModalType.RESERVATION,
        buttonId: 'contract created',
        onSuccess: () => {
          onSuccessBookAndPay(paymentResponse);
        },
      });
    },
    [onSuccessBookAndPay, openGratitudeModal, t],
  );

  const handleBookAndPay = useCallback(async (): Promise<void> => {
    if (!boxInfo || !loggedUserData || !warehouseInfo || !contractInfo.rentOption) {
      return;
    }

    closeContractPreview();

    try {
      const endDate = dayjs(contractInfo.startDate).add(contractInfo.contractDuration, contractInfo.invoiceFrequencyType);

      const bookAndPayData = {
        processor: processor === PaymentProcessor.OTHER ? PaymentProcessor.BANK_TRANSFER : processor,
        isAutopayment: isAutopaymentEnabled,
        boxId: boxInfo.boxId,
        userId: loggedUserData.userId,
        warehouseId: warehouseInfo.warehouseId,
        insuranceId: contractInfo.insuranceId,
        rentOptionId: contractInfo.rentOption.rentOptionId,
        startDate: getServerDateFormat(contractInfo.startDate),
        endDate: getServerDateFormat(endDate),
        invoiceFrequencyType: contractInfo.invoiceFrequencyType,
        invoiceFrequency: 1,
        paymentFrequencyPeriod: contractInfo.payForEntirePeriod ? 'entire period' : 'regular',
      };

      const paymentResponse = await bookAndPay(bookAndPayData).unwrap();

      if (paymentResponse) {
        handleOpenGratitudeModal(paymentResponse);
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error'), t('Error when booking box'));
      console.log('error', error);
    }

    onCompleteStep();
  }, [
    boxInfo,
    loggedUserData,
    warehouseInfo,
    contractInfo.rentOption,
    contractInfo.startDate,
    contractInfo.contractDuration,
    contractInfo.invoiceFrequencyType,
    contractInfo.insuranceId,
    contractInfo.payForEntirePeriod,
    closeContractPreview,
    onCompleteStep,
    processor,
    isAutopaymentEnabled,
    bookAndPay,
    handleOpenGratitudeModal,
    t,
  ]);

  return (
    <>
      <Button
        size="large"
        containerClassName="w-full"
        isDisabled={isDisabled}
        isLoading={getContractPreviewLoading}
        onClick={openContractPreview}
        description={t('You will find the invoice for payment in your personal account.')}
      >
        {t(isPayLaterMethod ? 'Book' : 'Book and pay')}
      </Button>
      <ContractPreviewModal
        isOpened={isContractPreviewOpened}
        isLoading={bookAndPayLoading}
        contractPreview={data}
        onOk={handleBookAndPay}
        onCancel={closeContractPreview}
      />
    </>
  );
});
