import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useLogOutMutation } from 'entities/User';
import React, { FC, useCallback } from 'react';
import { Button } from 'shared/ui/Button';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { bookingHeaderActions } from 'widgets/BookingHeader';
import { bookingPageActions } from 'pages/BookingPage';

interface LogOutButtonProps {
  className?: string;
}

export const LogOutButton: FC<LogOutButtonProps> = (props) => {
  const { className } = props;

  const { t } = useAppTranslation('header');
  const dispatch = useAppDispatch();

  const [logout] = useLogOutMutation();

  const logOut = useCallback(async (): Promise<void> => {
    await logout().unwrap();
    dispatch(bookingHeaderActions.resetAllBookingData());
    dispatch(bookingPageActions.resetAllBookingInfo());
  }, [dispatch, logout]);

  return (
    <Button containerClassName={className} theme="secondary" size="small" onClick={logOut}>
      {t('Log out')}
    </Button>
  );
};
