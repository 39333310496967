import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { usePayInvoiceMutation } from 'entities/Invoice/api/payInvoiceApi';
import { PaymentProcessor } from 'entities/Payment';
import { Button } from 'shared/ui/Button';
import { CollapseRadioButtonGroup } from 'shared/ui/CollapseRadioButtonGroup';
import { Checkbox } from 'shared/ui/Checkbox';
import { Modal } from 'shared/ui/Modal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { ReactComponent as VisaIcon } from 'shared/assets/icons/VisaIcon.svg';
import { ReactComponent as MastercardIcon } from 'shared/assets/icons/MastercardIcon.svg';
import { ReactComponent as AmexIcon } from 'shared/assets/icons/AmexIcon.svg';
import { ReactComponent as GoogleIcon } from 'shared/assets/icons/GoogleIcon.svg';
import { ReactComponent as AppleIcon } from 'shared/assets/icons/AppleIcon.svg';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useNavigate } from 'react-router-dom';
import { Invoice } from 'entities/Invoice';
import { AutochargeStatus } from 'entities/Contract';

interface PaymentModalProps {
  invoice: Invoice;
  isOpen: boolean;
  onClose: () => void;
}

export const PaymentModal: FC<PaymentModalProps> = memo((props) => {
  const { invoice, isOpen, onClose } = props;

  const navigate = useNavigate();

  const { t } = useAppTranslation(['booking', 'common']);

  const [processor, setProcessor] = useState(PaymentProcessor.STRIPE);
  const [isAutoPaymentEnabled, setAutoPaymentEnabled] = useState(false);

  const [payInvoice] = usePayInvoiceMutation();

  const isAutochargesAllowed = useMemo(
    () => invoice?.contract?.autocharges === AutochargeStatus.DISABLED,
    [invoice?.contract?.autocharges],
  );

  useEffect(() => {
    const watchAutoPaymentStatus = (): void => {
      switch (processor) {
        case PaymentProcessor.STRIPE:
          setAutoPaymentEnabled(false);
          break;
        case PaymentProcessor.PAYPAL:
          setAutoPaymentEnabled(false);
          break;
        case PaymentProcessor.SEPA:
          setAutoPaymentEnabled(true);
          break;
        case PaymentProcessor.BANK_TRANSFER:
          setAutoPaymentEnabled(false);
          break;
      }
    };

    watchAutoPaymentStatus();
  }, [processor]);
  const toggleAutoPayment = useCallback((): void => {
    setAutoPaymentEnabled((prevState) => !prevState);
  }, []);

  const handlePayInvoice = useCallback(async (): Promise<void> => {
    if (invoice) {
      try {
        const response = await payInvoice({ invoiceId: invoice.invoiceId, processor, isAutopayment: isAutoPaymentEnabled }).unwrap();

        if (response.redirectionUrl) {
          if (response?.redirectionUrl?.includes(PaymentProcessor.STRIPE || PaymentProcessor.SEPA)) {
            const query = response?.redirectionUrl.split('?')[1];
            navigate(`${AppRoutes.CHECKOUT}?${query}`);
          } else {
            window.location.href = response.redirectionUrl;
          }
        } else {
          showNotification('info', t('Success'), t('Invoice has been successfully paid'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when paying invoice'));
      } finally {
        onClose();
      }
    }
  }, [invoice, payInvoice, processor, isAutoPaymentEnabled, navigate, t, onClose]);

  const options = useMemo(
    () => [
      // TODO получать из отдельного запроса (бэк не готов)
      {
        label: (
          <div className="flex flex-col items-start justify-between w-full desktop:flex-row desktop:items-center">
            <span>{t('Credit/Debit card')}</span>
            <div className="flex flex-wrap items-center gap-2">
              <AmexIcon className="w-10 h-10" />
              <VisaIcon className="w-10 h-10" />
              <MastercardIcon className="w-10 h-10" />
              <AppleIcon className="w-7 h-7" />
              <GoogleIcon className="w-7 h-7" />
            </div>
          </div>
        ),
        value: PaymentProcessor.STRIPE,
        content: (
          <div>
            <div className="mb-4 text-primaryLight">
              {t('We accept all major credit and debit cards, including Visa, Mastercard, American Express, GooglePay and ApplePay.')}{' '}
              <br />
            </div>
            {isAutochargesAllowed && (
              <>
                <div className="font-semibold text-lg">{t('Auto pay')}</div>
                <div className="mb-2">
                  {t('Turn on the automatic payment feature so you don`t miss payments. You can turn it off at any time!')}
                </div>
                <Checkbox
                  name="isAutopayment"
                  checked={isAutoPaymentEnabled}
                  onChange={toggleAutoPayment} /* disabled={isPrePaymentApplied} */
                >
                  {t('Allow autopayment')}
                </Checkbox>
              </>
            )}
          </div>
        ),
      },
      // {
      //   label: t('Bank transfer'),
      //   value: PaymentProcessor.BANK_TRANSFER,
      //   content: t('You will find the invoice for payment in your personal account. Please note that you cannot use the box until you have paid the first bill. Please pay your first bill before your move-in date'),
      // },
      // {
      //   label: 'Paypal',
      //   value: PaymentProcessor.PAYPAL,
      //   content: <div>{t('Use your PayPal account for payment')}</div>,
      // },
      // {
      //   label: 'Sepa',
      //   value: PaymentProcessor.SEPA,
      //   content: (
      //     <div>
      //       <div className="font-semibold text-lg">{t('Auto pay')}</div>
      //       <div className="mb-2">{t('By choosing this payment method, the next payments will be paid automatically')}</div>
      //       <Checkbox name="isAutoPayment" disabled defaultChecked>
      //         {t('Allow auto payment')}
      //       </Checkbox>
      //     </div>
      //   ),
      // },
    ],
    [isAutoPaymentEnabled, isAutochargesAllowed, t, toggleAutoPayment],
  );

  const actions = useCallback((): JSX.Element => {
    return (
      <div className="flex space-x-3 justify-end">
        <Button theme="secondary" onClick={onClose}>
          {t('Cancel', { ns: 'common' })}
        </Button>
        <Button onClick={handlePayInvoice}>{t('Pay', { ns: 'common' })}</Button>
      </div>
    );
  }, [onClose, handlePayInvoice, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} footer={actions()} closeIcon={false}>
      <div className="min-h-[350px]">
        <div className="font-semibold text-3xl mb-6">{t('Select payment method')}</div>
        <CollapseRadioButtonGroup options={options} selectedOption={processor} setSelectedOption={setProcessor} />
      </div>
    </Modal>
  );
});
