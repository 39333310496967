import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { RequestModalMode, requestFormModalActions } from 'widgets/RequestModal';
import React, { FC, memo, useCallback } from 'react';
import { Button } from 'shared/ui/Button';

interface ContactUsProps {
  className?: string;
  square?: number;
}

export const ContactUs: FC<ContactUsProps> = memo((props) => {
  const { square, className } = props;

  const { t } = useAppTranslation('booking');
  const dispatch = useAppDispatch();

  const handleOpenRequestFormModal = useCallback((): void => {
    dispatch(
      requestFormModalActions.openRequestModal({
        title: t('No matching box size? Send us a request!'),
        description: '',
        mode: RequestModalMode.REQUEST_BOX,
        square,
      }),
    );
  }, [dispatch, square, t]);

  return (
    <Button containerClassName={className} onClick={handleOpenRequestFormModal}>
      {t('Request a box')}
    </Button>
  );
});
