import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractInfo, ContractOptionsSchema } from '../types';
import { contractOptionsState } from './initialState';

const initialState: ContractOptionsSchema = {
  contractOptions: contractOptionsState,
};

const contractOptionsSlice = createSlice({
  name: 'contractOptions',
  initialState,
  reducers: {
    setContractOptions: (state: ContractOptionsSchema, action: PayloadAction<Partial<ContractInfo>>) => {
      state.contractOptions = { ...state.contractOptions, ...action.payload };
    },
    resetContractOptions: (state: ContractOptionsSchema) => {
      state.contractOptions = { ...contractOptionsState, accountType: state.contractOptions.accountType };
    },
    resetContractOptionsAfterRentChange: (state: ContractOptionsSchema) => {
      state.contractOptions = {
        ...contractOptionsState,
        accountType: state.contractOptions.accountType,
        startDate: state.contractOptions.startDate,
      };
    },
  },
});

export const { actions: contractOptionsActions, reducer: contractOptionsReducer } = contractOptionsSlice;
