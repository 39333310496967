import { api } from 'app/config/apiConfig';
import { SizeCode } from '../model/types';

export const updateSizeCodeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateSizeCode: builder.mutation<SizeCode, Partial<SizeCode>>({
      query: (sizeCode) => ({
        url: `/size-codes/${sizeCode.sizeCodeId}`,
        method: 'PUT',
        body: sizeCode,
      }),
      invalidatesTags: ['Size codes', 'Available sizeCodes'],
    }),
  }),
});

export const { useUpdateSizeCodeMutation } = updateSizeCodeApi;
