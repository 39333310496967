import React, { FC, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { InvoiceCard } from 'entities/Invoice';
import { useGetUserInvoicesQuery } from 'entities/User';
import { InvoicesModal } from './InvoicesModal';

interface PersonalInvoicesProps {
  userId: string | undefined;
}

export const PersonalInvoices: FC<PersonalInvoicesProps> = memo((props) => {
  const { userId } = props;

  const { t } = useAppTranslation('profile');

  const [openedAccountsModal, setOpenedAccountsModal] = useState(false);

  const { data } = useGetUserInvoicesQuery({ userId, limit: 5 }, { skip: !userId });

  const hasRecords = (data?.content.length ?? 0) > 0;

  const openAccountsModal = useCallback(() => {
    setOpenedAccountsModal(true);
  }, []);

  const closeAccountsModal = useCallback(() => {
    setOpenedAccountsModal(false);
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between mb-7">
        <div className="text-2xl font-semibold">{t('Personal invoices')}</div>
        {hasRecords && (
          <div className="text-lg text-accent cursor-pointer" onClick={openAccountsModal}>
            {t('Show all')}
          </div>
        )}
      </div>
      <div className="space-y-2.5 max-h-[470px] pr-2 overflow-y-auto">
        {hasRecords ? (
          data?.content.map((invoice) => <InvoiceCard key={invoice.invoiceId} invoice={invoice} />)
        ) : (
          <div className="text-xl text-primaryLight">{t('No data')}</div>
        )}
      </div>
      <InvoicesModal userId={userId} isOpened={openedAccountsModal} onCLose={closeAccountsModal} />
    </div>
  );
});
