import React, { FC, memo } from 'react';
import { CreditNotesTable } from './CreditNotesTable';
import { TableFilterContextProvider } from 'features/TableFilter';

export const CreditNotesPage: FC = memo(() => {
  return (
    <TableFilterContextProvider>
      <CreditNotesTable />
    </TableFilterContextProvider>
  );
});
