import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from '../../model/selectors/getGlobalSettings';
import { Currencies } from '../../model/types';

export const useGetCurrencySymbol = (): string => {
  const globalSettings = useAppSelector(getGlobalSettings);

  const currency = globalSettings?.currency;

  const getSymbol = (): string => {
    switch (currency) {
      case Currencies.EUR:
        return '€';
      case Currencies.USD:
        return '$';
      case Currencies.CHF:
        return 'CHF';
      case Currencies.PLN:
        return 'zł';
      default:
        return 'CHF';
    }
  };

  return getSymbol();
};
