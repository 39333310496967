import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { ServerTableActions, ServerTableRowActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { ReactComponent as BoxEditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useTableFilterContext } from 'features/TableFilter';
import { useGetCurrencySymbol } from 'app/appState';
import { InsuranceListFilter } from './InsuranceListFilter';
import { transformInsuranceListFilters } from '../utils/transformInsuranceListFilters';
import {
  Insurance,
  useActivateInsuranceMutation,
  useDeactivateInsuranceMutation,
  useDeleteInsuranceMutation,
  useGetPaginatedInsuranceQuery,
} from 'entities/Insurance';
import { Toggle } from 'shared/ui/Toggle';
import { showNotification } from 'app/providers/NotificationsProvider';
import { WarnTag } from 'shared/ui/WarnTag';
import { DrawerMode, InsuranceFormDrawer, insuranceFormDrawerActions } from 'features/InsuranceFormDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGlobalConfirmModal } from 'app/globalConfirmModal';

export const InsuranceSettingsTable: FC = memo(() => {
  const currencySymbol = useGetCurrencySymbol();
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const [activateInsurance] = useActivateInsuranceMutation();
  const [deactivateInsurance] = useDeactivateInsuranceMutation();
  const [deleteInsurance] = useDeleteInsuranceMutation();

  const { openModal, closeModal } = useGlobalConfirmModal();

  const handleDeleteInsurance = useCallback(
    async (selectedInsurance: Insurance): Promise<void> => {
      try {
        if (selectedInsurance) {
          await deleteInsurance(selectedInsurance.insuranceId).unwrap();
          showNotification('info', t('Success'), t('Insurance setting deleted successfully'));
        }

        closeModal();
      } catch (error) {
        showNotification('error', t('Error'), t('Error when deleting insurance'));
        console.log('error', error);
      }
    },
    [closeModal, deleteInsurance, t],
  );

  const openConfirmModal = useCallback(
    (selectedInsurance: Insurance): void => {
      openModal({
        title: t('Delete insurance'),
        description: t('Are you sure you want to delete this insurance?'),
        onCancel: closeModal,
        onOk: async () => {
          await handleDeleteInsurance(selectedInsurance);
        },
      });
    },
    [closeModal, handleDeleteInsurance, openModal, t],
  );

  const createInsurance = useCallback((): void => {
    dispatch(insuranceFormDrawerActions.setOpenInsuranceForm({ mode: DrawerMode.INSURANCE_CREATE }));
  }, [dispatch]);

  const editInsurance = useCallback(
    (insurance: Insurance): void => {
      dispatch(insuranceFormDrawerActions.setOpenInsuranceForm({ mode: DrawerMode.INSURANCE_EDIT, insurance }));
    },
    [dispatch],
  );

  const toggleInsuranceActivation = useCallback(
    async (insurance: Insurance) => {
      try {
        if (insurance.isActive) {
          await deactivateInsurance(insurance.insuranceId).unwrap();
          showNotification('info', t('Success'), t('Insurance has been successfully deactivated'));
        } else {
          await activateInsurance(insurance.insuranceId).unwrap();
          showNotification('info', t('Success'), t('Insurance has been successfully activated'));
        }
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), 'Error when changing insurance status');
      }
    },
    [activateInsurance, deactivateInsurance, t],
  );

  const columns = useMemo<ColumnsType<Insurance>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        render: (_, record) => {
          return <div className="text-primary font-normal">{record.name}</div>;
        },
      },
      {
        title: t('Cost'),
        key: 'monthRate',
        render: (_, record) => (
          <div className="text-primary">
            {record.dailyRate && (
              <div>
                {record.dailyRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Day')}</span>
              </div>
            )}
            {record.weekRate && (
              <div>
                {record.weekRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Week')}</span>
              </div>
            )}
            {record.monthRate && (
              <div>
                {record.monthRate} {currencySymbol}
                <span className="text-sm text-primaryLight">/{t('Month')}</span>
              </div>
            )}
          </div>
        ),
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        render: (_, record) => {
          return <div className="text-primary font-normal">{record.warehouse?.name}</div>;
        },
      },
      {
        title: t('Status'),
        key: 'isActive',
        render: (_, record) => (
          <div>
            <Toggle
              checked={record.isActive}
              onChange={async () => {
                await toggleInsuranceActivation(record);
              }}
            />
            <div className="text-xs text-primaryLight mt-2">{t(record.isActive ? 'Enabled' : 'Disabled')}</div>
          </div>
        ),
      },
      {
        title: t('Coverage amount'),
        key: 'coverageAmount',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.coverageAmount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Insurers price'),
        key: 'insurerPrice',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => {
          return <div className="text-primary font-normal">{record?.insurerPrice && `${record.insurerPrice} ${currencySymbol}`}</div>;
        },
      },
    ],
    [t, currencySymbol, toggleInsuranceActivation],
  );

  const tableActions: Array<ServerTableActions<Insurance>> = useMemo(
    () => [
      {
        name: t('Add Insurance'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createInsurance,
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [createInsurance, setFiltersOpened, t],
  );

  const rowActions: Array<ServerTableRowActions<Insurance>> = useMemo(
    () => [
      {
        name: 'editInsurance',
        icon: <BoxEditIcon />,
        theme: 'clear',
        description: t('Edit insurance'),
        onClick: editInsurance,
      },
      {
        name: 'deleteInsurance',
        icon: <DeleteIcon />,
        theme: 'clear',
        description: t('Delete insurance'),
        onClick: openConfirmModal,
      },
    ],
    [editInsurance, openConfirmModal, t],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Insurance')}</div>
      <WarnTag className="w-max" textSize="medium">
        {t(
          'Attention! In case of insurance not specified warehouse, this insurance is common for all warehouses! If you need to add insurance to a specific warehouse, please specify this warehouse to the insurance!',
        )}
      </WarnTag>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedInsuranceQuery}
        rowKey="insuranceId"
        search
        tableActionsPosition="prev"
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformInsuranceListFilters(appliedFilters)}
        rowActions={rowActions}
      />
      <InsuranceListFilter />
      <InsuranceFormDrawer />
    </div>
  );
});
