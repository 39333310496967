import { api } from 'app/config/apiConfig';
import { SendOfferParams } from '../model/types';

export const sendOfferApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendOffer: builder.mutation<void, SendOfferParams>({
      query: (data) => ({
        url: '/leads/send-offer',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendOfferMutation } = sendOfferApi;
