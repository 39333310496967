import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SizeGroupFilter, SizeGuideModalSchema } from '../types';

const initialState: SizeGuideModalSchema = {
  isOpened: false,
  selectedSizeGroup: 'All',
};

const sizeGuideModalSlice = createSlice({
  name: 'sizeGuideModal',
  initialState,
  reducers: {
    setOpenModal: (state: SizeGuideModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeSizeGroup: (state: SizeGuideModalSchema, action: PayloadAction<SizeGroupFilter>) => {
      state.selectedSizeGroup = action.payload;
    },
  },
});

export const { actions: sizeGuideModalActions, reducer: sizeGuideModalReducer } = sizeGuideModalSlice;
