import classNames from 'classnames';
import React, { FC } from 'react';
import { ReactComponent as DefaultLogo } from 'shared/assets/icons/BussLogo.svg';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from '../model/selectors/getGlobalSettings';

interface AppLogoProps {
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const AppLogo: FC<AppLogoProps> = (props) => {
  const { className } = props;

  const globalSettings = useAppSelector(getGlobalSettings);

  const logoClass = classNames('w-[77px] desktop:w-[120px]', className);

  if (!globalSettings?.logoUrl) {
    return <DefaultLogo className={logoClass} height="100%" />;
  }

  return <img className={logoClass} src={globalSettings.logoUrl} height="100%" alt="Logo" />;
};
