import { InvoiceFrequencyType } from 'entities/Invoice';
import { TableFilterType } from 'features/TableFilter';

interface BoxListFilters {
  priceFrom?: number;
  priceTo?: number;
  squareFrom?: number;
  squareTo?: number;
  priceRateType?: InvoiceFrequencyType;
  floorId?: string;
}

export const transformBoxesListFilters = ({
  priceFrom,
  priceTo,
  priceType,
  squareFrom,
  squareTo,
  floorId,
}: TableFilterType): BoxListFilters => {
  return {
    priceFrom: priceFrom?.value || undefined,
    priceTo: priceTo?.value || undefined,
    squareFrom: squareFrom?.value || undefined,
    squareTo: squareTo?.value || undefined,
    priceRateType: priceType?.value || undefined,
    floorId: floorId?.value || undefined,
  };
};
