import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import i18n from 'app/config/i18Config/i18n';
import { Divider, Form } from 'antd';
import { RadioButtonGroup } from 'shared/ui/RadioButtonGroup';
import { ContentLine } from 'shared/ui/ContentLine';
import { Box } from 'entities/Box';
import { useContractOptions } from 'entities/Contract';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { DatePicker } from 'shared/ui/DatePicker';
import { Checkbox } from 'shared/ui/Checkbox';
import { Select } from 'shared/ui/Select';
import { InfoTag } from 'shared/ui/InfoTag';
import { useGetCurrencySymbol } from 'app/appState';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { User, UserAccountType } from 'entities/User';
import { PaymentFrequencySetting } from 'entities/RentOption';
import { PriceDetailsTable } from 'features/PriceDetailsTable';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { AppLanguage } from 'app/config/i18Config/types';
import { WarehouseForBooking } from 'entities/Warehouse';

interface ContractDetailsProps {
  selectedWarehouse: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  userInfo: Nullable<User>;
  userAccountType: UserAccountType | undefined;
}

export const ContractDetails: FC<ContractDetailsProps> = memo((props) => {
  const { selectedWarehouse, boxInfo, userAccountType, userInfo } = props;

  const { t } = useAppTranslation('booking');

  const currentLanguage = i18n.language as AppLanguage;

  const currencySymbol = useGetCurrencySymbol();

  const {
    contractOptions,
    insuranceOptions,
    availableRentOptions,
    changeRentOption,
    handleEndDateChange,
    disabledEndDate,
    handleInsuranceChange,
    disabledStartDate,
    handleEntirePaymentChange,
    handleStartDateChange,
    firstAvailiableEndDate,
    firstAvailiableStartDate,
  } = useContractOptions({
    userAccountType,
    user: userInfo,
    selectedWarehouse,
    box: boxInfo,
  });

  const contractDurationOptions = useMemo<SelectOption[]>(() => {
    return availableRentOptions.map((rentOption) => ({
      label: getLocalizedString(rentOption.label),
      value: rentOption.rentOptionId,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRentOptions, currentLanguage]);

  const changeContractDuration = useCallback(
    (rentOptionId: string) => {
      const selectedRentOption = availableRentOptions.find((rentOption) => rentOption.rentOptionId === rentOptionId);

      changeRentOption(selectedRentOption || null);
    },
    [availableRentOptions, changeRentOption],
  );

  return (
    <div>
      <div className="flex flex-col-reverse desktop:flex-row desktop:space-x-6">
        <div className="flex-1">
          <div className="font-semibold text-base mb-1">{t('Contract duration')}</div>
          <div className="text-sm text-primaryLight mb-3">
            <sup>*</sup>
            {t(
              'It is a permanent contract with a minimum contract period. After the minimum contract period has expired, the contract is automatically renewed monthly until it is terminated with a notice period of 14 days.',
            )}
          </div>
          <div className="mt-4">
            <RadioButtonGroup
              options={contractDurationOptions}
              direction="vertical"
              withBorder
              onChange={changeContractDuration}
              value={contractOptions?.rentOption?.rentOptionId}
              labelPosition="post"
            />
          </div>
          {contractOptions?.rentOption?.description ? (
            <div className="text-sm text-primaryLight mt-4">{getLocalizedString(contractOptions.rentOption.description)}</div>
          ) : null}
        </div>
        <div className="mb-6 desktop:flex-1 desktop:p-6 desktop:mb-0">
          <PriceDetailsTable boxInfo={boxInfo} contractInfo={contractOptions} />
        </div>
      </div>

      <Divider />

      {contractOptions?.rentOption?.paymentFrequencySetting === PaymentFrequencySetting.ALL && (
        <div>
          <InfoTag>{t('Discount information for entire period!')}</InfoTag>
          <Checkbox
            name="payForEntirePeriod"
            className="mt-4"
            checked={contractOptions.payForEntirePeriod}
            onChange={handleEntirePaymentChange}
          >
            {t('Pay for the entire period')}
          </Checkbox>

          <Divider />
        </div>
      )}

      <ContentLine title={t('Move-in date')}>
        <DatePicker
          className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
          format={CLIENT_DATE_FORMAT}
          onChange={handleStartDateChange}
          placeholder={t(CLIENT_DATE_FORMAT)}
          inputReadOnly
          requiredMark
          disabledDate={disabledStartDate}
          value={contractOptions.startDate}
          defaultPickerValue={firstAvailiableStartDate}
        />
      </ContentLine>

      <Divider />

      {contractOptions.invoiceFrequencyType === InvoiceFrequencyType.DAY && (
        <div>
          <ContentLine title={t('Move-out date')}>
            <DatePicker
              format={CLIENT_DATE_FORMAT}
              className="py-[8px] px-[16px] h-[56px] w-full max-w-[280px] desktop:h-[56px]"
              onChange={handleEndDateChange}
              placeholder={t(CLIENT_DATE_FORMAT)}
              disabled={!contractOptions.startDate}
              inputReadOnly
              requiredMark
              disabledDate={disabledEndDate}
              showToday={false}
              value={contractOptions.endDate}
              defaultPickerValue={firstAvailiableEndDate}
            />
          </ContentLine>

          <Divider />
        </div>
      )}

      <ContentLine
        title={
          <div className="flex flex-col">
            <span className="font-semibold">{t('Coverage amount')}</span>
          </div>
        }
        description={t('Storage insurance is a requirement of self storage')}
      >
        <Form.Item name="insuranceId">
          {!insuranceOptions?.length ? (
            <InfoTag>{t('No insurance provided!')}</InfoTag>
          ) : (
            <div>
              <Select
                className="!h-[56px] w-full max-w-[280px]"
                placeholder={t('Select')}
                onChange={handleInsuranceChange}
                options={insuranceOptions}
                value={contractOptions.insuranceId}
                bordered
                requiredMark
              />
              {contractOptions.insuranceId && (
                <div className="text-sm mt-2 pl-1 font-semibold">
                  {t(`{{rate}} {{currencySymbol}}/${contractOptions.invoiceFrequencyType}`, {
                    rate: contractOptions.insuranceRate,
                    currencySymbol,
                  })}
                </div>
              )}
            </div>
          )}
        </Form.Item>
      </ContentLine>
    </div>
  );
});
