import { api } from 'app/config/apiConfig';
import { Invoice } from '../model/types';

export const getInvoiceByInvoiceIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceById: build.query<Invoice, string | undefined>({
      query: (invoiceId) => ({
        url: `/billing-documents/invoices/${invoiceId}`,
      }),
    }),
  }),
});

export const { useGetInvoiceByIdQuery } = getInvoiceByInvoiceIdApi;
