import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { TableFilter, useTableFilterContext } from 'features/TableFilter';
import { WarehouseSelect } from './WarehouseSelect';
import { InsuranceListFilterKeys } from '../../model/types';

export const InsuranceListFilter: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);

  const { filters, changeFilters, clearCurrentFilters } = useTableFilterContext<InsuranceListFilterKeys>();

  const selectWarehouse = useCallback(
    (value: string, label: string) => {
      changeFilters({ key: InsuranceListFilterKeys.selectedWarehouseId, label, value, title: t('Warehouse') });
    },
    [changeFilters, t],
  );

  return (
    <TableFilter>
      <div className="space-y-2">
        <WarehouseSelect value={filters.selectedWarehouseId?.value} onChange={selectWarehouse} onClear={clearCurrentFilters} />
      </div>
    </TableFilter>
  );
});
