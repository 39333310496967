import { api } from 'app/config/apiConfig';

export const resumeContractAutochargesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resumeAutocharges: builder.mutation<void, string>({
      query: (contractId) => ({
        url: `/contracts/${contractId}/autocharges/resume`,
        method: 'POST',
      }),
      invalidatesTags: ['Contracts', 'Contract details', 'Invoices'],
    }),
  }),
});

export const { useResumeAutochargesMutation } = resumeContractAutochargesApi;
