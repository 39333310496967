import React, { FC, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { BulletsTable } from 'shared/ui/BulletsTable';
import { Box } from 'entities/Box';
import { ContractInfo } from 'entities/Contract';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { PriceDetailsTable } from 'features/PriceDetailsTable';

interface OrderSummaryStackProps {
  contractInfo: ContractInfo;
  boxInfo: Nullable<Box>;
}

export const OrderSummaryStack: FC<OrderSummaryStackProps> = (props) => {
  const { contractInfo, boxInfo } = props;
  const { t } = useAppTranslation('booking');

  const rentInfoBullets = useMemo(() => {
    const rentInfoLabels = [
      { label: t('Account type'), value: t(contractInfo?.accountType || '-') },
      { label: t('Move-in date'), value: getClientDateFormat(contractInfo?.startDate) || '-' },
    ];

    if (contractInfo.invoiceFrequencyType === InvoiceFrequencyType.DAY) {
      return [...rentInfoLabels, { label: t('Move-out date'), value: getClientDateFormat(contractInfo?.endDate) }];
    }

    return rentInfoLabels;
  }, [contractInfo, t]);

  return (
    <div className="flex flex-col space-y-6">
      <BulletsTable heading={t('Rent information')} rows={rentInfoBullets} />
      <PriceDetailsTable boxInfo={boxInfo} contractInfo={contractInfo} />
    </div>
  );
};
