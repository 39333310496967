import { DefaultOptionType } from 'antd/es/select';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { Select } from 'shared/ui/Select';
import { BoxesListFilterKeys } from '../../model/types';

interface PriceTypeSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const PriceTypeSelect: FC<PriceTypeSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('boxes');

  const priceTypeOptions = useMemo<Array<{ label: string; value: InvoiceFrequencyType }>>(() => {
    return [
      { label: t('Day'), value: InvoiceFrequencyType.DAY },
      { label: t('Week'), value: InvoiceFrequencyType.WEEK },
      { label: t('Month'), value: InvoiceFrequencyType.MONTH },
    ];
  }, [t]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(BoxesListFilterKeys.priceType);
  }, [onClear]);

  return (
    <Select
      containerClassName="basis-1/3"
      className="w-full"
      label={t('Price type')}
      placeholder={t('Select')}
      bordered
      options={priceTypeOptions}
      value={value}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
