import React, { FC, ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { Form } from 'antd';
import { NotifyLeaveDateParams, useNotifyLeaveDateMutation, useRemoveNotificationLeaveDateMutation } from 'entities/Contract';
import { DatePicker } from 'shared/ui/DatePicker';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getGlobalSettings } from 'app/appState';
import { InvoiceFrequencyType } from 'entities/Invoice';
import dayjs, { Dayjs } from 'dayjs';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { useAdminRole } from 'entities/User';

interface NotifyLeaveDateProps {
  contractId: string;
  initialEndDate: Dayjs;
  invoiceFrequencyType: InvoiceFrequencyType;
  action: ReactElement;
  noticeLeaveDate?: Dayjs;
}

export const NotifyLeaveDate: FC<NotifyLeaveDateProps> = memo((props) => {
  const { contractId, initialEndDate, invoiceFrequencyType, action, noticeLeaveDate } = props;

  const [form] = Form.useForm();

  const { t } = useAppTranslation(['contracts', 'common']);

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { isDesktop } = useScreenBreakpoints();

  const isAdmin = useAdminRole();

  const [notifyLeaveDate, { isLoading }] = useNotifyLeaveDateMutation();
  const [removeNotificationLeaveDate] = useRemoveNotificationLeaveDateMutation();

  const globalSettings = useAppSelector(getGlobalSettings);
  const notifyLeaveDays = globalSettings?.notifyLeaveDays;

  useEffect(() => {
    if (noticeLeaveDate) {
      form.setFieldValue('noticeLeaveDate', dayjs(noticeLeaveDate));
    }

    return () => {
      form.resetFields();
    };
  }, [form, noticeLeaveDate]);

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const handleRemoveNotificationLeaveDate = useCallback(async (): Promise<void> => {
    try {
      if (contractId && noticeLeaveDate) {
        await removeNotificationLeaveDate(contractId).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Notification has been successfully sent', { ns: 'common' }));
        closeModal();
      }
    } catch (error: CustomAny) {
      showNotification('error', t('Error', { ns: 'common' }), t('Error when sending notification', { ns: 'common' }));
    }
  }, [contractId, noticeLeaveDate, removeNotificationLeaveDate, t, closeModal]);

  const handleNotifyLeaveDate = useCallback(
    async (data: NotifyLeaveDateParams): Promise<void> => {
      try {
        if (contractId) {
          await notifyLeaveDate({
            contractId,
            noticeLeaveDate: getServerDateFormat(data.noticeLeaveDate),
          }).unwrap();
          showNotification('info', t('Success', { ns: 'common' }), t('Notification has been successfully sent', { ns: 'common' }));
          closeModal();
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error', { ns: 'common' }), t('Error when sending notification', { ns: 'common' }));
      }
    },
    [contractId, notifyLeaveDate, t, closeModal],
  );

  const getAvailableLeaveDate = useCallback((): Nullable<Dayjs> => {
    const today = dayjs().startOf('day');

    if (isAdmin) {
      return today;
    }

    if (notifyLeaveDays) {
      const noticeLeaveDateLimit = notifyLeaveDays[invoiceFrequencyType];
      const minRentalEndDate = dayjs(initialEndDate);
      const minNotifyDate = today.add(noticeLeaveDateLimit, 'day');

      const availableLeaveDate = minRentalEndDate < minNotifyDate ? minNotifyDate : minRentalEndDate.subtract(1, 'day');

      return availableLeaveDate;
    }

    return null;
  }, [initialEndDate, invoiceFrequencyType, isAdmin, notifyLeaveDays]);

  const disableDate = useCallback(
    (currentDate: Dayjs): boolean => {
      const availableLeaveDate = getAvailableLeaveDate();

      if (availableLeaveDate) {
        return currentDate.isBefore(availableLeaveDate, 'day');
      }

      return false;
    },
    [getAvailableLeaveDate],
  );

  const datePickerValue = useMemo(() => {
    const availableLeaveDate = getAvailableLeaveDate();

    if (availableLeaveDate) {
      const today = dayjs().startOf('day');
      return today.isAfter(availableLeaveDate, 'day') ? today : availableLeaveDate;
    }

    return dayjs();
  }, [getAvailableLeaveDate]);

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal width={isDesktop ? 400 : 350} isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleNotifyLeaveDate} className="flex flex-col">
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Notify about end of Rent')}</div>
          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to notify about end of rent? You must enter leave date!')}
          </div>

          <div className="my-5">
            <Form.Item
              className="w-full"
              name="noticeLeaveDate"
              rules={[
                {
                  required: true,
                  message: t('Please enter leave date!'),
                },
              ]}
            >
              <DatePicker
                className="h-[52px] w-full"
                format={CLIENT_DATE_FORMAT}
                placeholder={t('Enter leave date')}
                inputReadOnly
                disabledDate={disableDate}
                defaultPickerValue={datePickerValue}
              />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            {!noticeLeaveDate ? (
              <Button theme="secondary" onClick={closeModal}>
                {t('Cancel', { ns: 'common' })}
              </Button>
            ) : (
              <Button theme="danger" onClick={handleRemoveNotificationLeaveDate}>
                {t('Remove', { ns: 'common' })}
              </Button>
            )}
            <Button type="submit" isLoading={isLoading}>
              {t('Notify', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
