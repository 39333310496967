import { api } from 'app/config/apiConfig';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';
import { appActions } from 'app/appState';
import { userActions } from '../model/slice/userSlice';
import { User } from '../model/types';
import { AUTH_ME_PATH } from '../utils/constants';

export const getMeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => AUTH_ME_PATH,
      keepUnusedDataFor: 10,
      providesTags: ['Users'],
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          dispatch(appActions.startLoading());
          const { data: userData } = await queryFulfilled;

          dispatch(userActions.setUserData(userData));
        } catch (error) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when receiving user data', { ns: 'common' }));
        } finally {
          dispatch(appActions.stopLoading());
        }
      },
    }),
  }),
});

export const { useGetMeQuery } = getMeApi;
