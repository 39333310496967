import React, { FC, memo } from 'react';
import { useAuthorizedState } from 'entities/User';
import { LogInButton } from './LogInButton';
import { UserMenu } from './UserMenu';

interface HeaderAuthActionProps {
  userEmail: string | undefined;
}

export const HeaderAuthAction: FC<HeaderAuthActionProps> = memo((props) => {
  const { userEmail } = props;

  const isAuthorized = useAuthorizedState();

  return <>{!isAuthorized ? <LogInButton /> : <UserMenu userEmail={userEmail} />}</>;
});
