import { api } from 'app/config/apiConfig';
import { Lead, LeadData } from '../model/types';

export const createLeadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createLead: builder.mutation<Lead, LeadData>({
      query: (data) => ({
        url: '/leads',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Leads'],
    }),
  }),
});

export const { useCreateLeadMutation } = createLeadApi;
