import React, { FC, memo, PropsWithChildren } from 'react';
import { Tag } from 'shared/ui/Tag';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';

type TagTextSize = 'small' | 'medium' | 'large';

interface ErrorTagProps extends PropsWithChildren {
  className?: string;
  textSize?: TagTextSize;
  onClick?: () => void;
}

export const ErrorTag: FC<ErrorTagProps> = memo((props) => {
  const { children, className, textSize, onClick } = props;

  return (
    <Tag className={className} icon={<InfoIcon className="stroke-error shrink-0" />} theme="error" textSize={textSize} onClick={onClick}>
      {children}
    </Tag>
  );
});
