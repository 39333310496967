import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { HistoryAction } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  userName?: string;
}

export const getPaginatedHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedHistory: builder.query<Pagination<HistoryAction>, Params>({
      query: (params) => ({
        url: '/history',
        params,
      }),
    }),
  }),
});

export const { useGetPaginatedHistoryQuery } = getPaginatedHistoryApi;
