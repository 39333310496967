import { api } from 'app/config/apiConfig';
import { Lead } from '../model/types';

export const updateLeadApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateLead: builder.mutation<Lead, Lead>({
      query: (lead: Lead) => ({
        url: `/leads/${lead.leadId}`,
        method: 'PUT',
        body: lead,
      }),
      invalidatesTags: ['Leads'],
    }),
  }),
});

export const { useUpdateLeadMutation } = updateLeadApi;
