import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { ReactComponent as BoxEditIcon } from 'shared/assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/DeleteIcon.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/PlusIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { Table } from 'shared/ui/Table';
import { LateChargeSetting, useDeleteLateChargeSettingMutation, useGetLateChargeSettingsQuery } from 'entities/LateChargeSetting';
import { TableActions, TableRowActions } from 'shared/ui/Table/types';
import { DrawerMode, LateChargeSettingFormDrawer, lateChargeSettingFormDrawerActions } from 'features/LateChargeSettingFormDrawer';
import { globalConfirmModalActions } from 'app/globalConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { WarnTag } from 'shared/ui/WarnTag';

export const LateChargeSettingsPage: FC = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation('common');

  const { data: lateChargeSettings } = useGetLateChargeSettingsQuery();

  const [deleteLateChargeSetting] = useDeleteLateChargeSettingMutation();

  const closeConfirmModal = useCallback((): void => {
    dispatch(globalConfirmModalActions.setGlobalConfirmModalData(null));
  }, [dispatch]);

  const handleDeleteLateChargeSetting = useCallback(
    async (selectedLateChargeSetting: LateChargeSetting): Promise<void> => {
      try {
        if (selectedLateChargeSetting) {
          await deleteLateChargeSetting(selectedLateChargeSetting.lateChargeSettingId).unwrap();
          showNotification('info', t('Success'), t('Late charge setting deleted successfully'));
        }

        closeConfirmModal();
      } catch (error) {
        showNotification('error', t('Error'), t('Error when deleting late charge setting'));
        console.log('error', error);
      }
    },
    [closeConfirmModal, deleteLateChargeSetting, t],
  );

  const openConfirmModal = useCallback(
    (selectedLateChargeSetting: LateChargeSetting): void => {
      dispatch(
        globalConfirmModalActions.setGlobalConfirmModalData({
          title: t('Delete late charge setting'),
          description: t('Are you sure you want to delete this late charge setting?'),
          onCancel: closeConfirmModal,
          onOk: async () => {
            await handleDeleteLateChargeSetting(selectedLateChargeSetting);
          },
        }),
      );
    },
    [closeConfirmModal, dispatch, handleDeleteLateChargeSetting, t],
  );

  const createLateChargeSetting = useCallback((): void => {
    dispatch(lateChargeSettingFormDrawerActions.setOpenLateChargeSettingForm({ mode: DrawerMode.LATE_CHARGE_SETTING_CREATE }));
  }, [dispatch]);

  const editLateChargeSetting = useCallback(
    (lateChargeSetting: LateChargeSetting): void => {
      dispatch(
        lateChargeSettingFormDrawerActions.setOpenLateChargeSettingForm({
          mode: DrawerMode.LATE_CHARGE_SETTING_EDIT,
          lateChargeSetting,
        }),
      );
    },
    [dispatch],
  );

  const rowActions: Array<TableRowActions<LateChargeSetting>> = useMemo(
    () => [
      {
        name: 'editLateChargeSetting',
        icon: <BoxEditIcon />,
        theme: 'clear',
        onClick: editLateChargeSetting,
      },
      {
        name: 'deleteLateChargeSetting',
        icon: <DeleteIcon />,
        theme: 'clear',
        onClick: (selectedLateChargeSetting: LateChargeSetting) => {
          openConfirmModal(selectedLateChargeSetting);
        },
      },
    ],
    [editLateChargeSetting, openConfirmModal],
  );

  const columns = useMemo<ColumnsType<LateChargeSetting>>(
    () => [
      {
        title: t('Name'),
        key: 'name',
        align: 'center',
        render: (_, record) => <div>{record.name}</div>,
      },
      {
        title: t('Overdue days limit'),
        key: 'overdueDaysLimit',
        align: 'center',
        render: (_, record) => <div className="font-bold">{record.overdueDaysLimit}</div>,
      },
      {
        title: t('Min amount'),
        key: 'minAmount',
        align: 'center',
        render: (_, record) => <div>{record.minAmount}</div>,
      },
      {
        title: t('Amount'),
        key: 'amount',
        align: 'center',
        render: (_, record) => <div>{record.amount}</div>,
      },
      {
        title: t('Strict email reminder'),
        key: 'isStrictEmailReminder',
        align: 'center',
        render: (_, record) => record.isStrictEmailReminder && <div>{t('Setting with strict email reminder')}</div>,
      },
      // {
      //   title: t('Notification days'),
      //   key: 'notificationDays',
      //   align: 'center',
      //   width: '10%',
      //   render: (_, record) => <div>{record.notificationDays.join(' , ')}</div>,
      // },
      // {
      //   title: t('Description'),
      //   key: 'description',
      //   align: 'center',
      //   render: (_, record) => <div>{getLocalizedString(record?.description)}</div>,
      // },
    ],
    [t],
  );

  const tableActions: Array<TableActions<LateChargeSetting>> = useMemo(
    () => [
      {
        name: t('Add late charge setting'),
        icon: <PlusIcon />,
        iconPosition: 'prev',
        onClick: createLateChargeSetting,
      },
    ],
    [createLateChargeSetting, t],
  );

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Late charge settings')}</div>
      <WarnTag className="w-max" textSize="medium">
        {t('Attention! The number of settings depends on the number of penalty invoices!')}
      </WarnTag>
      <Table
        data={lateChargeSettings}
        columns={columns}
        rowKey="lateChargeSettingId"
        size="middle"
        tableActions={tableActions}
        rowActions={rowActions}
        tableActionsPosition="prev"
      />
      <LateChargeSettingFormDrawer />
    </div>
  );
});
