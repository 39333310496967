import React, { FC, memo, useLayoutEffect } from 'react';
import { useGetRentOptionsQuery } from 'entities/RentOption';
import { Slider } from 'features/Slider';
import { useScrollToElement } from 'shared/utils/hooks/useScrollToElement';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';
import { PromotionSlide } from './PromotionSlide';

interface PromotionContentProps {
  selectedWarehouseId: string | undefined;
}

export const PromotionContent: FC<PromotionContentProps> = memo((props) => {
  const { selectedWarehouseId } = props;

  const { scrollToElement, scrollRef } = useScrollToElement('center', 500);

  const { isDesktop } = useScreenBreakpoints();

  const { data } = useGetRentOptionsQuery({ warehouseId: selectedWarehouseId }, { skip: !selectedWarehouseId });

  const promoItemsToRender = data?.filter(({ showAsPromotion }) => showAsPromotion);

  useLayoutEffect(() => {
    scrollToElement();
  }, [scrollToElement]);

  return (
    <div ref={scrollRef}>
      <Slider
        slidesPerView={isDesktop ? 2 : 1}
        spaceBetween={20}
        autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
        slides={promoItemsToRender?.map(({ rentOptionId, label, description, accountTypeSetting }) => (
          <PromotionSlide key={rentOptionId} label={label} description={description} accountTypeSetting={accountTypeSetting} />
        ))}
      />
    </div>
  );
});
