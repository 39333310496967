import { Lead } from 'entities/Lead';

export interface LeadFormModalSchema {
  lead?: Nullable<Lead>;
  mode: Nullable<LeadFormModalMode>;
}

export enum LeadFormModalMode {
  LEAD_EDIT = 'lead_edit',
  LEAD_CREATE = 'lead_create',
}
