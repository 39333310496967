import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import React, { FC, PropsWithChildren, useCallback } from 'react';
import { ReactComponent as GoogleIcon } from 'shared/assets/icons/GoogleIcon.svg';
import { Button } from 'shared/ui/Button';
import { useGoogleAuthMutation } from '../api/googleAuthApi';
import { getBoxInfo, getWarehouseInfo } from 'pages/BookingPage';
import { LeadData, useCreateLeadMutation } from 'entities/Lead';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface GoogleAuthButtonProps extends PropsWithChildren {
  onSuccessAuth: () => void;
  onFailAuth: (error: string) => void;
  className?: string;
}

export const GoogleAuthButton: FC<GoogleAuthButtonProps> = (props) => {
  const { children, className, onSuccessAuth, onFailAuth } = props;
  const { t } = useAppTranslation('booking');

  const [googleLogin] = useGoogleAuthMutation();
  const [createLead] = useCreateLeadMutation();

  const warehouseData = useAppSelector(getWarehouseInfo);
  const boxData = useAppSelector(getBoxInfo);

  const handleGoogleAuthSuccess = useCallback(
    async (response: TokenResponse) => {
      const { access_token: googleToken } = response;

      try {
        const response = await googleLogin(googleToken).unwrap();

        if (response?.email) {
          const leadData: LeadData = {
            email: response.email,
            square: boxData?.sizeCode?.square,
            warehouseId: warehouseData?.warehouseId,
            note: t('Lead signed in with Google'),
            isThirdPartyAuthorized: true,
          };

          await createLead(leadData);
        }

        onSuccessAuth();
      } catch (error: CustomAny) {
        onFailAuth(error?.data?.message);
      }
    },
    [boxData?.sizeCode?.square, createLead, googleLogin, onFailAuth, onSuccessAuth, t, warehouseData?.warehouseId],
  );

  const handleGoogleAuthFailure = useCallback(
    (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      onFailAuth(error.error_description as string);
    },
    [onFailAuth],
  );

  const signInWithGoogle = useGoogleLogin({
    onSuccess: handleGoogleAuthSuccess,
    onError: handleGoogleAuthFailure,
  });

  return (
    <Button
      containerClassName={className}
      theme="secondary"
      size="large"
      iconPosition="prev"
      icon={<GoogleIcon />}
      onClick={signInWithGoogle}
    >
      {children}
    </Button>
  );
};
