import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import i18n from 'i18next';
import { AppLanguage } from 'app/config/i18Config/types';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

const configLocale = (): void => {
  const localesMap: Record<AppLanguage, string> = {
    [AppLanguage.en]: 'en',
    [AppLanguage.de]: 'de',
    [AppLanguage.pl]: 'pl',
  };

  i18n.on('languageChanged', (currentLanguage: AppLanguage) => {
    dayjs.locale(localesMap[currentLanguage]);
  });
};

configLocale();

export default dayjs;
