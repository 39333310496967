import React from 'react';
import { I18nextProvider } from 'react-i18next';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { App } from 'app/App';
import { StoreProvider } from 'app/providers/StoreProvider';
import i18n from 'app/config/i18Config/i18n';
import { ThemeProvider } from 'app/providers/ThemeProvider';
import { GOOGLE_CLIENT_ID } from 'app/config/googleAuthConfig/constants';
import { AntdConfigProvider } from 'app/providers/AntdConfigProvider';
import { NotificationsProvider } from 'app/providers/NotificationsProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <ThemeProvider>
        <I18nextProvider i18n={i18n}>
          <AntdConfigProvider>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <NotificationsProvider>
                <App />
              </NotificationsProvider>
            </GoogleOAuthProvider>
          </AntdConfigProvider>
        </I18nextProvider>
      </ThemeProvider>
    </StoreProvider>
  </React.StrictMode>,
);
