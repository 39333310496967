import React, { FC, ReactElement, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useRefundDepositMutation } from 'entities/Deposit';

interface RefundDepositProps {
  depositId: string;
  action: ReactElement;
}

export const RefundDeposit: FC<RefundDepositProps> = memo((props) => {
  const { depositId, action } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { t } = useAppTranslation(['contracts', 'common']);

  const [refundDeposit, { isLoading }] = useRefundDepositMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const handleRefundDeposit = useCallback(async (): Promise<void> => {
    try {
      if (depositId) {
        await refundDeposit(depositId).unwrap();
        showNotification('info', t('Success'), t('Successfully send deposit refund request', { ns: 'common' }));
      }
    } catch (error) {
      showNotification('error', t('Error'), t('Error while deposit refund request'));
      console.log('error', error);
    } finally {
      closeModal();
    }
  }, [refundDeposit, closeModal, depositId, t]);
  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <ConfirmModal
        isOpened={isOpenedModal}
        title={t('Close contract')}
        description={t('Are you sure you want to refund this Deposit?')}
        isLoading={isLoading}
        onOk={handleRefundDeposit}
        onCancel={closeModal}
      />
    </>
  );
});
