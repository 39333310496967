import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LateChargeSettingFormDrawerSchema, DrawerMode } from '../types';
import { LateChargeSetting } from 'entities/LateChargeSetting';

const initialState: LateChargeSettingFormDrawerSchema = {
  lateChargeSetting: null,
  mode: null,
};

const lateChargeSettingFormDrawerSlice = createSlice({
  name: 'lateChargeSettingFormDrawer',
  initialState,
  reducers: {
    setOpenLateChargeSettingForm: (
      state: LateChargeSettingFormDrawerSchema,
      action: PayloadAction<{ mode: Nullable<DrawerMode>; lateChargeSetting?: Nullable<LateChargeSetting> }>,
    ) => {
      state.mode = action.payload.mode;
      state.lateChargeSetting = action.payload.lateChargeSetting;
    },
  },
});

export const { actions: lateChargeSettingFormDrawerActions, reducer: lateChargeSettingFormDrawerReducer } =
  lateChargeSettingFormDrawerSlice;
