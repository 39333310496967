import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { SizeFilter } from '../model/types';

interface FilterOptionProps {
  label: string;
  description?: string;
  value: SizeFilter;
  isActive: boolean;
  onChange: (value: SizeFilter) => void;
  isDisabled?: boolean;
}

export const FilterOption: FC<FilterOptionProps> = memo((props) => {
  const { label, description, value, isActive, onChange, isDisabled } = props;

  const { t } = useAppTranslation('booking');

  const switchOptionTransition = 'ease-linear duration-200';

  const handleClick = (): void => {
    value && !isDisabled && onChange(value);
  };

  return (
    <div
      className={classNames(
        'px-3 py-2 rounded-lg shrink-0',
        {
          'bg-accent cursor-default': isActive,
          'bg-secondary cursor-pointer': !isActive,
          'opacity-50 cursor-not-allowed': isDisabled,
        },
        switchOptionTransition,
      )}
      onClick={handleClick}
    >
      <div
        className={classNames(
          'font-semibold text-sm',
          { 'text-secondaryLight': isActive, 'text-primary': !isActive },
          switchOptionTransition,
        )}
      >
        {label}
      </div>
      <div
        className={classNames(
          'font-normal text-xs',
          { 'text-secondaryLight': isActive, 'text-primaryLight': !isActive },
          switchOptionTransition,
        )}
      >
        {description || t('No available boxes')}
      </div>
    </div>
  );
});
