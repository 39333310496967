import { Progress } from 'antd';
import React, { FC, memo } from 'react';
import s from './index.module.scss';

interface ProgressBarProps {
  percent: number;
  description?: string;
  revertSuccessColor?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = memo((props) => {
  const { percent, description, revertSuccessColor = false } = props;

  const getStrokeColor = (): string => {
    if (percent < 30) {
      return revertSuccessColor ? 'var(--success)' : 'var(--error)';
    }

    if (percent < 80) {
      return 'var(--warn)';
    }

    return revertSuccessColor ? 'var(--error)' : 'var(--success)';
  };

  return (
    <div>
      <Progress className={s.progressBar} percent={percent} strokeColor={getStrokeColor()} trailColor="var(--secondaryLight)" />
      <div className="font-normal text-sm text-primaryLight">{description}</div>
    </div>
  );
});
