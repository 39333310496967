import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContractDetailDrawerSchema } from '../types';

const initialState: ContractDetailDrawerSchema = {
  contractId: null,
  paymentInvoiceId: null,
};

const contractDetailDrawerSlice = createSlice({
  name: 'contractDetailDrawer',
  initialState,
  reducers: {
    setOpenContractDetails: (state: ContractDetailDrawerSchema, action: PayloadAction<Nullable<string>>) => {
      state.contractId = action.payload;
    },
    setPaymentInvoiceId: (state: ContractDetailDrawerSchema, action: PayloadAction<Nullable<string>>) => {
      state.paymentInvoiceId = action.payload;
    },
  },
});

export const { actions: contractDetailsDrawerActions, reducer: contractDetailsDrawerReducer } = contractDetailDrawerSlice;
