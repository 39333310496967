import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useChangeUserAddressMutation, ChangeUserAddressParams } from 'entities/User';

interface HookArgs {
  userId: string | undefined;
  updatedData: Nullable<ChangeUserAddressParams>;
  onSuccess?: () => void;
}

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  handleChangeUserAddress: () => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useChangeUserAddress = ({ userId, updatedData, onSuccess }: HookArgs): HookApi => {
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [changeUserAddress, { isLoading }] = useChangeUserAddressMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const handleChangeUserAddress = useCallback(async (): Promise<void> => {
    if (userId && updatedData) {
      try {
        await changeUserAddress({
          userId,
          params: updatedData,
        }).unwrap();
        showNotification('info', t('Success'), t('User has been successfully updated'));
        onSuccess?.();
      } catch (error) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when updating user'));
      } finally {
        closeConfirmModal();
      }
    }
  }, [userId, changeUserAddress, updatedData, t, onSuccess, closeConfirmModal]);

  return {
    openConfirmModal,
    closeConfirmModal,
    handleChangeUserAddress,
    isOpenedConfirm,
    isLoading,
  };
};
