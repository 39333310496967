import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerMode, NoteDrawerSchema } from '../types';
import { Note } from 'entities/Note';

const initialState: NoteDrawerSchema = {
  note: null,
  mode: null,
};

const noteDrawerSlice = createSlice({
  name: 'noteDrawer',
  initialState,
  reducers: {
    setOpenNoteDrawer: (state: NoteDrawerSchema, action: PayloadAction<{ mode: Nullable<DrawerMode>; note?: Nullable<Note> }>) => {
      state.mode = action.payload.mode;
      state.note = action.payload.note;
    },
  },
});

export const { actions: noteDrawerActions, reducer: noteDrawerReducer } = noteDrawerSlice;
