import { useAppTranslation } from 'app/config/i18Config/hooks';
import React, { FC, ReactElement, memo, useCallback } from 'react';
import { Modal } from 'shared/ui/Modal';
import { ReactComponent as GratitudeImage } from 'shared/assets/images/GratitudeImage.svg';
import { ReactComponent as ReservationGratitudeImage } from 'shared/assets/images/ReservationGratitudeImage.svg';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getGratitudeModalState } from '../model/selectors/getOpenState';
import { Button } from 'shared/ui/Button';
import { gratitudeModalActions } from '../model/slice/gratitudeModalSlice';
import { GratitudeModalType } from '../model/types';

export const GratitudeModal: FC = memo(() => {
  const { isOpened, description, buttonId, type, onSuccess } = useAppSelector(getGratitudeModalState);

  const dispatch = useAppDispatch();

  const { t } = useAppTranslation('common');

  const renderImage = useCallback((): Nullable<ReactElement> => {
    switch (type) {
      case GratitudeModalType.RESERVATION:
        return <ReservationGratitudeImage className="[&>path#like]:fill-accent fill-successLight" />;
      default:
        return <GratitudeImage className="[&>path#cloud]:fill-accentLight" />;
    }
  }, [type]);

  const closeModal = useCallback((): void => {
    onSuccess?.();

    dispatch(gratitudeModalActions.closeModal());
  }, [dispatch, onSuccess]);

  return (
    <Modal
      className="max-w-[400px]"
      isOpen={isOpened}
      closeIcon={false}
      footer={
        <Button id={buttonId} onClick={closeModal}>
          {t('OK')}
        </Button>
      }
    >
      <div className="flex flex-col text-center gap-4 items-center">
        {renderImage()}
        <div className="font-semibold text-xl">{t('Thank You!')}</div>
        <div className="text-primaryLight font-normal mb-2">{description}</div>
      </div>
    </Modal>
  );
});
