import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { WarehouseWithOccupancyStatistic } from 'entities/Warehouse';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { ReactComponent as KeyIcon } from 'shared/assets/icons/KeyIcon.svg';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { ReactComponent as SettingsIcon } from 'shared/assets/icons/SettingsIcon.svg';
import { ProgressBar } from 'shared/ui/ProgressBar';
import { ContextMenu } from 'shared/ui/ContextMenu';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { warehouseStatisticCardActions } from '../model/slice/warehouseStatisticCardSlice';

interface WarehouseStatisticCardProps {
  warehouseData: WarehouseWithOccupancyStatistic;
}

export const WarehouseStatisticCard: FC<WarehouseStatisticCardProps> = memo((props) => {
  const {
    warehouseData: { warehouseId, name, address, occupiedBoxesAmount, totalBoxesAmount, occupiedSquare, totalSquare },
  } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getEncodedString } = useQueryString();

  const { t } = useAppTranslation('warehouses');

  const boxesPercent = roundNumber((occupiedBoxesAmount / totalBoxesAmount) * 100, 0);
  const squarePercent = roundNumber((occupiedSquare / totalSquare) * 100, 0);

  const contextMenuItems = useMemo(
    () => [
      { key: '1', label: <Link to="#">{t('Settings')}</Link>, icon: <SettingsIcon /> },
      { key: '2', label: <Link to="#">{t('Reports')}</Link>, icon: <DocumentIcon /> },
    ],
    [t],
  );

  const goToBoxesPage = useCallback(() => {
    const encodedWarehouse = getEncodedString({ selectedWarehouseId: { value: warehouseId, label: name, title: t('Warehouse') } });

    navigate(`${AppRoutes.BOXES}?selectedWarehouseId=${encodedWarehouse.selectedWarehouseId}`);
  }, [getEncodedString, name, navigate, t, warehouseId]);

  const openDoorsModal = useCallback((): void => {
    dispatch(warehouseStatisticCardActions.setDoorsModalOpened(warehouseId));
  }, [dispatch, warehouseId]);

  return (
    <div className="flex flex-col w-[350px] bg-secondaryLight py-10 px-6 rounded-lg">
      <div className="bg-white py-7 px-6 rounded-lg space-y-4">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-lg h-12">
            {name} {address}
          </div>
          <ContextMenu items={contextMenuItems} />
        </div>
        <ProgressBar
          percent={boxesPercent}
          description={t('{{occupiedBoxesAmount}} boxes out of {{totalBoxesAmount}} are occupied', {
            occupiedBoxesAmount,
            totalBoxesAmount,
          })}
        />
        <ProgressBar
          percent={squarePercent}
          description={t('{{occupiedSquare}} m² out of {{totalSquare}} m² are occupied', {
            occupiedSquare,
            totalSquare,
          })}
        />
        <div className="space-y-2.5">
          <Button theme="secondary" onClick={goToBoxesPage}>
            {t('Boxes')}
          </Button>
          <Button icon={<KeyIcon className="stroke-white" />} iconPosition="prev" onClick={openDoorsModal}>
            {t('Doors')}
          </Button>
        </div>
      </div>
    </div>
  );
});
