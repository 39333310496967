import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lead } from 'entities/Lead';
import { LeadNoteDrawerSchema } from '../types';

const initialState: LeadNoteDrawerSchema = {
  lead: null,
};

const leadNoteDrawerSlice = createSlice({
  name: 'leadNoteDrawer',
  initialState,
  reducers: {
    setOpenLeadNoteDrawer: (state: LeadNoteDrawerSchema, action: PayloadAction<Nullable<Lead>>) => {
      state.lead = action.payload;
    },
  },
});

export const { actions: leadNoteDrawerActions, reducer: leadNoteDrawerReducer } = leadNoteDrawerSlice;
