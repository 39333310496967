import { BoxForListing } from 'entities/Box';

export interface BoxFormDrawerSchema {
  mode: Nullable<DrawerMode>;
  box?: Nullable<BoxForListing>;
}

export interface BoxFormFields {
  warehouseId: string;
  name: string;
  sizeCodeId: string;
  height: number;
  length: number;
  width: number;
  dailyRate?: number;
  weekRate?: number;
  monthRate?: number;
  description?: string;
  isFowOwnNeeds?: boolean;
  maxOpeningTimeMS?: Nullable<number>;
  timeToOpenLockMS?: Nullable<number>;
  lockAddress?: string;
  floorId?: string;
}

export enum DrawerMode {
  BOX_EDIT = 'box_edit',
  BOX_CREATE = 'box_create',
}
