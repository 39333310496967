import { InvoiceStatus } from 'entities/Invoice';
import { TableFilterType } from 'features/TableFilter';
import { getFilterServerFormatDate } from 'shared/utils/helpers/getDateFormat';

interface InvoiceListFilters {
  warehouseId?: string;
  isActive?: boolean;
  personalInvoices?: boolean;
  invoiceAmount?: number;
  invoicingDateFrom?: string;
  invoicingDateTo?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  status?: InvoiceStatus;
}

export const transformInvoicesListFilters = ({
  selectedWarehouseId,
  invoiceType,
  isActive,
  invoiceAmount,
  invoicingDateFrom,
  invoicingDateTo,
  creationDateFrom,
  creationDateTo,
  status,
}: TableFilterType): InvoiceListFilters => {
  return {
    warehouseId: selectedWarehouseId?.value || undefined,
    isActive: isActive?.value === undefined ? undefined : isActive?.value === 'active',
    personalInvoices: invoiceType?.value === undefined ? undefined : invoiceType?.value === 'personal',
    invoiceAmount: invoiceAmount?.value || undefined,
    invoicingDateFrom: getFilterServerFormatDate(invoicingDateFrom?.value),
    invoicingDateTo: getFilterServerFormatDate(invoicingDateTo?.value),
    creationDateFrom: getFilterServerFormatDate(creationDateFrom?.value),
    creationDateTo: getFilterServerFormatDate(creationDateTo?.value),
    status: status?.value || undefined,
  };
};
