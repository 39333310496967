export const stripeErrorCodes = {
  insufficient_funds: 'The card has insufficient funds to complete the purchase',
  incorrect_cvc: 'The card`s security code is incorrect. Check the card`s security code or use a different card',
  currency_not_supported: 'The card does not support the specified currency',
  invalid_amount: 'The payment amount is invalid, or exceeds the amount that`s allowed',
  invalid_cvc: 'The CVC number is incorrect',
  withdrawal_count_limit_exceeded: 'The customer has exceeded the balance or credit limit available on their card',
  invalid_pin: 'The PIN entered is incorrect',
  incorrect_pin: 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader',
  duplicate_transaction: 'A transaction with identical amount and credit card information was submitted very recently',
  card_velocity_exceeded: 'The customer has exceeded the balance, credit limit, or transaction amount limit available on their card',
  card_not_supported: 'The card does not support this type of purchase',

  default: 'Oops, something went wrong. Please, check your data and try again later',
};
