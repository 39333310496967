import { api } from 'app/config/apiConfig';
import { PaymentResponse } from 'entities/Payment';
import { BookAndPayData } from '../model/types';

export const bookAndPayApi = api.injectEndpoints({
  endpoints: (builder) => ({
    bookAndPay: builder.mutation<PaymentResponse, BookAndPayData>({
      query: (data) => ({
        url: '/boxes/book-and-pay',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Available boxes', 'Available sizeCodes', 'Warehouses for booking'],
    }),
  }),
});

export const { useBookAndPayMutation } = bookAndPayApi;
