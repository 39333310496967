import { Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import { FC, memo } from 'react';
import s from './index.module.scss';

interface RadioButtonGroupProps {
  options: Array<{ value: string; label: string }>;
  value?: string;
  direction?: 'horizontal' | 'vertical';
  withBorder?: boolean;
  onChange: (value: CustomAny) => void;
  buttonClassName?: string;
  labelPosition?: 'prev' | 'post';
  isDisabled?: boolean;
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = memo((props) => {
  const {
    options,
    value,
    direction = 'horizontal',
    withBorder = false,
    onChange,
    buttonClassName,
    labelPosition = 'post',
    isDisabled = false,
  } = props;

  const handleChange = (e: RadioChangeEvent): void => {
    onChange?.(e.target.value);
  };

  const directionClass: Record<'horizontal' | 'vertical', string> = {
    horizontal: 'flex items-center gap-1.5 flex-wrap desktop:flex-nowrap desktop:gap-4',
    vertical: 'flex flex-col gap-4',
  };

  const getLabelClass = (isActive: boolean): string => {
    return classNames({ 'font-normal': !isActive });
  };

  const getRadioButtonClass = (isActive: boolean): string => {
    return classNames(
      'rounded-lg p-2 cursor-pointer ease-linear duration-200',
      {
        border: withBorder,
        'border-accent': isActive && withBorder,
        'border-secondaryAccent': !isActive && withBorder,
      },
      buttonClassName,
    );
  };

  return (
    <Radio.Group className={directionClass[direction]} onChange={handleChange} disabled={isDisabled} value={value}>
      {options.map((option) => {
        const isActive = option.value === value;

        return (
          <label key={option.value} className={getRadioButtonClass(isActive)}>
            <Radio value={option.value} className={classNames(s.radio, { 'flex-row-reverse': labelPosition === 'prev' })}>
              <span className={getLabelClass(isActive)}>{option.label}</span>
            </Radio>
          </label>
        );
      })}
    </Radio.Group>
  );
});
