import { api } from 'app/config/apiConfig';
import { CreditInvoiceParams } from '../model/types';
import { CreditNote } from 'entities/CreditNote';

export const creditInvoiceApi = api.injectEndpoints({
  endpoints: (build) => ({
    creditInvoice: build.mutation<CreditNote, CreditInvoiceParams>({
      query: (data) => ({
        url: `/billing-documents/invoices/${data.invoiceId}/credit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoices'],
    }),
  }),
});

export const { useCreditInvoiceMutation } = creditInvoiceApi;
