import { api } from 'app/config/apiConfig';
import { CreditNote } from 'entities/CreditNote';
import { CreditAndRefundPaymentParams } from '../model/types';

export const creditAndRefundPaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    creditAndRefundPayment: build.mutation<CreditNote, CreditAndRefundPaymentParams>({
      query: (data) => ({
        url: `/billing-documents/payments/${data.paymentId}/refund`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Invoices'],
    }),
  }),
});

export const { useCreditAndRefundPaymentMutation } = creditAndRefundPaymentApi;
