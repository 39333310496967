import { api } from 'app/config/apiConfig';
import { ChangeUserDetailsParams } from '../model/types';
import { USERS_PATH } from '../utils/constants';

export const changeUserDetailsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeUserDetails: builder.mutation<void, { userId: string; params: ChangeUserDetailsParams }>({
      query: ({ userId, params }) => ({
        url: `${USERS_PATH}/${userId}/details`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useChangeUserDetailsMutation } = changeUserDetailsApi;
