import React, { FC, MouseEvent } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { copyText } from 'shared/utils/helpers/copyText';
import { Tooltip } from 'shared/ui/Tooltip';

interface CopyTooltipProps {
  children: Nullable<string | undefined>;
  className?: string;
}

export const CopyTooltip: FC<CopyTooltipProps> = (props) => {
  const { children, className } = props;

  // const { t } = useAppTranslation('common'); TODO мешает рендеру компонент внутри карты

  const copy = async (e: MouseEvent<HTMLDivElement>): Promise<void> => {
    e.stopPropagation();
    await copyText(children);
  };

  return (
    <div onClick={copy}>
      <Tooltip className={className} title="Click to copy" placement="top">
        {children}
      </Tooltip>
    </div>
  );
};
