import { useCallback, useState } from 'react';

interface HookApi {
  isOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useCreateFranchiseModal = (): HookApi => {
  const [isOpened, setOpened] = useState(false);

  const openModal = useCallback(() => {
    setOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  return {
    isOpened,
    openModal,
    closeModal,
  };
};
