import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { FailScreen } from 'shared/ui/FailScreen';
import { Modal } from 'shared/ui/Modal';
import { stripeErrorCodes } from 'entities/Payment';

interface PaymentFailedModalProps {
  errorCode: Nullable<string>;
  closeModal: () => void;
}

export const PaymentFailedModal: FC<PaymentFailedModalProps> = (props) => {
  const { errorCode, closeModal } = props;

  const { t } = useAppTranslation('payment');

  const errorDescription = t(stripeErrorCodes[errorCode as keyof typeof stripeErrorCodes] || stripeErrorCodes.default);

  const navigate = useNavigate();

  const goToHomePage = (): void => {
    navigate(AppRoutes.HOME);
  };

  return (
    <Modal isOpen={Boolean(errorCode)} onClose={closeModal} className="w-full">
      <FailScreen
        title={t('Payment failed!')}
        description={errorDescription}
        mode="modal"
        actions={
          <div className="flex flex-col gap-3">
            <Button size="large" onClick={closeModal}>
              {t('Try again')}
            </Button>
            <Button theme="secondary" onClick={goToHomePage} size="large" containerClassName="w-full">
              {t('Home')}
            </Button>
          </div>
        }
      />
    </Modal>
  );
};
