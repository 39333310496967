import { api } from 'app/config/apiConfig';
import { RentOptionDocument } from '../model/types';
import { UserAccountType } from 'entities/User';

interface Params {
  warehouseId?: string;
  accountType?: UserAccountType;
}

export const getRentOptionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRentOptions: build.query<RentOptionDocument[], Params>({
      query: (params) => ({
        url: '/contracts/rent/rent-options',
        params,
      }),
    }),
  }),
});

export const { useGetRentOptionsQuery } = getRentOptionsApi;
