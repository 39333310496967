import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import React, { FC, memo, useCallback } from 'react';
import { SizeCodeForBooking } from 'entities/SizeCode';
import { getSelectedFilter } from '../model/selectors/getSelectedFilter';
import { boxSizeFilterActions } from '../model/slice/boxSizeFilterSlice';
import { SizeFilter } from '../model/types';
import { useSizeGroupsFilters } from '../utils/hooks/useSizeGroupsFilters';
import { FilterOption } from './FilterOption';

interface BoxSizeFilterProps {
  sizeCodes: SizeCodeForBooking[] | undefined;
}

export const BoxSizeFilter: FC<BoxSizeFilterProps> = memo((props) => {
  const { sizeCodes } = props;

  const dispatch = useAppDispatch();
  const { t } = useAppTranslation('booking');

  const selectedFilter = useAppSelector(getSelectedFilter);

  const sizesFilters = useSizeGroupsFilters(sizeCodes);

  const changeFilter = useCallback(
    (newFilter: SizeFilter): void => {
      dispatch(boxSizeFilterActions.changeFilter(newFilter));
    },
    [dispatch],
  );

  return (
    <div>
      <div className="mb-2">
        {Boolean(sizesFilters.length) && <div className="text-sm text-primaryLight">{t('Filter units by size:')}</div>}
      </div>
      <div className="flex space-x-2 overflow-x-auto pb-3">
        {sizesFilters.map(({ label, description, value, isDisabled }) => (
          <FilterOption
            key={value}
            label={label}
            description={description}
            value={value}
            isActive={selectedFilter === value}
            isDisabled={isDisabled}
            onChange={changeFilter}
          />
        ))}
      </div>
    </div>
  );
});
