import { useEffect, useState } from 'react';
import { GeolocationCoords } from 'app/types/common';

const defaultCoordinates = {
  lat: Number(process.env.REACT_APP_MAP_DEFAULT_LAT) || 47.42440333994072,
  lng: Number(process.env.REACT_APP_MAP_DEFAULT_LNG) || 9.37727223341191,
};

export const useGeolocation = (): GeolocationCoords => {
  const [coordinates, setCoordinates] = useState<GeolocationCoords>(defaultCoordinates);

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      setCoordinates({ lat: latitude, lng: longitude });
    });
  }, []);

  return coordinates;
};
