import { UserRoles, CreateUserParams } from 'entities/User';
import { StepData, stepsMapper } from 'features/Stepper';

export enum CreateUserSteps {
  ACCOUNT_DETAILS = 'Account Details',
  CHOSE_WAREHOUSE = 'Choose Warehouse',
}

export const createUserSteps = stepsMapper([...Object.values(CreateUserSteps)]);

export interface CreateUserModalSchema {
  isOpened: boolean;
  steps: StepData[];
  selectedRole: Nullable<UserRoles>;
  userData: Nullable<CreateUserParams>;
  selectedWarehouses: string[];
}

export interface WarehouseListFilters {
  franchiseId: string;
}

export enum WarehouseListFilterKeys {
  franchiseId = 'franchiseId',
}
