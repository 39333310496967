import axios from 'axios';
import { getAccessToken } from 'entities/User';
import { Column, ExportEntity } from 'features/ExportModal/model/types';
import { getExportEnityUrl } from './getExportEntityUrl';

export const downloadExcelFile = async (entity: Nullable<ExportEntity>, filters: AnyObject, columns: Column[]): Promise<void> => {
  try {
    if (!entity) {
      return;
    }
    const token = getAccessToken();

    const exportEntityUrl = getExportEnityUrl(entity);

    const response = await axios.post(
      exportEntityUrl,
      { filters, columns },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      },
    );

    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'data.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};
