import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { SizeCodeForBooking } from 'entities/SizeCode';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';
import { Modal } from 'shared/ui/Modal';
import { FilterOption, useSizeGroupsFilters } from 'features/BoxSizeFilter';
import { Slider } from 'features/Slider';
import { getOpenState } from '../model/selectors/getOpenState';
import { getSelectedSizeGroup } from '../model/selectors/getSelectedSizeGroup';
import { sizeGuideModalActions } from '../model/slice/sizeGuideModalSlice';
import { SizeGroupFilter } from '../model/types';
import { SizeGuideSlide } from './SizeGuideSlide';

interface SizeGuideModalProps {
  sizeCodes: SizeCodeForBooking[] | undefined;
}

export const SizeGuideModal: FC<SizeGuideModalProps> = memo((props) => {
  const { sizeCodes } = props;

  const { t } = useAppTranslation('booking');
  const dispatch = useAppDispatch();

  const sizesFilters = useSizeGroupsFilters(sizeCodes);
  const selectedSizeGroup = useAppSelector(getSelectedSizeGroup);
  const isOpened = useAppSelector(getOpenState);

  const filterBySizeCode = useMemo<SizeCodeForBooking[] | undefined>(() => {
    return sizeCodes?.filter((sizeCode) => (selectedSizeGroup === 'All' ? true : selectedSizeGroup === sizeCode.sizeGroup));
  }, [selectedSizeGroup, sizeCodes]);

  const changeFilter = useCallback(
    (newFilter: SizeGroupFilter) => {
      dispatch(sizeGuideModalActions.changeSizeGroup(newFilter));
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    dispatch(sizeGuideModalActions.setOpenModal(false));
  }, [dispatch]);

  return (
    <Modal isOpen={isOpened} closeIcon={false} width={925} onClose={closeModal}>
      <div className="flex justify-between mb-3">
        <div className="font-semibold text-3xl">{t('Size guide')}</div>
        <div
          className="w-[30px] h-[30px] rounded-full bg-secondary flex justify-center items-center cursor-pointer desktop:w-[44px] desktop:h-[44px]"
          onClick={closeModal}
        >
          <CloseIcon className="[&>path]:stroke-primary" width={32} height={32} />
        </div>
      </div>
      <div className="flex space-x-2 mb-4 overflow-x-auto pb-3">
        {sizesFilters.map(({ label, description, value }) => (
          <FilterOption
            key={value}
            label={label}
            description={description}
            value={value}
            isActive={selectedSizeGroup === value}
            onChange={changeFilter}
          />
        ))}
      </div>
      <Slider
        slides={filterBySizeCode?.map((sizeCode) => (
          <SizeGuideSlide key={sizeCode.sizeCodeId} sizeCode={sizeCode} />
        ))}
      />
    </Modal>
  );
});
