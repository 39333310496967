import React, { FC, memo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { LockState } from '../model/types';

interface LockStatusMarkerProps {
  lockStatus: LockState | undefined;
  isExternalBox?: boolean;
}

export const LockStatusMarker: FC<LockStatusMarkerProps> = memo((props) => {
  const { lockStatus, isExternalBox } = props;

  const { t } = useAppTranslation('contracts');

  if (isExternalBox) {
    return null;
  }

  return (
    <div
      className={`flex gap-1 items-center font-normal text-sm ${
        lockStatus === LockState.OPENED ? 'text-success' : lockStatus === LockState.CLOSED ? 'text-error' : 'text-primaryLight'
      }`}
    >
      <div
        className={`w-2 h-2 rounded-full ${
          lockStatus === LockState.OPENED ? 'bg-success' : lockStatus === LockState.CLOSED ? 'bg-error' : 'bg-primaryLight'
        }`}
      />
      {lockStatus === LockState.OPENED ? t('Open') : lockStatus === LockState.CLOSED ? t('Close') : t('N/A')}
    </div>
  );
});
