import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { InvoiceFrequencyType, RentState } from 'entities/Invoice';
import { BoxForListing } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  extendFloor?: boolean;
  warehouseId?: string | string[];
  sizeCodeId?: string | string[];
  isActive?: boolean;
  isForOwnNeeds?: boolean;
  hasNotes?: boolean;
  priceFactor?: number;
  priceFrom?: number;
  priceTo?: number;
  priceRateType?: InvoiceFrequencyType;
  rentType?: InvoiceFrequencyType;
  rentState?: RentState;
  floorId?: string | null;
  lockAddress?: string | null;
  orderBy?: string;
}

export const getPaginatedBoxListingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedBoxListing: build.query<Pagination<BoxForListing>, Params | void>({
      query: (args) => ({
        url: '/boxes/listing',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Boxes'],
    }),
  }),
});

export const { useGetPaginatedBoxListingQuery } = getPaginatedBoxListingApi;
