import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useCreateUserMutation, CreateUserParams } from 'entities/User';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  createUser: (userData: CreateUserParams) => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useCreateUser = (onSuccess?: () => void): HookApi => {
  const { t } = useAppTranslation('common');

  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const [createUserService, { isLoading }] = useCreateUserMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const createUser = useCallback(
    async (userData: CreateUserParams): Promise<void> => {
      try {
        await createUserService({
          ...userData,
          identificationNumber: userData.identificationNumber || null,
          dateOfBirth: userData.dateOfBirth ? getServerDateFormat(userData.dateOfBirth) : undefined,
        }).unwrap();

        showNotification('info', t('Success'), t('User has been successfully created'));
        onSuccess?.();
      } catch (error) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when creating user'));
      } finally {
        closeConfirmModal();
      }
    },
    [closeConfirmModal, createUserService, onSuccess, t],
  );

  return {
    openConfirmModal,
    closeConfirmModal,
    createUser,
    isLoading,
    isOpenedConfirm,
  };
};
