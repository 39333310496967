import React, { FC, memo, ReactElement, ReactNode, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ContractInfo } from 'entities/Contract';
import { Link } from 'react-router-dom';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { getGlobalSettings } from 'app/appState';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Checkbox } from 'shared/ui/Checkbox';
import { useReserveBox } from '../utils/hooks/useReserveBox';

interface ReserveBoxProps {
  warehouseId: string | undefined;
  boxId: string | undefined;
  userId: string | undefined;
  contractOptions: ContractInfo;
  action: ReactElement;
  onSuccess?: () => void;
  needConfirmBookingDocs?: boolean;
}

export const ReserveBox: FC<ReserveBoxProps> = memo((props) => {
  const { contractOptions, boxId, warehouseId, userId, action, onSuccess, needConfirmBookingDocs } = props;

  const { t } = useAppTranslation(['booking', 'common']);

  const [termsAgreement, setTermsAgreement] = useState<boolean>(false);

  const globalSettings = useAppSelector(getGlobalSettings);

  const { handleReserveBox, closeConfirmModal, openConfirmModal, isOpenedConfirm, isLoading } = useReserveBox({
    contractOptions,
    boxId,
    warehouseId,
    userId,
    onSuccess,
  });

  const toggleTermsAgreement = useCallback((): void => {
    setTermsAgreement((prevState) => !prevState);
  }, []);

  const renderDescription = (): ReactNode => {
    return (
      <>
        <div className="mb-5">{t('Are you sure you want to reserve box?')}</div>
        <Checkbox name="termsAgreement" requiredMark checked={termsAgreement} onChange={toggleTermsAgreement}>
          {t('I have read the following additions to the contract and accept them in full:')}
        </Checkbox>
        <div className="flex flex-col mt-2 mb-6">
          {globalSettings?.bookingDocuments.map(({ docName, link }) => {
            return (
              <Link key={link} className="text-accent text-sm font-normal" to={link} target="_blank">
                {t(docName)}
              </Link>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      {React.cloneElement(action, { onClick: openConfirmModal })}
      <ConfirmModal
        isOpened={isOpenedConfirm}
        title={t('Reserve box')}
        description={needConfirmBookingDocs ? renderDescription() : t('Are you sure you want to reserve box?')}
        isLoading={isLoading}
        isConfirmDisabled={needConfirmBookingDocs ? !termsAgreement : false}
        onOk={handleReserveBox}
        onCancel={closeConfirmModal}
      />
    </>
  );
});
