import { InvoiceFrequencyType } from 'entities/Invoice';
import { UserAccountType } from 'entities/User';
import { ContractInfo } from '../types';

export const contractOptionsState: ContractInfo = {
  accountType: UserAccountType.PERSONAL,
  contractDuration: 1,
  invoiceFrequencyType: InvoiceFrequencyType.MONTH,
  startDate: undefined,
  endDate: undefined,
  insuranceId: null,
  insuranceAmountWithVat: 0,
  insuranceAmountWithoutVat: 0,
  insuranceVatRateAmount: 0,
  insuranceRate: 0,
  coverageAmount: 0,
  payForEntirePeriod: false,
  totalToPay: 0,
  contractPeriodsUnitPrices: [],
  vatRatePercent: 0,
  vatRateAmount: 0,
  discountPercent: 0,
  discountAmount: 0,
  insuranceVatRatePercent: 0,
  rentOption: null,
};
