import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';

interface WarningPingProps {
  type?: 'info' | 'error' | 'warning';
  className?: string;
}

export const WarningPing: FC<WarningPingProps> = memo((props) => {
  const { type = 'info', className } = props;

  const commonClasses = 'relative inline-flex rounded-full h-6 w-6';

  const pingClasses = classNames('animate-ping absolute inline-flex h-full w-full rounded-full right-[25px] top-1.5 ', {
    'bg-accent opacity-50': type === 'info',
    'bg-error opacity-50': type === 'error',
    'bg-warn opacity-50': type === 'warning',
  });

  const infoIconStrokeClass = classNames({
    'stroke-accent': type === 'info',
    'stroke-error': type === 'error',
    'stroke-warn': type === 'warning',
  });

  return (
    <div className={classNames('flex relative', className)}>
      <InfoIcon width={30} height="100%" className={infoIconStrokeClass} />
      <span className={commonClasses}>
        <span className={pingClasses}></span>
      </span>
    </div>
  );
});
