import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Input } from 'shared/ui/Input';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/ArrowRight.svg';
import { InputNumber } from 'shared/ui/InputNumber';

interface CommonProps {
  label?: string;
  placeholder?: [string, string];
  size?: SizeType;
  containerClassName?: string;
}

interface StringRange extends CommonProps {
  type?: 'string';
  values?: [string, string];
  onChangeFrom: (value: string) => void;
  onChangeTo: (value: string) => void;
}

interface NumberRange extends CommonProps {
  type?: 'number';
  values?: [number, number];
  onChangeFrom: (value: Nullable<number>) => void;
  onChangeTo: (value: Nullable<number>) => void;
}

type RangeInputProps = StringRange | NumberRange;

export const RangeInput: FC<RangeInputProps> = memo((props) => {
  const { onChangeFrom, onChangeTo, placeholder, size = 'middle', type = 'string', label, containerClassName, values } = props;

  return (
    <div className={classNames('flex flex-col', containerClassName)}>
      {label && <div className="mb-2">{label}</div>}
      <div className="flex items-center border rounded-lg border-secondaryAccent">
        {type === 'string' ? (
          <Input
            placeholder={placeholder?.[0]}
            value={values?.[0] as string}
            onChange={onChangeFrom as (value: string) => void}
            size={size}
          />
        ) : (
          <InputNumber
            className="pr-4"
            placeholder={placeholder?.[0]}
            value={values?.[0] as number}
            onChange={onChangeFrom as (value: Nullable<number>) => void}
            size={size}
          />
        )}
        <ArrowRight className="shrink-0 [&>path]:stroke-primaryLight" />
        {type === 'string' ? (
          <Input
            placeholder={placeholder?.[1]}
            value={values?.[1] as string}
            onChange={onChangeTo as (value: string) => void}
            size={size}
          />
        ) : (
          <InputNumber
            className="pl-4"
            placeholder={placeholder?.[1]}
            value={values?.[1] as number}
            onChange={onChangeTo as (value: Nullable<number>) => void}
            size={size}
          />
        )}
      </div>
    </div>
  );
});
