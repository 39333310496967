import React, { FC, memo, PropsWithChildren } from 'react';
import { Tag } from 'shared/ui/Tag';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';

type TagTextSize = 'small' | 'medium' | 'large';

interface WarnTagProps extends PropsWithChildren {
  className?: string;
  textSize?: TagTextSize;
  disableIcon?: boolean;
}

export const WarnTag: FC<WarnTagProps> = memo((props) => {
  const { children, className, textSize, disableIcon = false } = props;

  return (
    <Tag className={className} icon={!disableIcon ? <InfoIcon className="stroke-warn" /> : null} theme="warn" textSize={textSize}>
      {children}
    </Tag>
  );
});
