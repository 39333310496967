import { Dayjs } from 'dayjs';
import { StepData, stepsMapper } from 'features/Stepper';
import { City } from 'entities/City';
import { Franchise } from 'entities/Franchise';

export enum CreateWarehouseSteps {
  SELECT_CITY = 'Select a City',
  SELECT_FRANCHISE = 'Select a Franchise',
  CREATE_WAREHOUSE = 'Create Warehouse',
}

export const createWarehouseSteps = stepsMapper([...Object.values(CreateWarehouseSteps)]);

export interface CreateWarehouseModalSchema {
  isOpened: boolean;
  steps: StepData[];
  selectedCity: Nullable<City>;
  selectedFranchise: Nullable<Franchise>;
  formData: Partial<CreateWarehouseFormFields>;
}

export interface CreateWarehouseFormFields {
  name: string;
  address: string;
  zip: string;
  phone: string;
  email: string;
  longitude: string;
  latitude: string;
  launchDate: Dayjs;
  isActive: boolean;
  isComingSoon: boolean;
}
