import { Form } from 'antd';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData, UserRoles } from 'entities/User';
import { Select } from 'shared/ui/Select';
import { createUserModalActions } from '../model/slice/createUserModalSlice';

export const RoleSelect: FC = memo(() => {
  const { t } = useAppTranslation('user');

  const dispatch = useAppDispatch();

  const loggedUserData = useAppSelector(getLoggedUserData);

  const options = useMemo(() => {
    if (!loggedUserData?.role) {
      return [];
    }

    const availableRoles: Record<UserRoles, Array<{ label: string; value: UserRoles }>> = {
      [UserRoles.SUPERUSER]: [
        { label: t('Super user'), value: UserRoles.SUPERUSER },
        { label: t('Manager'), value: UserRoles.MANAGER },
        { label: t('Customer'), value: UserRoles.CUSTOMER },
      ],
      [UserRoles.MANAGER]: [{ label: t('Customer'), value: UserRoles.CUSTOMER }],
      [UserRoles.CUSTOMER]: [],
    };

    return availableRoles[loggedUserData.role];
  }, [loggedUserData?.role, t]);

  const selectRole = useCallback(
    (role: string): void => {
      dispatch(createUserModalActions.setSelectedRole(role as UserRoles));
    },
    [dispatch],
  );

  return (
    <Form.Item label={t('Role')} name="role" rules={[{ required: true, message: t('Please, select role') }]}>
      <Select placeholder={t('Select role')} bordered options={options} onChange={selectRole} />
    </Form.Item>
  );
});
