import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { useGetAvailableBoxesQuery } from 'entities/Box';
import { useGetWarehousesForBookingQuery } from 'entities/Warehouse';
import { bookingPageActions } from '../../model/slice/bookingPageSlice';
import { useCallback, useEffect } from 'react';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { BookingSteps, bookingHeaderActions } from 'widgets/BookingHeader';
import { warehouseMapActions } from 'widgets/SelectWarehouseMap';

export const useSetBookingDataFromQuery = (): void => {
  const dispatch = useAppDispatch();

  const {
    parsedQuery: { warehouseId, boxId, sizeCodeId },
  } = useQueryString();

  const { data: warehouses } = useGetWarehousesForBookingQuery();
  const { data } = useGetAvailableBoxesQuery({ sizeCodeId }, { skip: !sizeCodeId });

  const selectWarehouseFromQuery = useCallback(() => {
    if (warehouseId) {
      const selectedWarehouse = warehouses?.find((warehouse) => warehouse.warehouseId === warehouseId);

      if (selectedWarehouse) {
        dispatch(warehouseMapActions.setSelectedCard(warehouseId));
        dispatch(bookingPageActions.setWarehouseInfo(selectedWarehouse));
        dispatch(bookingHeaderActions.changeActiveStep(BookingSteps.SELECT_BOX));
      }
    }
  }, [dispatch, warehouseId, warehouses]);

  const selectBoxFromQuery = useCallback(() => {
    let selectedBox;

    if (boxId) {
      selectedBox = data?.content.find((box) => box.boxId === boxId);
    }

    if (selectedBox) {
      dispatch(bookingPageActions.setBoxInfo({ selectedBox, selectedSizeCodeId: selectedBox.sizeCode.sizeCodeId }));
    }
  }, [boxId, data?.content, dispatch]);

  useEffect(() => {
    selectWarehouseFromQuery();
    selectBoxFromQuery();
  }, [selectWarehouseFromQuery, selectBoxFromQuery]);
};
