import { Collapse as AntdCollapse } from 'antd';
import classNames from 'classnames';
import React, { FC, memo, ReactElement, ReactNode, useEffect, useState } from 'react';
import { ReactComponent as CollapseArrow } from 'shared/assets/icons/CollapseArrow.svg';
import s from './index.module.scss';
import { CollapsibleType } from 'antd/es/collapse/CollapsePanel';

interface CollapseProps {
  title: string;
  content: ReactNode;
  activeKey: string;
  isOpened: boolean;
  onChange?: (key: string) => void;
  className?: string;
  collapsible?: CollapsibleType;
  Icon?: React.ComponentType<{
    className?: string;
  }>;
}

export const Collapse: FC<CollapseProps> = memo((props) => {
  const { title, content, activeKey, isOpened, className, Icon, onChange, collapsible = 'header' } = props;

  const [localOpened, setLocalOpened] = useState<Nullable<boolean>>(null);

  useEffect(() => {
    setLocalOpened(isOpened);
  }, [isOpened]);

  const handleChange = (key: string | string[]): void => {
    setLocalOpened((prevState) => !prevState); // To reset opened state when props.isOpened = true

    if (Array.isArray(key)) {
      const isActive = key.includes(activeKey);

      isActive && onChange?.(activeKey);
    }
  };

  const CollapseHeading = (): ReactElement => {
    return (
      <div className="flex items-center font-semibold text-lg">
        {Icon && <Icon className="mr-2 stroke-primary" />}
        {title}
      </div>
    );
  };

  return (
    <AntdCollapse
      className={classNames(s.collapse, className)}
      bordered={false}
      activeKey={localOpened ? activeKey : undefined}
      onChange={handleChange}
      collapsible={collapsible}
      expandIcon={(panelProps) => (
        <CollapseArrow
          className={classNames('ease-linear duration-200', {
            'rotate-90 fill-accent': panelProps.isActive,
            'fill-secondaryAccent': !panelProps.isActive,
          })}
        />
      )}
      expandIconPosition="end"
      items={[
        {
          key: activeKey,
          label: <CollapseHeading />,
          children: content,
        },
      ]}
    />
  );
});
