import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { InvoiceInsurance, InvoiceStatus } from '../model/types';

interface Params {
  warehouseId?: string;
  contractId?: string;
  search?: string;
  userId?: string;
  creditedAmount?: number;
  issuedBy?: string | null;
  orderBy?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  status?: InvoiceStatus;
}

export const getPaginatedInvoicesInsuranceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedInvoicesInsurance: build.query<Pagination<InvoiceInsurance>, Params | void>({
      query: (args) => ({
        url: '/billing-documents/invoices/insurance',
        params: args ? { ...args } : undefined,
      }),
    }),
  }),
});

export const { useGetPaginatedInvoicesInsuranceQuery } = getPaginatedInvoicesInsuranceApi;
