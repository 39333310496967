import { api } from 'app/config/apiConfig';

interface BoxOpeningParamsDto {
  boxId: string;
  longitude?: number;
  latitude?: number;
}

export const openBoxApi = api.injectEndpoints({
  endpoints: (builder) => ({
    openBox: builder.mutation<void, BoxOpeningParamsDto>({
      query: (boxOpeningParamsDto) => ({
        url: `/boxes/${boxOpeningParamsDto.boxId}/open`,
        method: 'PUT',
        body: boxOpeningParamsDto,
      }),
      invalidatesTags: ['Boxes'],
    }),
  }),
});

export const { useOpenBoxMutation } = openBoxApi;
