import dayjs, { Dayjs } from 'dayjs';
import { InvoiceFrequencyType } from 'entities/Invoice';

interface Arguments {
  startDate: Nullable<Dayjs>;
  contractDuration: number;
  invoiceFrequencyType: InvoiceFrequencyType;
  endDate?: Nullable<Dayjs>;
}

export const getEndRentDateString = (data: Arguments): Dayjs => {
  const { startDate, endDate, invoiceFrequencyType, contractDuration } = data;

  const start = dayjs(startDate);
  const defaultEndDate = start.add(1, 'day');

  if (invoiceFrequencyType === InvoiceFrequencyType.DAY) {
    const end = start.add(contractDuration, 'day');

    return endDate || end;
  }

  if (invoiceFrequencyType === InvoiceFrequencyType.WEEK) {
    const end = start.add(contractDuration, 'week');

    return end;
  }

  if (invoiceFrequencyType === InvoiceFrequencyType.MONTH) {
    const end = start.add(contractDuration, 'month');

    return end;
  }

  return defaultEndDate;
};
