import { useCallback } from 'react';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { Box } from 'entities/Box';
import { ContractInfo, getEndRentDateString } from 'entities/Contract';
import { useReserveBoxMutation } from 'entities/Reservation';
import {
  CompleteRegisterUserParams,
  getInfoFromToken,
  getLoggedUserData,
  RegisterUserParams,
  useAuthorizedState,
  useCheckUserDataComplete,
  useCompleteRegistrationMutation,
  useRegistrationMutation,
} from 'entities/User';
import { WarehouseForBooking } from 'entities/Warehouse';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { UserDetailsInfo } from '../../model/types';
import i18n from 'app/config/i18Config/i18n';
import { AppLanguage } from 'app/config/i18Config/types';
import { LeadData, useCreateLeadMutation } from 'entities/Lead';
import dayjs from 'dayjs';

interface HookArgs {
  warehouseInfo: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  contractInfo: ContractInfo;
  closeConfirmModal: () => void;
  formData?: Nullable<UserDetailsInfo>;
  onSuccess?: () => void;
}

interface HookApi {
  isReservationLoading: boolean;
  executeReservation: () => Promise<void>;
}

export const useReserveBox = ({ boxInfo, contractInfo, warehouseInfo, closeConfirmModal, formData, onSuccess }: HookArgs): HookApi => {
  const { t } = useAppTranslation('common');

  const loggedUserData = useAppSelector(getLoggedUserData);
  const isAuthorized = useAuthorizedState();
  const isUserDataCompleted = useCheckUserDataComplete();

  const [registration, { isLoading: isRegistrationLoading }] = useRegistrationMutation();
  const [completeRegistration, { isLoading: isCompleteRegistrationLoading }] = useCompleteRegistrationMutation();
  const [reserveBox, { isLoading: isReservationLoading }] = useReserveBoxMutation();
  const [createLead] = useCreateLeadMutation();

  const isLoading = isRegistrationLoading || isCompleteRegistrationLoading || isReservationLoading;

  const handleReserveBox = useCallback(
    async (userId: string | undefined): Promise<void> => {
      if (userId && warehouseInfo && boxInfo && contractInfo.startDate && contractInfo.rentOption) {
        const { boxId } = boxInfo;
        const { warehouseId } = warehouseInfo;
        const { startDate, endDate, invoiceFrequencyType, contractDuration, payForEntirePeriod, insuranceId, rentOption } = contractInfo;

        const endRentDate = getEndRentDateString({ startDate, endDate, contractDuration, invoiceFrequencyType });

        await reserveBox({
          boxId,
          params: {
            userId,
            warehouseId,
            invoiceFrequencyType,
            invoiceFrequency: 1,
            paymentFrequencyPeriod: payForEntirePeriod ? 'entire period' : 'regular',
            startRentDate: getServerDateFormat(startDate),
            endRentDate: getServerDateFormat(endRentDate),
            insuranceId,
            rentOptionId: rentOption.rentOptionId,
          },
        }).unwrap();

        onSuccess?.();
      }
    },
    [boxInfo, contractInfo, onSuccess, reserveBox, warehouseInfo],
  );

  const checkRegistration = useCallback(async (): Promise<void> => {
    if (formData) {
      const {
        firstName,
        lastName,
        email,
        street,
        country,
        city,
        password,
        phone,
        zip,
        house,
        dateOfBirth,
        company,
        identificationNumber,
        apartment,
      } = formData;

      const completeRegistrationData = {
        firstName,
        lastName,
        company,
        phone,
        country,
        dateOfBirth: getServerDateFormat(dateOfBirth),
        street,
        house,
        zip,
        apartment,
        city,
        language: i18n.language as AppLanguage,
        identificationNumber,
        accountType: contractInfo.accountType,
      };

      const registrationData = {
        ...completeRegistrationData,
        email,
        password,
      };

      const today = dayjs().startOf('day');

      const leadData: LeadData = {
        firstName,
        lastName,
        phone,
        email,
        language: i18n.language as AppLanguage,
        warehouseId: warehouseInfo?.warehouseId,
        square: boxInfo?.sizeCode?.square,
        note: t('Lead registration from booking page'),
      };

      try {
        if (!isAuthorized) {
          const authTokens = await registration(registrationData as RegisterUserParams).unwrap();
          const user = getInfoFromToken(authTokens.accessToken);

          if (user) {
            await createLead({
              ...leadData,
              userId: user.userId,
              note: t('Lead completed full registration from booking page'),
              userCreationDate: getServerDateFormat(today),
            }).unwrap();
          }

          await handleReserveBox(user?.userId);
          showNotification('info', t('Success'), t('Box has been successfully reserved'));
        } else if (loggedUserData?.userId && !isUserDataCompleted) {
          await completeRegistration({
            userId: loggedUserData.userId,
            userData: completeRegistrationData as CompleteRegisterUserParams,
          }).unwrap();

          await createLead({
            ...leadData,
            userId: loggedUserData.userId,
            note: t('Lead completed full registration from booking page'),
            userCreationDate: getServerDateFormat(today),
          }).unwrap();

          await handleReserveBox(loggedUserData.userId);
          showNotification('info', t('Success'), t('Box has been successfully reserved'));
        } else {
          await handleReserveBox(loggedUserData?.userId);
          showNotification('info', t('Success'), t('Box has been successfully reserved'));
        }
      } catch (error: CustomAny) {
        await createLead({ ...leadData, isThirdPartyAuthorized: true });

        console.log(error);
        showNotification('error', t('Error'), t('Error when reserving box'));
      } finally {
        closeConfirmModal();
      }
    }
  }, [
    formData,
    contractInfo.accountType,
    warehouseInfo?.warehouseId,
    boxInfo?.sizeCode?.square,
    t,
    isAuthorized,
    loggedUserData?.userId,
    isUserDataCompleted,
    registration,
    handleReserveBox,
    createLead,
    completeRegistration,
    closeConfirmModal,
  ]);

  return { executeReservation: checkRegistration, isReservationLoading: isLoading };
};
