import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import React, { FC, memo, useCallback } from 'react';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/ArrowRight.svg';
import { ReactComponent as InfoIcon } from 'shared/assets/icons/InfoIcon.svg';
import { DatePicker } from 'shared/ui/DatePicker';
import { Popup } from 'shared/ui/Popup';
import { Dayjs } from 'dayjs';
import { CLIENT_DATE_FORMAT } from 'shared/utils/constants';
import { useTheme } from 'app/providers/ThemeProvider';

interface CommonProps {
  label?: string;
  placeholder?: [string, string];
  size?: SizeType;
  containerClassName?: string;
  tooltip?: string;
}

interface RangeDatePickerProps extends CommonProps {
  values?: [Nullable<Dayjs>, Nullable<Dayjs>];
  onChangeFrom: (value: Dayjs) => void;
  onChangeTo: (value: Dayjs) => void;
}

export const RangeDatePicker: FC<RangeDatePickerProps> = memo((props) => {
  const { onChangeFrom, onChangeTo, placeholder, label, containerClassName, values, tooltip } = props;

  const theme = useTheme();

  const disableDateTo = useCallback(
    (currentDate: Dayjs): boolean => {
      return values?.[0] ? currentDate.isBefore(values?.[0]) : false;
    },
    [values],
  );

  const disableDateFrom = useCallback(
    (currentDate: Dayjs): boolean => {
      return values?.[1] ? currentDate.isAfter(values?.[1]) : false;
    },
    [values],
  );

  return (
    <div className={classNames('flex flex-col', containerClassName)}>
      <div className="flex">
        {label && <div className="mb-2 mr-2">{label}</div>}
        {tooltip && (
          <Popup title={label} description={tooltip} color={theme.primaryLight} textColor={theme.white}>
            <InfoIcon width={22} height={22} className="[&>path]:stroke-primaryLight" />
          </Popup>
        )}
      </div>
      <div className="flex items-center justify-between border rounded-lg border-secondaryAccent">
        <DatePicker
          className="pl-4 py-4"
          placeholder={placeholder?.[0]}
          value={values?.[0] as Dayjs}
          onChange={onChangeFrom as (value: Nullable<Dayjs>) => void}
          inputReadOnly
          format={CLIENT_DATE_FORMAT}
          bordered={false}
          disabledDate={disableDateFrom}
          showPresets
        />
        <ArrowRight className="shrink-0 [&>path]:stroke-primaryLight" />

        <DatePicker
          className="pr-4 py-4"
          placeholder={placeholder?.[1]}
          value={values?.[1] as Dayjs}
          onChange={onChangeTo as (value: Nullable<Dayjs>) => void}
          inputReadOnly
          format={CLIENT_DATE_FORMAT}
          bordered={false}
          disabledDate={disableDateTo}
          showPresets
        />
      </div>
    </div>
  );
});
