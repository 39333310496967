import classNames from 'classnames';
import React, { FC, memo, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { GroupedSizeCode, SizeCodeForBooking } from '../model/types';
import { Popup } from 'shared/ui/Popup';

interface SizeGroupsTagsProps {
  sizeCodes: SizeCodeForBooking[] | undefined;
}

export const SizeGroupsTags: FC<SizeGroupsTagsProps> = memo((props) => {
  const { sizeCodes } = props;

  const { t } = useAppTranslation('booking');

  const groupedBySizeGroups = useMemo<GroupedSizeCode[] | undefined>(
    () =>
      sizeCodes?.reduce((acc: GroupedSizeCode[], sizeCode) => {
        const existingGroup = acc.find((item) => item.sizeGroup === sizeCode.sizeGroup);

        if (existingGroup) {
          const minBoxSquare = Math.min(existingGroup.minBoxSquare || Infinity, Infinity);
          const maxBoxSquare = Math.max(existingGroup.maxBoxSquare || -Infinity, -Infinity);

          existingGroup.minBoxSquare = minBoxSquare < sizeCode.square ? minBoxSquare : sizeCode.square;
          existingGroup.maxBoxSquare = maxBoxSquare < sizeCode.square ? sizeCode.square : maxBoxSquare;
          existingGroup.availableBoxesCount += sizeCode.availableBoxesCount;
        } else {
          acc.push({ ...sizeCode, minBoxSquare: sizeCode.square, maxBoxSquare: sizeCode.square });
        }
        return acc;
      }, []),
    [sizeCodes],
  );

  return (
    <div className="flex space-x-2.5 mb-4">
      {groupedBySizeGroups?.map((sizeCode) => {
        const description =
          sizeCode.availableBoxesCount > 0 ? (
            t('This category includes boxes from {{minBoxSize}}m² to {{maxBoxSize}}m²', {
              minBoxSize: sizeCode.minBoxSquare,
              maxBoxSize: sizeCode.maxBoxSquare,
            })
          ) : (
            <Trans
              t={t}
              i18nKey="<0>All boxes in this category are occupied!</0> <1>Please select another category or look for a</1> <2>box suitable for you in another warehouse</2>"
              components={[<div key="0" />, <div key="1" />, <div key="2" />]}
            />
          );

        return (
          <Popup
            key={sizeCode.sizeCodeId}
            title={t('Box size {{sizeGroup}}', { sizeGroup: sizeCode.sizeGroup })}
            description={description}
            color="var(--accent)"
            textColor="var(--white)"
          >
            <div
              className={classNames(
                'flex justify-center items-center text-white text-sm font-normal bg-accent rounded-lg w-8 h-8 cursor-pointer',
                {
                  'opacity-30': sizeCode.availableBoxesCount < 1,
                },
              )}
            >
              {sizeCode.sizeGroup}
            </div>
          </Popup>
        );
      })}
    </div>
  );
});
