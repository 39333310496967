import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Button } from 'shared/ui/Button';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { FailScreen } from 'shared/ui/FailScreen';
import { stripeErrorCodes } from 'entities/Payment';

export const PaymentFailedPage: FC = () => {
  const { state } = useLocation();

  const errorCode: keyof typeof stripeErrorCodes = state.errorCode;

  const errorDescription = stripeErrorCodes[errorCode] || stripeErrorCodes.default;

  const navigate = useNavigate();
  const { t } = useAppTranslation('payment');

  const goToHomePage = (): void => {
    navigate(AppRoutes.HOME);
  };

  return (
    <div className="p-[100px]">
      <FailScreen
        title={t('Payment failed!')}
        description={errorDescription}
        actions={
          <Button onClick={goToHomePage} size="large" containerClassName="w-full">
            {t('Home')}
          </Button>
        }
      />
    </div>
  );
};
