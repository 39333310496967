import React, { FC, memo } from 'react';
import { ModalMode } from 'app/types/common';
import { Franchise } from 'entities/Franchise';
import { Modal } from 'shared/ui/Modal';
import { FranchiseForm } from './FranchiseForm';

interface CreateFranchiseModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: Nullable<ModalMode>;
  franchise?: Nullable<Franchise>;
  width?: number;
}

export const CreateFranchiseModal: FC<CreateFranchiseModalProps> = memo((props) => {
  const { isOpen, franchise, mode, width, onClose } = props;

  return (
    <Modal width={width} isOpen={isOpen} onClose={onClose}>
      <FranchiseForm franchise={franchise} mode={mode} onClose={onClose} />
    </Modal>
  );
});
