import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExportModalSchema, ExportModalPayload } from '../types';

const initialState: ExportModalSchema = {
  isOpened: false,
  filters: {},
  columns: [],
  entity: null,
};

const exportModalSlice = createSlice({
  name: 'exportModal',
  initialState,
  reducers: {
    openModal: (state: ExportModalSchema, action: PayloadAction<ExportModalPayload>) => {
      state.isOpened = true;
      state.filters = action.payload.filters;
      state.columns = action.payload.columns;
      state.entity = action.payload.entity;
    },
    closeModal: (state: ExportModalSchema) => {
      state.isOpened = false;
      state.filters = {};
      state.columns = [];
      state.entity = null;
    },
  },
});

export const { actions: exportModalActions, reducer: exportModalReducer } = exportModalSlice;
