import { api } from 'app/config/apiConfig';
import { City } from '../model/types';

export const updateCityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateCity: builder.mutation<City, City>({
      query: (city: City) => ({
        url: `/cities/${city.cityId}`,
        method: 'PUT',
        body: city,
      }),
      invalidatesTags: ['Cities'],
    }),
  }),
});

export const { useUpdateCityMutation } = updateCityApi;
