import _ from 'lodash';
import { Discount } from '../model/types';
import { roundNumber } from 'shared/utils/helpers/roundNumber';

interface CalculatedAmounts {
  initialAmount: number;
  totalDiscountPercent: number;
  totalDiscountAmount: number;
  totalAmount: number;
  contractPeriodsUnitPrices: ContractPeriodsUnitPrice[];
}

interface ContractPeriodsUnitPrice {
  periodNumber: number;
  rate: number;
  discountAmount: number;
  discountPercent: number;
  initialRate: number;
}

export const calculateDiscountValues = (
  baseRate: number,
  discounts: Discount[],
  initialContractDuration: number,
  currentInvoicingPeriod: number | number[],
): CalculatedAmounts => {
  const invoicingPeriods = Array.isArray(currentInvoicingPeriod) ? currentInvoicingPeriod : [currentInvoicingPeriod];
  const initialContractDurationArray = Array.from({ length: initialContractDuration }, (el, index) => index + 1);

  const contractPeriodsUnitPrices: ContractPeriodsUnitPrice[] = [];

  let initialAmount = 0;
  let totalDiscountPercent = 0;
  let totalDiscountAmount = 0;
  let totalAmount = 0;
  let discountSteps: string[][] = [];

  const primaryDiscount = discounts.find((discount) => discount.priority === 0);

  if (primaryDiscount) {
    discountSteps.push(primaryDiscount.discounts);
  }

  const groupedByPriorityDiscounts = _.groupBy(discounts, 'priority');

  Object.values(groupedByPriorityDiscounts).forEach((discountsWithSamePriorityArray) => {
    const mergedDiscounts = mergeDiscounts(discountsWithSamePriorityArray);

    discountSteps.push(mergedDiscounts);
  });

  discountSteps = discountSteps.map((group) => replaceContractDuration(group, initialContractDurationArray));

  invoicingPeriods.forEach((currentPeriod) => {
    let stepAmount = baseRate;
    initialAmount += baseRate;

    const isInvoicingPeriodAboveContractDuration = currentPeriod > initialContractDuration;

    discountSteps.forEach((discountsArray) => {
      const possibleDiscount = discountsArray.find((discountString) =>
        isInvoicingPeriodAboveContractDuration
          ? discountString.includes('all')
          : discountString.includes(`${currentPeriod}-`) && Number(discountString.split('-')[0]) === currentPeriod,
      );

      let discountAmount;

      if (possibleDiscount) {
        const discountValue = possibleDiscount.split('-')[1];
        discountAmount = discountValue.includes('%')
          ? stepAmount * (Number(discountValue.replace('%', '')) / 100)
          : stepAmount - Number(discountValue);
      } else {
        discountAmount = 0;
      }

      totalDiscountAmount += discountAmount;
      stepAmount -= discountAmount;
    });

    contractPeriodsUnitPrices.push({
      periodNumber: currentPeriod,
      rate: stepAmount,
      discountAmount: baseRate - stepAmount,
      discountPercent: roundNumber(((baseRate - stepAmount) / baseRate) * 100),
      initialRate: baseRate,
    });
  });

  totalDiscountPercent = roundNumber((totalDiscountAmount / initialAmount) * 100);
  totalDiscountAmount = roundNumber(totalDiscountAmount);
  totalAmount = initialAmount - totalDiscountAmount;

  return {
    initialAmount,
    totalDiscountPercent,
    totalDiscountAmount,
    totalAmount,
    contractPeriodsUnitPrices,
  };
};

const mergeDiscounts = (discountObjects: Discount[]): string[] => {
  const discounts = discountObjects.map((discount) => discount.discounts);

  const calculatedValues = discounts.reduce((acc: Record<string, string>, element) => {
    // Iterate over each element in the sub-array
    element.forEach((discountItem) => {
      // Split the string by '-', and get the index and the discountValue
      const [index, discountValue] = discountItem.split('-');

      // If the index exists in the accumulator, add the discountValue to it
      if (acc[index]?.includes('%')) {
        // if discountValue is number (not percent) we can not add discountValue
        acc[index] = discountValue.includes('%') ? `${parseInt(acc[index], 10) + parseInt(discountValue, 10)}%` : discountValue;
      } else if (!acc[index]) {
        // Otherwise, create a new key-value pair
        acc[index] = discountValue;
      }
    });

    return acc;
  }, {});

  const result = Object.keys(calculatedValues).map((key) => `${key}-${calculatedValues[key]}`);

  return result;
};

const replaceContractDuration = (discountIds: string[], currentInvoicingPeriod: number[]): string[] => {
  const keys = discountIds.map((string) => string.split('-')[0]);

  return discountIds
    .map((discountId) => {
      if (discountId.startsWith('contractDuration')) {
        const [, percentage] = discountId.split('-');
        const replacedDiscounts: string[] = [];

        currentInvoicingPeriod.forEach((num) => {
          if (!keys.find((key) => parseInt(key, 10) === num)) {
            replacedDiscounts.push(`${num}-${percentage}`);
          }
        });

        return replacedDiscounts;
      }
      return discountId;
    })
    .flat();
};
