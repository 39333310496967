import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useCreateWarehouseMutation, CreateWarehouseParams } from 'entities/Warehouse';

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  createWarehouse: (args: CreateWarehouseParams, onSuccess?: () => void) => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

export const useCreateWarehouse = (): HookApi => {
  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const { t } = useAppTranslation('common');

  const [createWarehouseService, { isLoading }] = useCreateWarehouseMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const createWarehouse = useCallback(
    async (args: CreateWarehouseParams, onSuccess?: () => void): Promise<void> => {
      try {
        await createWarehouseService(args).unwrap();

        showNotification('info', t('Success'), t('Warehouse has been successfully created'));

        onSuccess?.();
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t('Error when creating warehouse'));
      } finally {
        closeConfirmModal();
      }
    },
    [createWarehouseService, t, closeConfirmModal],
  );

  return {
    openConfirmModal,
    closeConfirmModal,
    createWarehouse,
    isOpenedConfirm,
    isLoading,
  };
};
