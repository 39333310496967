import { useContext, useEffect } from 'react';
import { useQueryString } from 'shared/utils/helpers/convertQueryString';
import { TableFilterContext } from '../../model/context/TableFilterContextProvider';

export const useWatchTableFilterQuery = (withQueryStringFilters: boolean): void => {
  const { parsedQuery, decode, encode } = useQueryString();

  const { filters, appliedFilters, applyQueryFilters } = useContext(TableFilterContext);

  useEffect(() => {
    const queryToFilters = (): void => {
      const decodedFilter = decode(parsedQuery);

      for (const key in decodedFilter) {
        if (!filters[key] && decodedFilter[key].value) {
          applyQueryFilters({ key, value: decodedFilter[key].value, label: decodedFilter[key].label, title: decodedFilter[key].title });
        }
      }
    };

    if (withQueryStringFilters) {
      queryToFilters();
    }
  }, [withQueryStringFilters]);

  useEffect(() => {
    const filtersToQuery = (): void => {
      encode(appliedFilters);
    };

    if (withQueryStringFilters) {
      filtersToQuery();
    }
  }, [withQueryStringFilters, appliedFilters, encode]);
};
