import React, { FC, memo, ReactNode } from 'react';

import { AuthForm, getAuthFormMode, ForgotPasswordForm } from 'widgets/AuthForm';
import { useAppSelector } from 'app/config/storeConfig/hooks';

export const LoginPage: FC = memo(() => {
  const mode = useAppSelector(getAuthFormMode);

  const renderForm = (): ReactNode => {
    switch (mode) {
      case 'login':
        return <AuthForm className="w-[370px] pt-[24px] tablet:w-[450px]" />;
      case 'resetPassword':
        return <ForgotPasswordForm />;
      default:
        return <AuthForm className="w-[370px] pt-[24px] tablet:w-[450px]" />;
    }
  };

  return <div className="h-screen flex flex-col justify-center items-center bg-accentLight">{renderForm()}</div>;
});
