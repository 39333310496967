import { LateChargeSetting } from 'entities/LateChargeSetting';

export interface LateChargeSettingFormDrawerSchema {
  mode: Nullable<DrawerMode>;
  lateChargeSetting?: Nullable<LateChargeSetting>;
}

export interface LateChargeSettingFormFields {
  name: string;
  notificationDays: string[];
  overdueDaysLimit: number;
  minAmount: number;
  amount: string;
  isStrictEmailReminder: boolean;
  description?: string;
}

export enum DrawerMode {
  LATE_CHARGE_SETTING_EDIT = 'late_charge_setting_edit',
  LATE_CHARGE_SETTING_CREATE = 'late_charge_setting_create',
}

export enum AmountType {
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
}
