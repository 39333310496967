import { Popover } from 'antd';
import React, { FC, memo, PropsWithChildren, ReactElement, MouseEvent, ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'shared/assets/icons/CloseIcon.svg';

type ActionType = 'hover' | 'click';

interface PopupProps extends PropsWithChildren {
  title?: string;
  description?: string | ReactNode;
  color?: string;
  textColor?: string;
  trigger?: ActionType | ActionType[];
  isOpened?: boolean;
  onChange?: () => void;
}

export const Popup: FC<PopupProps> = memo((props) => {
  const {
    children,
    title,
    description,
    color = 'var(--white)',
    textColor = 'var(--primary)',
    trigger = 'hover',
    isOpened,
    onChange,
  } = props;

  const close = (e: MouseEvent): void => {
    e.stopPropagation();
    onChange?.();
  };

  const renderContent = (): ReactElement => {
    return (
      <div className="relative">
        {Boolean(onChange) && <CloseIcon className="absolute top-0 right-0 cursor-pointer stroke-white" onClick={close} />}
        <div className="p-4 w-max space-y-3.5">
          <div className="font-semibold text-lg" style={{ color: textColor }}>
            {title}
          </div>
          <div style={{ color: textColor }}>{description}</div>
        </div>
      </div>
    );
  };

  return (
    <Popover overlayInnerStyle={{ padding: '5px' }} color={color} trigger={trigger} open={isOpened} content={renderContent()}>
      {children}
    </Popover>
  );
});
