import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Divider, Form } from 'antd';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { InputNumber } from 'shared/ui/InputNumber';
import { Select } from 'shared/ui/Select';
import { useGetCurrencySymbol } from 'app/appState';
import { TextArea } from 'shared/ui/TextArea';
import { Checkbox } from 'shared/ui/Checkbox';
import { BoxForListing, useCreateBoxMutation, useUpdateBoxMutation } from 'entities/Box';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import { useGetSizeCodeByIdQuery, useGetSizeCodesByWarehouseIdQuery } from 'entities/SizeCode';
import { showNotification } from 'app/providers/NotificationsProvider';
import { getLocalizedString, validateLocalizedJSON } from 'shared/utils/helpers/JSONLocalization';
import { BoxFormFields, DrawerMode } from '../model/types';
import { useGetFloorsByWarehouseIdQuery } from 'entities/Floor';
import { INTEGER_REGEX, LOCK_ADDRESS_REGEX } from 'shared/utils/regex';
import { LockMaskedInput } from 'features/LockMaskedInput';

interface BoxFormProps {
  box?: Nullable<BoxForListing>;
  mode: Nullable<DrawerMode>;
  handleClose: () => void;
}

export const BoxForm: FC<BoxFormProps> = memo((props) => {
  const { box, mode, handleClose } = props;
  const { t } = useAppTranslation(['boxes', 'common']);

  const [form] = Form.useForm();

  const [selectedWarehouseId, setSelectedWarehouseId] = useState<Nullable<string>>(box?.warehouseId || null);
  const [selectedSizeCodeId, setSelectedSizeCodeId] = useState<Nullable<string>>(box?.sizeCodeId || null);

  const currencySymbol = useGetCurrencySymbol();

  const [createBox, { isLoading: isCreationLoading }] = useCreateBoxMutation();
  const [updateBox, { isLoading: isUpdationLoading }] = useUpdateBoxMutation();
  const { data: warehouses } = useGetAllWarehousesQuery();
  const { data: sizeCodes } = useGetSizeCodesByWarehouseIdQuery(selectedWarehouseId, { skip: !selectedWarehouseId });
  const { data: floors } = useGetFloorsByWarehouseIdQuery(selectedWarehouseId, { skip: !selectedWarehouseId });
  const { data: sizeCode } = useGetSizeCodeByIdQuery(selectedSizeCodeId, { skip: !selectedSizeCodeId });

  const isLoading = isUpdationLoading || isCreationLoading;

  const warehouseOptions = useMemo(() => {
    return (
      warehouses?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [warehouses]);

  const sizeCodeOptions = useMemo(() => {
    return (
      sizeCodes?.map((sizeCode) => ({
        label: `${sizeCode.square} ${sizeCode.sizeCodeType.toLowerCase()}`,
        value: sizeCode.sizeCodeId,
      })) || []
    );
  }, [sizeCodes]);

  const floorsOptions = useMemo(() => {
    return (
      floors?.map((floor) => ({
        label: getLocalizedString(floor.name),
        value: floor.floorId,
      })) || []
    );
  }, [floors]);

  useEffect(() => {
    if (selectedWarehouseId !== box?.warehouseId) {
      form.setFieldValue('sizeCodeId', null);
      form.setFieldValue('floorId', null);
      form.setFieldValue('dailyRate', null);
      form.setFieldValue('weekRate', null);
      form.setFieldValue('monthRate', null);
    }
  }, [selectedWarehouseId, form, box?.warehouseId]);

  useEffect(() => {
    if (sizeCode && selectedSizeCodeId !== box?.sizeCodeId) {
      form.setFieldValue('dailyRate', sizeCode.dailyRate);
      form.setFieldValue('weekRate', sizeCode.weekRate);
      form.setFieldValue('monthRate', sizeCode.monthRate);
    }
  }, [sizeCode, form, selectedSizeCodeId, box?.sizeCodeId]);

  useEffect(() => {
    if (box) {
      const boxFormData = {
        ...box,
        maxOpeningTimeMS: box.maxOpeningTimeMS ? box.maxOpeningTimeMS / 60000 : null,
        timeToOpenLockMS: box.timeToOpenLockMS ? box.timeToOpenLockMS / 1000 : null,
      };

      form.setFieldsValue(boxFormData);
      setSelectedWarehouseId(box.warehouseId);
    } else {
      setSelectedWarehouseId(null);
      form.resetFields();
    }
  }, [form, box]);

  const handleChangeWarehouse = useCallback((warehouseId: string): void => {
    setSelectedWarehouseId(warehouseId);
  }, []);

  const handleChangeSizeCodeId = useCallback((sizeCodeId: string): void => {
    setSelectedSizeCodeId(sizeCodeId);
  }, []);

  const handleChangeLockAddress = useCallback(
    (value: string): void => {
      form.setFieldValue('lockAddress', value || null);
    },
    [form],
  );

  const handleSubmit = useCallback(
    async (data: BoxFormFields): Promise<void> => {
      const convertedMaxOpeningTime = data.maxOpeningTimeMS ? data.maxOpeningTimeMS * 60000 : null;
      const convertedTimeToOpenLock = data.timeToOpenLockMS ? data.timeToOpenLockMS * 1000 : null;

      try {
        switch (mode) {
          case DrawerMode.BOX_CREATE:
            await createBox({ ...data, maxOpeningTimeMS: convertedMaxOpeningTime, timeToOpenLockMS: convertedTimeToOpenLock }).unwrap();
            showNotification('info', t('Success'), t('Box has been successfully created'));
            break;
          case DrawerMode.BOX_EDIT:
            if (box) {
              const { boxId } = box;
              await updateBox({
                ...data,
                boxId,
                maxOpeningTimeMS: convertedMaxOpeningTime,
                timeToOpenLockMS: convertedTimeToOpenLock,
              }).unwrap();
              showNotification('info', t('Success'), t('Box has been successfully updated'));
            }
            break;
        }

        /* form.resetFields();

        handleClose(); */
      } catch (error: CustomAny) {
        console.log(error);
        showNotification('error', t('Error'), t(`Error when ${mode === 'box_create' ? 'creating' : 'updating'} box`));
      }
    },
    [mode, createBox, box, updateBox, t],
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit} className="flex flex-col px-10 py-4">
      <div className="flex justify-between items-center pt-4 pb-10">
        <div className="font-semibold text-3xl">{mode === DrawerMode.BOX_EDIT ? `${t('Edit')} #${box?.name}` : t('Create box')}</div>
        <div className="text-primaryLight font-normal cursor-pointer" onClick={handleClose}>
          {t('Close')}
        </div>
      </div>

      <Form.Item label={t('Box name')} name="name" rules={[{ required: true, message: `${t('Please, enter box name')}!` }]}>
        <Input placeholder={t('Please, enter box name')} bordered />
      </Form.Item>
      <Form.Item
        label={t('Warehouse')}
        name="warehouseId"
        rules={[{ required: true, message: `${t('Please, select warehouse')}!` }]}
        className="flex-1"
      >
        <Select
          options={warehouseOptions}
          placeholder={t('Please, select warehouse')}
          bordered
          onChange={handleChangeWarehouse}
          disabled={mode === DrawerMode.BOX_EDIT}
        />
      </Form.Item>
      <div className="flex gap-4">
        <Form.Item
          label={t('Size code')}
          rules={[{ required: true, message: `${t('Please, select size code')}!` }]}
          className="flex-1"
          name="sizeCodeId"
        >
          <Select
            options={sizeCodeOptions}
            placeholder={t('Size code')}
            bordered
            disabled={!selectedWarehouseId}
            onChange={handleChangeSizeCodeId}
          />
        </Form.Item>
        <Form.Item
          label={t('Floor')}
          rules={[{ required: true, message: `${t('Please, select floor')}!` }]}
          className="flex-1"
          name="floorId"
        >
          <Select
            options={floorsOptions}
            placeholder={t('Floor')}
            bordered
            disabled={!selectedWarehouseId || mode === DrawerMode.BOX_EDIT}
          />
        </Form.Item>
      </div>
      <div className="flex gap-4">
        <Form.Item
          label={t('Height')}
          name="height"
          rules={[{ required: true, message: `${t('Please, enter height')}!` }]}
          className="flex-1"
        >
          <InputNumber placeholder={t('Height')} bordered />
        </Form.Item>
        <Form.Item label={t('Width')} name="width" className="flex-1" rules={[{ required: true, message: `${t('Please, enter width')}!` }]}>
          <InputNumber placeholder={t('Width')} bordered />
        </Form.Item>
        <Form.Item
          label={t('Length')}
          name="length"
          className="flex-1"
          rules={[{ required: true, message: `${t('Please, enter length')}!` }]}
        >
          <InputNumber placeholder={t('Length')} bordered />
        </Form.Item>
      </div>

      <Divider className="mb-4 mt-0" />

      <Form.Item
        label={t('Price factor')}
        name="priceFactor"
        className="flex-1"
        rules={[{ required: true, message: `${t('Please, enter price factor')}!` }]}
      >
        <InputNumber placeholder={t('Price factor')} bordered />
      </Form.Item>
      <div className="flex gap-4 mb-0">
        <Form.Item label={`${t('Daily rate')}/${currencySymbol}`} name="dailyRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Daily rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Week rate')}/${currencySymbol}`} name="weekRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Week rate')} bordered />
        </Form.Item>
        <Form.Item label={`${t('Month rate')}/${currencySymbol}`} name="monthRate" className="flex-1 mb-0">
          <InputNumber placeholder={t('Month rate')} bordered />
        </Form.Item>
      </div>
      <Form.Item
        className="my-0 text-center"
        validateStatus="error"
        help={form.getFieldError(['dailyRate', 'weekRate', 'monthRate'])?.join(' ') || ''}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.dailyRate !== currentValues.dailyRate ||
          prevValues.weekRate !== currentValues.weekRate ||
          prevValues.monthRate !== currentValues.monthRate
        }
      >
        {({ getFieldValue }) => {
          const dailyRate = getFieldValue('dailyRate');
          const weekRate = getFieldValue('weekRate');
          const monthRate = getFieldValue('monthRate');

          if (!dailyRate && !weekRate && !monthRate) {
            return <span className="my-0 text-error">{t('Please fill in at least one of the rates!')}</span>;
          }

          return null;
        }}
      </Form.Item>

      <Divider className="mb-6 mt-0" />

      <div className="flex gap-4">
        <Form.Item
          label={t('Lock number')}
          className="flex-1"
          name="lockAddress"
          rules={[{ pattern: LOCK_ADDRESS_REGEX, message: t('Please provide a valid lock number!') }]}
        >
          <LockMaskedInput placeholder="1:02:03" bordered onChange={handleChangeLockAddress} />
        </Form.Item>
        <Form.Item
          label={t('Permissible opening time (min)')}
          name="maxOpeningTimeMS"
          className="flex-2"
          rules={[{ pattern: INTEGER_REGEX, message: t('Please provide integer!') }]}
        >
          <InputNumber placeholder={t('Example: 30')} bordered />
        </Form.Item>
      </div>

      <Form.Item
        label={t('Time to open lock (sec)')}
        name="timeToOpenLockMS"
        className="flex-2"
        rules={[{ pattern: INTEGER_REGEX, message: t('Please provide integer!') }]}
      >
        <InputNumber placeholder={t('For external boxes only')} bordered />
      </Form.Item>

      <Form.Item
        label={t('Description')}
        name="description"
        rules={[
          () => ({
            async validator(_, value) {
              const isValidDescription = validateLocalizedJSON(value);

              if (!value || isValidDescription) {
                await Promise.resolve();
                return;
              }
              return await Promise.reject(new Error(t('Description must be a valid localized JSON')));
            },
          }),
        ]}
      >
        <TextArea placeholder={t('Add description...')} bordered rows={3} />
      </Form.Item>

      <Form.Item name="isForOwnNeeds" valuePropName="checked">
        <Checkbox name="isForOwnNeeds">{t('For own needs')}</Checkbox>
      </Form.Item>

      <Button type="submit" isLoading={isLoading}>
        {t('Save')}
      </Button>
    </Form>
  );
});
