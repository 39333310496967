import React, { FC, useEffect, ReactElement, memo, useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { CreditInvoiceParams, Invoice, useCreditInvoiceMutation } from 'entities/Invoice';
import { Modal } from 'shared/ui/Modal';
import { TextArea } from 'shared/ui/TextArea';
import { InputNumber } from 'shared/ui/InputNumber';
import { WarningPing } from 'shared/ui/WarningPing';
import { showNotification } from 'app/providers/NotificationsProvider';
import { Form } from 'antd';
import { WarnTag } from 'shared/ui/WarnTag';
import { Deposit } from 'entities/Deposit';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { creditAmoutValidator } from '../utils/creditAmoutValidator';

interface CreditInvoiceProps {
  invoice: Invoice;
  deposit?: Deposit;
  action: ReactElement;
}

export const CreditInvoice: FC<CreditInvoiceProps> = memo((props) => {
  const { invoice, action, deposit } = props;

  const [isOpenedModal, setOpenedModal] = useState(false);

  const { t } = useAppTranslation('contracts');

  const [form] = Form.useForm();

  const [creditInvoice, { isLoading }] = useCreditInvoiceMutation();

  const openModal = useCallback((): void => {
    setOpenedModal(true);
  }, []);

  const closeModal = useCallback((): void => {
    setOpenedModal(false);
  }, []);

  const hasPayments = invoice.payments.length > 0;

  const isDepositInvoice = Boolean(invoice.invoiceItems.find(({ invoiceItemId }) => deposit?.invoiceItem.invoiceItemId === invoiceItemId));

  const maxCreditAmountDueToDepositAmount =
    isDepositInvoice && deposit && invoice.debt > deposit.initialDepositAmount
      ? roundNumber(invoice.debt - deposit.initialDepositAmount)
      : null; // ALLOWED CREDIT AMOUNT FOR UNPAID INVOICE ITEMS AND DEBT > DEPOSIT AMOUNT !!!

  const allowedCreditDepositAmount = // ALLOWED CREDIT AMOUNT FOR TOTALLY PAID INVOICE ITEMS (WITHOUT DEPOSIT) AND DEBT = DEPOSIT AMOUNT !!!
    (isDepositInvoice && deposit && hasPayments && invoice.debt === deposit.initialDepositAmount && deposit.initialDepositAmount) || null;

  useEffect(() => {
    if (invoice) {
      form.setFieldValue('creditedAmount', invoice.debt);
    }
  }, [invoice, form]);

  const handleCreditInvoice = useCallback(
    async (data: CreditInvoiceParams): Promise<void> => {
      try {
        if (invoice.invoiceId) {
          await creditInvoice({ ...data, invoiceId: invoice.invoiceId }).unwrap();
          showNotification('info', t('Success'), t('Invoice has been successfully credited'));
        }
      } catch (error: CustomAny) {
        showNotification('error', t('Error'), t('Error when crediting invoice'));
      } finally {
        closeModal();
      }
    },
    [closeModal, creditInvoice, invoice, t],
  );

  return (
    <>
      {React.cloneElement(action, { onClick: openModal })}

      <Modal className="max-w-[400px]" isOpen={isOpenedModal} onClose={closeModal}>
        <Form form={form} layout="vertical" onFinish={handleCreditInvoice} className="flex flex-col">
          <div className="mb-2">
            <WarningPing type="warning" />
          </div>
          <div className="font-semibold text-xl mb-3">{t('Credit invoice')}</div>

          {isDepositInvoice && Boolean(maxCreditAmountDueToDepositAmount) && (
            <div className="mt-2 mb-6">
              <WarnTag textSize="medium" disableIcon>
                {t(
                  'This is deposit related invoice. Credited amount should be from 1 to {{maxCreditAmountDueToDepositAmount}} or full invoice debt amount {{debt}}',
                  {
                    debt: invoice.debt,
                    maxCreditAmountDueToDepositAmount,
                  },
                )}
              </WarnTag>
            </div>
          )}

          {isDepositInvoice && allowedCreditDepositAmount && (
            <div className="mt-2 mb-6">
              <WarnTag textSize="medium" disableIcon>
                {t(
                  'This is deposit related invoice and has already been partially paid. Credited amount should be equal {{allowedCreditDepositAmount}}!',
                  {
                    allowedCreditDepositAmount,
                  },
                )}
              </WarnTag>
            </div>
          )}

          <div className="text-primaryLight font-normal">
            {t('Are you sure you want to credit this invoice? You also can add description!')}
          </div>

          <div className="my-5">
            <Form.Item
              label={t('Enter credited amount')}
              name="creditedAmount"
              rules={[
                { required: true, message: `${t('Please, enter credited amount')}!` },
                creditAmoutValidator(invoice.debt, maxCreditAmountDueToDepositAmount, allowedCreditDepositAmount, t),
              ]}
            >
              <InputNumber placeholder={t('Enter credited amount')} bordered />
            </Form.Item>
            <Form.Item name="description">
              <TextArea placeholder={t('Add your description...')} rows={4} bordered />
            </Form.Item>
          </div>

          <div className="flex justify-end gap-3">
            <Button theme="secondary" onClick={closeModal}>
              {t('Cancel', { ns: 'common' })}
            </Button>

            <Button type="submit" isLoading={isLoading}>
              {t('Save', { ns: 'common' })}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
});
