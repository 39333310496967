import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getLoggedUserData } from '../../model/selectors/getLoggedUserData';

export const useAuthorizedState = (): boolean => {
  const userData = useAppSelector(getLoggedUserData);

  const isAuthorized = Boolean(userData?.userId);

  return isAuthorized;
};
