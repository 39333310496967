import React, { FC, MouseEvent, ReactNode, memo } from 'react';
import { Collapse } from 'antd';

const { Panel } = Collapse;

interface CollapseRadioButtonGroupProps {
  options: Array<{ value: string; label: ReactNode; content: ReactNode }>;
  selectedOption: string;
  setSelectedOption: (value: CustomAny) => void;
}

export const CollapseRadioButtonGroup: FC<CollapseRadioButtonGroupProps> = memo((props) => {
  const { options, selectedOption, setSelectedOption } = props;

  const handleOptionClick = (e: MouseEvent<HTMLDivElement>, optionValue: string): void => {
    e.stopPropagation();

    if (selectedOption !== optionValue) {
      setSelectedOption(optionValue);
    }
  };

  const handleHeaderClick = (e: MouseEvent<HTMLDivElement>, optionValue: string): void => {
    e.stopPropagation();

    setSelectedOption(optionValue);
  };

  return (
    <div className="flex flex-col gap-3">
      {options.map((option) => (
        <Collapse key={option.value} bordered activeKey={selectedOption} className="bg-white">
          <Panel
            key={option.value}
            showArrow={false}
            header={
              <div
                className="flex items-center font-normal text-primaryLight"
                onClick={(e) => {
                  handleHeaderClick(e, option.value);
                }}
              >
                <div
                  className="w-[24px] h-[24px] shrink-0 border border-accent rounded-full flex justify-center items-center mr-2.5"
                  onClick={(e) => {
                    handleOptionClick(e, option.value);
                  }}
                >
                  {selectedOption === option.value && <div className="rounded-full bg-accent w-full h-full scale-75" />}
                </div>
                {option.label}
              </div>
            }
          >
            {option.content}
          </Panel>
        </Collapse>
      ))}
    </div>
  );
});
