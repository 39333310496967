import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { stepperModalActions } from 'features/StepperModal';
import { getOpenedState } from '../../model/selectors/getOpenedState';
import { createWarehouseModalActions } from '../../model/slice/createWarehouseModalSlice';

interface HookApi {
  isOpened: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useCreateWarehouseModal = (): HookApi => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(getOpenedState);

  const openModal = useCallback(() => {
    dispatch(createWarehouseModalActions.setOpened(true));
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(createWarehouseModalActions.resetModalState());
    dispatch(stepperModalActions.changeActiveStepIndex(0));
  }, [dispatch]);

  return {
    isOpened,
    openModal,
    closeModal,
  };
};
