import { BaseQueryApi, QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { AUTH_REFRESH_PATH } from './contants';
import { getAccessToken, getRefreshToken, getRememberMeState } from 'entities/User';
import { API_URL } from 'app/config/envVariables';

let isRefreshing = false;
let refreshSubscribers: Array<() => void> = [];

const addSubscriber = (callback: () => void): void => {
  refreshSubscribers.push(callback);
};

const notifySubscribers = (): void => {
  refreshSubscribers.forEach((callback) => {
    callback();
  });

  refreshSubscribers = [];
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const token = getAccessToken();

    if (token) {
      headers.set('authorization', `Bearer ${token}`);

      return headers;
    }
  },
});

const refreshQuery = fetchBaseQuery({
  baseUrl: API_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const refresh = getRefreshToken();

    if (refresh) {
      headers.set('authorization', `Bearer ${refresh}`);

      return headers;
    }
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
): Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const refreshResult = await refreshQuery(AUTH_REFRESH_PATH, api, extraOptions);

        if (refreshResult?.error) {
          api.dispatch({ type: 'user/logOut' });
        }

        if (refreshResult?.data) {
          const { accessToken, refreshToken } = refreshResult.data as Record<'refreshToken' | 'accessToken', string>;

          api.dispatch({
            type: 'user/setCredentials',
            payload: {
              credentials: {
                accessToken,
                refreshToken,
              },
              remember: getRememberMeState(),
            },
          });

          notifySubscribers();

          result = await baseQuery(args, api, extraOptions);
        }
      } catch (error) {
        api.dispatch({ type: 'user/logOut' });
      } finally {
        isRefreshing = false;
      }
    } else {
      result = await new Promise((resolve) => {
        addSubscriber(async () => {
          const newResult = await baseQuery(args, api, extraOptions);

          resolve(newResult);
        });
      });
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Cities',
    'Reservations',
    'Available boxes',
    'Invoices',
    'Boxes',
    'Leads',
    'Contracts',
    'Available sizeCodes',
    'Contract details',
    'Warehouses for booking',
    'My bookings',
    'Notes',
    'Late charge settings',
    'Users',
    'Credit Notes',
    'Insurance',
    'Size codes',
    'Warehouses',
    'Franchises',
  ],
  endpoints: () => ({}),
});
