import { api } from 'app/config/apiConfig';
import { CloseContractParams, Contract } from '../model/types';

export const closeContractApi = api.injectEndpoints({
  endpoints: (builder) => ({
    closeContract: builder.mutation<Contract, { contractId: string; params: CloseContractParams }>({
      query: (args) => ({
        url: `/contracts/${args.contractId}/close`,
        method: 'POST',
        body: args.params,
      }),
      invalidatesTags: ['Contracts', 'Invoices', 'Available boxes', 'Available sizeCodes', 'Contract details', 'Warehouses for booking'],
    }),
  }),
});

export const { useCloseContractMutation } = closeContractApi;
