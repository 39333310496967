import { InvoiceFrequencyType } from 'entities/Invoice';
import { UserAccountType } from 'entities/User';

export const contractInfoState = {
  accountType: UserAccountType.PERSONAL,
  contractDuration: 1,
  invoiceFrequencyType: InvoiceFrequencyType.MONTH,
  startDate: undefined,
  endDate: undefined,
  insuranceId: null,
  insuranceAmountWithVat: 0,
  coverageAmount: 0,
  payForEntirePeriod: false,
  total: 0,
  totalToPay: 0,
  vatRatePercent: 0,
  vatRateAmount: 0,
  discountPercent: 0,
  discountAmount: 0,
  insuranceVatRatePercent: 0,
};

export const userDetailsFormState = {
  firstName: null,
  lastName: null,
  phone: null,
  dateOfBirth: null,
  identificationNumber: null,
  language: null,
  company: null,
  email: null,
  password: null,
  street: null,
  country: null,
  city: null,
  house: null,
  apartment: null,
  zip: null,
};
