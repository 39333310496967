import classNames from 'classnames';
import React, { FC, memo } from 'react';
import SizeCodeDefaultImage from 'shared/assets/images/SizeCodeDefaultImage.png';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { SizeCodeType, SizeGroup } from 'entities/SizeCode';
import { RentOptionDocument } from 'entities/RentOption';
import { getGlobalSettings } from 'app/appState';
import { useAppSelector } from 'app/config/storeConfig/hooks';
import { ContactUs } from './ContactUs';

interface CollapseHeadingProps {
  square: number;
  availableBoxesCount: number;
  rentDiscounts: RentOptionDocument[];
  generalDiscounts: RentOptionDocument[];
  isOpened: boolean;
  imageUrl?: string;
  description?: string;
  sizeGroup?: SizeGroup;
  sizeCodeType?: SizeCodeType;
}

export const CollapseHeading: FC<CollapseHeadingProps> = memo((props) => {
  const { square, description, imageUrl, availableBoxesCount, sizeGroup, sizeCodeType, rentDiscounts, generalDiscounts, isOpened } = props;

  const { t } = useAppTranslation('booking');

  const globalSettings = useAppSelector(getGlobalSettings);

  const noAvailableBoxes = availableBoxesCount < 1;

  const disabledElement = { 'opacity-30': noAvailableBoxes };

  const hasRentDiscounts = rentDiscounts.length > 0;
  const hasGeneralDiscounts = generalDiscounts.length > 0;

  const hidePromotion = (): boolean => {
    if (noAvailableBoxes) {
      return true;
    }

    if (isOpened) {
      return true;
    }

    if (hasRentDiscounts) {
      return false;
    }

    if (globalSettings?.showGeneralPromotions) {
      return !hasGeneralDiscounts;
    }

    return true;
  };

  return (
    <div className={classNames('flex items-center space-x-1 desktop:space-x-5', { 'cursor-default': noAvailableBoxes })}>
      <div
        className={classNames('absolute top-0 left-0 py-[1px] px-3 bg-accent rounded-br-lg text-white', {
          hidden: hidePromotion(),
        })}
      >
        {t('Discount')}
      </div>
      <div className="basis-1/13 shrink-0">
        <img className={classNames('w-[80px] object-contain', disabledElement)} src={imageUrl || SizeCodeDefaultImage} alt="Size image" />
      </div>
      <div
        className={classNames('text-xl font-semibold text-center basis-3/12 shrink-0 desktop:basis-2/12 desktop:text-2xl', disabledElement)}
      >
        {square} m<sup className="text-sm desktop:text-lg">{sizeCodeType === SizeCodeType.STANDART_BOX ? 2 : 3}</sup>
      </div>
      <div className={classNames(`${noAvailableBoxes ? 'text-xs' : 'text-sm'} font-normal basis-7/12`, disabledElement)}>
        {noAvailableBoxes
          ? t('There is no available box of this category')
          : `${sizeGroup ? `${sizeGroup}:` : ''} ${getLocalizedString(description)}`}
      </div>
      {noAvailableBoxes ? (
        <ContactUs square={square} />
      ) : (
        <div className="w-[140px] text-success font-normal text-m">
          {t('Boxes available: {{availableBoxesCount}}', { availableBoxesCount })}
        </div>
      )}
    </div>
  );
});
