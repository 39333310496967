import { api } from 'app/config/apiConfig';
import { Reservation } from '../model/types';

export const cancelReservationApi = api.injectEndpoints({
  endpoints: (build) => ({
    cancelReservation: build.mutation<Reservation, string>({
      query: (reservationId) => ({
        url: `/reservations/${reservationId}/cancel`,
        method: 'PUT',
      }),
      invalidatesTags: ['Reservations', 'Available boxes', 'Available sizeCodes', 'Warehouses for booking'],
    }),
  }),
});

export const { useCancelReservationMutation } = cancelReservationApi;
