import { useSubscriber } from 'app/socketConnection';
import { useEffect } from 'react';

interface ContractPaymentsChangedData {
  paymentId: string;
  info: 'new_payment' | 'new_refund';
}

export const useOnContractPaymentsChanged = (callback?: (socketData: Nullable<ContractPaymentsChangedData>) => void): void => {
  const socketData = useSubscriber<ContractPaymentsChangedData>('contractPaymentsChanged');

  useEffect(() => {
    if (socketData?.paymentId) {
      callback?.(socketData);
    }
  }, [socketData, callback]);
};
