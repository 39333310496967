import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSchema, GlobalSettings } from '../types';

const initialState: AppSchema = {
  loadingCount: 0,
  globalSettings: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state: AppSchema) => {
      state.loadingCount = state.loadingCount + 1;
    },
    stopLoading: (state: AppSchema) => {
      state.loadingCount = state.loadingCount > 0 ? state.loadingCount - 1 : 0;
    },
    setGlobalSettings: (state: AppSchema, action: PayloadAction<Nullable<GlobalSettings>>) => {
      state.globalSettings = action.payload;
    },
  },
});

export const { actions: appActions, reducer: appReducer } = appSlice;
