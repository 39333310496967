import { notification } from 'antd';
import { ReactNode } from 'react';
import { notificationDuration, notificationPlacement } from '../utils/constants';

type NotificationType = 'info' | 'warning' | 'error';

export const showNotification = (type: NotificationType, message: string, description: string | string[]): void => {
  let descriptionText: ReactNode;

  if (Array.isArray(description)) {
    descriptionText = description.map((text, index) => <div key={index}>{text}</div>);
  } else {
    descriptionText = description;
  }

  notification[type]({
    message,
    description: descriptionText,
    placement: notificationPlacement,
    duration: notificationDuration,
  });
};
