import React, { FC, memo, ReactNode, useCallback, useState } from 'react';
import { TabSwitcher } from 'shared/ui/TabSwitcher';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ActiveContractsTable } from './ActiveContractsTable';
import { RentalHistoryContractsTable } from './RentalHistoryContractsTable';
import { ContractDetailsDrawer } from 'widgets/ContractDetailsDrawer';

export const UserContractsPage: FC = memo(() => {
  const [selectedTabKey, setSelectedTabKey] = useState('active');

  const { t } = useAppTranslation('contracts');

  const switchTab = useCallback((tabKey: string): void => {
    setSelectedTabKey(tabKey);
  }, []);

  const renderTabContent = useCallback((): Nullable<ReactNode> => {
    switch (selectedTabKey) {
      case 'active':
        return <ActiveContractsTable />;
      case 'rentalHistory':
        return <RentalHistoryContractsTable />;
      default:
        return null;
    }
  }, [selectedTabKey]);

  const tabs = [
    { key: 'active', label: t('Active') },
    { key: 'rentalHistory', label: t('Rental history') },
  ];

  return (
    <>
      <TabSwitcher className="mt-7" tabs={tabs} selectedTabKey={selectedTabKey} onChangeTab={switchTab}>
        {renderTabContent()}
      </TabSwitcher>
      <ContractDetailsDrawer />
    </>
  );
});
