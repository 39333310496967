import { API_URL } from 'app/config/envVariables';
import { getAccessToken } from 'entities/User';

export const generateContractPdfLink = (contractId: string): string => {
  const token = getAccessToken();

  const contractPdfLink = `${API_URL}/contracts/${contractId}/pdf?token=${token}`;

  return contractPdfLink;
};
