export interface RequestFormModalSchema {
  isOpen: boolean;
  title?: Nullable<string>;
  description?: Nullable<string>;
  mode?: Nullable<RequestModalMode>;
  square?: Nullable<number>;
}

export interface RequestFormModalPayload {
  title?: Nullable<string>;
  description?: Nullable<string>;
  mode?: Nullable<RequestModalMode>;
  square?: Nullable<number>;
}

export enum RequestModalMode {
  CONTACT_US = 'contact us',
  REQUEST_BOX = 'request a box',
  REQUEST_OFFER = 'request an offer',
}
