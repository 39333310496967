import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useGetCurrencySymbol } from 'app/appState';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { Box, CachedBox } from '../../model/types';
import { getBoxRateField } from '../helpers/getBoxRateField';

export const useGetBoxRentalPrice = (
  boxInfo: Nullable<Box> | Nullable<CachedBox>,
  invoiceFrequencyType?: InvoiceFrequencyType,
): string | undefined => {
  const { t } = useAppTranslation('booking');

  const currencySymbol = useGetCurrencySymbol();

  if (!boxInfo) {
    return undefined;
  }

  if (invoiceFrequencyType) {
    const rateField = getBoxRateField(invoiceFrequencyType);

    if (rateField && boxInfo[rateField]) {
      return `${roundNumber((boxInfo[rateField] as number) * boxInfo.priceFactor)} ${currencySymbol}/${t(invoiceFrequencyType)}`;
    }

    return undefined;
  }

  if (boxInfo?.monthRate) {
    return `${roundNumber(boxInfo.monthRate * boxInfo.priceFactor)} ${currencySymbol}/${t('month')}`;
  }

  if (boxInfo?.weekRate) {
    return `${roundNumber(boxInfo.weekRate * boxInfo.priceFactor)} ${currencySymbol}/${t('week')}`;
  }

  if (boxInfo?.dailyRate) {
    return `${roundNumber(boxInfo.dailyRate * boxInfo.priceFactor)} ${currencySymbol}/${t('day')}`;
  }
};
