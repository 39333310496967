import { useAppSelector } from 'app/config/storeConfig/hooks';
import { getContractOptions } from 'entities/Contract';
import { getLoggedUserData } from '../../model/selectors/getLoggedUserData';
import { checkUserDataComplete } from '../helpers/checkUserDataComplete';

export const useCheckUserDataComplete = (): boolean => {
  const loggedUserData = useAppSelector(getLoggedUserData);
  const contractOptions = useAppSelector(getContractOptions);

  return checkUserDataComplete(loggedUserData, contractOptions.accountType);
};
