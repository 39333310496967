import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookingHeaderSchema, bookingSteps, BookingSteps } from '../types';

const initialState: BookingHeaderSchema = {
  steps: bookingSteps,
  activeStep: BookingSteps.SELECT_LOCATION,
  maxProgressStep: BookingSteps.SELECT_LOCATION,
  completedSteps: [],
};

const bookingHeaderSlice = createSlice({
  name: 'bookingHeader',
  initialState,
  reducers: {
    changeActiveStep: (state: BookingHeaderSchema, action: PayloadAction<BookingSteps>) => {
      const currentStepIndex = state.steps.findIndex((step) => step.label === action.payload);
      const previousSteps = state.steps.filter((step, index) => index < currentStepIndex);

      state.completedSteps = previousSteps;
      state.activeStep = action.payload;

      // If user goes to any previous step we need to save current booking progress
      const previousMaxProgressIndex = state.steps.findIndex((step) => step.label === state.maxProgressStep);

      if (previousMaxProgressIndex < currentStepIndex) {
        state.maxProgressStep = action.payload;
      }
    },
    changeStepData: (state: BookingHeaderSchema, action: PayloadAction<{ targetStep: BookingSteps; stepData: string }>) => {
      state.steps = state.steps.map((step) =>
        step.label === action.payload.targetStep ? { ...step, stepData: action.payload.stepData } : step,
      );
    },
    goToPreviousStep: (state) => {
      const currentStepIndex = state.steps.findIndex((step) => step.label === state.activeStep);
      const previousStepLabel = state.steps.find((_, index) => index === currentStepIndex - 1)?.label || BookingSteps.SELECT_LOCATION;
      const previousSteps = state.steps.filter((_, index) => index < currentStepIndex - 1);

      state.completedSteps = previousSteps;
      state.activeStep = previousStepLabel as BookingSteps;
    },
    goToMaxProgressStep: (state) => {
      const maxProgressStepIndex = state.steps.findIndex((step) => step.label === state.maxProgressStep);
      const previousSteps = state.steps.filter((step, index) => index < maxProgressStepIndex);

      state.completedSteps = previousSteps;
      state.activeStep = state.maxProgressStep;
    },
    clearBookingProgressData: (state) => {
      state.completedSteps = [];
      state.maxProgressStep = BookingSteps.SELECT_LOCATION;
    },
    resetAllBookingData: (state) => {
      state.activeStep = BookingSteps.SELECT_LOCATION;
      state.completedSteps = [];
      state.maxProgressStep = BookingSteps.SELECT_LOCATION;
    },
  },
});

export const { actions: bookingHeaderActions, reducer: bookingHeaderReducer } = bookingHeaderSlice;
