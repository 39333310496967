import { DefaultOptionType } from 'antd/es/select';
import { useGetAllWarehousesQuery } from 'entities/Warehouse';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Select } from 'shared/ui/Select';
import { InvoicesInsuranceListFilterKeys } from '../../model/types';

interface WarehouseSelectProps {
  value: string;
  onChange: (value: string, label: string) => void;
  onClear: (filterKey: string) => void;
}

export const WarehouseSelect: FC<WarehouseSelectProps> = memo((props) => {
  const { value, onChange, onClear } = props;

  const { t } = useAppTranslation('common');

  const { data } = useGetAllWarehousesQuery();

  const warehouseOptions = useMemo(() => {
    return (
      data?.map((warehouse) => ({
        label: warehouse.name,
        value: warehouse.warehouseId,
      })) || []
    );
  }, [data]);

  const handleChange = useCallback(
    (value: string, option?: DefaultOptionType | DefaultOptionType[]): void => {
      if (!Array.isArray(option) && typeof option?.label === 'string') {
        onChange(value, option.label);
      }
    },
    [onChange],
  );

  const clearFilter = useCallback(() => {
    onClear(InvoicesInsuranceListFilterKeys.selectedWarehouseId);
  }, [onClear]);

  return (
    <Select
      className="w-full"
      label={t('Warehouse')}
      placeholder={t('All')}
      value={value}
      bordered
      allowClear
      options={warehouseOptions}
      onChange={handleChange}
      onClear={clearFilter}
    />
  );
});
