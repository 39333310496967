import { API_URL } from 'app/config/envVariables';
import { getAccessToken } from 'entities/User';

export const generateInvoiceCreditNotePdfLink = (invoiceId: string, creditNoteId: string): string => {
  const token = getAccessToken();

  const creditNotePdfLink = `${API_URL}/billing-documents/invoices/${invoiceId}/credit-note/${creditNoteId}/pdf?token=${token}`;

  return creditNotePdfLink;
};
