import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Franchise } from '../model/types';

interface Params {
  search?: string;
}

export const getPaginatedFranchisesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPaginatedFranchises: builder.query<Pagination<Franchise>, Params>({
      query: (params) => ({
        url: '/franchises/paginated',
        params,
      }),
      providesTags: ['Franchises'],
    }),
  }),
});

export const { useGetPaginatedFranchisesQuery } = getPaginatedFranchisesApi;
