import classNames from 'classnames';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { Box } from 'entities/Box';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { RentOptionDocument } from 'entities/RentOption';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { useGetBoxesSuggestionQuery } from '../api/getBoxesSuggestion';
import { BoxRow } from './BoxRow';

interface BoxesContainerProps {
  sizeCodeId: string;
  selectedBoxId: string | undefined;
  rentDiscounts: RentOptionDocument[];
  generalDiscounts: RentOptionDocument[];
  onSelectBox: (box: Box, sizeCodeId: string) => void;
  onBookBox: (box: Box, sizeCodeId: string) => void;
}

export const BoxesContainer: FC<BoxesContainerProps> = memo((props) => {
  const { sizeCodeId, selectedBoxId, rentDiscounts, generalDiscounts, onSelectBox, onBookBox } = props;

  const { t } = useAppTranslation('booking');

  const { data } = useGetBoxesSuggestionQuery(sizeCodeId, { skip: !sizeCodeId });

  const selectBox = useCallback(
    (box: Box): void => {
      onSelectBox(box, sizeCodeId);
    },
    [onSelectBox, sizeCodeId],
  );

  const bookBox = useCallback(
    (box: Box): void => {
      onBookBox(box, sizeCodeId);
    },
    [onBookBox, sizeCodeId],
  );

  const hasRentDiscounts = rentDiscounts.length > 0;
  const hasGeneralDiscounts = generalDiscounts.filter(({ showAsPromotion }) => showAsPromotion).length > 0;

  const discountsLabel = useMemo(() => {
    const rentDiscountsLabel = rentDiscounts.map((discount) => `“${getLocalizedString(discount.label)}“`).join(', ');
    const generalDiscountsLabel = generalDiscounts
      .filter((discount) => discount.showAsPromotion)
      .map((discount) => `“${getLocalizedString(discount.label)}“`)
      .join(', ');

    const needComma = rentDiscountsLabel && generalDiscountsLabel;

    return `${rentDiscountsLabel}${needComma ? ', ' : ''}${generalDiscountsLabel}`;
  }, [generalDiscounts, rentDiscounts]);

  return (
    <div className={classNames('relative', { 'min-h-[307px]': !data?.content.length })}>
      {discountsLabel && (
        <div className="bg-accent text-white rounded-b-lg text-center px-2">
          {t('This size is discounted the {{discountsLabel}}', { discountsLabel })}
        </div>
      )}
      <div className={classNames('desktop:p-4', { 'blur-[3px] pointer-events-none': data?.noAvailableBoxes })}>
        {Boolean(data?.content.length) &&
          data?.content.map((box) => (
            <BoxRow
              key={box.boxId}
              box={box}
              selectedBoxId={selectedBoxId}
              hasRentDiscounts={hasRentDiscounts}
              hasGeneralDiscounts={hasGeneralDiscounts}
              onSelectBox={selectBox}
              onBookBox={bookBox}
            />
          ))}
      </div>
    </div>
  );
});
