import { api } from 'app/config/apiConfig';
import { Insurance } from '../model/types';

export const getInsuranceByWarehouseIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInsuranceByWarehouseId: build.query<Insurance[], string | undefined>({
      query: (warehouseId) => ({
        url: `/warehouses/${warehouseId}/insurance`,
      }),
    }),
  }),
});

export const { useGetInsuranceByWarehouseIdQuery } = getInsuranceByWarehouseIdApi;
