import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { CreditNote, CreditNoteType } from '../model/types';

interface Params {
  warehouseId?: string;
  contractId?: string;
  search?: string;
  userId?: string;
  creditedAmount?: number;
  issuedBy?: string | null;
  orderBy?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  creditNoteType?: CreditNoteType;
}

export const getPaginatedCreditNotesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedCreditNotes: build.query<Pagination<CreditNote>, Params | void>({
      query: (args) => ({
        url: '/billing-documents/credit-notes',
        params: args ? { ...args } : undefined,
      }),
      providesTags: ['Credit Notes'],
    }),
  }),
});

export const { useGetPaginatedCreditNotesQuery } = getPaginatedCreditNotesApi;
