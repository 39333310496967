import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as UserIcon } from 'shared/assets/icons/UserIcon.svg';
import { Button } from 'shared/ui/Button';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { logInModalActions } from 'widgets/LogInModal';
import { useScreenBreakpoints } from 'shared/utils/hooks/useScreenBreakpoints';

interface LogInButtonProps {
  className?: string;
}

export const LogInButton: FC<LogInButtonProps> = memo((props) => {
  const { className } = props;

  const { t } = useAppTranslation('header');
  const dispatch = useAppDispatch();
  const { isDesktop } = useScreenBreakpoints();

  const openLogInModal = useCallback((): void => {
    dispatch(logInModalActions.setOpened(true));
  }, [dispatch]);

  return (
    <Button containerClassName={className} size="small" icon={isDesktop ? <UserIcon /> : undefined} onClick={openLogInModal}>
      {t('Log in')}
    </Button>
  );
});
