import React, { FC, memo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker as AntdDatePicker } from 'antd';
import { DatePickerProps as AntdDatePickerProps } from 'antd/es/date-picker';
import s from './index.module.scss';
import classNames from 'classnames';
import { useAppTranslation } from 'app/config/i18Config/hooks';

interface DatePickerProps {
  format?: string;
  className?: string;
  onChange?: (date: Dayjs) => void;
  placeholder?: string;
  disabled?: boolean;
  inputReadOnly?: boolean;
  disabledDate?: (date: Dayjs) => boolean;
  showToday?: boolean;
  showPresets?: boolean;
  value?: Nullable<Dayjs>;
  defaultPickerValue?: Nullable<Dayjs>;
  bordered?: boolean;
  requiredMark?: boolean;
  presets?: AntdDatePickerProps['presets'];
}

export const DatePicker: FC<DatePickerProps> = memo((props) => {
  const {
    format,
    className,
    onChange,
    placeholder,
    disabled,
    inputReadOnly,
    disabledDate,
    showToday,
    showPresets,
    value,
    defaultPickerValue,
    requiredMark,
    bordered = true,
    presets,
  } = props;

  const { t } = useAppTranslation('common');

  const defaultPresets = [
    {
      label: t('Today'),
      value: dayjs(),
    },
    {
      label: t('7 Days ago'),
      value: dayjs().subtract(7, 'day'),
    },
    {
      label: t('2 Weeks ago'),
      value: dayjs().subtract(2, 'week'),
    },
    {
      label: t('1 Month ago'),
      value: dayjs().subtract(1, 'month'),
    },
    {
      label: t('3 Months ago'),
      value: dayjs().subtract(3, 'month'),
    },
    {
      label: t('6 Months ago'),
      value: dayjs().subtract(6, 'month'),
    },
    {
      label: t('1 Year ago'),
      value: dayjs().subtract(1, 'year'),
    },
  ];

  return (
    <div className="relative">
      {requiredMark && <div className="text-error text-2xl absolute z-10 top-0 left-1 pointer-events-none">*</div>}
      <AntdDatePicker
        format={format}
        className={classNames(s.datepicker, { [s.borderless]: !bordered }, className)}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        inputReadOnly={inputReadOnly}
        disabledDate={disabledDate}
        value={value || undefined}
        defaultPickerValue={defaultPickerValue}
        presets={showPresets ? presets || defaultPresets : undefined}
        showNow={!showPresets && showToday}
      />
    </div>
  );
});
