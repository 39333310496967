import { API_URL } from 'app/config/envVariables';
import { getAccessToken } from 'entities/User';

export const generateInvoicesPdfLink = (query: AnyObject): string => {
  const token = getAccessToken();

  const filteredQuery = Object.fromEntries(Object.entries(query).filter(([_, value]) => value !== undefined));

  const urlParams = new URLSearchParams(filteredQuery);

  if (token) {
    urlParams.append('token', token);
  }

  // Construct the complete URL
  const invoicePdfLink = `${API_URL}/billing-documents/invoices/pdf?${urlParams.toString()}`;

  return invoicePdfLink;
};
