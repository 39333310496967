import { Card, Form } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { AppLogo } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useResetPasswordMutation } from 'entities/User';
import { LangSwitcher } from 'features/LangSwitcher';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { useCountDown } from 'shared/utils/hooks/useCountDown';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';

export const ForgotPasswordPage: FC = memo(() => {
  const { t } = useAppTranslation(['login-page', 'common']);

  const { isCountDown, secondsLeft, runCountDown } = useCountDown();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [form] = Form.useForm();

  const handleSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await resetPassword(email).unwrap();
        runCountDown(60);
        showNotification('info', t('Success', { ns: 'common' }), t('Instructions was sent on your email', { ns: 'common' }));
      } catch (error: CustomAny) {
        if (isErrorApiResponse(error)) {
          showNotification('error', t('Error', { ns: 'common' }), t(error.data.message, { ns: 'common' }));
        } else {
          console.log(error);
        }
      }
    },
    [resetPassword, runCountDown, t],
  );

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-accentLight">
      <Card
        className="w-[370px] pt-[24px] tablet:w-[450px]"
        title={
          <div className="flex items-center p-3 justify-center">
            <AppLogo width={180} height="100%" />
            <div className="absolute top-0 right-0">
              <LangSwitcher />
            </div>
          </div>
        }
      >
        <Form name="forgotPassword" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('Please enter your email!') },
              { type: 'email', message: t('Please enter correct email!') },
            ]}
          >
            <Input placeholder={t('Your email')} bordered />
          </Form.Item>
          <Button type="submit" isLoading={isLoading} isDisabled={isCountDown}>
            {!isCountDown ? t('Send instructions') : t('Resend instructions in {{secondsLeft}} sec', { secondsLeft })}
          </Button>
        </Form>
      </Card>
    </div>
  );
});
