export const copyText = async (text: string | null | undefined): Promise<void> => {
  if (!navigator.clipboard) {
    console.log('Clipboard is unavailable');

    return;
  }

  if (text) {
    await navigator.clipboard.writeText(text);
  }
};
