import { User, UserAccountType } from '../../model/types';

export const checkUserDataComplete = (userData: Nullable<User>, accountType?: UserAccountType): boolean => {
  if (!userData) {
    return false;
  }

  const {
    userId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    street,
    house,
    zip,
    email,
    country,
    city,
    language,
    accountType: userDataAccountType,
    role,
    company,
  } = userData;

  let hasEmptyFields =
    !userId ||
    !firstName ||
    !lastName ||
    !phone ||
    !street ||
    !house ||
    !zip ||
    !email ||
    !country ||
    !city ||
    !language ||
    !userDataAccountType ||
    !role;

  if (accountType === UserAccountType.BUSINESS) {
    hasEmptyFields = hasEmptyFields || !company;
  }

  return !hasEmptyFields;
};
