export interface HistoryAction {
  actionId: string;
  actionName: HistoryActionName;
  actionData: HistoryActionData;
  warehouseId?: string;
  createdAt: string;
  updatedAt: string;
}

export enum UserHistoryActionNames {
  login_success = 'login-success',
  login_fail = 'login-fail',
  password_change = 'password-change',
  new_registration = 'new-registration',
  registration_fail = 'user-registration-fail',
  user_updated = 'user-updated',
}

export enum BoxHistoryActionNames {
  box_open = 'box-open',
  box_close = 'box-close',
  box_open_attempt = 'box-open-attempt',
  box_open_long_time = 'box-open-long-time',
  box_unauthorized_access = 'box-unauthorized-access',
  box_verify_state = 'box-verify-state',
  box_update = 'box-update',
  box_create = 'box-create',
}

export enum WarehouseHistoryActionNames {
  door_open_attempt = 'warehouse-open-attempt',
  siren_turned_on = 'siren-turned-on',
}

export enum ReservationHistoryActionNames {
  reservation_create = 'reservation-create',
  reservation_take_up = 'reservation-take-up',
}

export enum ContractHistoryActionNames {
  contract_create = 'contract-create',
}

export enum AppealHistoryActionNames {
  appeal_create = 'appeal-create',
  appeal_creation_fail = 'appeal-creation-fail',
}

export enum LeadHistoryActionNames {
  lead_create = 'lead-create',
  lead_creation_fail = 'lead-creation-fail',
}

export type HistoryActionName =
  | UserHistoryActionNames
  | BoxHistoryActionNames
  | WarehouseHistoryActionNames
  | ReservationHistoryActionNames
  | ContractHistoryActionNames
  | AppealHistoryActionNames
  | LeadHistoryActionNames;

export interface HistoryActionData {
  warehouseId?: string;
  boxId?: string;
  userId?: string;
  targetName?: string;
  targetType?: HistoryActionTargetType;
  warehouseName?: string;
  userName?: string;
  boxName?: string;
  boxTimeToAlert?: number;
}

export enum HistoryActionTargetType {
  BOX = 'box',
  WAREHOUSE = 'warehouse',
  USER = 'user',
  APPEAL = 'appeal',
  LEAD = 'lead',
}
