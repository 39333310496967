import { useCallback, useState } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useCompleteRegistrationMutation, CompleteRegisterUserParams } from 'entities/User';
import { getServerDateFormat } from 'shared/utils/helpers/getDateFormat';

interface HookApi {
  isOpenedConfirm: boolean;
  isLoading: boolean;
  completeRegistration: (args: HookArgs) => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
}

interface HookArgs {
  userId: string;
  userData: CompleteRegisterUserParams;
  onSuccess?: () => void;
}

export const useCompleteRegistration = (): HookApi => {
  const { t } = useAppTranslation('common');

  const [isOpenedConfirm, setOpenedConfirm] = useState(false);

  const [completeRegistrationService, { isLoading }] = useCompleteRegistrationMutation();

  const openConfirmModal = useCallback((): void => {
    setOpenedConfirm(true);
  }, []);

  const closeConfirmModal = useCallback((): void => {
    setOpenedConfirm(false);
  }, []);

  const completeRegistration = useCallback(
    async ({ userId, userData, onSuccess }: HookArgs): Promise<void> => {
      try {
        await completeRegistrationService({
          userId,
          userData: { ...userData, dateOfBirth: getServerDateFormat(userData.dateOfBirth) },
        }).unwrap();

        showNotification('info', t('Success'), t('User has been successfully updated'));
        setTimeout(() => {
          onSuccess?.();
        }, 0); // костыль, если форма complete registration рисуется в модалке, и надо сначала закрыть confirm модалку, а потом её
      } catch (error) {
        console.log(error);
      } finally {
        closeConfirmModal();
      }
    },
    [closeConfirmModal, completeRegistrationService, t],
  );

  return {
    openConfirmModal,
    closeConfirmModal,
    completeRegistration,
    isLoading,
    isOpenedConfirm,
  };
};
