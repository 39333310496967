import React, { FC, memo } from 'react';
import { TableFilterContextProvider } from 'features/TableFilter';
import { InvoicesTable } from './InvoicesTable';

export const InvoicesPage: FC = memo(() => {
  return (
    <TableFilterContextProvider>
      <InvoicesTable />
    </TableFilterContextProvider>
  );
});
