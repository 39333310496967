import { api } from 'app/config/apiConfig';
import { InternalEquipment } from '../model/types';

export const getInternalEquipmentByWarehouseIdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInternalEquipmentByWarehouseId: build.query<InternalEquipment[], Nullable<string>>({
      query: (warehouseId) => ({
        url: `/warehouses/${warehouseId}/internal-equipments`,
      }),
    }),
  }),
});

export const { useGetInternalEquipmentByWarehouseIdQuery } = getInternalEquipmentByWarehouseIdApi;
