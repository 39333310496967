import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useTakeUpReservationMutation } from 'entities/Reservation';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { getTakeUpReservationState } from '../model/selectors/getTakeUpReservationState';
import { takeUpReservationModalActions } from '../model/slice/takeUpReservationModalSlice';

export const TakeUpReservationModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { reservation } = useAppSelector(getTakeUpReservationState);

  const [takeUpReservation, { isLoading }] = useTakeUpReservationMutation();

  const closeTakeUpReservationModal = useCallback((): void => {
    dispatch(takeUpReservationModalActions.openTakeUpReservationModal(null));
  }, [dispatch]);

  const handleTakeUpReservation = useCallback(async (): Promise<void> => {
    try {
      if (reservation) {
        await takeUpReservation(reservation.reservationId).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Contract has been successfully created', { ns: 'common' }));
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error', { ns: 'common' }), t('Error when creating contract', { ns: 'common' }));
    } finally {
      closeTakeUpReservationModal();
    }
  }, [takeUpReservation, reservation, closeTakeUpReservationModal, t]);

  return (
    <>
      <ConfirmModal
        isOpened={Boolean(reservation)}
        title={t('Enter into contract')}
        description={t('Are you sure you want to enter into contract?')}
        isLoading={isLoading}
        onOk={handleTakeUpReservation}
        onCancel={closeTakeUpReservationModal}
      />
    </>
  );
});
