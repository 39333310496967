import React, { FC, memo, PropsWithChildren } from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import s from './index.module.scss';

interface CheckboxProps extends PropsWithChildren {
  name: string;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (isChecked: boolean) => void;
  onChangeEvent?: (e: CheckboxChangeEvent) => void;
  requiredMark?: boolean;
  indeterminate?: boolean;
}

export const Checkbox: FC<CheckboxProps> = memo((props) => {
  const { className, name, checked, defaultChecked, onChange, children, disabled, requiredMark, onChangeEvent, indeterminate } = props;

  const handleChange = (e: CheckboxChangeEvent): void => {
    onChange?.(e.target.checked);
  };

  return (
    <div className="flex items-center">
      {requiredMark && <div className="text-error text-2xl mr-1 pointer-events-none">*</div>}
      <AntdCheckbox
        className={classNames(s.checkbox, className)}
        name={name}
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChangeEvent || handleChange}
        disabled={disabled}
        indeterminate={indeterminate}
      >
        {children}
      </AntdCheckbox>
    </div>
  );
});
