import { api } from 'app/config/apiConfig';
import { ChangeUserAddressParams } from '../model/types';
import { USERS_PATH } from '../utils/constants';

export const changeUserAddressApi = api.injectEndpoints({
  endpoints: (builder) => ({
    changeUserAddress: builder.mutation<void, { userId: string; params: ChangeUserAddressParams }>({
      query: ({ userId, params }) => ({
        url: `${USERS_PATH}/${userId}/address`,
        method: 'PUT',
        body: params,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useChangeUserAddressMutation } = changeUserAddressApi;
