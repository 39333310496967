import { api } from 'app/config/apiConfig';
import { LateChargeSetting } from '../model/types';

export const deleteLateChargeSettingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteLateChargeSetting: builder.mutation<LateChargeSetting, string>({
      query: (lateChargeSettingId: string) => ({
        url: `/late-charge-settings/${lateChargeSettingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Late charge settings'],
    }),
  }),
});

export const { useDeleteLateChargeSettingMutation } = deleteLateChargeSettingApi;
