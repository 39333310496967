import { api } from 'app/config/apiConfig';
import { AuthResponse, userActions } from 'entities/User';
import i18n from 'app/config/i18Config/i18n';
import { showNotification } from 'app/providers/NotificationsProvider';
import { AUTH_LOGOUT_PATH } from '../utils/constants';

export const logOutApi = api.injectEndpoints({
  endpoints: (builder) => ({
    logOut: builder.mutation<AuthResponse, void>({
      query: () => ({
        url: AUTH_LOGOUT_PATH,
        method: 'PUT',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          dispatch(userActions.logOut());
        } catch (error: CustomAny) {
          showNotification('error', i18n.t('Error', { ns: 'common' }), i18n.t('Error when logging out', { ns: 'common' }));
        }
      },
    }),
  }),
});

export const { useLogOutMutation } = logOutApi;
