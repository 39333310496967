import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Box } from '../model/types';

interface Params {
  page?: number;
  limit?: number;
  sizeCodeId?: string;
  orderBy?: string;
  extendFloor?: boolean;
  warehouseId?: string;
}

export const getPaginatedBoxesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedBoxes: build.query<Pagination<Box>, Params>({
      query: (args) => ({
        url: '/boxes',
        params: args,
      }),
      providesTags: ['Boxes'],
    }),
  }),
});

export const { useGetPaginatedBoxesQuery } = getPaginatedBoxesApi;
