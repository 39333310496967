import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Warehouse } from 'entities/Warehouse';
import { Box } from 'entities/Box';
import { SendOfferModalSchema, sendOfferSteps } from '../types';
import { AppLanguage } from 'app/config/i18Config/types';

const initialState: SendOfferModalSchema = {
  isOpened: false,
  steps: sendOfferSteps,
  selectedEmail: null,
  selectedLanguage: null,
  selectedWarehouse: null,
  selectedBoxes: [],
  note: null,
};

const sendOfferModalSlice = createSlice({
  name: 'sendOfferModal',
  initialState,
  reducers: {
    setOpened: (state: SendOfferModalSchema, action: PayloadAction<boolean>) => {
      state.isOpened = action.payload;
    },
    changeStepData: (state: SendOfferModalSchema, action: PayloadAction<{ targetStepIndex: number; stepData: string }>) => {
      state.steps = state.steps.map((step, index) =>
        index === action.payload.targetStepIndex ? { ...step, stepData: action.payload.stepData } : step,
      );
    },
    setSelectedEmail: (state: SendOfferModalSchema, action: PayloadAction<string>) => {
      state.selectedEmail = action.payload;
    },
    setSelectedLanguage: (state: SendOfferModalSchema, action: PayloadAction<AppLanguage>) => {
      state.selectedLanguage = action.payload;
    },
    setSelectedWarehouse: (state: SendOfferModalSchema, action: PayloadAction<Warehouse>) => {
      state.selectedWarehouse = action.payload;
    },
    setSelectedBoxes: (state: SendOfferModalSchema, action: PayloadAction<Box[]>) => {
      state.selectedBoxes = action.payload;
    },
    setNote: (state: SendOfferModalSchema, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    resetModalState: (state: SendOfferModalSchema) => {
      state.selectedEmail = null;
      state.selectedLanguage = null;
      state.selectedWarehouse = null;
      state.selectedBoxes = [];
      state.note = null;
      state.isOpened = false;
    },
  },
});

export const { actions: sendOfferModalActions, reducer: sendOfferModalReducer } = sendOfferModalSlice;
