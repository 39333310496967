import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { InvoiceFrequencyType } from 'entities/Invoice';
import { Box, CachedBox } from '../../model/types';
import { getBoxRateField } from './getBoxRateField';

export const getBoxRentalPrice = (
  boxInfo?: Nullable<Box> | Nullable<CachedBox>,
  invoiceFrequencyType?: InvoiceFrequencyType,
): number | undefined => {
  if (!boxInfo) {
    return undefined;
  }

  if (invoiceFrequencyType) {
    const rateField = getBoxRateField(invoiceFrequencyType);

    if (!rateField) {
      return undefined;
    }

    return roundNumber((boxInfo[rateField] as number) * boxInfo.priceFactor);
  }

  if (boxInfo.monthRate) {
    return roundNumber(boxInfo.monthRate * boxInfo.priceFactor);
  }

  if (boxInfo.weekRate) {
    return roundNumber(boxInfo.weekRate * boxInfo.priceFactor);
  }

  if (boxInfo.dailyRate) {
    return roundNumber(boxInfo.dailyRate * boxInfo.priceFactor);
  }
};
