import classNames from 'classnames';
import { Box } from 'entities/Box';
import { BoxInfoStack } from 'features/BoxInfoStack';
import { StepData } from 'features/Stepper';
import { WarehouseInfoStack } from 'features/WarehouseInfoStack';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { ReactComponent as BoxIcon } from 'shared/assets/icons/BoxIcon.svg';
import { ReactComponent as DocumentIcon } from 'shared/assets/icons/DocumentIcon.svg';
import { ReactComponent as WarehouseIcon } from 'shared/assets/icons/WarehouseIcon.svg';
import { Collapse } from 'shared/ui/Collapse';
import { BookingSteps } from 'widgets/BookingHeader';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { WarehouseForBooking } from 'entities/Warehouse';
import { useSubscribeOpenedStack } from '../lib/hooks/useSubscribeOpenedStack';
import { bookingInfoStackActions } from '../model/slice/bookingInfoStackSlice';
import { BookingInfoStacks, BookingStack } from '../model/types';
import { OrderSummaryStack } from 'features/OrderSummaryStack';
import { ContractInfo } from 'entities/Contract';

interface BookingInfoStackProps {
  bookingStep: Nullable<BookingSteps>;
  completedSteps: StepData[];
  warehouseInfo: Nullable<WarehouseForBooking>;
  boxInfo: Nullable<Box>;
  contractInfo: ContractInfo;
  className?: string;
}

export const BookingInfoStack: FC<BookingInfoStackProps> = memo((props) => {
  const { bookingStep, completedSteps, warehouseInfo, boxInfo, contractInfo, className } = props;

  const { t } = useAppTranslation('booking');
  const dispatch = useAppDispatch();

  const showWarehouseInfo = Boolean(completedSteps.find((step) => step.label === BookingSteps.SELECT_LOCATION));
  const showBoxInfo = Boolean(completedSteps.find((step) => step.label === BookingSteps.SELECT_LOCATION)) && Boolean(boxInfo);
  const showSummaryInfo = Boolean(completedSteps.find((step) => step.label === BookingSteps.CONTRACT_INFORMATION));

  const openedStack = useSubscribeOpenedStack(bookingStep, boxInfo);

  const openStack = useCallback(
    (key: string): void => {
      dispatch(bookingInfoStackActions.setOpenedStack(key as BookingInfoStacks));
    },
    [dispatch],
  );

  return (
    <div className={classNames('space-y-3', className)}>
      {showSummaryInfo && (
        <Collapse
          title={t(BookingStack.ORDER_SUMMARY)}
          content={<OrderSummaryStack contractInfo={contractInfo} boxInfo={boxInfo} />}
          activeKey={BookingInfoStacks.ORDER_SUMMARY}
          isOpened={openedStack === BookingInfoStacks.ORDER_SUMMARY}
          Icon={DocumentIcon}
          onChange={openStack}
        />
      )}
      {showBoxInfo && (
        <Collapse
          title={t(BookingStack.YOUR_BOX_UNIT)}
          content={<BoxInfoStack boxInfo={boxInfo} />}
          activeKey={BookingInfoStacks.YOUR_BOX_UNIT}
          isOpened={openedStack === BookingInfoStacks.YOUR_BOX_UNIT}
          Icon={BoxIcon}
          onChange={openStack}
        />
      )}
      {showWarehouseInfo && (
        <Collapse
          title={t(BookingStack.WAREHOUSE_INFORMATION)}
          content={<WarehouseInfoStack warehouseInfo={warehouseInfo} />}
          activeKey={BookingInfoStacks.WAREHOUSE_INFORMATION}
          isOpened={openedStack === BookingInfoStacks.WAREHOUSE_INFORMATION}
          Icon={WarehouseIcon}
          onChange={openStack}
        />
      )}
    </div>
  );
});
