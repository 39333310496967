import { Form } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useResetPasswordMutation } from 'entities/User';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/ArrowRight.svg';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { isErrorApiResponse } from 'shared/utils/helpers/isErrorApiResponse';
import { useCountDown } from 'shared/utils/hooks/useCountDown';
import { logInModalActions } from '../model/slice/loginModalSlice';

export const ForgotPasswordForm: FC = memo(() => {
  const { t } = useAppTranslation(['login-page', 'common']);

  const dispatch = useAppDispatch();

  const { isCountDown, secondsLeft, runCountDown } = useCountDown();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [form] = Form.useForm();

  const openAuthScreen = (): void => {
    dispatch(logInModalActions.changeMode('login'));
  };

  const submit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await resetPassword(email).unwrap();
        runCountDown(60);
        showNotification('info', t('Success', { ns: 'common' }), t('Instructions was sent on your email', { ns: 'common' }));
      } catch (error: CustomAny) {
        if (isErrorApiResponse(error)) {
          showNotification('error', t('Error', { ns: 'common' }), t(error.data.message));
        } else {
          console.log(error);
        }
      }
    },
    [resetPassword, runCountDown, t],
  );

  return (
    <>
      <div className="flex items-center text-sm text-accent cursor-pointer mb-10" onClick={openAuthScreen}>
        <ArrowRight className="[&>path]:stroke-accent rotate-180 mr-2 w-5" />
        {t('Go back')}
      </div>
      <Form name="forgotPassword" form={form} onFinish={submit}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t('Please enter your email!') },
            { type: 'email', message: t('Please enter correct email!') },
          ]}
        >
          <Input placeholder={t('Your email')} bordered />
        </Form.Item>
        <Button type="submit" isLoading={isLoading} isDisabled={isCountDown}>
          {!isCountDown ? t('Send instructions') : t('Resend instructions in {{secondsLeft}} sec', { secondsLeft })}
        </Button>
      </Form>
    </>
  );
});
