import { API_URL } from 'app/config/envVariables';
import { getAccessToken } from 'entities/User';

export const generateInvoicePdfLink = (invoiceId: string): string => {
  const token = getAccessToken();

  const invoicePdfLink = `${API_URL}/billing-documents/invoices/${invoiceId}/pdf?token=${token}`;

  return invoicePdfLink;
};
