import React, { FC, memo } from 'react';

export type BadgeColor = 'success' | 'accent' | 'warn' | 'primary' | 'primaryLight' | 'error';

interface RowBadgeProps {
  title: string;
  color?: BadgeColor;
}

export const RowBadge: FC<RowBadgeProps> = memo((props) => {
  const { title, color = 'success' } = props;

  const bgColorMap: Record<BadgeColor, string> = {
    success: 'bg-success',
    accent: 'bg-accent',
    warn: 'bg-warn',
    primary: 'bg-primary',
    primaryLight: 'bg-primaryLight',
    error: 'bg-error',
  };

  return (
    <div className={`absolute top-0 left-0 text-sm text-white ${bgColorMap[color]} font-normal rounded-br-lg px-5 py-0.5`}>{title}</div>
  );
});
