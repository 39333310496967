import classNames from 'classnames';
import React, { FC, memo, ReactNode, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/SelectArrow.svg';
import { AutoplayOptions } from 'swiper/types/modules/autoplay';

interface SliderProps {
  slides: ReactNode[] | undefined;
  slidesPerView?: number;
  spaceBetween?: number;
  autoplay?: AutoplayOptions | boolean;
  pagination?: boolean;
}

export const Slider: FC<SliderProps> = memo((props) => {
  const { slides = [], slidesPerView = 1, spaceBetween, autoplay, pagination } = props;

  const prevNavigationRef = useRef<Nullable<HTMLDivElement>>(null);
  const nextNavigationRef = useRef<Nullable<HTMLDivElement>>(null);

  const navigationCommonClass =
    'absolute top-1/2 z-10 w-[30px] h-[30px] rounded-full bg-secondary flex justify-center items-center cursor-pointer -translate-y-1/2 desktop:w-[44px] desktop:h-[44px]';

  return (
    <div className="relative">
      {slides.length > slidesPerView ? (
        <>
          <div ref={prevNavigationRef} className={classNames(navigationCommonClass, 'left-1')}>
            <SelectArrow className={classNames('[&>path]:stroke-primary rotate-90')} />
          </div>
          <div ref={nextNavigationRef} className={classNames(navigationCommonClass, 'right-1')}>
            <SelectArrow className={classNames('[&>path]:stroke-primary -rotate-90')} />
          </div>
        </>
      ) : null}
      <Swiper
        className="w-[75%] [&>.swiper-wrapper]:w-0 tablet:w-[87%]" // w-0 to fix a million pixels width
        modules={[Navigation, Autoplay, Pagination]}
        pagination={pagination ? { dynamicBullets: true } : false}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        autoplay={autoplay}
        navigation={{ nextEl: nextNavigationRef.current, prevEl: prevNavigationRef.current, disabledClass: 'opacity-30' }}
        onBeforeInit={(swiper: SwiperType) => {
          swiper.params.navigation.prevEl = prevNavigationRef.current;
          swiper.params.navigation.nextEl = nextNavigationRef.current;
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});
