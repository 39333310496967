import React, { FC, memo, useCallback } from 'react';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { useCancelReservationMutation } from 'entities/Reservation';
import { ConfirmModal } from 'shared/ui/ConfirmModal';
import { showNotification } from 'app/providers/NotificationsProvider';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { cancelReservationModalActions } from '../model/slice/cancelReservationModalSlice';
import { getCancelReservationState } from '../model/selectors/getCancelReservationState';

export const CancelReservationModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { reservation } = useAppSelector(getCancelReservationState);

  const [cancelReservation, { isLoading }] = useCancelReservationMutation();

  const closeReservationModal = useCallback((): void => {
    dispatch(cancelReservationModalActions.openCancelReservationModal(null));
  }, [dispatch]);

  const handleCancelReservation = useCallback(async (): Promise<void> => {
    try {
      if (reservation) {
        await cancelReservation(reservation.reservationId).unwrap();
        showNotification('info', t('Success', { ns: 'common' }), t('Reservation has been successfully canceled'));
      }
    } catch (error: CustomAny) {
      console.log(error);
      showNotification('error', t('Error', { ns: 'common' }), t('Error when cancelling reservation'));
    } finally {
      closeReservationModal();
    }
  }, [cancelReservation, reservation, closeReservationModal, t]);

  return (
    <>
      <ConfirmModal
        isOpened={Boolean(reservation)}
        title={t('Cancel reservation')}
        description={t('Are you sure you want to cancel this reservation?')}
        isLoading={isLoading}
        onOk={handleCancelReservation}
        onCancel={closeReservationModal}
      />
    </>
  );
});
