import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { Box } from '../model/types';
import { InvoiceFrequencyType } from 'entities/Invoice';

interface Params {
  sizeCodeId?: string;
  warehouseId?: string;
  page?: number;
  limit?: number;
  isAvailable?: boolean;
  priceFactor?: number;
  priceFrom?: number;
  priceTo?: number;
  priceRateType?: InvoiceFrequencyType;
  floorId?: Nullable<string>;
  orderBy?: string;
}

export const getAvailableBoxesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAvailableBoxes: build.query<Pagination<Box>, Params>({
      query: (args) => ({
        url: '/boxes/booking',
        params: { ...args },
      }),
      providesTags: ['Available boxes'],
    }),
  }),
});

export const { useGetAvailableBoxesQuery } = getAvailableBoxesApi;
