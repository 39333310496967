import { InputNumber as AntdInputNumber } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';
import s from './index.module.scss';

interface InputNumberProps {
  value?: number;
  onChange?: (value: Nullable<number>) => void;
  placeholder?: string;
  prefix?: ReactNode;
  addonAfter?: ReactNode;
  className?: string;
  bordered?: boolean;
  requiredMark?: boolean;
  isDisabled?: boolean;
  size?: SizeType;
}

export const InputNumber: FC<InputNumberProps> = memo((props) => {
  const {
    value,
    placeholder,
    prefix,
    addonAfter,
    className,
    onChange,
    bordered = false,
    requiredMark,
    isDisabled,
    size = 'middle',
  } = props;

  const handleChange = (value: Nullable<number>): void => {
    onChange?.(value);
  };

  return (
    <>
      {requiredMark && <div className="text-error text-2xl absolute z-10 top-0 left-1 pointer-events-none">*</div>}
      <AntdInputNumber
        className={classNames(s.inputNumber, s[size], className)}
        variant={bordered ? 'outlined' : 'borderless'}
        placeholder={placeholder}
        prefix={prefix}
        value={value}
        onChange={handleChange}
        controls={false}
        disabled={isDisabled}
        addonAfter={addonAfter}
      />
    </>
  );
});
