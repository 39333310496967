import { Divider } from 'antd';
import React, { FC, memo } from 'react';
import { AccountTypeSetting } from 'entities/RentOption';
import { ReactComponent as RentOptionIcon } from 'shared/assets/icons/RentOptionIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';

interface PromotionSlideProps {
  label: string;
  accountTypeSetting: AccountTypeSetting;
  description?: string;
}

export const PromotionSlide: FC<PromotionSlideProps> = memo((props) => {
  const { label, description, accountTypeSetting } = props;

  const { t } = useAppTranslation('booking');

  const accountTypesMap: Record<AccountTypeSetting, string> = {
    [AccountTypeSetting.ALL]: t('For business and personal use'),
    [AccountTypeSetting.PERSONAL]: t('For personal use'),
    [AccountTypeSetting.BUSINESS]: t('For business'),
  };

  return (
    <div className="flex justify-between space-x-3.5 border border-accent rounded-lg p-4">
      <div className="flex flex-col flex-1">
        <div className="font-semibold text-lg text-accent">{getLocalizedString(label)}</div>
        <div className="text-sm text-primaryLight">{getLocalizedString(description)}</div>
        <div className="max-w-[40%]">
          <Divider className="my-3.5" />
        </div>
        <div className="font-normal text-xs border rounded-2xl bg-accentLight w-fit px-3 py-1">{accountTypesMap[accountTypeSetting]}</div>
      </div>
    </div>
  );
});
