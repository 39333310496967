import { ColumnsType } from 'antd/es/table';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServerTableActions } from 'shared/ui/ServerTable/types';
import { ReactComponent as FiltersIcon } from 'shared/assets/icons/FiltersIcon.svg';
import { ServerTable } from 'shared/ui/ServerTable';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { AppRoutes } from 'app/config/routerConfig/types';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { useAppDispatch } from 'app/config/storeConfig/hooks';
import { ExportEntity, ExportModal, getInsuranceColumnKeys, useOpenExportModal } from 'features/ExportModal';
import { useTableFilterContext } from 'features/TableFilter';
import { ReactComponent as ExportIcon } from 'shared/assets/icons/ExportIcon.svg';
import { useGetCurrencySymbol } from 'app/appState';
import { InsuranceListFilter } from './InsuranceListFilter';
import { InvoiceInsurance, useGetPaginatedInvoicesInsuranceQuery } from 'entities/InvoiceInsurance';
import { transformInvoicesInsuranceListFilters } from '../utils/transformInsuranceListFilters';
import { getVatRateAmount } from 'entities/Contract';
import classNames from 'classnames';
import { checkInvoiceOverdueDate } from 'entities/Invoice';

export const InvoicesInsuranceTable: FC = memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currencySymbol = useGetCurrencySymbol();

  const { openExportModal } = useOpenExportModal();

  const { t } = useAppTranslation(['contracts', 'common']);

  const { setFiltersOpened, appliedFilters, tags, clearAllFilters } = useTableFilterContext();

  const goToUserContracts = useCallback(
    (userId: string): void => {
      navigate(`${AppRoutes.USERS}/${userId}/contracts`);
    },
    [navigate],
  );

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  const columns = useMemo<ColumnsType<InvoiceInsurance>>(
    () => [
      {
        title: t('Customer'),
        width: '20%',
        key: 'user',
        render: (_, record) => (
          <div
            className="text-accent font-semibold underline cursor-pointer"
            onClick={() => {
              goToUserContracts(record.userId);
            }}
          >
            {record.userFullName}
          </div>
        ),
      },
      {
        title: t('Box'),
        key: 'boxNumber',
        width: '10%',
        render: (_, record) => {
          return <div className="text-primary font-normal">{record.boxNumber}</div>;
        },
      },
      {
        title: t('Invoice number'),
        key: 'documentNumber',
        width: '15%',
        render: (_, record) => (
          <div className="text-primary font-normal">
            {t('Invoice')} #{record.invoiceNumber}
          </div>
        ),
      },
      {
        title: t('Contract number'),
        width: '15%',
        key: 'contractNumber',
        render: (_, record) => {
          return (
            <div
              className="text-accent underline cursor-pointer"
              onClick={() => {
                openContractDetails(record.contractId);
              }}
            >
              #{record.contractNumber}
            </div>
          );
        },
      },
      {
        title: t('Warehouse'),
        key: 'warehouse',
        width: '10%',
        render: (_, record) => {
          return <div className="text-primary font-normal">{record.warehouseName}</div>;
        },
      },
      {
        title: t('Amount'),
        key: 'initialPrice',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        width: '10%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.initialPrice} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('VAT'),
        key: 'vatAmount',
        width: '10%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {getVatRateAmount(record.initialPrice, record.vatRatePercent)} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Coverage amount'),
        key: 'coverageAmount',
        width: '15%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.coverageAmount} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Insurers price'),
        key: 'insurerPrice',
        width: '15%',
        render: (_, record) => {
          return (
            <div className="text-primary font-normal">
              {record.insurerPrice} {currencySymbol}
            </div>
          );
        },
      },
      {
        title: t('Date from'),
        key: 'dateFrom',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.dateFrom)}</div>,
      },
      {
        title: t('Date to'),
        key: 'dateTo',
        width: '10%',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (_, record) => <div className="text-primary font-normal">{getClientDateFormat(record.dateTo)}</div>,
      },
    ],
    [t, goToUserContracts, openContractDetails, currencySymbol],
  );

  const tableActions: Array<ServerTableActions<InvoiceInsurance>> = useMemo(
    () => [
      {
        name: t('Export', { ns: 'common' }),
        theme: 'secondary',
        icon: <ExportIcon />,
        iconPosition: 'prev',
        onClick: () => {
          openExportModal({
            filters: transformInvoicesInsuranceListFilters(appliedFilters),
            columns: getInsuranceColumnKeys(t),
            entity: ExportEntity.INSURANCE,
          });
        },
      },
      {
        name: t('Filters', { ns: 'common' }),
        icon: <FiltersIcon />,
        theme: 'secondary',
        iconPosition: 'prev',
        onClick: () => {
          setFiltersOpened(true);
        },
      },
    ],
    [appliedFilters, openExportModal, setFiltersOpened, t],
  );

  const highlightRow = (record: InvoiceInsurance): string => {
    return classNames({
      'bg-successLight': record.invoiceDebt === 0,
      'bg-errorLight': record.invoiceDebt !== 0 && checkInvoiceOverdueDate(record.invoiceDueDate),
    });
  };

  return (
    <div className="pt-4">
      <div className="font-semibold text-3xl mb-7">{t('Insurance')}</div>
      <ServerTable
        columns={columns}
        fetchData={useGetPaginatedInvoicesInsuranceQuery}
        rowKey="invoiceItemId"
        search
        tableActionsPosition="prev"
        searchPlaceholder={t('Enter customer name or invoice | contract number')}
        tableActions={tableActions}
        tags={tags}
        onAllFiltersClear={clearAllFilters}
        defaultFilters={transformInvoicesInsuranceListFilters(appliedFilters)}
        rowClassName={highlightRow}
      />
      <ExportModal />
      <ContractDetailsDrawer />
      <InsuranceListFilter />
    </div>
  );
});
