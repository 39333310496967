import { FormInstance } from 'antd';
import React, { FC, memo, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button, ButtonSize } from 'shared/ui/Button';

interface ReserveBoxButtonProps {
  formInstance: FormInstance;
  setReservationMode: () => void;
  isDisabled?: boolean;
  className?: string;
  size?: ButtonSize;
}

export const ReserveBoxButton: FC<ReserveBoxButtonProps> = memo((props) => {
  const { isDisabled, className, size = 'large', setReservationMode, formInstance } = props;

  const { t } = useAppTranslation('booking');

  const handleSubmit = useCallback(() => {
    setReservationMode();
    formInstance.submit();
  }, [formInstance, setReservationMode]);

  return (
    <>
      <Button
        containerClassName={className}
        size={size}
        theme="secondary"
        type="submit"
        isDisabled={isDisabled}
        description={
          <Trans
            t={t}
            i18nKey="Reserve for <0>3 days free</0>. <1>No credit card needed!</1>"
            components={[<span key="0" className="text-accent text-xs font-medium" />, <div key="1" className="text-xs font-normal" />]}
          />
        }
        onClick={handleSubmit}
      >
        {t('Reserve')}
      </Button>
    </>
  );
});
