import React, { FC, memo, useCallback } from 'react';
import _ from 'lodash';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button } from 'shared/ui/Button';
import { ReactComponent as WarehouseIcon } from 'shared/assets/icons/WarehouseIcon.svg';
import { ReactComponent as ReservationIcon } from 'shared/assets/icons/ReservationIcon.svg';
import NoResultsImage from 'shared/assets/images/NoResultsImage.svg';
import { MyBooking } from 'widgets/MyBooking';
import { Collapse } from 'shared/ui/Collapse';
import { InfoTag } from 'shared/ui/InfoTag';
import { getLoggedUserData, useGetUserInvoicesQuery, useGetUserReservationsQuery } from 'entities/User';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { NoResults } from 'shared/ui/NoResults';
import { AppRoutes } from 'app/config/routerConfig/types';
import { useNavigate } from 'react-router-dom';
import { useGetContractsWithBookingInfoQuery, useGetUserBookingsQuery } from 'entities/Contract';
import { ContractDetailsDrawer, contractDetailsDrawerActions } from 'widgets/ContractDetailsDrawer';
import { CancelReservationModal } from 'features/CancelReservationModal';
import { TakeUpReservationModal } from 'features/TakeUpReservationModal';
import { PageLoader } from 'features/PageLoader';
import { UnpaidBillsNotification } from './UnpaidBillsNotification';
import { GratitudeModal } from 'features/GratitudeModal';

export const MyBoxesPage: FC = memo(() => {
  const { t } = useAppTranslation(['contracts', 'common']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loggedUserData = useAppSelector(getLoggedUserData);

  const { data: reservations, isLoading: reservationsLoading } = useGetUserReservationsQuery(loggedUserData?.userId, {
    skip: !loggedUserData?.userId,
  });

  const { data: bookings, isLoading: bookingsLoading } = useGetUserBookingsQuery(loggedUserData?.userId, {
    skip: !loggedUserData?.userId,
  });

  const { data: contractsData, isLoading: bookingContractsLoading } = useGetContractsWithBookingInfoQuery(loggedUserData?.userId, {
    skip: !loggedUserData?.userId,
  });

  const { data: unpaidInvoices, isLoading: unpaidInvoicesLoading } = useGetUserInvoicesQuery(
    {
      userId: loggedUserData?.userId,
      isActive: true,
      orderBy: 'createdAt:ASC',
      limit: 3,
    },
    {
      skip: !loggedUserData?.userId,
    },
  );

  const goToBookingPage = (): void => {
    navigate(AppRoutes.BOOKING);
  };

  const openContractDetails = useCallback(
    (contractId: string): void => {
      dispatch(contractDetailsDrawerActions.setOpenContractDetails(contractId));
    },
    [dispatch],
  );

  if (reservationsLoading || bookingsLoading || unpaidInvoicesLoading || bookingContractsLoading) {
    return <PageLoader />;
  }

  return (
    <div className="w-full">
      <ContractDetailsDrawer />
      <CancelReservationModal />
      <TakeUpReservationModal />
      <GratitudeModal />
      {unpaidInvoices?.content && !_.isEmpty(unpaidInvoices.content) && <UnpaidBillsNotification invoices={unpaidInvoices.content} />}
      {!_.isEmpty(reservations) || !_.isEmpty(bookings) ? (
        <div className="flex flex-col gap-6">
          {reservations?.map((reservation) => {
            const { reservationNumber, warehouse, boxes, reservationId } = reservation;

            return (
              <Collapse
                key={reservationId}
                title={`${t('Reservation')} ${reservationNumber} ${t('in')} ${warehouse.name}`}
                content={
                  <MyBooking
                    warehouse={warehouse}
                    boxes={boxes}
                    reservation={reservation}
                    type="reservation"
                    onOpenContractDetails={openContractDetails}
                  />
                }
                isOpened
                activeKey="1"
                Icon={ReservationIcon}
                collapsible="disabled"
              />
            );
          })}

          {!_.isEmpty(bookings) && (
            <div className="mt-2">
              <InfoTag>
                {t('You can extend the rental of the box or notify Us about the end of the rental by going to Contract details')}
              </InfoTag>
            </div>
          )}
          {bookings?.map((warehouse) => (
            <Collapse
              key={warehouse.warehouseId}
              title={warehouse.name}
              content={
                <MyBooking
                  warehouse={warehouse}
                  boxes={warehouse.userBoxes}
                  contractsData={contractsData}
                  type="contract"
                  onOpenContractDetails={openContractDetails}
                />
              }
              isOpened
              activeKey="1"
              Icon={WarehouseIcon}
            />
          ))}
        </div>
      ) : (
        <NoResults
          image={NoResultsImage}
          title={t('Looks like it`s empty here')}
          description={t('Oops, you don`t have reservations or rental boxes. Go to the booking page if you would like to rent a box')}
          actions={
            <Button containerClassName="w-full desktop:w-auto" size="medium" onClick={goToBookingPage}>
              {t('Go to booking')}
            </Button>
          }
        />
      )}
    </div>
  );
});
