import React, { FC, memo, useCallback } from 'react';
import { ReactComponent as MapIcon } from 'shared/assets/icons/MapBlackIcon.svg';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Button, ButtonThemes } from 'shared/ui/Button';

interface ShowOnMapButtonProps {
  latitude: number;
  longitude: number;
  theme?: ButtonThemes;
}

export const ShowOnMapButton: FC<ShowOnMapButtonProps> = memo((props) => {
  const { latitude, longitude, theme = 'primary' } = props;

  const { t } = useAppTranslation('common');

  const handleShowOnMap = useCallback(() => {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

    window.open(googleMapsUrl, '_blank');
  }, [latitude, longitude]);

  return (
    <Button theme={theme} onClick={handleShowOnMap} icon={<MapIcon />} iconPosition="prev">
      {t('Show on map')}
    </Button>
  );
});
