import { api } from 'app/config/apiConfig';
import { Contract, UpdateContractParams } from '../model/types';

export const updateContractApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateContract: builder.mutation<Contract, { contractId: string; params: UpdateContractParams }>({
      query: (args) => ({
        url: `/contracts/${args.contractId}`,
        method: 'PUT',
        body: args.params,
      }),
      invalidatesTags: ['Contracts'],
    }),
  }),
});

export const { useUpdateContractMutation } = updateContractApi;
