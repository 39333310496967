/* eslint-disable @typescript-eslint/require-array-sort-compare */
import { Divider } from 'antd';
import dayjs from 'dayjs';
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useGetCurrencySymbol } from 'app/appState';
import { useAppTranslation } from 'app/config/i18Config/hooks';
import { Contract, UnitType, generateContractPdfLink, AutochargeStatus } from 'entities/Contract';
import { Invoice, InvoiceFrequencyType } from 'entities/Invoice';
import { BulletsTable, TableRow } from 'shared/ui/BulletsTable';
import { Button } from 'shared/ui/Button';
import { getClientDateFormat } from 'shared/utils/helpers/getDateFormat';
import { roundNumber } from 'shared/utils/helpers/roundNumber';
import { CloseContract } from 'features/CloseContract';
import { getUserName, useAdminRole } from 'entities/User';
import { NotifyLeaveDate } from 'features/NotifyLeaveDate';
import { useGetOneVatRateQuery } from 'entities/VatRate';
import { calculateDiscountValues } from 'entities/Discount';
import { getBoxRentalPrice } from 'entities/Box';
import { getLocalizedString } from 'shared/utils/helpers/JSONLocalization';
import { AutochargeSettings } from 'features/AutochargeSettings';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'app/config/routerConfig/types';

interface ContractDetailsProps {
  contract: Contract;
  invoices: Invoice[];
  closeContractDetails: () => void;
}

export const ContractDetails: FC<ContractDetailsProps> = memo((props) => {
  const {
    contract: {
      contractItems,
      contractNumber,
      user,
      startDate,
      endDate,
      initialEndDate,
      invoiceFrequencyType,
      contractId,
      leaveDate,
      noticeLeaveDate,
      autocharges,
      deposit,
      warehouse,
      isPrePaymentApplied,
      description,
    },
    closeContractDetails,
  } = props;

  const { t } = useAppTranslation('contracts');

  const navigate = useNavigate();

  const currencySymbol = useGetCurrencySymbol();
  const isAdmin = useAdminRole();

  const boxVatRateParams = {
    accountType: user.accountType,
    unitType: UnitType.BOX,
  };

  const insuranceVatRateParams = {
    accountType: user.accountType,
    unitType: UnitType.INSURANCE,
  };

  const { data: boxVatRate } = useGetOneVatRateQuery({ warehouseId: warehouse.warehouseId, args: boxVatRateParams });
  const { data: insuranceVatRate } = useGetOneVatRateQuery({ warehouseId: warehouse.warehouseId, args: insuranceVatRateParams });

  const boxData = contractItems?.find((item) => item.contractUnitType === 'Box');
  const insuranceData = contractItems?.find((item) => item.contractUnitType === 'Insurance');

  const contractDuration = dayjs(endDate).diff(startDate, invoiceFrequencyType);
  const minRentalPeriod = dayjs(initialEndDate).diff(startDate, invoiceFrequencyType);

  const priceDetailsBullets = useMemo(() => {
    const tableRows: TableRow[] = [];

    const insuranceAmount: Record<InvoiceFrequencyType, number | null> = {
      [InvoiceFrequencyType.DAY]: insuranceData?.insurance?.dailyRate ?? null,
      [InvoiceFrequencyType.WEEK]: insuranceData?.insurance?.weekRate ?? null,
      [InvoiceFrequencyType.MONTH]: insuranceData?.insurance?.monthRate ?? null,
    };

    const insuranceVatPercent = insuranceVatRate?.ratePercent || 0;
    const boxVatPercent = boxVatRate?.ratePercent || 0;
    const insuranceBaseRate = insuranceAmount[invoiceFrequencyType] as number;
    const contractDurationPeriodNumbers = Array.from({ length: contractDuration }, (el, index) => index + 1);
    const rentalPrice = getBoxRentalPrice(boxData?.box, invoiceFrequencyType);

    if (boxData?.box && rentalPrice) {
      const { contractPeriodsUnitPrices } = calculateDiscountValues(
        rentalPrice,
        boxData.box.discounts,
        contractDuration,
        contractDurationPeriodNumbers,
      );

      tableRows.push({
        label: t('Base box rent price'),
        value: `${rentalPrice} ${currencySymbol}/${t(invoiceFrequencyType)}`,
      });

      tableRows.push({
        label: t('Box rent prices', { boxVatPercent }),
        highlighted: true,
        value: '',
      });

      const groupedPrices = contractPeriodsUnitPrices.reduce((acc: Record<number, number[]>, priceobject) => {
        if (acc[priceobject.rate]) {
          acc[priceobject.rate].push(priceobject.periodNumber);
        } else {
          acc[priceobject.rate] = [priceobject.periodNumber];
        }
        return acc;
      }, {});

      Object.entries(groupedPrices).forEach(([key, periodNumbers]) => {
        const currentPeriodUnitPriceWithoutVat = roundNumber(Number(key));

        if (periodNumbers.length === 1) {
          tableRows.push({
            label: t('{{periodNumber}} {{invoiceFrequencyType}} price', {
              periodNumber: periodNumbers[0],
              invoiceFrequencyType: t(invoiceFrequencyType),
            }),
            value: `${currentPeriodUnitPriceWithoutVat} ${currencySymbol}`,
          });
        } else {
          tableRows.push({
            label: t('{{startPeriodNumber}} - {{endPeriodNumber}} {{invoiceFrequencyType}} price', {
              startPeriodNumber: periodNumbers.sort()[0],
              endPeriodNumber: periodNumbers.sort()[periodNumbers.length - 1],
              invoiceFrequencyType: t(`${invoiceFrequencyType}s`),
            }),
            value: `${currentPeriodUnitPriceWithoutVat} ${currencySymbol}`,
          });
        }
      });

      /* tableRows.push({
        label: t('Rental price from {{contractDuration}} {{invoiceFrequencyType}}', {
          contractDuration: contractDuration + 1,
          invoiceFrequencyType: t(invoiceFrequencyType),
        }),
        value: `${rentalPrice} ${currencySymbol}/${t(invoiceFrequencyType)}`,
      }); */
    }

    if (boxVatPercent > 0) {
      tableRows.push({
        label: t('VAT'),
        value: `${boxVatPercent}%`,
      });
    }

    tableRows.push({
      label: t('Insurance rate', { insuranceVatPercent }),
      value: `${roundNumber(insuranceBaseRate)} ${currencySymbol}/${t(invoiceFrequencyType)}`,
    });

    if (insuranceVatPercent > 0) {
      tableRows.push({
        label: t('Insurance VAT'),
        value: `${insuranceVatPercent}%`,
      });
    }

    tableRows.push({
      label: t('Insurance coverage amount'),
      value: insuranceData?.insurance?.coverageAmount ? `${roundNumber(insuranceData.insurance.coverageAmount)} ${currencySymbol}` : null,
      emptyValueSymbol: `0 ${currencySymbol}`,
    });

    if (boxVatPercent === 0 && insuranceVatPercent === 0) {
      tableRows.push({
        label: t('VAT'),
        value: '0%',
      });
    }

    return tableRows;
  }, [
    boxData,
    boxVatRate?.ratePercent,
    contractDuration,
    currencySymbol,
    insuranceData,
    insuranceVatRate?.ratePercent,
    invoiceFrequencyType,
    t,
  ]);

  const discountsBullets = useMemo(() => {
    const bullets = boxData?.box?.discounts.map((discount, index) => {
      return {
        label: `${index + 1}. ${getLocalizedString(discount.description)}`,
        value: '',
      };
    });

    return bullets || [];
  }, [boxData?.box?.discounts]);

  const generalInfoBullets = useMemo(() => {
    let bullets: TableRow[] = [
      { label: t('Rent type'), value: t(`${invoiceFrequencyType} rent`) },
      { label: t('Start Date'), value: getClientDateFormat(startDate) },
    ];

    if (leaveDate) {
      bullets.push({ label: t('Close Date'), value: getClientDateFormat(leaveDate) });
    }

    let autochargesTextColor;

    switch (autocharges) {
      case AutochargeStatus.DISABLED:
        autochargesTextColor = '';
        break;
      case AutochargeStatus.ENABLED:
        autochargesTextColor = 'text-success';
        break;
      case AutochargeStatus.STOPPED:
        autochargesTextColor = 'text-warn';
        break;
      default:
        autochargesTextColor = '';
        break;
    }

    bullets = bullets.concat([
      {
        label: t('Min rental period'),
        value: `${minRentalPeriod} ${t(minRentalPeriod === 1 ? `${invoiceFrequencyType}` : `${invoiceFrequencyType}s`)}`,
      },
      { label: t('Autocharges'), value: <span className={classNames(autochargesTextColor)}>{t(autocharges)}</span> },
      { label: t('Box Number'), value: boxData?.box?.name },
      { label: t('Area'), value: `${boxData?.box?.square} m²` },
    ]);

    return bullets;
  }, [autocharges, boxData?.box?.name, boxData?.box?.square, invoiceFrequencyType, leaveDate, minRentalPeriod, startDate, t]);

  const goToUserProfile = useCallback((): void => {
    closeContractDetails();
    navigate(`${AppRoutes.PROFILE}/${user.userId}`);
  }, [navigate, user, closeContractDetails]);

  return (
    <>
      <div className="font-semibold text-3xl">{t('Contract details')}</div>
      <div className="flex justify-between items-center pt-4">
        <div className="font-semibold text-lg">
          {t('Contract')} #{contractNumber} {leaveDate ? <span className="text-lg text-error">({t('Closed')})</span> : ''}
        </div>

        <a href={generateContractPdfLink(contractId)} target="_blank" rel="noopener noreferrer" className="text-sm font-medium text-accent">
          {t('Download PDF')}
        </a>
      </div>
      {isAdmin && description && (
        <div className="text-normal text-primaryLight py-1">{getLocalizedString(JSON.stringify(description))}</div>
      )}
      {warehouse.bankingRequisits && (
        <div className="text-sm text-primaryLight pt-2">
          {t('Bank requisites: {{bankRequisites}}', { bankRequisites: warehouse.bankingRequisits })}
        </div>
      )}
      <BulletsTable className="pt-8" heading={t('General Information')} theme="clear" rows={generalInfoBullets} />

      {isAdmin && (
        <>
          <BulletsTable
            className="pt-5"
            heading={t('Client Information')}
            theme="clear"
            rows={[
              { label: t('Client'), value: getUserName(user), onClick: goToUserProfile },
              { label: t('Phone'), value: user.phone },
              { label: t('Email'), value: user.email },
              {
                label: `${user.street} ${user.house} ${user.apartment || ''} ${user.city} ${user.zip} ${user.country} ${
                  user.company ? `${user.firstName} ${user.lastName}` : ''
                }`,
                value: '',
              },
            ]}
          />
          <Divider className="border-primary" />
        </>
      )}

      <BulletsTable heading={t('Price details')} labelClassName="max-w-[200px]" theme="clear" rows={priceDetailsBullets} />
      {discountsBullets.length > 0 && (
        <>
          <Divider className="border-primary" />
          <BulletsTable heading={t('Discounts')} theme="clear" rows={discountsBullets} />
        </>
      )}
      <Divider className="border-primary" />
      <BulletsTable
        heading={t('Other')}
        theme="clear"
        rows={[
          {
            label: t('Deposit'),
            value: `${roundNumber(deposit?.depositAmount)} ${currencySymbol}`,
            emptyValueSymbol: `0 ${currencySymbol}`,
          },
          { label: t('Prepayment'), value: t(isPrePaymentApplied ? 'Applied' : 'Not applied') },
        ]}
      />

      {/* {subscriptionId && (
        <>
          <Divider className="border-primary" />
          <ContentLine title={t('Subscription')} description={t('Subscription enabled')} titleClassName="text-accent">
            <Button theme="outlined" size="small">
              {t('Cancel subscription')}
            </Button>
          </ContentLine>
        </>
      )} */}

      {noticeLeaveDate && (
        <>
          <Divider className="border-primary" />
          <BulletsTable
            heading={t('Date of departure')}
            theme="clear"
            rows={[
              {
                label: t('Planned Departure Date'),
                value: getClientDateFormat(noticeLeaveDate),
              },
            ]}
          />
        </>
      )}
      {!leaveDate && (
        <div className="pt-12 space-y-4">
          {[AutochargeStatus.ENABLED, AutochargeStatus.STOPPED].includes(autocharges) && (
            <AutochargeSettings contract={props.contract} action={<Button theme="secondary">{t('Autocharge settings')}</Button>} />
          )}
          <NotifyLeaveDate
            contractId={contractId}
            invoiceFrequencyType={invoiceFrequencyType}
            initialEndDate={initialEndDate}
            noticeLeaveDate={noticeLeaveDate}
            action={<Button theme="secondary">{t(`${noticeLeaveDate ? 'Change end of Rent' : 'Notify about end of Rent'}`)}</Button>}
          />
          {isAdmin && <CloseContract contract={props.contract} action={<Button theme="danger">{t('Close contract')}</Button>} />}
        </div>
      )}
    </>
  );
});
