import React, { FC, memo, ReactNode, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/storeConfig/hooks';
import { StepperModal, stepperModalActions } from 'features/StepperModal';
import { TableFilterContextProvider, useTableFilterContext } from 'features/TableFilter';
import { getOpenedState } from '../model/selectors/getOpenedState';
import { getSteps } from '../model/selectors/getSteps';
import { useWatchStep } from '../utils/hooks/useWatchStep';
import { SummaryStep } from './steps/SummaryStep';
import { SelectBoxStep } from './steps/SelectBoxesStep/SelectBoxesStep';
import { SelectEmailStep } from './steps/SelectEmailStep';
import { SelectWarehouseStep } from './steps/SelectWarehouseStep';
import { sendOfferModalActions } from '../model/slice/sendOfferModalSlice';

export const SendOfferModal: FC = memo(() => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(getOpenedState);
  const steps = useAppSelector(getSteps);

  const { clearAllFilters } = useTableFilterContext();

  useWatchStep();

  const closeModal = useCallback(() => {
    dispatch(sendOfferModalActions.resetModalState());
    dispatch(stepperModalActions.changeActiveStepIndex(0));

    clearAllFilters();
  }, [dispatch, clearAllFilters]);

  const stepsContent: ReactNode[] = [
    <SelectEmailStep key="1" />,
    <SelectWarehouseStep key="2" />,
    <TableFilterContextProvider key="3">
      <SelectBoxStep />
    </TableFilterContextProvider>,
    <SummaryStep key="4" onClose={closeModal} />,
  ];

  return <StepperModal isOpened={isOpened} onClose={closeModal} steps={steps} stepsContent={stepsContent} width={1129} />;
});
