import { AppLanguage } from 'app/config/i18Config/types';
import { Box } from 'entities/Box';
import { Warehouse } from 'entities/Warehouse';
import { StepData, stepsMapper } from 'features/Stepper';

export enum SendOfferSteps {
  SELECT_LEAD = 'Select a Lead',
  SELECT_WAREHOUSE = 'Select a Warehouse',
  SELECT_BOXES = 'Select Boxes',
  SUMMARY = 'Summary',
}

export const sendOfferSteps = stepsMapper([...Object.values(SendOfferSteps)]);

export interface SendOfferModalSchema {
  isOpened: boolean;
  steps: StepData[];
  selectedEmail: Nullable<string>;
  selectedLanguage: Nullable<AppLanguage>;
  selectedWarehouse: Nullable<Warehouse>;
  selectedBoxes: Box[];
  note: Nullable<string>;
}

export enum BoxesListFilterKeys {
  priceFrom = 'priceFrom',
  priceTo = 'priceTo',
  priceType = 'priceType',
  squareFrom = 'squareFrom',
  squareTo = 'squareTo',
  floorId = 'floorId',
}
