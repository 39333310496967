import { api } from 'app/config/apiConfig';
import { Pagination } from 'app/types/common';
import { City } from '../model/types';

interface Params {
  search?: string;
}

export const getPaginatedCitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedCities: build.query<Pagination<City>, Params>({
      query: (params) => ({
        url: '/cities/paginated',
        params,
      }),
      providesTags: ['Cities'],
    }),
  }),
});

export const { useGetPaginatedCitiesQuery } = getPaginatedCitiesApi;
